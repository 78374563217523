import React, { Fragment } from 'react'
import axios from 'axios'
import SimpleModalWrapped from './../common/Modal'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from './../common/Tooltip'
import MenuItem from './../common/MenuItem'
import Grid from '@material-ui/core/Grid'
import { categoriesList, checkPrivileges, errorHandler } from '../../Helpers'
import SingleSelect from '../common/SingleSelect'
import { unitsList } from './unitsPortList'
import { withLocalize } from 'react-localize-redux'
import { timeZonesTypes } from './../Attributes/timezone'
import SearchGroups from './searchItems'
import instance from '../../axios'
import IconButton from '@material-ui/core/IconButton'
import CrossIcons from '@material-ui/icons/Remove'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'

const styles = theme => ({})
//let emptyLink = "javascript:void(0)";
class addDevice extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: true,
      at_modal_visable: false,
      isVisableDeviceBtn: false,
      form: {
        attributes: {}
      },
      open: true,
      isAdvSettingEnable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.searchFromList = this.searchFromList.bind(this)
    this.searchFromZoneList = this.searchFromZoneList.bind(this)
    // this.checkRequiredFields=this.checkRequiredFields.bind(this)
    // this.formSubmit=this.formSubmit.bind(this)
  }

  closeModal () {
    this.setState({
      visible: false
    })
  }

  toggleCollapse () {
    let a = this.state.open
    this.setState({
      open: !a
    })
  }

  addAttributes () {
    this.setState({
      at_modal_visable: !this.state.at_modal_visable
    })
  }

  async searchFromList (inputValue) {
    if (!inputValue) {
      return []
    }

    return await instance({
      method: 'GET',
      url: `/api/devices/models?search=${inputValue}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.data){
        return response.data.map(item => {
          return {
            id: item.name,
            name: item.name,
            key: item.name,
            port: item.port,
            protocol: item.protocol,
            label: item.name,
            value: item.name
          }
        })
      }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    const result = timeZonesTypes.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return await result.map(item => {
      return { id: item.key, key: item.key, label: item.label, value: item.key }
    })
  }

  render () {
    const { classes,unitList } = this.props
    const defaultOptions = []
    unitList.data&&unitList.data.map((item, i) => {
      if (i <= 9) {
        defaultOptions.push({
          id: item.name,
          name: item.name,
          key: item.name,
          port: item.port,
          protocol: item.protocol,
          label: item.name,
          value: item.name
        })
      }
      return null
    })
    if (
      defaultOptions &&
      defaultOptions.length < unitsList.length &&
      unitsList
    ) {
      defaultOptions.push({
        id: '',
        name: 'Search for more items...',
        key: '',
        port: '',
        label: 'Search for more items...',
        value: '',
        isDisabled: true
      })
    }
    const defaultZones = []
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
        defaultZones.push({
          id: item.key,
          key: item.key,
          label: item.label,
          value: item.key
        })
      //}
      return null
    })

    /* defaultZones.push({
      id: '',
      name: 'Search for more items...',
      key: '',
      port: '',
      label: 'Search for more items...',
      value: '',
      isDisabled: true
    }) */
    return (
      <Fragment>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title='sharedRequired'
          formSubmit={this.props.formSubmit}
          notShowAttributesBtn
          notShowGroupBtn={this.props.notShowGroupBtn}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableDeviceBtn}
          btnCancelNotShow={this.props.btnCancelNotShow}
          canUpdate={checkPrivileges(this.props.isHaveAccess)}
          hasPremissions
          HaveGroupCreateAccess={checkPrivileges('groupCreate')}
          elevation={this.props.editOption !== undefined ? 0 : 2}
          showPortList={this.props.showPortList}
          showPort={this.props.showPort}
          content={
            <form autoComplete='off'>
              <div>
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='name'
                      label={this.props.translate('sharedName')}
                      type='text'
                      placeholder=''
                      required
                      value={this.props.form.name || ''}
                      onChange={this.props.handleChange('name')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  {((this.props.editOption) && (this.props.logInUser.attributes&&this.props.logInUser.attributes.viewModel)) ? 
                     checkPrivileges('deviceModel') ? ( 
                      <Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                        <SingleSelect
                          array={[]}
                          async
                          selectName='model'
                          isClearable
                          defaultOptions={defaultOptions}
                          loadOptions={this.searchFromList}
                          label={this.props.translate('deviceModel')}
                          value={
                            typeof this.props.form.model === 'object'
                              ? this.props.form.model
                              : this.props.form.model
                              ? {
                                  id: this.props.form.model,
                                  key: this.props.form.model,
                                  label: this.props.form.model,
                                  value: this.props.form.model
                                }
                              : ''
                          }
                          handleChange={this.props.port_handleChange}
                          canAssign
                        />
                      </Grid>
                      ) : null
                    : (this.props.logInUser.userType ===-1 || this.props.addOption) ?
                    (<Grid item md={3} sm={6} xs={12} style={{position: 'relative', zIndex: 9}}>
                          <SingleSelect
                            array={[]}
                            async
                            selectName='model'
                            isClearable
                            defaultOptions={defaultOptions}
                            loadOptions={this.searchFromList}
                            label={this.props.translate('deviceModel')}
                            value={
                              typeof this.props.form.model === 'object'
                                ? this.props.form.model
                                : this.props.form.model
                                ? {
                                    id: this.props.form.model,
                                    key: this.props.form.model,
                                    label: this.props.form.model,
                                    value: this.props.form.model
                                  }
                                : ''
                            }
                            handleChange={this.props.port_handleChange}
                            canAssign
                          />
                      </Grid>):null
                 } 
                {   /*   identifier Imei */}
                {(this.props.editOption && this.props.logInUser.attributes&&this.props.logInUser.attributes.viewIMEI) ? 
                   (<Grid item md={3} sm={6} xs={12}>
                  {this.props.form.model !== 'FX Tracker' ? (
                    <TextField
                      id='uniqueId'
                      error={this.props.duplicateIdentifier}
                      label={this.props.translate('deviceIdentifier')}
                      InputProps={ this.props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                      // disabled={
                      //   this.props.editOption &&
                      //   this.props.logInUser &&
                      //   this.props.logInUser.userType !== -1
                      // }
                      type='text'
                      placeholder=''
                      value={this.props.form.uniqueId}
                      onChange={this.props.handleChange('uniqueId')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      required
                    />
                  ) : (
                    <Tooltip title={this.props.translate('autoGeneratedId')}>
                      <TextField
                        id='uniqueId'
                        label={this.props.translate('deviceIdentifier')}
                        type='text'
                        placeholder=''
                        value={this.props.form.uniqueId}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                    </Tooltip>
                  )}
                </Grid>)
                 :(this.props.logInUser.userType ===-1 || this.props.addOption) ? 
                 (<Grid item md={3} sm={6} xs={12}>
                  {this.props.form.model !== 'FX Tracker' ? (
                    <TextField
                      id='uniqueId'
                      error={this.props.duplicateIdentifier}
                      label={this.props.translate('deviceIdentifier')}
                      InputProps={ this.props.editOption ? {readOnly: !checkPrivileges('deviceImei')}: ''  }
                      // disabled={
                      //   this.props.editOption &&
                      //   this.props.logInUser &&
                      //   this.props.logInUser.userType !== -1
                      // }
                      type='text'
                      placeholder=''
                      value={this.props.form.uniqueId}
                      onChange={this.props.handleChange('uniqueId')}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      required
                    />
                  ) : (
                    <Tooltip title={this.props.translate('autoGeneratedId')}>
                      <TextField
                        id='uniqueId'
                        label={this.props.translate('deviceIdentifier')}
                        type='text'
                        placeholder=''
                        value={this.props.form.uniqueId}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        required
                      />
                    </Tooltip>
                  )}
                </Grid>)
                 :null}
                  {this.props.addOption && (
                    <Grid item md={3} xs={12}>
                      <p
                        style={{
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                          fontSize: '16',
                          background: this.props.themecolors.themeLightColor,
                          color: this.props.themecolors.themeLightInverse,
                          padding: '12px 20px',
                          marginTop: 0,
                          marginLeft: '25'
                        }}
                      >
                        <span>
                          {this.props.translate(
                            'toConfigureYourDeviceManuallyUseConnectionParameters'
                          )}
                        </span>
                        <span style={{ display: 'block' }}>
                          <label
                            style={{ width: '40%', display: 'inline-block' }}
                          >
                            {this.props.translate('serverIP')}:
                          </label>
                          <span>{this.props.serverIp}</span>
                        </span>
                        <span style={{ display: 'block' }}>
                          <label
                            style={{ width: '40%', display: 'inline-block' }}
                          >
                            {this.props.translate('Port')}:
                          </label>
                          <span>{this.props.form.device}</span>
                        </span>
                      </p>
                    </Grid>
                  )}
                </Grid>
              </div>

              <h4 style={{ margin: '10px 0' }} className='with-border pull-left v-tab-heading'>
                {this.props.translate('sharedExtra')}
              </h4>
              <Grid container spacing={1}>
                <Grid item md={3} xs={12}>
                  <TextField
                    id='phone'
                    label={this.props.translate('sharedPhone')}
                    type='text'
                    placeholder=''
                    value={this.props.form.phone || ''}
                    onChange={this.props.handleChange('phone')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* <---------- temprary comment --------------> */}
                {/* <Grid
                  item
                  xl={3}
                  md={3}
                  xs={12}
                  style={{ position: 'relative', zIndex: 3 }}
                >
                  <SearchGroups
                    isMulti={false}
                    api='groups'
                    name='groupId'
                    onChange={this.props.handleChange2}
                    value={
                      this.props.form.groupId
                        ? {
                            value: this.props.form.groupId,
                            label: this.props.group.find(
                              a => this.props.form.groupId === a.id
                            )
                              ? this.props.group.find(
                                  a => this.props.form.groupId === a.id
                                )['name']
                              : ''
                          }
                        : null
                    }
                    placeholder={this.props.translate('searchGroup')}
                  />
                </Grid> */}
                <Grid item md={3} xs={12}>
                  <TextField
                    id='contact'
                    label={this.props.translate('deviceContact')}
                    placeholder=''
                    value={this.props.form.contact || ''}
                    onChange={this.props.handleChange('contact')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {this.props.addOption && (
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='category'
                      select
                      label={this.props.translate('deviceCategory')}
                      value={this.props.form.category || ''}
                      onChange={this.props.handleChange('category')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      margin='dense'
                      fullWidth
                    >
                      <MenuItem value={0}>
                        <em>None</em>
                      </MenuItem>
                      {this.props.categoriesList.map(option => (
                        <MenuItem key={option} value={option}>
                          {this.props.translate(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>

              <h4 style={{ margin: '10px 0' }} className='with-border pull-left v-tab-heading'>
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container spacing={1}>
                <Grid item md={3} xs={12}>
                  <TextField
                    id='attributeDevicePassword'
                    label={this.props.translate('attributeDevicePassword')}
                    placeholder=''
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes.devicePassword
                        ? this.props.form.attributes.devicePassword
                        : ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('devicePassword', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName='decoder.timezone'
                    isClearable={false}
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate('sharedTimezone')}
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes['decoder.timezone']
                        ? timeZonesTypes.find(
                            t =>
                              t.key ===
                              this.props.form.attributes['decoder.timezone']
                          )
                        : ''
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      margin='dense'
                      label={this.props.translate('installDate')}
                      variant='outlined'
                      fullWidth
                      value={
                        this.props.form.attributes?.installDate
                          ? new Date(
                              `${this.props.form.attributes.installDate}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChangeLicenseExpDate('installDate')}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={3} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      margin='dense'
                      label={this.props.translate('expireDate')}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form?.expirationTime
                          ? new Date(
                              `${this.props.form?.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChangeLicenseExpDate('expirationTime')}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                    </MuiPickersUtilsProvider>
                  </Grid>
                {/* <Grid item md={3} xs={12}>
                  <TextField
                    id='attributeSpeedLimit'
                    label={
                      this.props.translate('attributeSpeedLimit') + '(Km/h)'
                    }
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.speedLimit) ||
                      ''
                    }
                    onChange={e => this.props.changeAttribute2('speedLimit', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
              </Grid>
              <h4 style={{ margin: '10px 0' }} className='with-border pull-left v-tab-heading'>
                    {this.props.translate('tags')}
                  </h4>
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='tag_1'
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.ut1
                          ? this.props.logInUser.attributes.ut1
                          : this.props.translate('tag_1')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_1 !== undefined
                          ? this.props.form.tag_1
                          : this.props.form.attributes.tag_1) || ''
                      }
                      onChange={e => this.props.changeAttribute2('tag_1', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='tag_2'
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.ut2
                          ? this.props.logInUser.attributes.ut2
                          : this.props.translate('tag_2')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_2 !== undefined
                          ? this.props.form.tag_2
                          : this.props.form.attributes.tag_2) || ''
                      }
                      onChange={e => this.props.changeAttribute2('tag_2', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='tag_3'
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.ut3
                          ? this.props.logInUser.attributes.ut3
                          : this.props.translate('tag_3')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_3 !== undefined
                          ? this.props.form.tag_3
                          : this.props.form.attributes.tag_3) || ''
                      }
                      onChange={e => this.props.changeAttribute2('tag_3', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='tag_4'
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.ut4
                          ? this.props.logInUser.attributes.ut4
                          : this.props.translate('tag_4')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_4 !== undefined
                          ? this.props.form.tag_4
                          : this.props.form.attributes.tag_4) || ''
                      }
                      onChange={e => this.props.changeAttribute2('tag_4', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      id='tag_5'
                      label={
                        this.props.logInUser.attributes &&
                        this.props.logInUser.attributes.ut5
                          ? this.props.logInUser.attributes.ut5
                          : this.props.translate('tag_5')
                      }
                      type='text'
                      placeholder=''
                      value={
                        (this.props.form.tag_5 !== undefined
                          ? this.props.form.tag_5
                          : this.props.form.attributes.tag_5) || ''
                      }
                      onChange={e => this.props.changeAttribute2('tag_5', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>
            </form>
          }
        />
      </Fragment>
    )
  }
}

const AddDevice = withStyles(styles)
export default AddDevice(withLocalize(addDevice))
