import React from 'react'
import Table from '../../Components/common/tableWithBackEndPagination'
import Button from '../../Components/common/Button'
import { checkPrivileges } from '../../Helpers'
import Loader from '../../Layout/Loader'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu';
import { Grid } from '@material-ui/core'

export const MaintenanceComp = ({
    data,
    translate,
    setPagination,
    isDataRecieved,
    // histFormVisible,
    openCreateFrom,
    imagePopup,
    handleChangeRowsPerPage,
    handleChangePage,
    SearchItem,
    SearchVehicle,
    anchorEl,
    onRemove,
    mode,
    vehicles,
    themecolors,
    editHistoryFrom,
    deviceRelatedData,
    dueActive,
    createExpense,
    logInUser,
    expenseForm,
    searchVehicleId,
    ServerSettings,
    downloadWorkOrder,
    selectedAreaId,
    filterStatusType,
    selectedVehicleId,
    from,
    to,
    createdBy,
    selectedAccount
  }) => {


    return (
      <div>
     {
    //  !histFormVisible &&(
     <Grid container style={{ height: '100%' }}>
        <Grid item sm={12} xs={12}>
            {isDataRecieved ?(<Table
             ServerSettings={ServerSettings}
              rows={data && data.data || []}
              pagination={setPagination(data)}
              handleChangeRowsPerPage={(n)=>handleChangeRowsPerPage(n,'maintenanceHist')}
              handleChangePage={(n)=>handleChangePage(n,'maintenanceHist')}
              SearchItem={(n)=>SearchItem(n,'maintenanceHist')}
            //   SearchVehicle={SearchVehicle}
              onEdit={(n)=>editHistoryFrom(n)}
              imagePopup={imagePopup}
              vehicles={vehicles}
              expenseForm={expenseForm}
              downloadWorkOrder={downloadWorkOrder}
            //   searchVehicleId={searchVehicleId}
              // showInvoice={mode ==='history'?true:false} only for ntsc
            //   createButton={
            //     checkPrivileges('serviceCreate') && (
            //       <div style={{ marginLeft: 10 }}>
            //         <Button onClick={createExpense}>
            //           {translate('sharedCreate')}
            //         </Button>
            //       </div>
            //     )
            //   }
              onDelete={(n)=>onRemove(n)}
              // hasAccessOfDelete={checkPrivileges('serviceDelete')}
              hasAccessOfDelete={false}
              logInUser={logInUser}
              // isDownload={
              //   (<ExportImportMenu
              //     fileName = 'services'
              //     checkPrivilege = 'serviceCreate'
              //     isMaintenance
              //     data = {data && data.data || []}
              //     themecolors = {themecolors}
              //     createdBy={createdBy}
              //     selectedAccount={selectedAccount}
              //     selectedAreaId={selectedAreaId}
              //     filterStatusType={filterStatusType}
              //     selectedVehicleId={selectedVehicleId}
              //     from={from}
              //     to={to}
              //   />)}
              servicesEstimationTable
              isEditable={true}
              deviceRelatedData={deviceRelatedData}
            //   completeIcon={mode ==='due'? true : false}
              themecolors={themecolors}
              translate={translate}
            //   searchable
            //   isSearchVehicle
              // hasAccessOfUpdate={checkPrivileges('serviceUpdate')}
              hasAccessOfUpdate={false}
              // hasAccessOfDelete={checkPrivileges('userDelete')}----
              // title={translate('sharedMaintenance')}
              rowDefinition={[
                  {
                    id: 'id',
                    numeric: false,
                    disablePadding: false,
                    label: translate('serviceId')
                  },
                  {
                    id: 'creationMode',
                    numeric: false,
                    disablePadding: false,
                    label: translate('createdBy')
                  },
                  {
                    id: 'vehicleLicensePlate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('sharedVehicle')
                  },
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: translate('serviceMaintenance')
                  },
                  {
                    id: 'status',//attribute.status
                    numeric: false,
                    disablePadding: false,
                    label: translate('status'),
                    hide: mode==='due'? false : true
                  },
                  {
                    id: 'odometer',//'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('totalDistance')
                  },
                  // {
                  //   id: 'engineHours',//'attributes.cDate',
                  //   numeric: false,
                  //   disablePadding: false,
                  //   label: translate('reportEngineHours')
                  // },
                  {
                    id: 'serviceTime',//'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate('date')
                  },
                  {
                    id: 'files',
                    numeric: false,
                    disablePadding: false,
                    label: translate('sharedFile'),
                    hide: mode==='due'? true : false
                  },
                  // {
                  //   id: 'viewInvoice',
                  //   numeric: false,
                  //   disablePadding: false,
                  //   label: translate('invoice'),
                  //   hide: mode==='due'? true : false
                  // },
                  {
                    id: 'saleNetAmount',//attribute.cost
                    numeric: false,
                    disablePadding: false,
                    label: translate('totalWithVat')
                  }
                ]
            }
              />):<Loader/>}
              {/* {!isDataRecieved && <Loader/>} */}
        </Grid>
      </Grid>
      // )
      }
      </div>
    )
  }