import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import VehicleTagSelector from './../../Layout/Sidebar/Components/VehicleFilterModal'
import DriverTagSelector from './../../Layout/Sidebar/Components/DriverFilterModal'
import DrawMap from './../../Components/Maps/DrawMap'
import { checkPrivileges, errorHandler } from '../../Helpers'
import {
  unsetDeviceId,
  resetFilters,
  prepareDriverTags,
  resetGroupBy
} from '../../Actions/Devices'
import { getGroups, saveGroupWiseDevices } from '../../Actions/Groups'
import { connect } from 'react-redux'
import './Style.scss'
import axios from 'axios'
import Notifications from 'react-notification-system-redux'
import TrackingBox from '../../Components/common/TrackingBox'
import ResourceModal from '../../Components/Recources/resourceModal'
import withResources from '../HOCRecources'
import instance from '../../axios'

const options = {
  width: 250,
  margin: 10
}

const maxBoxes = 20;

const CancelToken = axios.CancelToken
let source
class Monitoring extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',

      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      boxes: []
    }
    this.map = null
  }

  showResources = (type) => {
    if(!type) {
      this.props.dispatch(saveGroupWiseDevices([]))
      this.props.dispatch(resetFilters())
    }
    this.setState({
      resourceList: type
    })
  }

  showBox = ({id, name}) => {
    const boxes1 = this.state.boxes.filter(box => box.id !== id);
    const boxes = boxes1.filter((e, index) => (index + 1) < maxBoxes);
    
    this.setState({ boxes: [...boxes, { id, name, ...options }] });
  }

  closeBox = (id, event) => {
    event.stopPropagation()
    this.setState({boxes: this.state.boxes.filter(box => box.id !== id)});
  } 

  componentWillMount () {
    this.props.dispatch(saveGroupWiseDevices([]))
    this.props.dispatch(resetFilters())
    if (this.props.logInUser && this.props.logInUser.id) {
      this.fetchData2(
        this.props.logInUser.id,
        this.state.currentPage2,
        this.state.pageSize2
      )
    }
  }

  setMapRef = ref => {
    this.map = ref
  }

  displayError = e => {
    if (e) {
      // e.text().then(err => {
      //   console.log(err)
      // })
      console.log(e)
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData2(
            n.logInUser.id,
            this.state.currentPage2,
            this.state.pageSize2
          )
        }
      )
    }
  }
  openDeviceSettings = deviceId => {
    this.props.history.push('/units/' + deviceId)
  }

  fetchMoreItems2 = (a, b, c) => {
    this.setState(
      {
        searchText2: ''
      },
      () => {
        this.fetchData2(
          this.props.logInUser.id,
          this.state.currentPage2,
          this.state.pageSize2,
          true
        )
      }
    )
  }

  removeDuplicates = (n, o) => {
    let items = []
    o.map(j => {
      let found = n.data.find(e => e.id === j.id)
      if (found) {
        items.push(found)
      }
      return null
    })

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            isFetching: false,
            items: o.concat(n.data)
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page
        },
        () => {
          this.props.dispatch(getGroups(o.concat(n.data)))
        }
      )
    }
  }

  fetchData2 = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText2

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = this.state.itemPagination2.items
    if (reset) {
      this.setState(
        {
          itemPagination2: {
            ...this.state.itemPagination2,
            isFetching: true,
            items: []
          }
        },
        () => {
          items = []
        }
      )
    }
    source = CancelToken.source()
    instance({
      method: 'GET',
      url: `/api/groups/get?page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      // if (response.status === 200) {
        this.removeDuplicates(response, items)
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  searchItems2 = text => {
    this.setState(
      {
        searchText2: text
      },
      () => {
        this.fetchData2(this.props.logInUser.id, 1, this.state.pageSize2, true)
      }
    )
  }

  componentWillUnmount () {
    this.props.dispatch(unsetDeviceId(0))
    this.props.dispatch(resetFilters())
    this.props.dispatch(saveGroupWiseDevices([]))
    if (source) {
      source.cancel()
    }
    this.setState({
      userInfoFront: {},
      userFetch: false,
      userId: 0,
      initFetch: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',

      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      }
    })
  }

  copyToClipboard = obj => {
    const el = document.createElement('textarea')
    el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

  openVehicleTagsModal = () => {
    this.setState({
      vehicleModal: true
    })
  }

  openDriverTagsModal = () => {
    this.setState({
      driverModal: true
    })
  }

  cancelFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false
    })
  }

  saveFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false
    })
  }

  onLinkResource = (item) =>{
    this.setState({
      linkResource: true,
      selecteditem: item,
    },()=>{this.props.fetchNestedItems(item.id,1)})
  }
  onCloseResource = () =>{
    this.setState({
      linkResource: false,
      selecteditem: ''
    });
  }

  render () {
    if (checkPrivileges('device')) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            -- {this.props.translate('end')} --
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {this.props.translate('notFound')}
          </p>
        )
      const {devices3, ...resetProps}  = this.props;
      return (
        <Layout
          {...resetProps}
          monitoringMap={this.map}
          openDeviceSettings={this.openDeviceSettings}
          classFromChildren='main-view'
          //showDeviceModal={this.state.showDeviceModal}
          fetchMoreItems={this.fetchMoreItems}
          showBox={this.showBox}
          openVehicleTagsModal={this.openVehicleTagsModal}
          openDriverTagsModal={this.openDriverTagsModal}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          searchItems2={this.searchItems2}
          resourceList={this.state.resourceList}
          showResources={this.showResources}
          onLinkResource={this.onLinkResource}
          copyToClipboard={this.copyToClipboard}
        >
          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            onCloseResource={this.onCloseResource}
            itemPagination={devices3}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            fetchNestedItems={this.props.fetchNestedItems}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            linkResource={this.state.linkResource}
            themecolors={this.props.themecolors}
            itemType='Device'
            title='device'
          />
          <TrackingBox boxes={this.state.boxes} translate={this.props.translate} logInUser={this.props.logInUser} copyToClipboard={this.copyToClipboard} closeBox={this.closeBox} />
          <DrawMap
            {...resetProps}
            setMapRef={this.setMapRef}
            devices={this.props.devices.data}
            copyToClipboard={this.copyToClipboard}
          />

          {this.state.vehicleModal ? (
            <VehicleTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.tags}
              vehicleModal={this.state.vehicleModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
          {this.state.driverModal ? (
            <DriverTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.driverTags}
              driverModal={this.state.driverModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
        </Layout>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    devicesFetched: state.devices.isFetching,
    devices: state.devices,
    devices3: state.devices3,
    trailers: state.trailers,
    tags: state.tags,
    driverTags: state.driverTags,
    allNotifications: state.allNotifications,
    deviceId: state.deviceId,
    trackId: state.trackId,
    drivers: state.drivers,
    ServerSetting: state.ServerSetting,
    mapLayer: state.mapLayer,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    filterTagsList: state.filterTagsList,
    filterDriversList: state.filterDriversList,
    filterList:
      state.filterList && state.filterList.filters
        ? state.filterList.filters
        : []
  }
}

export default connect(mapStateToProps)(withLocalize(withResources(Monitoring, 'Device')))
