import React, { Component } from 'react'
import MenuItem from '../common/MenuItem'
import { checkPrivileges} from '../../Helpers';
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
import TextField from './TextField';
import { Grid} from '@material-ui/core';
import Loader from '../../Layout/Loader';
import VehicleSelector from '../Devices/VehicleSelector'
import AreaSelector from './AreaSelector';
import Scrollbar from 'react-scrollbars-custom'

// let authorizedArray = [
//   { id: 0, name: "All" },
//   { id: 1, name: "Valid" },
//   { id: 2, name: "Invalid" },
// ];

// let unAuthorizedArray = [
//   { id: 1, name: "Valid" },
// ];

export default  class MaintenanceFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openFilterModal:false,
      from: '',
      to: '',
      selectedDate: '',
      vehicleId:[],
      areaId:[],
      loader:false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId:[],
      selectedAreaId:[],
      steps: [{id: 1, title: 'selectServiceTimeControl'}],
      // , {id: 2, title: 'selectVehicles'},{id:3, title:'selectAreaIds'}
      activeStep: 1,
      reportType:0,
      isSubmitBtn:false,
      invoiceStatus:1,
    }
  }


  // onCloseDueMaintenanceModel = () =>{
  //   this.setState({
  //     openFilterModal:false,
  //     to:'',
  //     from:'',
  //     isSubmitBtn:false,
  //     selectedDate:'',
  //     from: '',
  //     to: '',
  //     vehicleId:[],
  //     areaId:[],
  //     countChecked: 0,
  //     anchorEl: null,
  //     activeStep:0,
  //   })
  // }

  showDates = (e, date) => {
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  // handleChange =(name, e)=>{
  //     if(e ==='area'){
  //         this.setState(
  //           {
  //             areaId: name
  //           })
  //           this.setState({areaId: name },()=>{  
  //             let filter = '';
  //               Object.entries(this.state.areaId).map(([key,value])=>{
  //                 if(value){
  //                   filter += '&areaId=' + value
  //                   this.setState({
  //                   })
  //                 }
  //               })
  //               this.setState({
  //                 selectedAreaId: filter
  //               })
  //           })
  //     }
  //     else{
  //       this.setState(
  //         {
  //             vehicleId: name
  //         })
  //         this.setState({vehicleId: name },()=>{  
  //           let filter = '';
  //             Object.entries(this.state.vehicleId).map(([key,value])=>{
  //               if(value){
  //                 filter += '&vehicleId=' + value
  //                 this.setState({
  //                 })
  //               }
  //             })
  //             this.setState({
  //               selectedvehicleId: filter
  //             })
  //         })
  //     }
  // }

  goToNextStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1){ 
      if(this.state.to && this.state.from){
        validated = true
        errorMsg= null
        this.setState({
          openFilterModal:false,
        }, ()=>{
          this.props.formSubmit(this.state,type)
          // this.onCloseDueMaintenanceModel()
      })
      }
      else {
        validated = false
        errorMsg= <span className="text-warning">{this.props.translate('Please select Date and Maintenance Status from above')}</span>
      }
    // }
    // else{
    //   this.validatedCurrentStep(step, type, index);
    // }
    if(validated === true) {
      // this.setState({activeStep: index + 1}, ()=>{
      //   if(this.state.activeStep===2){
          this.setState({isSubmitBtn:true,})
        // }})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else {
        steps.push(s)
      }
      
      return null
    })

    this.setState({steps})

    
  }
  // goToBackStep = () => {
  //   const currentStep = this.state.activeStep;
  //     this.setState({activeStep: currentStep-1}) 
  // }

  // validatedCurrentStep = (step, type, index) => {
  //   const steps = [];
  //   let validated = false;
  //   let errorMsg = null;
  //   if(step === 1) {
  //     if(this.state.to && this.state.from){
  //         validated = true
  //         errorMsg= null
  //       }
  //       else {
  //         validated = false
  //         errorMsg= <span className="text-warning">Please select 'Date and Maintenance Status' from above</span>
  //       }
  //   }
    // else if(step === 2) {
    //       validated = true
    // }

    // if(validated === true) {
    //   this.setState({activeStep: index + 1}, ()=>{
    //     if(this.state.activeStep===2){
    //       this.setState({isSubmitBtn:true})
    //     }})
    // }

    // this.state.steps.map(s => {
    //   if(s.id === step) {
    //     steps.push({...s, errorMsg})
    //   }
    //   else steps.push(s)
      
    //   return null
    // })

    // this.setState({steps})

  }

  handleChangeStatus = (event) => {
    this.setState({
      invoiceStatus: event.target.value
    });
  }

  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ this.props.timeError || false}
            selectedDate={this.state.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            onEvent={this.showDates}
          />
        </Grid>
      </Grid>)
          default:
            return null
        }
    // case 2: 
    // return (<Grid container className='form-group'>
    //   <Grid item  xs={12}>
    //       <VehicleSelector
    //         api='vehicle'
    //         fill
    //         hideIcons
    //         isClearable
    //         placeholder={this.props.translate('searchVehicle')}
    //         onChange={this.handleChange}
    //         value={this.state?.vehicleId||[]}
    //       />
    //   </Grid>
    // </Grid>)
    // case 3: 
    //   return (<Grid container className='form-group'>
    //    <Grid item  xs={12}>
    //         <AreaSelector
    //             api='area'
    //             fill
    //             hideIcons
    //             isClearable
    //             placeholder={this.props.translate('searchArea')}
    //             onChange={this.handleChange}
    //             value={this.state?.areaId||[]}
    //           /></Grid>

    // {this.props.deliveryNote && ( 
    //       <Grid item xs={12}>
    //           <TextField
    //             id='invoiceStatus'
    //             label={this.props.translate('invoiceStatus')}
    //             type='text'
    //             value={
    //               this.state.invoiceStatus || 0
    //             }
    //             onChange={this.handleChangeStatus}
    //             variant='outlined'
    //             margin='dense'
    //             fullWidth
    //             select
    //           >
    //           {checkPrivileges("deliverynoteInvalidView") 
    //               ? authorizedArray.map((option) => (
    //                   <MenuItem key={option.id} value={option.id}>
    //                     {this.props.translate(option.name)}
    //                   </MenuItem>
    //                 ))
    //               : unAuthorizedArray.map((option) => (
    //                   <MenuItem key={option.id} value={option.id}>
    //                     {this.props.translate(option.name)}
    //                   </MenuItem>
    //                 ))}
    //             </TextField>
    //         </Grid>
    //     )}
    // </Grid>)
  }
  

  render () {
  
    const viewScreen = (
      <form style={{ padding: "10px 0" }}>
        {<>
            <Stepper 
                translate={this.props.translate}
                activeStepInvo={this.state.activeStep} 
                reportType={this.state.reportType} 
                getStepContent={this.getStepContent} 
                steps={this.state.steps}
                handleNext={this.goToNextStep} 
                handleBack={this.goToBackStep} 
                isSubmitBtn={this.state.isSubmitBtn}
                downloadInvoice={e=>this.props.downloadInvoice(this.state,e)}  
                invoices
                btnDisable={this.state.selectedDate}
                />
          </>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {
          !this.props.loader ?(
            <Scrollbar>
            {viewScreen}
          </Scrollbar>
          ):<Loader/>
        }
      </div>
  )
  }
}