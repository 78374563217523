import React, { Component } from 'react'
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from './MenuItem'
import Checkbox from './Checkbox'
import { errorHandler } from '../../Helpers';
import CustomDialog from './Dialog';
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
import TextField from './TextField';
import { Grid, FormControlLabel } from '@material-ui/core';
import Loader from '../../Layout/Loader';
import Button from './Button';
import VehicleSelector from '../Devices/VehicleSelector'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '../Menu/CommonMenu'
import AreaSelector from './AreaSelector';
import Scrollbar from 'react-scrollbars-custom'


let contractTypes = [
  { id: 0, name: "All" },
  { id: 1, name: "Over Due" },
  { id: 2, name: "Due" },
];
export default  class MissedOpportunityFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openFilterModal:false,
      from: '',
      to: '',
      selectedDate: '',
      loader:false,
      countChecked: 0,
      anchorEl: null,
      
      // steps: [{id: 1, title: 'selectServiceTimeControl'}, {id: 2, title: 'selectStatus'}],
      steps: [{id: 1, title: 'selectServiceTimeControl'}],
      activeStep: 0,
      reportType:0,
      isSubmitBtn:false,
      status:""
    }
  }


  onCloseDueMaintenanceModel = () =>{
    this.setState({
      openFilterModal:false,
      to:'',
      from:'',
      isSubmitBtn:false,
      selectedDate:'',
      countChecked: 0,
      anchorEl: null,
      activeStep:0,
    })
  }

  
  showDates = (e, date) => {
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      isSubmitBtn:true, 
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  

  goToNextStep = (step, type, index) => {
    if(step === 1){ 
        this.setState({
          openFilterModal:false,
        }, ()=>{
          // this.props.formSubmit(this.state,type)
          // this.onCloseDueMaintenanceModel()
          this.props.formSubmit(this.state,type);
      })
    }
    else{
      this.validatedCurrentStep(step, type, index);
    }
  }


  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      if(this.state.to && this.state.from){
          validated = true
          errorMsg= null
        }
        else {
          validated = false
          errorMsg= <span className="text-warning">{this.props.translate('Please select Date and Maintenance Status from above')}</span>
        }
    }
    // else if(step === 2) {
    //   validated = true
    // }

    // if(validated === true) {
    //   this.setState({activeStep: index + 1}, ()=>{
    //     if(this.state.activeStep===2){
    //       this.setState({isSubmitBtn:true},
    //         ()=>this.props.formSubmit(this.state,type)
            
    //         )
    //     }
    //   })
    // }

    if(validated === true) {
      // this.setState({activeStep: index + 1}, ()=>{
        if(this.state.activeStep===1){
          this.setState({isSubmitBtn:true})
        }
      // })
    }

    

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

    this.setState({steps})

  }

  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }
  handleChange = (name, e) => {
    // console.log("nam====",name,e.target.value);
    if(name==="status"){
      this.setState({
        [name]:e.target.value,
      });
    }
    else{
      this.setState({
           contractNumber : e
      })
    }
  
  };
  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ this.props.timeError || false}
            selectedDate={this.state.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            tab={this.props.selected}

            onEvent={this.showDates}
            futureDates={this.props.selected==="potentialOpportunity"?true:false}

          />
        </Grid>
      </Grid>)
    // case 2: 
    // return (<Grid container className='form-group'>
    //      <Grid item xs={12} style={{ padding: 10 }}>
    //           <TextField
    //           id="status"
    //           select
    //           label={this.props.translate("status")}
    //           value={this.state.status || 0}
    //           onChange={(e) => this.handleChange("status", e)}
    //           SelectProps={{
    //             MenuProps: {
    //               // className: classes.menu,
    //             },
    //           }}
    //           margin="dense"
    //           fullWidth
    //           // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
    //           // required
    //         >
    //           {contractTypes.map((option) => (
    //             <MenuItem key={option.id} value={option.id}>
    //               {this.props.translate(option.name)}
    //             </MenuItem>
    //           ))}
    //         </TextField>
    //           </Grid>
    // </Grid>)
    // case 3: 
    //   return (<Grid container className='form-group'>
    //    <Grid item  xs={12}>
    //         <AreaSelector
    //             api='area'
    //             fill
    //             hideIcons
    //             isClearable
    //             placeholder={this.props.translate('searchArea')}
    //             onChange={this.handleChange}
    //             value={this.state?.areaId||[]}
    //           /></Grid>
    // </Grid>)
    default:
        return null
    }
  }
  

  render () {

    const viewScreen = (
      <form style={{ padding: "10px 0" }}>
        {<>
            <Stepper 
                translate={this.props.translate}
                activeStep={this.state.activeStep} 
                reportType={this.state.reportType} 
                getStepContent={this.getStepContent} 
                steps={this.state.steps}
                handleNext={this.goToNextStep} 
                handleBack={this.goToBackStep} 
                isSubmitBtn={this.state.isSubmitBtn}
                // downloadInvoice={e=>this.props.downloadInvoice(this.state,e)}  
                missedOpportunity
                />
          </>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {
          !this.props.loader ?(
            <Scrollbar>
            {viewScreen}
          </Scrollbar>
          ):<Loader/>
        }
      </div>
  )
  }
}