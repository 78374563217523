import React, { PureComponent } from 'react'
import Menu from '../Menu/CommonMenu'
import MenuItem from '../common/MenuItem'
import { Tooltip } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/KeyboardArrowDown';

class ItemGroupMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render () {
    const { anchorEl } = this.state
    const empty = null
    return <div>
          <a
            href={empty}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
             <MoreVertIcon  style={{
              borderRadius: 20,
              backgroundColor: this.props.themecolors.themeLightColor,
              color: this.props.themecolors.themeLightInverse,
              width:20, display: 'block', height:20}} />
            
          </a>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
              <Tooltip title="{this.props.translate('uploadExcel')}">
                <MenuItem
                  style={{ minWidth: 'inherit' }}
                  component="label"
                  onClick={() => this.props.showResources(false)}
                >
                  {this.props.individualTitle}
                </MenuItem>
              </Tooltip>
              <Tooltip title="{this.props.translate('uploadExcel')}">
                <MenuItem
                  style={{ minWidth: 'inherit' }}
                  component="label"
                  onClick={() => this.props.showResources(true)}
                  >
                  {this.props.groupTitle}
                </MenuItem>
              </Tooltip>
          </Menu>
        </div>
  }
}
export default ItemGroupMenu
