import React, { Component } from "react";
import Dialog from "../../Components/common/Dialog"
import { Translate } from "react-localize-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "../../Components/common/TextField";
import Button from '../../Components/common/Button'
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    background: "blue",
    marginBottom: "10px",
  },
  root2: {
    background: "grey",
    marginBottom: "10px",
  },
  whiteColor: {
    color: "white",
  },
});

class SupportModal extends Component {
  constructor() {
    super();
    this.state = {

    };
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Dialog
          onClose={this.props.onCloseSubModal}
          maxWidth='sm'
          title={this.props.translate('contactSupport')}
          isVisableBtn
          disableCloseBtn
          headerActions={<Button disabled={this.props.disabledUserBtn} onClick={this.props.formSubmit}>{this.props.translate('submit')}</Button>}
          >
            <form autoComplete="off" style={{ padding: '10px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="subject"
                        label={<Translate id="Subject" />}
                        required
                        placeholder=""
                        value={this.props.form?.subject}
                        onChange={(e) => this.props.handleChange("subject", e)}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '-12px' }}>
                    <TextField
                        id="message"
                        multiline
                        label={<Translate id="message" />}
                        required
                        placeholder=""
                        value={this.props.form?.message}
                        onChange={(e) => this.props.handleChange("message", e)}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                </Grid>
              </Grid>
              {this.props.supportStatus ? (
                <ul className="process-list">
                  <li>{this.props?.supportStatus || ""}</li>
                </ul>
              ) : null}
            </form>
        </Dialog>
      </div>
    );
  }
}
const supportModal = withStyles(styles)(SupportModal);

export default supportModal;
