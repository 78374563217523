import React, { Component, Fragment } from 'react'
import { EditForm } from './../../../Components/Geofence/editForm'
import Button from './../../../Components/common/Button'
import SearchField from './../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import { toggleAllGeofences, geoFenceVisible, deleteGeofence } from './../../../Actions/Devices'
import isEqual from 'react-fast-compare'
import Icon from '@material-ui/core/Icon'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import { CheckIcon } from '../../../Components/common/CheckIcon'
import Checkbox from '../../../Components/common/Checkbox'
import Notifications from 'react-notification-system-redux'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import Loader from '../../Loader'
import RecourcesList from './RecourcesList'
import instance from '../../../axios'
import ItemGroupMenu from '../../../Components/Maps/ItemGroupMenu'
class Geofences extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
    this.addShape = this.addShape.bind(this)
  }

  search (e) {
    if(this.props.resourceList) {
      this.props.searchNestedResources(this.props.loadingItem, e.target.value)
    }
    else {
      this.props.searchItems(e.target.value)
    }
  }

  addShape (type) {
    let element
    if (type === 'circle') {
      element = document.getElementById('addCircle')
    } else if (type === 'polygon') {
      element = document.getElementById('addPolygon')
    } else if (type === 'polyline') {
      element = document.getElementById('addPolyline')
    }

    if (element) {
      element.click()
    }
    this.props.collapseSidebar()
  }

  toggleGeofences = e => {
    this.props.toggleGeofences(e.target.checked);
    this.props.dispatch(toggleAllGeofences(e.target.checked));
  }

  render () {
    const { geofenceData, geoFence, openGeofenceModal } = this.props
    const geo = this.props.itemPagination.items.map(g => (
      <ListRow
        key={g.id}
        item={g}
        onDelete={this.props.onDelete}
        {...geoFence}
        {...geofenceData}
        openGeofenceModal={openGeofenceModal}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <aside className='fms-sidebar'>
        <div className='fms-sidebar-monitoring'>
        {/* {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
          <li className='filter-nav'>
            <a
              className={
                'filter-nav-link ' +
                (!this.props.resourceList ? 'active' : '')
              }
              href={null}
              onClick={e => this.props.showResources(false)}
            >
              {this.props.translate('sharedGeofences')}
            </a>
            <a
              className={
                'filter-nav-link ' +
                (this.props.resourceList ? 'active' : '')
              }
              href={null}
              onClick={e => this.props.showResources(true)}
            >
              {this.props.translate('groups')}
            </a>
          </li>
        </ul> : null} */}



        <div className='sidebar-bg-layer'>
          {checkPrivileges('geofence') && (
            <div>
              <div
                  className='section-head section-head-filter'
                  style={{ padding: 0, paddingRight: 5, paddingLeft: 5, paddingLeft: 10 }}
                >
                  <ul className='filter-row'>
                    <li style={{ flex: 1 }} className="sidebar-icon-gap">
                        <SearchField
                          label={this.props.translate('searchGeofence')}
                          fullWidth
                          onChange={e => this.search(e)}
                        />
                      </li>
                  </ul>
                </div>
                <div
                  className='section-head section-head-filter'
                  style={{ padding: 5, paddingTop: 0 }}
                >
                  <ul className='filter-row'>
                    <li
                      style={{
                        paddingRight: 0,
                        paddingLeft: 0
                      }}
                    >
                     <Checkbox onChange={this.toggleGeofences} />
                    </li>
                    <li>{this.props.resourceList ? <Translate id='groups' /> : <Translate id='Geofences' />}</li>
                    <li style={{ padding: 0, marginRight: 'auto' }}>
                      <ItemGroupMenu
                        individualTitle={<Translate id='Geofences' />}
                        groupTitle={<Translate id='groups' />}
                        showResources={this.props.showResources}
                        themecolors = {this.props.themecolors}
                      />
                    </li>
                    {this.props.resourceList ?
                      <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                        {checkPrivileges('itemgroupCreate') && (
                          <Button
                            size='small'
                            fullWidth
                            onClick={e => this.props.addResource()}
                          >
                            {' '}
                            {this.props.translate('sharedCreate')}
                          </Button>
                        )}
                      </li>:
                      <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                      {checkPrivileges('geofenceCreate') && (
                        <Button
                          size='small'
                          fullWidth
                          onClick={e => this.props.openGeofenceModal()}
                        >
                          {' '}
                          {this.props.translate('sharedCreate')}
                        </Button>
                      )}
                      </li>}
                      <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                        <ExportImportMenu
                        fileName = 'geofences'
                        checkPrivilege = 'geofenceCreate'
                        data = {geoFence}
                        radius={10}
                        themecolors = {this.props.themecolors}
                        />
                      </li>
                    
                  </ul>
                </div>
            {this.props.resourceList ? 
             <RecourcesList 
             ItemChild={ListRow}
             ItemChildProps={{
              onDelete: this.props.onDelete,
              ...geoFence,
              ...geofenceData,
              openGeofenceModal
             }}
             fromArray={this.props.itemPagination.items}
               {...this.props}/> :
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                {!this.props.isGeofenceLoad ? <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {this.props &&
                    this.props.geofenceData &&
                    this.props.geofenceData.newVector &&
                    this.props.geofenceData.newVector.id && (
                      <ListRow
                        item={this.props.geofenceData.newVector}
                        {...geofenceData}
                      />
                    )}
                    {geo.length ? 
                      geo:
                      <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold', fontSize: 14 }}>
                        {this.props.translate('notFound')}
                      </p>
                    }
                </Scrollbar>:
                <Loader/>}
              </ul>}
            </div>
          )}
        </div>
      </div>
      </aside>
      </Style>
    )
  }
}

class ListRow1 extends Component {
  constructor (props) {
    super(props)
    this.state = { edit: false, item: {}, colorPicker: false }
    this.onDelete = this.onDelete.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.saveMapChanges = this.saveMapChanges.bind(this)
    this.cancelMapChanges = this.cancelMapChanges.bind(this)
    this.enableEditMap = this.enableEditMap.bind(this)

    this.updateGeoFanceName = this.updateGeoFanceName.bind(this)
    this.updateGeoFanceDescription = this.updateGeoFanceDescription.bind(this)
    this.updateGeoFanceCalendar = this.updateGeoFanceCalendar.bind(this)
    this.onChangeColor = this.onChangeColor.bind(this)
    this.updateGeoFanceSpeedLimit = this.updateGeoFanceSpeedLimit.bind(this)
    this.updateGeoFancePolylineDistance = this.updateGeoFancePolylineDistance.bind(
      this
    )

    this.showColorPicker = this.showColorPicker.bind(this)
    this.hideColorPicker = this.hideColorPicker.bind(this)
    this.runAction = this.runAction.bind(this)
  }

  onUpdate (event) {
    this.props.dispatch(
      geoFenceVisible({ visible: event.target.checked, id: this.props.item.id })
    )
    this.props.updateVisible()
  }

  onDelete () {
    if (this.props.item.id) {
      // fetch(`api/geofences/${this.props.item.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `api/geofences/${this.props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(deleteGeofence({ id: this.props.item.id }))
          this.setState({ 
            item: {} 
          },()=>{
            this.props.onDelete()
          })
        // }else{
        //   throw response
        // }
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('geofenceDeleted'),
            autoDismiss: 10
          })
        )
      }).catch(error => {errorHandler(error, this.props.dispatch)})
      // .catch(e => {
      //   this.props.dispatch(
      //     Notifications.error({
      //       message: this.props.translate('somthingWentWrongMessage'),
      //       autoDismiss: 10
      //     })
      //   )
      // })
    }
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true
    })
  }

  saveMapChanges () {
    this.setState({
      edit: false
    })

    this.props.save({ ...this.state.item })
  }

  cancelMapChanges () {
    this.props.cancelMapChanges(this.props.item.id)
    this.setState({
      edit: false
    })
  }
  runAction (id) {
    if (this.props.EditControlObject && this.props.EditControlObject[id]) {
      this.props.EditControlObject[
        id
      ]._toolbars.edit._toolbarContainer.children[0].click()
    }
  }

  enableEditMap (event, id) {
    this.props.dispatch(geoFenceVisible({ visible: true, id: id }))
    this.props.openGeofenceModal(this.props.item)
    /* if (this.props.Drawing === '') {
      this.props.dispatch(geoFenceVisible({ visible: true, id: id }))
      this.setState(
        {
          edit: true,
          item: { ...this.props.item, attributes: {...this.props.item.attributes, speedLimit: convertSpeedUnits((this.props.item.attributes.speedLimit || 0), 'kn', 'kmh')}, visible: true }
        },
        () => {
          this.props.enableEditMap(id)
        }
      )
    } else {
      alert('Please complete or cancel your new geofence vector first.')
    } */
  }

  onChangeColor (color) {
    this.setState({
      item: {
        ...this.state.item,
        attributes: {
          ...this.state.item.attributes,
          color
        }
      }
    })
  }

  updateGeoFanceSpeedLimit (speedLimit) {
    this.setState({
      item: {
        ...this.state.item,
        attributes: {
          ...this.state.item.attributes,
          speedLimit
        }
      }
    })
  }

  updateGeoFancePolylineDistance (polylineDistance) {
    this.setState({
      item: {
        ...this.state.item,
        attributes: {
          ...this.state.item.attributes,
          polylineDistance: parseFloat(polylineDistance)
        }
      }
    })
  }

  showColorPicker (e) {
    this.setState({
      colorPicker: true
    })
  }

  hideColorPicker (e) {
    this.setState({
      colorPicker: false
    })
  }

  updateGeoFanceName (value) {
    this.setState({
      item: {
        ...this.state.item,
        name: value
      }
    })
  }

  updateGeoFanceDescription (description) {
    this.setState({
      item: {
        ...this.state.item,
        description
      }
    })
  }

  updateGeoFanceCalendar (calendarId) {
    this.setState({
      item: {
        ...this.state.item,
        calendarId
      }
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }
  render () {
    const EditView = (
      <EditForm
        vector={this.state.item}
        updateName={this.updateGeoFanceName}
        updateColor={this.onChangeColor}
        updateDescription={this.updateGeoFanceDescription}
        updateCalendar={this.updateGeoFanceCalendar}
        updateSpeedLimit={this.updateGeoFanceSpeedLimit}
        updatePolylineDistance={this.updateGeoFancePolylineDistance}
        save={this.saveMapChanges}
        cancel={this.cancelMapChanges}
        translate={this.props.translate}
      />
    )
    return (
      <li
        className={!this.state.edit ? 'list-row' : null}
        key={this.props.item.id}
      >
        {this.state.edit && EditView}
        {!this.state.edit && (
          <Fragment>
            <label className='checkbox'>
              <input
                id={this.props.item.id}
                disabled={this.state.edit}
                type='checkbox'
                checked={this.props.item.visible || false}
                onChange={this.onUpdate}
              />
              <CheckIcon className='theme-input-checkbox' />
              <span className='unit-name' style={{ fontSize: 12, marginLeft: 6 }}>
                {this.props.item.name}
              </span>
            </label>
            <div className='callout-actions'>
              {checkPrivileges('geofenceDelete') && (
                <span className='action' onClick={this.onRemovedItem}>
                  <DeleteIcon className='material-icons'/>
                </span>
              )}
              {checkPrivileges('geofenceUpdate') && (
                <span
                  className='action'
                  onClick={e => this.enableEditMap(e, this.props.item.id)}
                >
                  <EditIcon className='material-icons'/>
                </span>
              )}
            </div>

            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.onDelete}
                title={this.props.translate('areYouWantToDelete')}
                children={this.props.item.name}
              />
            )}
          </Fragment>
        )}
      </li>
    )
  }
}

const mapState = state => ({
    isGeofenceLoad: state.isGeofenceLoad,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Geofences)
