import React, { Component } from 'react'
import List from '@material-ui/core/List'
import InfiniteScroll from 'react-infinite-scroll-component'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Loader from '../../Layout/Loader'
import moment from 'moment'
import { connect } from 'react-redux'
import Style from 'style-it'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'
import CloseIcon from '@material-ui/icons/Close';
import { getDateFormat, getDateTimeFormat, getTimeFormat, setAttributeFormat } from '../../Helpers'
import PinIcon from './../../assets/nav/pin.svg'
import UserIcon from './../../assets/nav/user-two.svg'
import CoordinatesIcon from './../../assets/nav/coordinates.svg'
import ReactHtmlParser from 'react-html-parser'
import Tooltip from '@material-ui/core/Tooltip'
import {Types} from '../Notifications/Wizard/Screen2'


class UserEvents extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedEventId: '',
      selecteditemId: '',
      currentDevice: '',
      prevProps: '',
      open: true
    }
    this.openNotification = this.openNotification.bind(this)
    this.selecteItem = this.selecteItem.bind(this)
  }
  selecteItem (item) {
    let open = true
    if (window.innerWidth <= 959) {
      open = false
    }
    this.setState(
      {
        open,
        selectedEventId: item.id
      },
      () => {
        this.props.showDetail(item)
      }
    )
  }
  openNotification () {
    this.setState({ open: !this.state.open })
  }

  render () {
    let title = `${this.props.translate('unread')} (${this.props.notificationCount})`;
    if(this.props.itemDetails && this.props.itemDetails.length) {
      title = this.props.translate('sharedLoading')  
      if(this.props.itemDetails.length === 1) {
        if(this.props.devices && this.props.devices.length) {
          const device = this.props.devices.find(d => parseInt(d.id) === parseInt(this.props.itemDetails[0]))
          title = device && device.name || ""
        }
      }
      else {
        title = `${this.props.itemDetails.length} ${this.props.translate("devicesEvents")}` 
      }
    }

    let data = ''

    if (this.props.itemPagination.items.length) {
      data = this.props.itemPagination.items.map(event => (
        <ListRow
          key={event.id}
          data={event}
          {...this.props}
          selectedEventId={this.state.selectedEventId}
          serverTimeZone={this.props.serverTimeZone}
          selecteItem={this.selecteItem}
        />
      ))
    }

    if (this.props.loading) {
      return <Loader defaultStyle />
    } else {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            -- {this.props.translate('end')} --{' '}
          </p>
        ) : (
          <p style={{ textAlign: 'center', color: '#ccc' }}>
            {' '}
            {this.props.translate('notFound')}{' '}
          </p>
        )
      return (
        <Style>
          {`
                
                .infinite-scroll-component .event-list-row {
                  border-bottom: 1px solid ${this.props.themecolors.textColor}22
                }
                .infinite-scroll-component .event-list-row.active,
                .infinite-scroll-component .event-list-row:hover {
                    background-color: ${this.props.themecolors.themeLightColor}88;
                } 
                
                `}
                <div style={{overflow: 'hidden'}}>
                  
          <div
            id='scrollableDiv'
            className='notification-scroller maintain-height'
          >
            <div className="notification-head-row"><span>{title}</span> { this.props.notificationCount > 0 ? <a onClick={this.props.markAsReadAll} style={{color: 'inherit', fontWeight: 'normal', fontSize: '0.85em', marginLeft: 'auto'}} href={null}>{this.props.translate('Mark all as read')}</a> : null }</div>
            
            <InfiniteScroll
              dataLength={this.props.itemPagination.items.length}
              next={() => {
                this.props.fetchMoreItems()
              }}
              hasMore={this.props.itemPagination.hasNext}
              loader={<Loader defaultStyle={true} />}
              endMessage={endMessage}
              className='center-height'
              scrollableTarget='scrollableDiv'
            >
              
              <div className='individual-view'>
                <Collapse in={this.state.open}>
                  <List component='nav'>{data}</List>
                </Collapse>
              </div>
            </InfiniteScroll>
          </div>
          </div>
        </Style>
      )
    }
  }
}

export function ListRow (props) {
  return (
    <li
      className={
        `event-list-row ${props.data.isRead !== true ? 'unread' : 'read'} ${props.data.id === props.selectedEventId ? 'active' : ''}`
      }
      style={{cursor: 'pointer'}}
      onClick={() => props.selecteItem(props.data)}
    >
      <Tooltip title={`Not read yet`}><span className="read-unread-mark"></span></Tooltip>
      <span className='event-title'>{props.data.name}</span>
      <span className='event-name'>{props.data.deviceName}</span>
      <span className='event-date'>
        {moment(props.data.eventTime)
          .tz(props.serverTimeZone)
          .format(getDateTimeFormat())}
      </span>
    </li>
  )
}

class UserEventDetails2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentItemId: '',
      isDetailsCompleted: false,
      geofenceName: null
    }
  }
  
  componentWillReceiveProps(n) {
    if(n.data && n.data.positionId !== this.state.currentItemId) {
      this.setState({currentItemId: n.data.positionId})
    }
  }
  
 
  
  /* drawUnit = data => {
    if (data && data.type === 'deviceOverspeed') {
      return data.attributes && data.attributes.speed
        ? ' (' +
            setAttributeFormat('speed', data.attributes.speed) +
            ')'
        : null
    } else if (
      data &&
      ([
        'geofenceEnter',
        'deviceEnterGeofence',
        'deviceExitGeofence',
        'geofenceExit'
      ].includes(data.type) ||
        data.geofenceName)
    ) {
      if (!this.state.geofenceName && !data.geofenceName) {
        return ''
      } else {
        return ` [  ${this.state.geofenceName} ${
          data.geofenceName ? data.geofenceName : ''
        } ]`
      }
    }
    return null
  } */

  render () {
    // let drawUnit = this.drawUnit(this.props.data)
    
    let value = '';
    Object.keys(Types).map(key => {
      if(Types[key]&&Types[key].find(type => type.value === this.props.data.type)){
          value = key
      }
       })
    return (
      <div>
        {<Card
          square
          style={{
            boxShadow: 'none',
            background: this.props.themecolors.themeDarkColor,
            color: this.props.themecolors.themeInverse
          }}
          >
            <CardContent
              className='card-content'
              style={{ padding: '6px 12px' }}
            >
              <div className={'notification-card clearfix'}>
                <Typography
                  variant='h6'
                  component='h6'
                  className='event-detail-heading'
                  style={{
                    color: 'inherit',
                    marginBottom: 10
                  }}
                >
                  {this.props.data&&this.props.data.deviceName || this.props.device&&this.props.device.name }&nbsp;
                </Typography>

                <CloseIcon
                    className='feature-close-button'
                    onClick={this.props.hideDetails}
                  />


                <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong>{this.props.data?.name || ''}</strong>
                    <br />
                    {this.props.translate(
                      'notification.' + this.props.data.type
                    )}
                    <br />
                    {this.props.data.attributes&&this.props.data.attributes.input ? (this.props.data.attributes&&this.props.data.attributes.input === "mileage" ? setAttributeFormat('mileage',this.props.data.attributes&&this.props.data.attributes.value):
                    this.props.data.attributes&&this.props.data.attributes.input === "hours" ? ((this.props.data.attributes&&this.props.data.attributes.value)/(1000*3600)).toFixed(2)+ ' hours':
                    this.props.data.attributes&&this.props.data.attributes.input === "date" ? moment(this.props.data.attributes&&this.props.data.attributes.value).tz(this.props.serverTimeZone).format(getDateFormat()): null)
                    :null}
                    {/* drawUnit && drawUnit.length ? drawUnit : null */}
                  </span>
                  <div style={{ margin: 'auto' }} />
                  <span
                    style={{
                      fontSize: 12,
                      color: 'inherit',
                      whiteSpace: 'nowrap'
                    }}
                    className='event-detail-date'
                  >
                    {moment(this.props.data&&this.props.data.eventTime)
                      .tz(this.props.serverTimeZone)
                      .format(getDateFormat())}
                    <br />
                    {moment(this.props.data&&this.props.data.eventTime)
                      .tz(this.props.serverTimeZone)
                      .format(getTimeFormat())}
                  </span>
                    
                </div>

                {(value === 'digital' || this.props.data?.type ==='workMode') ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('status')}{' : '}</strong>
                      {this.props.data&&this.props.data.attributes&&this.props.data.attributes.status}<br />
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'analog' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
                      {this.props.data.attributes&&this.props.data.attributes.minLimit + ' - ' + this.props.data.attributes&&this.props.data.attributes.maxLimit}<br />
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
                      {this.props.data.attributes&&this.props.data.attributes.value}<br />
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('status')}{' : '}</strong>
                      {this.props.data.attributes.logic + '-Range'}<br />
                    {this.props.data&&this.props.data.geofenceName?<><strong style={{ paddingRight: 10 }}>{this.props.translate('geofence')}{' : '}</strong>
                      {this.props.data&&this.props.data.geofenceName}<br /></>:null}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}

                {value === 'ecodriving' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
                    {this.props.data.attributes&&this.props.data.attributes.minLimit}{this.props.translate('g')} {' - '} {this.props.data.attributes&&this.props.data.attributes.maxLimit}{this.props.translate('g')}<br />
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
                    {this.props.data.attributes&&this.props.data.attributes.value}{this.props.translate('g')}<br />
                    <strong style={{ paddingRight: 10 }}>{this.props.translate('tolerance')}{' : '}</strong>
                    {this.props.data.attributes&&this.props.data.attributes.tolerance}{this.props.translate('g')}<br />
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'movement' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {this.props.data.attributes.maxLimit && <><strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
                    {setAttributeFormat ('speed', this.props.data.attributes.minLimit)} {' - '} {setAttributeFormat ('speed', this.props.data.attributes.maxLimit)}
                      <br /></>}
                    {this.props.data.attributes.value && <><strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
                      {setAttributeFormat ('speed', this.props.data.attributes.value)}
                      <br /></>}
                    {this.props.data.attributes.tolerance && <><strong style={{ paddingRight: 10 }}>{this.props.translate('tolerance')}{' : '}</strong>
                    {setAttributeFormat ('speed', this.props.data.attributes.tolerance)}
                      <br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'other' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(this.props.data.attributes.newValue !== null) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('newValue')}{' : '}</strong>
                      {this.props.data.attributes.newValue}<br /></>}
                    {(this.props.data.attributes.oldValue !== null) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('oldValue')}{' : '}</strong>
                      {this.props.data.attributes.oldValue}<br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'fuel' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(this.props.data?.attributes?.newValue !== null) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('newValue')}{' : '}</strong>
                      {setAttributeFormat ('fuel',this.props.data?.attributes?.newValue)}<br /></>}
                    {(this.props.data?.attributes?.oldValue !== null) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('oldValue')}{' : '}</strong>
                      {setAttributeFormat ('fuel',this.props.data?.attributes?.oldValue)}<br /></>}
                    {/* {(this.props.data?.attributes?.tolerance !== null) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('tolerance')}{' : '}</strong>
                      {this.props.data?.attributes?.tolerance}{this.props.translate('g')}<br /></>} */}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {value === 'customAlert' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: 'inherit'
                    }}
                  >
                    {(this.props.data?.attributes?.ps_trafficFileNumber) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_trafficFileNumber')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_trafficFileNumber}<br /></>}
                    {(this.props.data?.attributes?.ps_plateNumber) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_plateNumber')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_plateNumber}<br /></>}
                    {(this.props.data?.attributes?.ps_brand) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_brand')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_brand}<br /></>}
                    {(this.props.data?.attributes?.ps_model) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_model')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_model}<br /></>}
                    {(this.props.data?.attributes?.ps_state) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_state')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_state}<br /></>}
                    {(this.props.data?.attributes?.ps_color) && <><strong style={{ paddingRight: 10 }}>{this.props.translate('ps_color')}{' : '}</strong>
                      {this.props.data?.attributes?.ps_color}<br /></>}
                  </span>
                  <div style={{ margin: 'auto' }} />
                </div> : null}
                {this.props.address && 
                (<Typography
                  style={{
                    fontSize: 12,
                    color: 'inherit',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                  className='event-detail-address'
                >
                  <strong style={{ paddingRight: 6 }}>
                    <svg fill='currentColor' width={14} height={14}>
                      <use xlinkHref={`${PinIcon}#icon`} />
                    </svg>
                  </strong>
                  {this.props.address ? <span>{ReactHtmlParser(`${this.props.address}`)}</span> : 'N/A'}
                </Typography>)
                }
                {this.props.data.coordinate && 
                (<Typography
                  className='detail-right-section'
                  style={{
                    fontSize: 12,
                    color: 'inherit',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                >
                  <strong style={{ paddingRight: 6 }}>
                    <svg fill='currentColor' width={14} height={14}>
                      <use xlinkHref={`${CoordinatesIcon}#icon`} />
                    </svg>
                  </strong>
                  {this.props.data.coordinate ? <span>
                    <span>
                      {setAttributeFormat(
                        'latitude',
                        this.props.data.coordinate ? this.props.data.coordinate.split(',')[0] : ""
                      )}
                      ,
                    </span>
                    <span>
                      {setAttributeFormat(
                        'longitude',
                        this.props.data.coordinate ? this.props.data.coordinate.split(',')[1] : ""
                      )}
                    </span>
                  </span> : 'N/A'}
                </Typography>)}
                {this.props.data&&this.props.data.driverName && this.props.data.driverName!=="N/A" ?
                  (<Typography
                    style={{
                      fontSize: 12,
                      color: 'inherit',
                      marginBottom: 10,
                      display: 'flex'
                    }}
                    className='event-detail-driver'
                  >
                    <strong style={{ paddingRight: 6 }}>
                      <svg fill='currentColor' width={14} height={14}>
                        <use xlinkHref={`${UserIcon}#icon`} />
                      </svg>
                    </strong>
                    <span>{this.props.data.driverName || 'Unknow Driver'}</span>
                  </Typography>):null}
              </div>
            </CardContent>
          </Card>
         /*  : (
          <Card
            style={{
              borderRadius: 10,
              boxShadow: 'none',
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor
            }}
          >
            <CardContent className='card-content'>
              <div className={'notification-card event-detail-empty-heading'}>
                <Typography
                  variant='h6'
                  component='h6'
                  style={{ color: 'inherit', textAlign: 'center' }}
                >
                  {this.props.translate('notFound')}
                </Typography>
              </div>
            </CardContent>
          </Card>
        ) */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export const UserEventDetails = connect(mapStateToProps)(UserEventDetails2)
export const UserWiseEvents = connect(mapStateToProps)(UserEvents)
