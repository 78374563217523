import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withLocalize } from 'react-localize-redux'
import isEqual from 'react-fast-compare'
import Modal from './modal'
import Button from '../../../common/Button'
import Tooltip from '../../../common/Tooltip'

class screen1 extends React.Component {
  constructor () {
    super()
    this.state = {
      attached: false
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    let { wizardData, mode } = this.props
    if (wizardData && wizardData.form4) {
      this.setState(
        {
          ...wizardData.form4
        },
        () => {
          if (
            wizardData &&
            wizardData.form4 &&
            wizardData.form4.form &&
            wizardData.form4.form.schedul
          ) {
            let calData = wizardData.form4.form.schedul
            this.setState({
              form: { ...this.state.form, schedul: calData },
              attached: true
            })
          }
          if (mode && mode === 'update') {
            this.checkRequiredFields()
          }
        }
      )
    }
  }
  handleChange = name => event => {
    const { target } = event
    if (target && target.type === 'checkbox') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  checkRequiredFields () {
    let { name, interval } = this.state.form
    let { wizardData, mode } = this.props
    let { form } = wizardData.form4
    let { form1, form2, form3, form4 } = wizardData
    let vailidation = true
    if (mode && mode === 'update') {
      if (
        form1.validatedForm ||
        form2.validatedForm ||
        form3.validatedForm ||
        form4.validatedForm
      ) {
        vailidation = true
      } else {
        vailidation = false
      }
    } else {
      if (
        form1.validatedForm &&
        form2.validatedForm &&
        form3.validatedForm &&
        form4.validatedForm
      ) {
        vailidation = true
      } else {
        vailidation = false
      }
    }
    let value = true
    if (mode && mode === 'update') {
      value = !isEqual(this.state.form, form)
    }
    if ((vailidation || value) && name) {      
      this.setState({
        validatedForm: interval < 5 ? false: true
      })
    }
    else {
        this.setState({
          validatedForm: false
        })
    }
  }

  removeCalendar = () => {
    let o = Object.assign({}, this.state.form)
    console.log(o)
    let form = this.state.form
    delete form.schedul
    this.setState({ attached: false, form })
  }
  attachCalendar = () => {
    this.setState({
      attached: true
    })
  }

  combineData = data => {
    this.setState(
      {
        form: {
          ...this.state.form,
          schedul: data
        }
      },
      () => {
        this.checkRequiredFields()
      }
    )
  }
  geofencesHandelChange = groFeneList => {
    this.setState(
      {
        form: {
          ...this.state.form,
          geoFences: groFeneList
        }
      },
      () => this.checkRequiredFields()
    )
  }
  next = () => {
    let data = { ...this.state, prv: true }
    this.props.combineAllData('form4', data, true)
  }
  prv = () => {
    if (this.props.handleChangeTab) {
      this.props.handleChangeTab('', this.props.tabPostion - 1)
    }
  }
  render () {
    return (
      <>
        <Modal
          {...this.props}
          {...this.state}
          handleChange={this.handleChange}
          geofencesHandelChange={this.geofencesHandelChange}
          combineData={this.combineData}
          removeCalendar={this.removeCalendar}
          attachCalendar={this.attachCalendar}
          expirationCheck={this.props.wizardData?.form2?.form?.functionalType ==='expiration'?true:false}
        />
        <div
          className='clearfix'
          style={{
            padding: '12px 0 15px',
            borderTop: `2px dashed ${this.props.themecolors.themeListingColor}4D`
          }}
        >
          <Tooltip
            title={this.props.translate('saveCurrentTabDataAndGotoNext')}
          >
            <span>
              <Button
                disabled={!this.state.validatedForm}
                variant='contained'
                onClick={this.next}
                style={{ float: 'right', marginTop: 4 }}
              >
                {this.props.translate('sharedSave')}
              </Button>
            </span>
          </Tooltip>
          <Button variant='contained' onClick={this.prv}>
            {this.props.translate('previous')}
          </Button>
        </div>
      </>
    )
  }
}

export const ScreenLast = connect(null, null)(withSnackbar(withLocalize(screen1)))
