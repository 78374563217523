import React from 'react'
import {  Grid, InputAdornment,  Tooltip } from '@material-ui/core'
import { checkPrivileges,  } from '../../Helpers'
import Loader from '../../Layout/Loader'
import Button from '../../Components/common/Button'
import SimpleModalWrapped from '../../Components/common/Modal'
import TextField from '../../Components/common/TextField'
import SingleSelect from '../../Components/common/SingleSelect'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import AsyncSelect from '../../Components/common/asyncSelect'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Checkbox from '../../Components/common/Checkbox'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SettingForm } from './SettingForm'
import CustomDialog from '../../Components/common/Dialog';
import Table from '../../Components/common/tableWithBackEndPagination'
import instance from '../../axios'
import axios from 'axios'


let source

const HelperTextStyle = {
  display:'flex',
  justifyContent:'flex-end',
  color:' white', 
  marginTop:0,
  fontSize:10
};
export const CreateExpense = ({
    expenseForm,
    handleChangeExpense,
    onChangedDevice,
    serviceExpenseCollection,
    handleChangeServices,
    drivers,
    garageList,
    vehicles,
    dispatch,
    notificationMode,
    subTypes,
    submitExpense,
    showDialogBtn,
    showDueMaintenanceDialogFunc,
    vehicleDueMaintenance,
    showDueDialog,
    setDuePagination,
    closeCreateFrom,
    addMore,
    isVisible,
    deleteRow,
    themecolors,
    selectedUnit,
    vehicleTracker,
    uploadImage,
    handleChangeRowsPerPage,
    handleChangePage,
    translate,
    mode,
    statusTypes,
    statusTypes1,
    uploadFile,
    loader,
    deleteImage,
    image64Type,
    expenseType,
    logInUser,
    dueActive,
    historyEdit,
    imagePopup,
    onCloseDueMaintenanceModel,
    saveToParent,
    completeMode,
    areaList,
    check,
    tab,
    onRetry,
    showRetry,
    isValidVehicle,
    allVehicles,
    zerovat,
    issueinvoice,
    handleChangeVAT,
    defaultAccountsList,
    ServerSetting,
    selectedVehilcesList,
    // tabBtn,
    searchsubTypeList
  }) => {
    const defaultAreaOptions = areaList
    const defaultGarageOptions = garageList
    let image =expenseForm.files && expenseForm.files.split(',')
    let imageCount = 1;
    let searchVehicle = [];
    let category=100
    // const TopVehicles = tabBtn ==='tab2' ? selectedVehilcesList : vehicles.slice(0, 50);
    const TopVehicles =(logInUser.customerType === 1 || logInUser.customerType === 2) ? selectedVehilcesList : vehicles.slice(0, 50);
   

    const searchGarageList = async (inputValue) => {
      if (!inputValue) {
        return []
      }
      if (source) {
        source.cancel('Operation canceled due to new request=======.');
      }
      source = axios.CancelToken.source();

      return await instance({
        method: 'GET',
        url: `/api/garages/get?userId=${logInUser&&logInUser.id}&page=1&limit=50&all=true&search=${inputValue}`,
        cancelToken: source.token,

        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.data){
          return response.data.map(item => {
            return {
              id:item.id,
              key:item.id,
              name:item.name +" - "+ item.garageNumber,
              label:item.name +" - "+ item.garageNumber,
              uniqueId:item.id,
              valueType:'string',
              garageNumber:item.garageNumber

            }
          })
        }
      }).catch(error => {
        // errorHandler(error, this.props.dispatch)
      })
    }
    const searchAreaList = async (inputValue) => {
      if (!inputValue) {
        return []
      }
  
      return await instance({
        method: 'GET',
        url: `/api/areas/get?&page=1&limit=10&search=${inputValue}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.data){
          return response.data.map(item => {
            return {
              key:item.id,
              name:item.name,
              label:item.name,
              uniqueId:item.id,
              valueType:'string'
            }
          })
        }
      }).catch(error => {
        // errorHandler(error, this.props.dispatch)
      })
    }

    const searchVehiclesList = async (inputValue) => {
      if (!inputValue) {
        return []
      }
      let id =  (logInUser.customerType === 1 || logInUser.customerType === 2)  ? expenseForm?.contractNumber?.userId : logInUser&&logInUser.id
    // const TopVehicles = tabBtn ==='tab2' ? selectedVehilcesList : vehicles.slice(0, 50);

    if (source) {
      source.cancel('Operation canceled due to new request=======.');
    }
    source = axios.CancelToken.source();

      return await instance({
        method: 'GET',
        cancelToken: source.token,

        url: `api/vehicles/gettemp?all=true&userId=${id}&page=1&limit=50&search=${inputValue}`,

        // url: `/api/garages/get?userId=${logInUser&&logInUser.id}&page=1&limit=50&all=true&search=${inputValue}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.data){
          return response.data.map(item => {
            const make = item.attributes.make || "";
            const model = item.attributes.model || "";
    
            const label = `${item.vehicleLicensePlate} - ${make} ${model}`;
        
            return {
                id: item.id,
                label: label.trim().endsWith("-") ? item.vehicleLicensePlate : label,
                value: item.id,
                vehicleLP: item.vehicleLicensePlate,
                valueType: "string",
            };
        });
        }
      }).catch(error => {
        // errorHandler(error, this.props.dispatch)
      })
    }
   
  return (
    <div style={{ height: '100%' }}>
      <div>
        <SimpleModalWrapped
            // {...props}
            visable
            title={translate('sharedMaintenance')}
            modalControle={()=> closeCreateFrom( (tab ==='showMaintenance')? 'showMaintenance' :tab === 'history'? 'history' : 'due')}
            notShowAttributesBtn
            btnCloseText='sharedBack'
            nopadding
            content={
            <>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                {logInUser.userType === 4 && 
                  ( logInUser.customerType === 1 || logInUser.customerType === 2) &&
                   checkPrivileges('serviceGarageInitiate') &&
                    (
                    <Grid item md={6} style={{ postion: 'relative', zIndex: 12 }}>
                      {notificationMode ==="update" ? 
                      <>
                          <TextField
                            id='contractNumber'
                            label={translate('contractNumber')}
                            type='text'
                            multiline
                            placeholder=''
                            value={expenseForm?.contractNumber?.name + " - " + expenseForm?.contractNumber.contractNumber  || ''}
                            onChange={e => handleChangeExpense('contractNumber', e.target.value)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            required

                            disabled
                            />
                          
                      </>
                 
                    
                    :
                    <>
                    {console.log("expenseForm=====", expenseForm)}
                       <SingleSelect
                          array={defaultAccountsList || []}
                          selectName='contractNumber'
                          label={translate('selectAccount') +" " + " *"}
                          value={expenseForm.contractNumber&&expenseForm.contractNumber?.id
                            ? {
                                id: expenseForm.contractNumber.contractNumber,
                                value: expenseForm.contractNumber.contractNumber,
                                label: expenseForm.contractNumber.name,
                                // uniqueId:expenseForm.contractNumber.uniqueId,
                              }
                            : ''
                          }
                          handleChange={handleChangeExpense}
                          canRemove={true}
                          canAssign={true}
                        />
                        {expenseForm.contractNumber.pacContractId && (
                         <p style={{color:"red"}}>
                       { translate('PAC Contract Id')}: {expenseForm.contractNumber.pacContractId ? expenseForm.contractNumber.pacContractId :''}
                        </p>)}
                    </>
                   
                        
                        }
                    </Grid>)}

                  <Grid item md={6}>
                    <TextField
                      id='name'
                      label={translate('sharedName')}
                      required
                      placeholder=''
                      value={expenseForm.name || ''}
                      onChange={e => handleChangeExpense('name', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  { (logInUser.customerType === 1 || logInUser.customerType === 2)  && (
                  <Grid item md={6}>
                    <TextField
                      id='workOrderNumber'
                      label={translate('workOrderNumber')}
                      // required
                      placeholder=''
                      value={expenseForm.workOrderNumber || ''}
                      onChange={e => handleChangeExpense('workOrderNumber', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>)}
                 
             
                  {/* <Grid item md={6}
                      style={{ postion: 'relative', zIndex: 99 }}>
                      <SearchDevices
                          isMulti={false}
                          api='devices'
                          name='devices'
                          isClearable
                          onChange={handleChangeExpense}
                          // value={expenseForm&&expenseForm.device}
                          value={expenseForm.device.label ? 
                            {
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.label,
                            }
                            :{
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.name,
                            }
                          }
                          placeholder={'Devices'}
                      />
                  </Grid> */}
                  
                  <Grid item md={6}>
                    <TextField
                      id='refNum'
                      label={translate('refNum')}
                      type='text'
                      required
                      rows={3}
                      placeholder=''
                      value={expenseForm.refNum || ''}
                      onChange={e => handleChangeExpense('refNum', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('refdate')}
                          variant='outlined'
                          fullWidth
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.refdate
                              ? new Date(`${expenseForm.refdate}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('refdate', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id='sharedDescription'
                      label={translate('sharedDescription')}
                      type='text'
                      multiline
                      placeholder=''
                      value={expenseForm.description || ''}
                      onChange={e => handleChangeExpense('description', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 8 , float:'right'}}>
                    
                  {/* {tabBtn ==='tab2' && notificationMode ==="update" ?  */}
                    { (logInUser.customerType === 1 || logInUser.customerType === 2)  && notificationMode ==="update" ? 
                            <TextField
                            id='vehicleLicensePlate'
                            label={translate('vehicleLicensePlate')}
                            type='text'
                            multiline
                            placeholder=''
                            value={expenseForm.vehicleLicensePlate ||  ''}
                            onChange={e => handleChangeExpense('description', e.target.value)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            disabled
                            required
                            />
                      :
                    <AsyncSelect
                      // value={expenseForm.vehicles}
                      value={expenseForm.vehicles&&expenseForm.vehicles.id
                        ? {
                            id: expenseForm.vehicles.id,
                            value: expenseForm.vehicles.id,
                            label: expenseForm.vehicles.label || expenseForm.vehicles.vehicleLP || expenseForm.vehicleLicensePlate ||expenseForm.vehicleName ,
                            uniqueId:expenseForm.vehicles.vehicleLicensePlate || expenseForm.vehicles.vehicleLP,
                          }
                        : expenseForm.vehicleLicensePlate || expenseForm.vehicleName ?{
                          label: expenseForm.vehicleLicensePlate || expenseForm.vehicleLicensePlate ||expenseForm.vehicleName ,
                        } : ''
                      }
                      placeholder={translate('selectVehicle')}
                      selectName='vehicles'
                      // resultMessage={resultMessage}
                      // defaultOptions={searchVehicle.length ? searchVehicle : TopVehicles}
                      defaultOptions={expenseForm.contractNumber ?  selectedVehilcesList :searchVehicle.length ? searchVehicle : TopVehicles}
                      handleChange={handleChangeExpense}
                      loadOptions={searchVehiclesList}
                      isMulti={false}
                      // readOnly
                    />
                    }

{/* 
                    {showDialogBtn ? (
                      <a
                        // onClick={e => this.2(e, [n])}
                        onClick={showDueMaintenanceDialogFunc}
                        // target="_blank"
                        style={{color: 'inherit', float:'inherit'}}
                      >
                        <LinkIcon
                          className='actionButton'
                          // fontSize='small'
                        />
                      </a>
                    ) : null
                    } */}
                  </Grid>
                  
                {/* {tabBtn ==='tab2' ? null :  */}
                  {/* {logInUser.customerType === 1 ? null :
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 7 }}>
                    <SingleSelect
                        array={drivers}
                        selectName='driver'
                        label={translate('selectedDrivers')}
                          value={expenseForm.driver&&expenseForm.driver.id
                            ? {
                                id: expenseForm.driver.id,
                                value: expenseForm.driver.id,
                                label: expenseForm.driver.name,
                                uniqueId:expenseForm.driver.uniqueId,
                              }
                            : ''
                          }
                        handleChange={handleChangeExpense}
                        canRemove={true}
                        canAssign={true}
                      />
                  </Grid>} */}
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('serviceTime')}
                          variant='outlined'
                          fullWidth
                          required
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.serviceTime
                              ? new Date(`${expenseForm.serviceTime}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('serviceTime', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('expireTime')}
                          variant='outlined'
                          fullWidth
                          required
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.expireTime
                              ? new Date(`${expenseForm.expireTime}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('expireTime', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  {/* {tabBtn ==='tab2' ? null : */}
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 6 }}>
                  {/* {tabBtn ==='tab2' && notificationMode ==="update" ?   */}
                  {/* {console.log("expenseForm=====", expenseForm)} */}
                  {
                  ServerSetting.garageNumber ||
                  ((logInUser.customerType === 1 || logInUser.customerType === 2)  && notificationMode ==="update") ? 
                          <TextField
                            id='garage'
                            label={translate('garage')}
                            type='text'
                            multiline
                            required
                            placeholder=''
                            value={expenseForm?.garage?.name || expenseForm.garageNumber || ''}
                            onChange={e => handleChangeExpense('garage', e.target.value)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            disabled
                          />

                  :    <AsyncSelect
                          value={expenseForm.garage&&expenseForm.garage.id
                            ? {
                                id: expenseForm.garage.id,
                                value: expenseForm.garage.id,
                                label: expenseForm.garage.name,
                                uniqueId:expenseForm.garage.uniqueId,
                              }
                            : expenseForm.garageNumber ? {
                              label: expenseForm.garageNumber,
                            } : ''
                          }
                          placeholder={translate('garage *') }
                          selectName='garage'
                          defaultOptions={defaultGarageOptions}
                          handleChange={handleChangeExpense}
                          loadOptions={searchGarageList}
                          isMulti={false}
                        />}
                  </Grid>
                  {/* } */}
                  
                  {/* {tabBtn ==='tab2' ? null : */}
                  {/* {logInUser.customerType === 1 ? null :
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 5 }}>                 
                      {!historyEdit ? (
                        <TextField
                          id='status'
                          select
                          label={translate('status')}
                          value={expenseForm.status&&expenseForm.status}
                          onChange={e=>handleChangeExpense('status',e )}
                          margin='dense'
                          fullWidth
                        >
                          {checkPrivileges("serviceMarkAsComplete")  ?
                              statusTypes.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                  {translate(option.name)}
                                </MenuItem>
                              ))
                                :
                              statusTypes1.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                  {translate(option.name)}
                                </MenuItem>
                              ))}

                        </TextField>
                      ) : null }
                  </Grid>} */}
                  {dueActive && (<><Grid item md={6} style={{marginLeft: 5}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Checkbox
                        checked={expenseForm&&expenseForm.attributes&&expenseForm.attributes.updateMaintenance}
                        onChange={e => handleChangeExpense('updateMaintenance', e.target.checked)}
                        color='primary'
                        label={translate('updateMaintenance')}
                      />
                      <Tooltip 
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                        title={translate('updateMaintenanceTooltip')}>
                        <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                      </Tooltip>
                    </div>
                  </Grid><Grid item md={6} style={{marginLeft: 5}}></Grid></>)}
                  
                  <Grid item md={12}>
                    <Grid spacing={1} container style={{
                      padding: "0 4px 4px",
                      margin: 0,
                      width: '100%',
                      maxHeight: 575,
                      // overflow: 'auto',
                      boxSizing: 'border-box',
                      border: '2px dashed '+ themecolors.themeLightColor + '7A'
                    }}>
                      <Grid item md={6} >
                        <TextField
                          id='odometer'
                          label={translate('odometer')}
                          type='number'
                          placeholder=''
                          required
                          value={expenseForm.odometer || ''}
                          onChange={e => handleChangeExpense('odometer', e.target.value)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                        {check ? 
                          <FormHelperText style={HelperTextStyle}>
                                {expenseForm.currentOdometer ? ('Current : '+expenseForm.currentOdometer):('Current : 0')}
                          </FormHelperText>
                          : ''}
                      </Grid>
                      
                       {/* {tabBtn ==='tab2' ? null : */}
                      {/* // {logInUser.customerType === 1 ? null :
                      <Grid item md={6}>  
                        <TextField
                          id='engineHours'
                          label={translate('reportEngineHours')}
                          type='text'
                          placeholder=''
                          value={expenseForm.engineHours || ''} 
                          onChange={e => handleChangeExpense('engineHours', e.target.value)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                        {check ? 
                          <FormHelperText style={HelperTextStyle}>
                            {expenseForm.currentEngineHours ? ('Current : '+expenseForm.currentEngineHours): ('Current : 0')}
                          </FormHelperText>
                          : ''  
                        }
                      </Grid>} */}
                    </Grid>
                  </Grid>
                  {checkPrivileges('area') ? 
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 5 }}>                 
                     <AsyncSelect
                          value={expenseForm.areaId&&expenseForm.areaId.key
                               ? {
                                   id: expenseForm.areaId.key,
                                   value: expenseForm.areaId.key,
                                   label: expenseForm.areaId.name,
                                   uniqueId:expenseForm.areaId.uniqueId,
                                 }
                               : ''
                          }
                          placeholder={translate('Area')}
                          label={translate('sharedArea')}
                          selectName='areaId'
                          // resultMessage={resultMessage}
                          defaultOptions={defaultAreaOptions}
                          handleChange={handleChangeExpense}
                          loadOptions={searchAreaList}
                          isMulti={false}
                          canRemove={true}
                          canAssign={true}
                        />
                  </Grid>
                  :null}

                <Grid item md={6}>
                      <TextField
                        id='contactNumber'
                        label={translate('driverContactNumber')}
                        type='text'
                        placeholder=''
                        value={expenseForm?.contactNumber || ''}
                        onChange={e => handleChangeExpense('contactNumber', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                    </Grid>
                  
                   {/* {tabBtn ==='tab2' ? null : */}
                   {/* {logInUser.customerType === 1 ? null :
                  <Grid item md={6}>
                      <TextField
                        id='contactNumber'
                        label={translate('driverContactNumber')}
                        type='text'
                        placeholder=''
                        value={expenseForm?.contactNumber || ''}
                        onChange={e => handleChangeExpense('contactNumber', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                    </Grid>} */}
                    
                    {/* {tabBtn ==='tab2' ? null :<> */}
                    {/* {logInUser.customerType === 1 ? null :<>
                    {checkPrivileges("serviceZeroVAT") &&(
                          <Grid item md={6}>
                            <Checkbox
                                checked={zerovat&&zerovat}
                                onChange={e => handleChangeVAT("zerovat", e.target.checked)}
                                color='primary'
                                label={translate('zerovat')}
                              />
                          </Grid>
                        )}</>} */}
                        
              {/* {tabBtn ==='tab2' ? null :<> */}
              {/* {logInUser.customerType === 1 ? null :<>
                   {checkPrivileges("serviceMarkAsComplete") && checkPrivileges("serviceIssueDeliveryNote") &&(
                      <Grid item md={6}>
                        <Checkbox
                            checked={issueinvoice&&issueinvoice}
                            onChange={e => handleChangeVAT("issueinvoice", e.target.checked)}
                            color='primary'
                            label={translate('issueinvoice')}
                          />
                      </Grid>
                    )}</>} */}

                  {/* <Grid item md={12}>
                    <TextField
                      id='Address'
                      label={translate('address')}
                      type='text'
                      multiline
                      rows={3}
                      placeholder=''
                      value={expenseForm.address || ''}
                      onChange={e => handleChangeExpense('address', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid> */}
                </Grid>
                <Grid container spacing={1}>
                <Grid item md={12}>
                  <h4 style={{ margin: '10px 0' }} className='page-title'>
                    {translate('tags')}
                  </h4></Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag1'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et1
                          ? logInUser.attributes.et1
                          : translate('tag_1')
                      }
                      placeholder=''
                      value={expenseForm.tag1 || ''}
                      onChange={e => handleChangeExpense('tag1', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag2'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et2
                          ? logInUser.attributes.et2
                          : translate('tag_2')
                      }
                      placeholder=''
                      value={expenseForm.tag2 || ''}
                      onChange={e => handleChangeExpense('tag2', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag3'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et3
                          ? logInUser.attributes.et3
                          : translate('tag_3')
                      }
                      placeholder=''
                      value={expenseForm.tag3 || ''}
                      onChange={e => handleChangeExpense('tag3', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>

              
              </Grid>
          
              <Grid item sm={4} xs={12}>
                <Grid container spacing={0}>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={1} >
                      {serviceExpenseCollection.map((row, index) => (
                        <Grid item md={12} xs={12} style={{
                          padding: "0 8px 4px",
                          marginTop: 12,
                          maxHeight: 575,
                          // overflow: 'auto',
                          boxSizing: 'border-box',
                          border: '2px dashed '+ themecolors.themeLightColor + '7A'
                        }}>
                        <Grid container spacing={1} >
                          <Grid item md={12} xs={12} style={{ postion: 'relative', zIndex: category--}}>
                          <SingleSelect
                              // array={expenseType}
                              array={(row.allExpenseTypes&&row.allExpenseTypes.length) ? row.allExpenseTypes : (expenseType)}  
                              id='expenseTypeId'
                              selectName='expenseTypeId'
                              label={translate('sharedType')}
                              // isDisabled={dueActive || completeMode}
                              value={row.expenseTypeId ?
                              {
                                key: row.expenseTypeId.key,
                                id: row.expenseTypeId.id,
                                value: row.expenseTypeId.id,
                                label: row.expenseTypeId.name ? row.expenseTypeId.name : row.expenseTypeId.name
                              }: ''
                              }
                              index={index}
                              handleChange={handleChangeServices}
                              canRemove={true}
                              canAssign={true}
                              readOnly= {!isValidVehicle ? true: false}
                              // index={index}
                              />
                              {/*  } */}
                      </Grid> 
                          <Grid item md={serviceExpenseCollection.length > 1 ? 11 : 12} xs={12} style={{ postion: 'relative', zIndex:category}}>
                         {/* qqqqqqqqqqqq */}
                          {/* <AsyncSelect
                      // value={expenseForm.vehicles}
                      value={row.expenseSubTypeId.id ?
                        {
                          key: row.expenseSubTypeId.key,
                          id: row.expenseSubTypeId.id,
                          value: row.expenseSubTypeId.id,
                          label: row.expenseSubTypeId.subTypeLabel ? row.expenseSubTypeId.subTypeLabel : row.expenseSubTypeId.label
                        }: ''
                        }
                      placeholder={translate('subType')}
                      selectName='expenseSubTypeId'
                      // resultMessage={resultMessage}
                      // defaultOptions={searchVehicle.length ? searchVehicle : TopVehicles}
                      defaultOptions={(row.selectedSubTypes&&row.selectedSubTypes.length) ? row.selectedSubTypes : (subTypes)}
                      // handleChange={(e)=>handleChangeServic/es(e, row, index, )}
                      handleChange={handleChangeServices}
                      // loadOptions={(e)=>searchUsersList(e, row, index, )}
                      loadOptions={(e)=>searchsubTypeList(e, row, index, )}
                      isMulti={false}
                      // readOnly
                    /> */}
                    
                            <SingleSelect
                            id='expenseSubTypeId'
                            array={(row.selectedSubTypes&&row.selectedSubTypes.length) ? row.selectedSubTypes : (subTypes)}                  
                            selectName='expenseSubTypeId'
                            label={translate('subType')}
                            value={row.expenseSubTypeId && row.expenseSubTypeId?.id ?
                            {
                              key: row.expenseSubTypeId.key,
                              id: row.expenseSubTypeId.id,
                              value: row.expenseSubTypeId.id,
                              label: row.expenseSubTypeId.label ?
                                     row.expenseSubTypeId.label : row.expenseSubTypeId.subTypeLabel || row.expenseSubTypeId.name
                            }: ''
                            }
                            async
                            expenseSubTypeId
                            handleChange={handleChangeServices}
                            canRemove={true}
                            index={index}
                            canAssign={true}
                            readOnly= {!isValidVehicle ? true: false}
                            loadOptions={(e)=>searchsubTypeList(e, row, index, )}

                          /> 
                          {/* } */}
                          </Grid>    
                          {serviceExpenseCollection.length > 1 && --category ? (<Grid item md={1} style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <DeleteIcon
                              onClick={e => deleteRow(row, index, e)}
                              style={{ cursor: 'pointer' }}
                              />            
                          </Grid>)  : null}          
                          <Grid item md={6}>
                            <TextField
                              id='Quantity'
                              label={translate('quantity')}
                              type='number'
                              placeholder=''
                              value={row.quantity || 0}
                              onChange={e => handleChangeServices('quantity', e.target.value, index)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              disabled={!row.expenseSubTypeId}
                            />
                           <FormHelperText error={row.quantity <= 0 ? true : false}>
                               {translate('minimum1Quantity')}
                              </FormHelperText>
                            </Grid>
                        <Grid item md={6}>
                          {checkPrivileges('servicePriceChange') && row.expenseSubTypeId  &&  row.expenseTypeId.name === "spot" &&( 
                           (notificationMode === 'update' && 
                           ((logInUser.customerType === 2 && (expenseForm.status === "WaitingForOpApproval"|| expenseForm.status === "CustomerReject" || 
                                                              expenseForm.status === "GarageToOp" || expenseForm.status === "CustomerToOp"
                                                              // || expenseForm.status === "OpToCustomer"
                                                            )) ||
                            (logInUser.customerType === 1 && (expenseForm.status === "GarageInitiate" ||  expenseForm.status === "OpReject" || 
                                                              expenseForm.status === "CustomerToGarage" ||  expenseForm.status === "OpToGarage"
                            )))) 
                           ||(notificationMode === 'create' && (logInUser.customerType === 1 || logInUser.customerType === 2)  ) ) ? 
                           <>
                              <TextField
                              id='unitPrice'
                              label={translate('sellingPriceWithoutVAT')}
                              type='number'
                              placeholder=''
                              value={row?.expenseSubTypeId?.unitPrice   || 0}
                              // value={row?.expenseSubTypeId?.unitPrice ?? ''}
                              onChange={e => handleChangeServices('unitPrice', e.target.value, index)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              InputProps={{
                                style: {padding: 0},
                                endAdornment: (
                                  <InputAdornment
                                    position='start'
                                    disableTypography
                                  >
                                    {(logInUser.attributes && logInUser.attributes.currencyLabel) || translate("aed")}
                                  </InputAdornment>
                                )
                              }}
                              />
                               <FormHelperText error={row?.expenseSubTypeId?.unitPrice < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
                              </>
                               :
                               <>
                            <TextField
                            disabled
                            id='unitPrice'
                            label={translate('sellingPriceWithoutVAT')}
                            type='number'
                            placeholder=''
                            value={row?.expenseSubTypeId?.unitPrice   || 0}
                            onChange={e => handleChangeServices('unitPrice', e.target.value, index)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            InputProps={{
                              style: {padding: 0},
                              endAdornment: (
                                <InputAdornment
                                  position='start'
                                  disableTypography
                                >
                                  {logInUser.attributes && logInUser.attributes.currencyLabel || translate("aed")}
                                </InputAdornment>
                              )
                            }}
                            /> 
                            <FormHelperText error={row.quantity < 0 ? true : false}>
                            {translate('valueMustbeGreaterthen0')}
                           </FormHelperText>
                           </>
                            }
                            {/* <TextField
                            // disabled={((!row.expenseSubTypeId || (!checkPrivileges('servicePriceChange') 
                            //             || row.expenseSubTypeId.name !== "spot") )   
                            //             &&((logInUser.customerType === 3 && expenseForm.status !== "GarageInitiate")
                            //             || (logInUser.customerType === 2 && expenseForm.status !== "WaitingForOpApproval"))
                            //         )}
                                    disabled={
                                      !( // Check if row.expenseSubTypeId exists
                                        row.expenseSubTypeId &&
                                        // Check privileges and subtype name
                                        (checkPrivileges('servicePriceChange') && row.expenseSubTypeId.name === "spot")
                                      ) && ( // Check customer type and expense form status
                                        (logInUser.customerType === 3 && expenseForm.status === "GarageInitiate") ||
                                        (logInUser.customerType === 2 && expenseForm.status === "WaitingForOpApproval")
                                      )
                                    }
                            id='unitPrice'
                            label={translate('sellingPriceWithoutVAT')}
                            type='number'
                            placeholder=''
                            value={row?.expenseSubTypeId?.unitPrice   || 0}
                            onChange={e => handleChangeServices('unitPrice', e.target.value, index)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            InputProps={{
                              style: {padding: 0},
                              endAdornment: (
                                <InputAdornment
                                  position='start'
                                  disableTypography
                                >
                                  {logInUser.attributes && logInUser.attributes.currencyLabel || translate("aed")}
                                </InputAdornment>
                              )
                            }}
                            /> */}
                      </Grid>
                      <Grid item md={6}>
                        <h3>
                           {translate('totalPrice')}: { parseFloat(row.cost).toFixed(2) || 0}
                           </h3>
                        </Grid>                                                                     
                          </Grid>  
                        </Grid>
                        ))}
                         
                    </Grid>
                  </Grid>
                <Grid item md={12} xs={12}>
                  <div style={{float:'right', marginTop:10}}>
                  
                      {/* {((tabBtn ==='tab1' && expenseForm ) ||
                        (tabBtn ==='tab2' && expenseForm && logInUser.customerType === 1 &&  */}
                        {(( expenseForm ) ||
                        ( (logInUser.customerType === 1 || logInUser.customerType === 2)  && expenseForm  && 
                        (notificationMode === 'create' || (notificationMode === 'update' && (expenseForm.status === "GarageInitiate" 
                          ||  expenseForm.status === "OpToCustomer"
                        ) ))) ) ? (
                        <div>
                          <Button
                            onClick={e => addMore(e)}
                            size='small'
                            color='inherit'
                            style={{ minWidth: '70px' }}
                          >
                            {translate('addMore')}
                          </Button>
                        </div>
                      ) :''}
                      </div>
                </Grid>

                </Grid>
              </Grid>
            </Grid>
            {/* {notificationMode === 'update' &&  tabBtn ==='tab1' && (
            <Grid container spacing={1} style={{marginTop:12}}>
              <Grid item sm={6} xs={12}>
                  <Tooltip title='Upload Image'>
                        <Button
                          style={{ float: 'left'}}
                          variant='contained'
                          margin='dense'
                          component="label"
                          onChange={e => uploadImage(e,'expenseCreate')}
                        >
                          <input
                            type="file"
                            hidden
                            multiple
                          />
                          {translate('onlyAcceptDocFile')}
                          <PublishIcon  style={{ color: '#fff', marginLeft:15}}/>
                        </Button>
                      </Tooltip>
                </Grid>   
            </Grid>)} */}
              {loader===true ? 
            <Grid container spacing={1} style={{marginTop:12}}>
                <Grid item sm={12} xs={12}><Loader defaultStyle={true}/></Grid>
              </Grid>:(
              <div style={{marginTop:12, display:'flex'}}>
                {image64Type&&image64Type.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                        <a href={item} download target="_blank">
                          <FileCopyIcon style={{width: 80, height: 80, }}/>
                        </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image64Type,'base64')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                    </div>
                  )
                )}
                {image&&image.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                      <a href={item} download target="_blank">
                        <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                      </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image,'url')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                  </div>
                  )
                )}
              </div>
              )}
              
              {/* {tabBtn ==='tab2' ? null :<> */}
              {/* {logInUser.customerType === 1 ? null :<> */}
              {expenseForm.status === 'required' ? (<>
              <Grid item md={6} style={{margin: '0 0 5px 5px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Checkbox
                        checked={expenseForm&&expenseForm.attributes&&expenseForm.attributes.sendAlert}
                        onChange={e => handleChangeExpense('sendAlert', e.target.checked)}
                        color='primary'
                        label={translate('sendAlert')}
                      />
                    </div>
              </Grid></>)
              :null}
              {/* </>} */}
             {expenseForm.attributes&&expenseForm.attributes.sendAlert ? (<>
                <Grid item xs={12}>
                   <SettingForm form={expenseForm&&expenseForm.attributes} mode={notificationMode} saveToParent={saveToParent} dispatch={dispatch} translate={translate} smsNotification />
                </Grid>
                </>) : null}           
             
             <hr/>
             {expenseForm.bookingStatus !==1 ? 
              (<Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
                  <Grid item sm={12} xs={12}>
                    <p style={{color: "#ffc107"}}>{expenseForm?.bookingMessege||''}</p>
                  </Grid>
                </Grid>)
            :null}
            
            <Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
            {showRetry && expenseForm.bookingStatus !==1 ? 
            (<Grid item sm={6} xs={6} >
                    <Button
                        size='small'
                        // className='button-white'
                        onClick={()=>onRetry()}
                        disabled={isVisible}
                    >
                        {translate('Retry')}{' '}
                    </Button>
                </Grid>):null}
                <Grid item sm={showRetry?6:12} xs={showRetry?6:12} style={{display:'flex', justifyContent:'flex-end'}}>
                  <Button
                        size='small'
                        // className='button-white'
                        onClick={()=>uploadFile(mode)} 
                        disabled={isVisible}
                    >
                        {translate('submit')}{' '}
                    </Button>
                </Grid>
             </Grid>  
             {showDueDialog ? (
              <Grid container spacing={1}>
                  <CustomDialog
                    title ='Due Maintenance'
                    themecolors={themecolors}
                    visable={true}
                    onClose={onCloseDueMaintenanceModel}
                    bodyPadding={10}
                    isVisableBtn
                    noFullScreen
                  >
                    <div>
                      <Table
                          rows={vehicleDueMaintenance? vehicleDueMaintenance.data : []}
                          pagination={setDuePagination(vehicleDueMaintenance)}
                          handleChangeRowsPerPage={(n)=>handleChangeRowsPerPage(n,'dueMaintenance')}
                          handleChangePage={(n)=>handleChangePage(n,'dueMaintenance')}
                          logInUser={logInUser}
                          isEditable={false}
                          status={false}
                          completeMark={false}
                          themecolors={themecolors}
                          translate={translate}
                          hasAccessOfCreate={false}
                          hasAccessOfUpdate={false}
                          hasAccessOfDelete={false}
                          rowDefinition={[
                            {
                              id: 'id',
                              numeric: false,
                              disablePadding: false,
                              label: translate('serviceId')
                            },
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: translate('serviceMaintenance')
                            },
                            {
                              id: 'odometer',
                              numeric: false,
                              disablePadding: false,
                              label: translate('totalDistance')
                            },
                            {
                              id: 'engineHours',
                              numeric: false,
                              disablePadding: false,
                              label: translate('reportEngineHours')
                            },
                            {
                              id: 'serviceTime',
                              numeric: false,
                              disablePadding: false,
                              label: translate('date')
                            }
                          ]}
                          />
                    </div>
                    
                </CustomDialog>
             </Grid>        
             ): null}         
            </>
        }
        />
        </div>
      </div>
  )
  }
   