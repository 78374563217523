import React, { useState } from "react"
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useExpanded, useResizeColumns } from 'react-table'
import { Table as MuiTable, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { getDateTimeFormat, PrepareAddress, setAttributeFormat } from "../../Helpers"
import Button from "./Button"
import TextField from "./TextField"
import { Link } from 'react-router-dom'
import LaunchIcon from '@material-ui/icons/Launch'
import { Translate } from "react-localize-redux";
import axios from "axios"
import moment from "moment"
import { ReactComponent as LoaderIcon } from './../../assets/tracks-loader.svg'
import CommonMenu from "../Menu/CommonMenu"
import CustomDialog from './Dialog'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HtmlParser from 'react-html-parser'
// Define a custom filter filter function!
export const filterGreaterThan = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row.original.find(e => e.f === id[0]);
    return rowValue.r >= filterValue
  })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number'

// Define a custom filter filter function!
export const filterGreaterThan2 = (rows, id, filterValue) => {
  return rows.filter(row => {
      const rowValue = row.original[id[0]] || ": 0";
      const v = parseFloat(rowValue.split(': ').pop());
      return v >= filterValue
  })
}

filterGreaterThan2.autoRemove = val => typeof val !== 'number'
// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, Header },
    }) => {
    // Calculate the min and max
    // using the preFilteredRows
    const [anchorEl, setAnchorEl] = React.useState(null)

    const [min, max] = React.useMemo(() => {
        const rowValue = preFilteredRows.length ? preFilteredRows[0].original.find(e => e.f === id) : { r: 0 };
        let min = rowValue.r
        let max = rowValue.r
        preFilteredRows.forEach(row => {
            const rowValue1 = row.original.find(e => e.f === id);
            min = Math.min(rowValue1.r, min)
            max = Math.max(rowValue1.r, max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div style={{display: 'inline-flex', alignItems: "center"}}>
          <a style={{display: 'inline-flex', alignItems: "center"}} href={null} onClick={e => setAnchorEl(e.currentTarget)}><ArrowDropDownIcon fill="currentColor" /></a>
          <CommonMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={e => setAnchorEl(null)}
          >
            <div style={{padding: "0 16px"}}>
              <div>{Header}</div>
              <input
                style={{width: "150px"}}
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                setFilter(parseInt(e.target.value, 10))
                }}
              />
              <a style={{color: 'inherit'}} href={null} onClick={() => setFilter(undefined)}><RotateLeftIcon fill="currentColor" /></a>
            </div>
          </CommonMenu>
      </div>
    )
}


export const SliderColumnFilter2 = ({
  column: { render, filterValue, setFilter, preFilteredRows, id },
  }) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [min, max] = React.useMemo(() => {
      const rowValue = preFilteredRows.length ? preFilteredRows[0].original[id] : ": 0";
      const v = rowValue.split(": ").pop();

      let min = parseFloat(v)
      let max = parseFloat(v)
      preFilteredRows.forEach(row => {
          const rowValue1 = row.original[id];
          const v1 = rowValue1.split(': ').pop();
          min = Math.min(parseFloat(v1), min)
          max = Math.max(parseFloat(v1), max)
      })
      return [min, max]
  }, [id, preFilteredRows])

  return (
      <div style={{display: 'inline-flex', alignItems: "center"}}>
        <a  style={{display: 'inline-flex', alignItems: "center"}} href={null} onClick={e => setAnchorEl(e.currentTarget)}><Translate id="filterByCount" /> <ArrowDropDownIcon fill="currentColor" /></a>
        <CommonMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={e => setAnchorEl(null)}
          >
            <div style={{padding: "0 16px"}}>
              <div>
                <Translate id="filterByCount" /></div>
                <input
                  style={{width: "150px"}}
                  type="range"
                  min={min}
                  max={max}
                  value={filterValue || min}
                  onChange={e => {
                  setFilter(parseInt(e.target.value, 10))
                  }}
                />
                <a style={{color: 'inherit'}} href={null} onClick={() => setFilter(undefined)}><RotateLeftIcon fill="currentColor" /></a>
            </div>
          </CommonMenu>
      </div>
  )
}
  
 
  // Define a default UI for filtering
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    



    return (
        <div style={{display: 'inline-flex', marginRight: 15}}>
          <TextField
          value={value || ""}
          onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          label={<Translate id="sharedSearch" />}
          style={{
          fontSize: '1.1rem',
          border: '0',
          marginTop: 0,
          marginBottom: 0
          }}
        />
        </div>
    )
}


  
// Define a default UI for filtering
function DefaultColumnFilter() {
    return null
}

function DrawTableCell ({cell, simple, serverTimeZone, openDialog}) {

    if (cell && cell.column && cell.column.id) {
      let v = null, image = [];
        switch (cell.column.id) {
          
          case 'address':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'latitude', end: 'longitude', alternate: 'coordinate' }} />
          break
  
          case 'entranceAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'entranceLat', end: 'entranceLon'}} />
          break
  
          case 'exitAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'exitLat', end: 'exitLon'}} />
          break
  
          case 'startAddress': 
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'startLat', end: 'startLon'}} />
          break
  
          case 'endAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'endLat', end: 'endLon'}} />
          break

          case 'startTime':
          case 'endTime':
          case 'entranceTime':
          case 'exitTime':
          case 'fixTime':
          case 'eventTime':
          case 'serverTime':
            v = simple ? new moment(cell.value)
              .tz(serverTimeZone || 'Asia/Dubai')
              .format(getDateTimeFormat()) : cell.render('Cell');
            break
          
          case 'coordinate':
            const val = cell.value ? cell.value.split(',') : [];
            if (val[0] && val[1]) {
              v = (
                <Link target='_blank' to={'/maps/' + val[0] + '/' + val[1]}>
                  {cell.value}
                  <LaunchIcon
                    style={{ verticalAlign: 'middle', fontSize: '1.3em' }}
                  />
                </Link>
              )
            }
          break
          case 'status':
            v =
            (cell.value && cell.value === 'completed') ? 
              <Translate id="completed" />:
            (cell.value && cell.value === 'required') ? 
              <Translate id="required" />:
            (cell.value && cell.value === 'expired') ? 
              <Translate id="expired" />:''
          break
          case 'files':
            image = cell.value && cell.value.split(',')
            v =
            (cell.value && cell.value !== 'undefined') ? 
            // <a href={null} onClick={e => window.open(cell.value)} download>Download</a>
            <span onClick={()=>openDialog(image)}>Download</span>
              : ''
          break
          case 'speed':
            v = setAttributeFormat ('speed', cell.value)
            break
          case 'startLat':
          case 'startLon':
          case 'endLat':
          case 'endLon':
          case 'latitude':
          case 'longitude':
          case 'entranceLat':
          case 'entranceLon':
          case 'exitLat':
          case 'exitLon':
            v = setAttributeFormat ('latitude', cell.value)
            break
          default:
            v = cell.render('Cell');
            break
        }
  
      return (
        <TableCell size="small" {...cell.getCellProps()}>{v}</TableCell>
      )
    } else {
        return cell ? <TableCell size="small" {...cell.getCellProps()}>{cell.render('Cell')}</TableCell> : <TableCell size="small" />
    }
}

function DrawTableHeadCell ({column, headGlobalFilter, ...rest}) {
  if (column && column.id) {
      switch (column.id) {
        
        case 'head':
          return <TableCell {...column.getHeaderProps()} {...rest} >
            
            <div>{headGlobalFilter} {column.canFilter ? column.render('Filter') : null}</div>
            <div
              {...column.getResizerProps()}
              className={`resizer ${
                column.isResizing ? 'isResizing' : ''
              }`}
            />
            </TableCell>
        default:
          return <TableCell {...column.getHeaderProps()} {...rest}>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>{column.render('Header')}
            {column.canFilter ? column.render('Filter') : null}
            <div
              {...column.getResizerProps()}
              className={`resizer ${
                column.isResizing ? 'isResizing' : ''
              }`}
            />
            </div>
          </TableCell>;
      }

  } else {
      return <TableCell {...column.getHeaderProps()} {...rest} />
  }
}



function DrawTFootCell ({column, total}) {
    const c = column.id
    let v = total && total[c] ? total[c].v : ''
  
    switch (c) {
      case 'distance':
        v = Math.round(v * 100) / 100
        break
      default:
        break
    }
    return (
        <TableCell {...column.getFooterProps()} size="small">{v}</TableCell>
    )
}

// Our table component
export const ReactBaseTable = ({ serverTimeZone, hiddenColumns, columns, data, total, id, renderRowSubComponent, disableFooter, trClass }) => {
    const [imagePopup, setImagePopup] = useState(false);
    const [images, setImages] = useState([]);
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,

      footerGroups,
      rows,
      page, // Instead of using 'rows', we'll use page,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },

      allColumns

    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        initialState: {expanded: { "0" : true}, hiddenColumns: hiddenColumns || []},
        
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useExpanded, // Use the useExpanded plugin hook
      useResizeColumns,
      usePagination
      
    )

    const openDialog = (image) =>{
      setImages(image)
      setImagePopup(true)
    }
    const closeDialog = () =>{
      setImagePopup(false)
    }

    return (
      <div style={{overflow: "hidden"}} className="react-base-table-wrapper" id={id}>
        <div style={{maxWidth: "100%", overflow: 'auto'}}>
            <MuiTable {...getTableProps()} className="react-base-table">
              <TableHead>
                  {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                      <DrawTableHeadCell headGlobalFilter={<GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        />} size="small" column={column} style={{width: column.width}} />
                      ))}
                  </TableRow>
                  ))}
                  
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                  {page.length ? page.map((row, i) => {
                  prepareRow(row)
                  return (
                      <>
                      <TableRow {...row.getRowProps()} className={trClass}>
                      {row.cells.map(cell => {
                          return <DrawTableCell cell={cell} serverTimeZone={serverTimeZone} openDialog={openDialog}/>
                      })}
                      </TableRow>
                      {row.isExpanded && renderRowSubComponent ? (
                          <TableRow>
                            <TableCell className="expandable-cell" style={{ padding: 0, borderBottom: 0 }} size="small" colSpan={visibleColumns.length + 1}>
                              {/*
                                  Inside it, call our renderRowSubComponent function. In reality,
                                  you could pass whatever you want as props to
                                  a component like this, including the entire
                                  table instance. But for this example, we'll just
                                  pass the row
                                */}
                              {renderRowSubComponent({ row })}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                  )
                  }) : <TableRow><TableCell colSpan={visibleColumns.length} style={{ borderBottom: 0 }}><Translate id="noRecordExist" /></TableCell></TableRow> }
              </TableBody>
              {imagePopup?(<CustomDialog
                  title={<Translate id='Images'/>}
                  // themecolors={this.props.themecolors}
                  visable={true}
                  onClose={closeDialog}
                  bodyPadding={10}
                  cancelText={<Translate id='sharedCancel'/>}
                  noFullScreen
                >
                  { images.length ?(<div style={{ display: 'flex',flexWrap: 'wrap'}}>
                    <ImageList cols={2.5} style={{flexWrap: 'nowrap'}}>
                    {images.map((item, i) =>
                      item && (<ImageListItem key={item} style={{height: 'auto', width:'auto'}}>
                        <a href={item} download target="_blank">
                          <Tooltip 
                            classes={{
                            popper: 'menu-popper',
                            tooltip: 'menu-popper-tooltip'
                          }}
                          title={<Translate id='DOWNLOAD'/>}>
                            <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                          </Tooltip>
                        </a>
                        <p style={{textAlign: 'center', marginTop: 0}}>File {i+1}</p>
                        </ImageListItem>))}
                      </ImageList>
                  </div>) : <h3 style={{display: 'flex',justifyContent: 'center'}}>No Image Selected</h3>}
                </CustomDialog>):null}
              {!disableFooter ? (rows.length > 0 ? <TableFooter>
                {footerGroups.map(group => (
                  <TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map(column => <DrawTFootCell column={column} total={total} />)}
                  </TableRow>
                ))}
              </TableFooter> : null) : null}
            </MuiTable>
        </div>
        {(pageIndex === 0 && rows.length < pageSize) ? null :
        <div style={{display: "flex", alignItems: "center"}}>
          <Pagination count={pageCount} showFirstButton showLastButton onChange={(e, page) => gotoPage(page - 1)} />
          <TablePagination
            style={{marginLeft: 'auto'}}
            component="div"
            count={data.length}
            page={pageIndex}
            onChangePage={(e, page) => gotoPage(page)}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={e => setPageSize(e.target.value)}
          />
        </div>}
      </div>
    )
}
  


// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalRecords,
  serverTimeZone
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    visibleColumns,
    rows,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    useResizeColumns,
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  // Render the UI for your table
  return (
    <div style={{overflow: "hidden"}} className="react-base-table-wrapper">
      <div style={{maxWidth: "100%", overflow: 'auto'}}>
        <MuiTable {...getTableProps()} className="react-base-table">
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell size="small" {...column.getHeaderProps()} style={{width: column.width}}>
                    {column.render('Header')}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <TableRow><TableCell colSpan={visibleColumns.length}><LoaderIcon style={{ width: 40, height: 60 }} /></TableCell></TableRow>
            ) : (page.length ? page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} serverTimeZone={serverTimeZone}>
                  {row.cells.map(cell => {
                    return <DrawTableCell cell={cell} simple />
                  })}
                </TableRow>
              )
            }) : <TableRow><TableCell colSpan={visibleColumns.length}>Record not found</TableCell></TableRow>)}
          </TableBody>
        </MuiTable>
    
      </div>
      {(pageIndex === 0 && rows.length < pageSize) ? null :
        <div style={{display: "flex", alignItems: "center"}}>
          <Pagination page={pageIndex + 1} count={pageCount} showFirstButton showLastButton onChange={(e, page) => gotoPage(page - 1)} />
          <TablePagination
            style={{marginLeft: 'auto'}}
            component="div"
            count={totalRecords}
            page={pageIndex}
            onChangePage={(e, page) => gotoPage(page)}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 20, 40, 50, 75, 100]}
            onChangeRowsPerPage={e => setPageSize(e.target.value)}
          />
        </div>}
    </div>
  )
}


export const RemoteReactTable = ({ columns, query, serverTimeZone }) => {

  // We'll start our table without any data
  const [data, setData] = React.useState([])
  const [totalRecords, setTotal] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const fetchIdRef = React.useRef(0)


  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    axios.get(`${query}&limit=${pageSize}&page=${pageIndex+1}`).then(response => {
      if(response.status === 200 && response.data && response.data.status === "success") {
        const { data, total } = response.data.data;
         data.map(r =>{
          r.address = HtmlParser(r.address)
          let attributes=[];
          Object.entries(r.attributes).map(([key,value])=>{
           let dataAttributes = setAttributeFormat (key, value)
           attributes.push([key,dataAttributes])

          })
          r.attributes = Object.fromEntries(attributes)
        })
        setData(data)
        setPageCount(Math.ceil(total / pageSize))
        setTotal(total);
        setLoading(false)
      }
    })
  }, [])

  return (
      <Table
        columns={columns}
        data={data}
        totalRecords={totalRecords}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
  )
}