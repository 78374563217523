import React, { Component } from "react";
import { checkPrivileges } from "../../../Helpers";
import { Grid } from "@material-ui/core";

import Style from "style-it";
import TextField from "../../../Components/common/TextField";
import Switch from "../../../Components/common/Switch";
import Button from "../../../Components/common/Button";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
export default class GroupsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelection: "both",
      bothTypes: {
        vehicleType: "Passenger",
        fuelType: "Gasoline",
        workingDays: 250,
        mileageYearly: 150000,
        fuelPrice: 2.23,
        depPeriodMonths: 5,
        fuelConsumption: 12,
      },
      ownTypes: {
        purchaseValue: 200000,
        repairCostYearly: 2000,
        monthlyLaborCost: 2000,
        monthlyAdminCost: 5000,
        otherCost: 0,
        disposalValue: 35,
        acquisitionValue: 5000,
        maintenanceCostYearly: 4000,
        noOfDownTime: 5,
        avgDownTimeCost: 4000,
        annualInsurance: 2000,
        modificationCost: 20000,
        telematicesHW: 1000,
        telematicsYearly: 500,
        bankInterestRate: 4,
        zakatRate: 2.5,
        FundsCostRate: 8,
      },
      rentTypes: {
        monthlyLeaseR: 2000,
        repairCostYearlyR: 2000,
        monthlyLaborCostR: 2000,
        adminMonthlyCostR: 5000,
        noOfDownTimeR: 5,
        avgDownTimeCostR: 4000,
        otherCostR: 0,
      },
      visible: true,
    };
    this.switchView = this.switchView.bind(this);
  }
  componentWillMount() {
    if (this.props.fetchMoreItems2 && this.props.itemPagination2.total === 0) {
      this.props.fetchMoreItems2();
    }
  }

  switchView = (select) => {
    this.setState({ tabSelection: select });
  };

  handleChangeBoth = (type, value) => {
    this.setState(
      {
        bothTypes: {
          ...this.state.bothTypes,
          [type]: value,
        },
      },
      () => {
        this.checkReqFields();
      }
    );
  };

  handleChangeOwn = (type, value) => {
    this.setState(
      {
        ownTypes: {
          ...this.state.ownTypes,
          [type]: value,
        },
      },
      () => {
        this.checkReqFields();
      }
    );
  };

  handleChangeRent = (type, value) => {
    this.setState(
      {
        rentTypes: {
          ...this.state.rentTypes,
          [type]: value,
        },
      },
      () => {
        this.checkReqFields();
      }
    );
  };

  checkReqFields = () => {
    const { rentTypes, ownTypes, bothTypes } = this.state;

    if (
      bothTypes.fuelConsumption &&
      rentTypes.monthlyLeaseR &&
      ownTypes.purchaseValue &&
      ownTypes.repairCostYearly &&
      ownTypes.disposalValue &&
      ownTypes.maintenanceCostYearly &&
      ownTypes.noOfDownTime &&
      ownTypes.annualInsurance
    ) {
      this.setState({ visible: true });
    }
  };

  submitForm = (day) => {
    const {
      depPeriodMonths,
      mileageYearly,
      fuelConsumption,
      fuelPrice,
      workingDays,
    } = this.state.bothTypes;
    const {
      purchaseValue,
      repairCostYearly,
      monthlyLaborCost,
      otherCost,
      maintenanceCostYearly,
      monthlyAdminCost,
      disposalValue,
      acquisitionValue,
      modificationCost,
      telematicesHW,
      noOfDownTime,
      avgDownTimeCost,
      annualInsurance,
      telematicsYearly,
    } = this.state.ownTypes;
    const {
      monthlyLeaseR,
      repairCostYearlyR,
      monthlyLaborCostR,
      adminMonthlyCostR,
      noOfDownTimeR,
      avgDownTimeCostR,
      otherCostR,
    } = this.state.rentTypes;

    let bankInterest = parseFloat(this.state.ownTypes.bankInterestRate) / 100;
    let zakat = parseFloat(this.state.ownTypes.zakatRate) / 100;
    let funds = parseFloat(this.state.ownTypes.FundsCostRate) / 100;

    // Own vehicle
    let ownFuelCost = (mileageYearly / fuelConsumption) * fuelPrice;
    let owntotalAcquisition =
      purchaseValue -
      (purchaseValue * disposalValue) / 100 +
      acquisitionValue +
      modificationCost +
      telematicesHW +
      purchaseValue * funds * depPeriodMonths;
    let ownMaintenanceCost =
      (repairCostYearly +
        otherCost +
        maintenanceCostYearly +
        depPeriodMonths * avgDownTimeCost) *
      depPeriodMonths;
    let ownMonthlyOps =
      (monthlyLaborCost + monthlyAdminCost) * depPeriodMonths * 12;
    let ownAnnulRenewalCharges =
      (annualInsurance + telematicsYearly) * depPeriodMonths;
    let ownBankinterest = purchaseValue * bankInterest * depPeriodMonths;

    let G33 = purchaseValue / depPeriodMonths;
    let G35 = purchaseValue - G33;
    let H35 = G35 - G33;
    let I35 = H35 - G33;
    let J35 = I35 - G33;

    let ownZakat = G35 * bankInterest + H35 * zakat + I35 * zakat + J35 * zakat;

    let ownTCO =
      ownFuelCost +
      owntotalAcquisition +
      ownMaintenanceCost +
      ownMonthlyOps +
      ownAnnulRenewalCharges +
      ownBankinterest +
      ownZakat;
    let ownTCOKM = ownTCO / mileageYearly;
    let ownTCODAY = ownTCO / workingDays;

    let formatOwnData = [
      { name: "Fuel Cost", y: ownFuelCost, color: "#7CB5EC" },
      { name: "Total Acquisition", y: owntotalAcquisition, color: "#90ED7D" },
      { name: "Maintenance Cost", y: ownMaintenanceCost, color: "#F7A35C" },
      { name: "Monthly Ops", y: ownMonthlyOps, color: "#CA62DD" },
      {
        name: "Annul Renewal charges",
        y: ownAnnulRenewalCharges,
        color: "#90ed7dd9",
      },
      { name: "Bank interest", y: ownBankinterest, color: "#434348" },
      { name: "Zakat", y: ownZakat, color: "#00FF00" }, //green
      { name: "TCO", y: ownTCO, color: "#E3D254" }, //yellow
      { name: "TCO per KM", y: ownTCOKM, color: "#F44336" }, //red
      { name: "TCO per Day", y: ownTCODAY, color: "#8C8E59" }, //light green
    ];

    // Rent data

    let rentFuelCost = (mileageYearly / fuelConsumption) * fuelPrice;
    let rentTotalAcquisition = depPeriodMonths * monthlyLeaseR * 12;
    let rentMaintenanceCost =
      (repairCostYearlyR + otherCostR + noOfDownTimeR * avgDownTimeCostR) *
      depPeriodMonths;
    let rentMonthlyOps =
      (monthlyLaborCostR + adminMonthlyCostR) * depPeriodMonths * 12;
    let rentAnaualCharges = 0;
    let rentTCO =
      rentFuelCost +
      rentTotalAcquisition +
      rentMonthlyOps +
      rentMaintenanceCost;
    let rentTCOKM = rentTCO / this.state.bothTypes.mileageYearly;
    let rentTCODay = rentTCO / this.state.bothTypes.workingDays;

    let formatRentData = [
      { name: "Fuel Cost", y: rentFuelCost, color: "#7CB5EC" },
      { name: "Total Acquisition", y: rentTotalAcquisition, color: "#90ED7D" },
      { name: "Maintenance Cost", y: rentMaintenanceCost, color: "#900972" },
      { name: "Monthly Ops", y: rentMonthlyOps, color: "#F7A35C" },
      { name: "TCO", y: rentTCO, color: "#CA62DD" },
      { name: "TCO per KM", y: rentTCOKM, color: "#90ed7dd9" },
      { name: "TCO Per Day", y: rentTCODay, color: "#434348" },
    ];
    this.props.submitData(formatOwnData, formatRentData);
  };

  render() {
    return (
      <Style>
        {`
        .material-icons {vertical-align: middle; color: inherit}
				`}
        <div className="fms-sidebar sidebar-bg-layer">
          <div
            style={{
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor,
              borderRadius: 6,
              padding: 8,
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "space-around" }}
              className="sidebar-icon-gap"
            >
              <span
                onClick={(e) => this.switchView("both")}
                className={
                  this.state.tabSelection === "both"
                    ? "common-tab tab-selected"
                    : "common-tab"
                }
              >
                {this.props.translate("both")}
              </span>
              <span
                onClick={(e) => this.switchView("own")}
                className={
                  this.state.tabSelection === "own"
                    ? "common-tab tab-selected"
                    : "common-tab"
                }
              >
                {this.props.translate("own")}
              </span>
              <span
                onClick={(e) => this.switchView("rent")}
                className={
                  this.state.tabSelection === "rent"
                    ? "common-tab tab-selected"
                    : "common-tab"
                }
              >
                {this.props.translate("rent")}
              </span>
            </div>
            {this.state.tabSelection === "both" ? (
              <Grid container spacing={1} style={{ marginTop: 20 }}>
                <Grid item md={6}>
                  <TextField
                    id="vehicleType"
                    label={this.props.translate("vehicleType")}
                    type="text"
                    placeholder=""
                    value={this.state?.bothTypes?.vehicleType || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("vehicleType", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="fuelType"
                    label={this.props.translate("fuelType")}
                    type="text"
                    placeholder=""
                    value={this.state?.bothTypes?.fuelType || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("fuelType", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="workingDays"
                    label={this.props.translate("workingDays")}
                    type="number"
                    placeholder=""
                    value={this.state?.bothTypes?.workingDays || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("workingDays", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="mileageYearly"
                    label={this.props.translate("mileageYearly")}
                    type="number"
                    placeholder=""
                    value={this.state?.bothTypes?.mileageYearly || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("mileageYearly", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="fuelPrice"
                    label={this.props.translate("fuelPrice")}
                    type="number"
                    placeholder=""
                    value={this.state?.bothTypes?.fuelPrice || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("fuelPrice", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="depPeriodMonths"
                    label={this.props.translate("depPeriodMonths")}
                    type="number"
                    placeholder=""
                    value={this.state?.bothTypes?.depPeriodMonths || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("depPeriodMonths", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="FuelConsumption"
                    label={this.props.translate("FuelConsumption")}
                    type="number"
                    placeholder=""
                    value={this.state?.bothTypes?.fuelConsumption || ""}
                    onChange={(e) =>
                      this.handleChangeBoth("fuelConsumption", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
            ) : this.state.tabSelection === "own" ? (
              <ul className="list-view with-padding-right geofence-sidebar-list">
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id="scrollableDiv"
                        />
                      );
                    },
                  }}
                >
                  <InfiniteScroll
                    dataLength={10}
                    // next={this.props.fetchMoreItems}
                    // hasMore={this.props.itemPagination.hasNext}
                    // loader={<Loader defaultStyle={true} imgSize={50} />}
                    scrollableTarget="scrollableDiv"
                    // endMessage={this.props.endMessage}
                  >
                    <Grid container spacing={1} style={{ marginTop: 20 }}>
                      <Grid item md={6}>
                        <TextField
                          id="purchaseValue"
                          label={this.props.translate("purchaseValue")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.purchaseValue || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "purchaseValue",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="repairCostYearly"
                          label={this.props.translate("repairCostYearly")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.repairCostYearly || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "repairCostYearly",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="monthlyLaborCost"
                          label={this.props.translate("monthlyLaborCost")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.monthlyLaborCost || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "monthlyLaborCost",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="monthlyAdminCost"
                          label={this.props.translate("monthlyAdminCost")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.monthlyAdminCost || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "monthlyAdminCost",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="otherCost"
                          label={this.props.translate("otherCost")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.otherCost || ""}
                          onChange={(e) =>
                            this.handleChangeOwn("otherCost", e.target.value)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="disposalValue"
                          label={this.props.translate("disposalValue")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.disposalValue || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "disposalValue",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="acquisitionValue"
                          label={this.props.translate("acquisitionValue")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.acquisitionValue || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "acquisitionValue",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="maintenanceCostYearly"
                          label={this.props.translate("maintenanceCostYearly")}
                          type="number"
                          placeholder=""
                          value={
                            this.state?.ownTypes?.maintenanceCostYearly || ""
                          }
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "maintenanceCostYearly",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="noOfDownTime"
                          label={this.props.translate("noOfDownTime")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.noOfDownTime || ""}
                          onChange={(e) =>
                            this.handleChangeOwn("noOfDownTime", e.target.value)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="avgDownTimeCost"
                          label={this.props.translate("avgDownTimeCost")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.avgDownTimeCost || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "avgDownTimeCost",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="annualInsurance"
                          label={this.props.translate("annualInsurance")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.annualInsurance || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "annualInsurance",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="modificationCost"
                          label={this.props.translate("modificationCost")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.modificationCost || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "modificationCost",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="telematicesHW"
                          label={this.props.translate("telematicesHW")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.telematicesHW || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "telematicesHW",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="telematicsYearly"
                          label={this.props.translate("telematicsYearly")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.telematicsYearly || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "telematicsYearly",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="bankInterestRate"
                          label={this.props.translate("bankInterestRate")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.bankInterestRate || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "bankInterestRate",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="zakatRate"
                          label={this.props.translate("zakatRate")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.zakatRate || ""}
                          onChange={(e) =>
                            this.handleChangeOwn("zakatRate", e.target.value)
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="FundsCostRate"
                          label={this.props.translate("FundsCostRate")}
                          type="number"
                          placeholder=""
                          value={this.state?.ownTypes?.FundsCostRate || ""}
                          onChange={(e) =>
                            this.handleChangeOwn(
                              "FundsCostRate",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </InfiniteScroll>
                </Scrollbar>
              </ul>
            ) : (
              <Grid container spacing={1} style={{ marginTop: 20 }}>
                <Grid item md={12}>
                  <TextField
                    id="monthlyLease"
                    label={this.props.translate("monthlyLease")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.monthlyLeaseR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("monthlyLeaseR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="repairCost"
                    label={this.props.translate("repairCost")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.repairCostYearlyR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("repairCostYearlyR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="monthlyLaborCosts"
                    label={this.props.translate("monthlyLaborCosts")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.monthlyLaborCostR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("monthlyLaborCostR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="adminMonthlyCost"
                    label={this.props.translate("adminMonthlyCost")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.adminMonthlyCostR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("adminMonthlyCostR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="noOfDownTime"
                    label={this.props.translate("noOfDownTime")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.noOfDownTimeR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("noOfDownTimeR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="avgDownTimeCost"
                    label={this.props.translate("avgDownTimeCost")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.avgDownTimeCostR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("avgDownTimeCostR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="otherCost"
                    label={this.props.translate("otherCost")}
                    type="number"
                    placeholder=""
                    value={this.state?.rentTypes?.otherCostR || ""}
                    onChange={(e) =>
                      this.handleChangeRent("otherCostR", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <Button
                size="small"
                // className='button-white'
                onClick={() => this.submitForm()}
                disabled={!this.state.visible}
              >
                {this.props.translate("submit")}{" "}
              </Button>
              {/* <Button
                        size='small'
                        // className='button-white'
                        // onClick={()=>uploadFile(mode)} 
                        disabled={false}
                    >
                        {('ResetForm')}{' '}
                    </Button> */}
            </div>
          </div>
        </div>
      </Style>
    );
  }
}
