import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
import { logInUserInfo } from '../../Actions/Users'
import { checkPrivileges } from '../../Helpers'
import instance from '../../axios'
import Dashboard from '../Dashboard'
import MaintenanceDashboard from '../MaintenanceDashboard'

class MainDashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected:checkPrivileges("maintenanceDashboard") ? "maintenance" : "dashboard",
    }
  }
   render () {
      if (this.props.logInUser ) {
        return (
          <Layout
            noSidebar
            {...this.props}
          >
              <div
                style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    marginTop:50,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
                >
                        <div style={{ maxWidth:500 }}>
                            {checkPrivileges("maintenanceDashboard") && (<span style={{display: "table-cell",
                                    width: "1%",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: "inherit",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    // background: "#0a83a1",
                                    cursor:"pointer",
                                    color: "#ffffff",
                                    background: this.state.selected==='maintenance'? this.props.themecolors.menuActiveBackground : this.props.themecolors.themeDarkColor,
                                    padding:8

                            }} onClick={()=>{this.setState({selected:"maintenance"})}}>{this.props.translate("maintenanceDashboard")}</span>)}
                            <span style={{display: "table-cell",
                                    width: "1%",
                                    borderRadius: "6px 6px 0 0",
                                    fontSize: "inherit",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    // background: "#0a83a1",
                                    cursor:"pointer",

                                    color:"#ffffff",
                                    padding:8,
                                    background: this.state.selected==='dashboard'? this.props.themecolors.menuActiveBackground  : this.props.themecolors.themeDarkColor,

                            }} onClick={()=>{this.setState({selected:"dashboard"})}}>{this.props.translate("dashboard")}</span>
                        </div>
                         <div style={{ position: 'relative', marginTop:15 }}>

                                {this.state.selected ==="dashboard" ? 
                                        <Dashboard  {...this.props}/>
                                :  this.state.selected ==="maintenance" && checkPrivileges("maintenanceDashboard") ?
                                 <MaintenanceDashboard {...this.props}/>
                                : null}
                            </div>
                </div>
          </Layout>
        )
      } else {
        instance({
          url: `/api/session`,
          method: 'GET',
          headers: { Accept: 'application/json; odata=verbose' }
        })
            .then(userInfo => {
              this.props.setActiveLanguage(userInfo.attributes.lang || 'en')
              this.props.dispatch(logInUserInfo(userInfo))
          }).catch(error => {
            this.props.history.push('/logout')
          })
        return null
      }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})

export default connect(mapStateToProps)(withLocalize(MainDashboard))
