import React, { Component } from 'react';
import SimpleModalWrapped from '../common/Modal'
import Grid from '@material-ui/core/Grid'
import TextField from '../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import { Translate, withLocalize } from 'react-localize-redux'
const styles = theme => ({})
class addRecourcesGroup extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
      // console.log("from =", this.props.onClose)
    }

    render() {
        return (
          <div style={{ height: '100%', flex: 1 }}>
          <SimpleModalWrapped
            visable={true}
            notShowAttributesBtn
            isButtonVisable={true}
            title={this.props.title || 'sharedName'}
            formSubmit={this.props.formSubmit}
            modalControle={this.props.onClose}
            buttonText={this.props.form && this.props.form.id ? 'update' : 'sharedCreate'}
            btnCloseText='sharedBack'
            isVisableBtn={this.props.isVisableUserBtn}
            selectedItemId={this.props.selectedVehicle}
            content={
              <form autoComplete='off'>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id='name'
                      label={<Translate id="sharedName"/>}
                      required
                      placeholder=''
                      value={(this.props.form&&this.props.form.name) || ''}
                      onChange={e => this.props.handleChange('name', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id='description'
                      label={<Translate id="sharedDescription"/>}
                      placeholder=''
                      value={(this.props.form&&this.props.form.description) || ''}
                      onChange={e => this.props.handleChange('description', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id='itemType'
                      label={'Item Type'}
                      placeholder=''
                      value={(this.props.form&&this.props.form.itemType) || ''}
                      onChange={e => this.props.handleChange('itemType', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid> */}
                  
                
                </Grid>
              </form>
            }
          />
      </div>
        );
    }
}

const AddRecourcesGroup = withStyles(styles)
export default AddRecourcesGroup(withLocalize(addRecourcesGroup))