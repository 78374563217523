import React, { Component } from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Notifications from 'react-notification-system-redux'
import Checkbox from '../../../Components/common/CheckboxPermissions'
import Tooltip from '../../../Components/common/Tooltip'
import Autocomplete from '../../../Components/common/Autocomplete'
import ReactHtmlParser from 'react-html-parser'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import '@webscopeio/react-textarea-autocomplete/style.css'
import { FormControlLabel } from '@material-ui/core'
import Switch from '../../../Components/common/Switch'
import instance from '../../../axios'
import { errorHandler } from '../../../Helpers'
const operators = [ 
  {value: '+', label: '(+) Sum'},
  {value: '-', label: '(-) Subtract'},
  {value: '*', label: '(*) Multiply'},
  {value: '/', label: '(/) Divide'}];
class DecoderSensors extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.computedAttributes_Selection = this.computedAttributes_Selection.bind(
      this
    )
  }

  handleChange (name, event) {
    this.props.handleChange(name, event)
  }

  changeAttribute (name, value) {
    this.props.changeAttribute(name, value)
  }

  computedAttributes_Selection (deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId
    }

    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
        url: `/api/permissions/`,
        method: 'POST',
        data: {
          ...obj
        }
      })
      .then(response => {
        // if (response.status === 204) {
          // this.props.dispatch(addComputedAttributeDeviceWise(item));
          // this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('attributeAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        // } else {
        //   throw response
        // }
      })
    //   .catch(e => {
    //     if (e && e.text) {
    //       e.text().then(err => {
    //         alert(err)
    //       })
    //     }
    //   })
    .catch(error => {errorHandler(error,this.props.dispatch)}) 
  }

  render () {

    return (<div style={{ minHeight: 300 }}>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextField
                id='description'
                label={this.props.translate('sharedDescription')}
                type='text'
                value={ReactHtmlParser(this.props.form.description) || ''}
                onChange={e => this.handleChange('description', e)}
                variant='outlined'
                margin='dense'
                fullWidth
                />
            </Grid>         
            <Grid item xs={6}>
                <TextField
                id='attribute'
                label={this.props.translate('sharedAttribute')}
                type='text'
                value={this.props.form.attribute || ''}
                onChange={e => this.handleChange('attribute', e)}
                variant='outlined'
                margin='dense'
                fullWidth
                />
            </Grid>  
            <Grid item xs={12} sm={6} md={6}>
                <Autocomplete
                    translate={this.props.translate}
                    value={this.props.form.attributes.input || ''}
                    suggestions={this.props.suggestions}
                    handleChange={this.changeAttribute}
                    fieldName='input'
                    label={this.props.translate('sensorInput')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <TextField
                    id='valueFrom'
                    label={this.props.translate('positionTooltip.valueFrom')}
                    type='text'
                    value={this.props.form.attributes && this.props.form.attributes.valueFrom ? this.props.form.attributes.valueFrom : ''}
                    onChange={e => this.changeAttribute('valueFrom', e.target.value)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    select
                >
                    {conversionTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <div style={{border: '1px solid rgba(0,0,0,0.1)', padding: 10 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}><span className="with-border">{this.props.translate('apply1stConversion')}</span></Grid>
                        {this.props.form.attributes.valueFrom !== 'decimal' ? <>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={this.props.form.attributes && this.props.form.attributes['1stLittleEndian']}
                                    onChange={e => this.changeAttribute('1stLittleEndian', e.target.checked)}
                                    name='1stLittleEndian'
                                />
                                }
                                label={this.props.translate('positionTooltip.littleEndian')}
                                classes={{ label: 'color-inherit' }}
                            />
                        </Grid>
                        <Grid item xs={this.props.form.attributes && this.props.form.attributes['1stFullValue'] !== true ? 4: 6}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={this.props.form.attributes && this.props.form.attributes['1stFullValue']}
                                    onChange={e => this.changeAttribute('1stFullValue', e.target.checked)}
                                    name='1stFullValue'
                                />
                                }
                                label={this.props.translate('positionTooltip.FullValue')}
                                classes={{ label: 'color-inherit' }}
                            />
                        </Grid>
                        {this.props.form.attributes && this.props.form.attributes['1stFullValue'] !== true ? <>
                        <Grid item xs={4}>
                            <TextField
                                id='1stStartBit'
                                label={this.props.translate('positionTooltip.StartBit')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['1stStartBit'] ? this.props.form.attributes['1stStartBit'] : ''}
                                onChange={e => this.changeAttribute('1stStartBit', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id='1stEndBit'
                                label={this.props.translate('positionTooltip.EndBit')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['1stEndBit'] ? this.props.form.attributes['1stEndBit'] : ''}
                                onChange={e => this.changeAttribute('1stEndBit', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                            />
                        </Grid>
                        </> : null}
                        </> : null}
                        <Grid item xs={this.props.form.attributes && this.props.form.attributes['1stFullValue'] !== true ? 12 : 6}>
                            <TextField
                                id='1stConv'
                                label={this.props.translate('positionTooltip.Conv')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['1stConv'] ? this.props.form.attributes['1stConv'] : ''}
                                onChange={e => this.changeAttribute('1stConv', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                select
                            >
                                {conversionTypes.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </div>
            </Grid> 
            
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                    <Switch
                        checked={this.props.form.attributes && this.props.form.attributes['need2ndConv']}
                        onChange={e => this.changeAttribute('need2ndConv', e.target.checked)}
                        name='need2ndConv'
                    />
                    }
                    label={this.props.translate('needfurtherConversion(Optional)')}
                    classes={{ label: 'color-inherit' }}
                />
            </Grid>
            {this.props.form.attributes && this.props.form.attributes['need2ndConv'] ?  
            <Grid item xs={12}>
                <div style={{border: '1px solid rgba(0,0,0,0.1)', padding: 10 }}>
                    <Grid container spacing={1}>
                        {this.props.form.attributes['1stConv'] !== 'decimal' ? <>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={this.props.form.attributes && this.props.form.attributes['2ndLittleEndian']}
                                    onChange={e => this.changeAttribute('2ndLittleEndian', e.target.checked)}
                                    name='2ndLittleEndian'
                                />
                                }
                                label={this.props.translate('positionTooltip.littleEndian')}
                                classes={{ label: 'color-inherit' }}
                            />
                        </Grid>

                        <Grid item xs={this.props.form.attributes && this.props.form.attributes['2ndFullValue'] !== true ? 4: 6}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={this.props.form.attributes && this.props.form.attributes['2ndFullValue']}
                                    onChange={e => this.changeAttribute('2ndFullValue', e.target.checked)}
                                    name='2ndFullValue'
                                />
                                }
                                label={this.props.translate('positionTooltip.FullValue')}
                                classes={{ label: 'color-inherit' }}
                            />
                        </Grid>
                        {this.props.form.attributes && this.props.form.attributes['2ndFullValue'] !== true ? <>
                        <Grid item xs={4}>
                            <TextField
                                id='2ndStartBit'
                                label={this.props.translate('positionTooltip.StartBit')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['2ndStartBit'] ? this.props.form.attributes['2ndStartBit'] : ''}
                                onChange={e => this.changeAttribute('2ndStartBit', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id='2ndEndBit'
                                label={this.props.translate('positionTooltip.EndBit')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['2ndEndBit'] ? this.props.form.attributes['2ndEndBit'] : ''}
                                onChange={e => this.changeAttribute('2ndEndBit', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                            />
                        </Grid>
                        </> : null}

                        </> : null }


                        <Grid item xs={this.props.form.attributes && this.props.form.attributes['2ndFullValue'] !== true ? 12 : 6}>
                            <TextField
                                id='2ndConv'
                                label={this.props.translate('positionTooltip.Conv')}
                                type='number'
                                value={this.props.form.attributes && this.props.form.attributes['2ndConv'] ? this.props.form.attributes['2ndConv'] : ''}
                                onChange={e => this.changeAttribute('2ndConv', e.target.value)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                select
                            >
                                {conversionTypes.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </div>
            </Grid> : null}
            {this.props.form.attributes['1stConv'] === 'decimal' && !this.props.form.attributes['need2ndConv'] || (this.props.form.attributes['2ndConv'] === 'decimal' && this.props.form.attributes['need2ndConv']) ? <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id='arithmeticOpp'
                        label={this.props.translate('positionTooltip.arithmeticOpp')}
                        type='text'
                        value={this.props.form.attributes && this.props.form.attributes.arithmeticOpp ? this.props.form.attributes.arithmeticOpp : ''}
                        onChange={e => this.changeAttribute('arithmeticOpp', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        select
                    >
                        {arithmeticTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id='arithmeticArgument'
                        label={this.props.translate('positionTooltip.arithmeticArgument')}
                        type='number'
                        value={this.props.form.attributes && this.props.form.attributes.arithmeticArgument ? this.props.form.attributes.arithmeticArgument : ''}
                        onChange={e => this.changeAttribute('arithmeticArgument', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                    />
                </Grid>
            </>: null}

            <Grid item xs={12}>
                <Tooltip
                classes={{
                    popper: 'menu-popper',
                    tooltip: 'menu-popper-tooltip'
                }}
                title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
                >
                <Checkbox
                    canAssign
                    canRemove
                    translate={this.props.translate}
                    checked={this.props.form.attributes.copyFromLast}
                    onChange={e =>
                    this.changeAttribute('copyFromLast', e.target.checked)
                    }
                    label={this.props.translate('copyFromLast')}
                />
                </Tooltip>
            </Grid>
        </Grid>
    </div>)
  }
}

const conversionTypes = [
    {label: 'Decimal', value: 'decimal'},
    {label: 'Binary', value: 'binary'},
    {label: 'Hexa Decimal', value: 'hexDecimal'}
]
const arithmeticTypes = [
    {label: 'Add', value: 'add'},
    {label: 'Subtract', value: 'subtract'},
    {label: 'Divide', value: 'divide'},
    {label: 'Multiple', value: 'multiple'}
]

const mapState = state => ({
  positions: state.positions,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withSnackbar(DecoderSensors))
