import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import Dialog from '../common/Dialog'
class DriverSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      displayList: [],
      search: '',
      isChecked: false,
      driverModal: false,
      modalData: {}
    }
  }
  componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }

    this.prepareDrivers(this.props)
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      displayList: [],
      isChecked: false
    })
  }

  componentWillReceiveProps (n) {
    let list = {}
    if (n.value && n.value.length) {
      n.value.map(d => (list[d] = true))
    }
    this.setState({ list }, () => {
      this.prepareDrivers(n)
    })
  }

  prepareDrivers = n => {
    if (n.applyFilter && n.driverList && n.driverList.length) {
      let ids = []
      let displayList = n.driverList.filter(dr => {
        if (n.displayList && n.displayList.includes(dr.uniqueId)) {
          ids.push(dr.uniqueId)
          return true
        } else {
          return false
        }
      })

      if (n.displayList && displayList.length !== this.props.displayList) {
        let remaining = n.displayList.filter(id => !ids.includes(id))
        let li = remaining.map(r => ({ name: '', uniqueId: r, id: r }))
        this.setState({ displayList: [...displayList, ...li] })
      }
    } else {
      let displayList = n.driverList
      this.setState({ displayList })
    }
  }
  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list))
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked: this.props.driverList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }
  searchItem = text => {
    this.setState({ search: text.target.value })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.props.driverList.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  onDriverClick = item => {
    let unitLinkDriver = null;
      unitLinkDriver = this.props.devices3.find(data => data.attributes.fixDriverId === item.id)
    this.setState(
      {
        modalData: {}
      },
      () => {
        const unitId = this.props.positions.find(
          p => p.attributes.driverUniqueId === item.uniqueId && this.props.allComputedAttributes && this.props.allComputedAttributes.length && this.props.allComputedAttributes.filter(row => row.item&&row.item.attribute ==='driverUniqueId' && row.deviceIds.includes(p.deviceId)).length)

        if (unitId && unitId.deviceId) {
          let unit = this.props.devices3.find(
            device => unitId.deviceId === device.id
          )
          this.setState({
            modalData: {
              ...item,
              unit
            },
            driverModal: true
          })
        } else {
          this.setState({
            modalData: {
              ...item,
              unit: unitLinkDriver || null
            },
            driverModal: true
          })
        }
      }
    )
  }

  handleCloseModal = () => {
    this.setState({
      modalData: {},
      driverModal: false
    })
  }

  render () {
    let list = this.state.displayList.filter(
      item => item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    )
    return (
      <Style>
        {`
        .selector-list li {
            margin-bottom: 6px;
          }
          .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            min-height: 26px;
        }
        .selector-list {
            padding: 0;
            margin: 0;
            list-style: none
        }
        .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-check {
            height: 26px;
            display: inline-flex;
            align-items: center;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px
        }`}
        <div>
          {/* {console.log("this.state.modalData ==",this.state.modalData)} */}
        {this.state.driverModal ? (
          <Dialog
            title={this.state.modalData.uniqueId}
            open={this.state.driverModal}
            maxWidth='xs'
            onClose={this.handleCloseModal}
            cancelText={this.props.translate('close')}
            noFullScreen
          >
            <div className='feature-section'>
              <div className='feature-content-section' style={{padding:'6px 16px'}}>
                <div className='item-row'>
                  <span className='row-label'>
                    {this.props.translate('driverName')}
                  </span>
                  <span className='row-value'>
                    {this.state.modalData.name || ''}
                  </span>
                </div>
                <div className='item-row'>
                  <span className='row-label'>
                    {this.props.translate('sensorsTranslation.driverUniqueId')}
                  </span>
                  <span className='row-value'>
                    {this.state.modalData.uniqueId || ''}
                  </span>
                </div>
                <div className='item-row'>
                  <span className='row-label'>
                    {this.props.translate('driverPhone')}
                  </span>
                  <span className='row-value'>
                    {(this.state.modalData.attributes &&
                      this.state.modalData.attributes.phone) ||
                      ''}
                  </span>
                </div>
                {this.props&&this.props.viewIMEI && (
                  <div className='item-row'>
                    <span className='row-label'>
                      {this.props.translate('unitId')}
                    </span>
                    <span className='row-value'>
                      {(this.state.modalData.unit &&
                        this.state.modalData.unit.uniqueId) ||
                        ''}
                    </span>
                  </div>
                )}
                <div className='item-row'>
                  <span className='row-label'>
                    {this.props.translate('reportDeviceName')}
                  </span>
                  <span className='row-value'>
                    {(this.state.modalData.unit &&
                      this.state.modalData.unit.name) ||
                      ''}
                  </span>
                </div>
              </div>
            </div>
          </Dialog>
        ) : null}
          <div
            className='section-head section-head-filter'
            style={{ padding: 0, marginLeft: -6 }}
          >
            <ul className='filter-row'>
              <li>
                <SearchField
                  label={this.props.translate('searchDriver')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className='selector-count'>
              {' '}
              {Object.keys(this.state.list).length}{' '}
              {this.props.translate('DriverSelected')}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDivDriver'
                  />
                )
              }
            }}
          >
            <ul className='selector-list'>
              {list.map(row => (
                <li
                  key={row.id}
                  // onClick={e => this.onDriverClick(row)}
                  onClick={e => !this.props.disableModal ? this.onDriverClick(row) : null}
                >
                  <label className={this.state.list[row.id] ? 'active' : null}>
                    {!this.props.readOnly ? (
                      <span className='selector-check'>
                        <Checkbox
                          checked={this.state.list[row.id]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                    ) : null}
                    <span className='selector-label'>
                      {row.name || row.uniqueId}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  driverList: state.drivers,
  themecolors: state.themeColors,
  positions: state.positions,
  devices3: state.devices3,
  allComputedAttributes: state.allComputedAttributes
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(DriverSelector))
