import { Grid } from '@material-ui/core';
import React from 'react';
import { checkPrivileges, selectAll } from '../../Helpers';
import Dialog from '../common/Dialog';
import Table from '../common/TableMultiCheckbox';
import AddRecourcesGroup from './AddRecourcesGroup';
class resourceModal extends React.PureComponent {
    constructor () {
        super()
        this.state = {
          isCreate: true,
          isVisableUserBtn: false,
          rowsPerPage: 5,
          processing: false,
          form: {}
        }
    }
    componentWillMount() {
        if(this.props.selectedResourse){
          this.setState({
            form: this.props.selectedResourse
          })
        }else{
          this.setState({
            form:{
              itemType: this.props.itemType
            }
          }) 
        }
    }
    componentWillReceiveProps(n){
        if(n.selectedResourse &&((n.selectedResourse.id) !== (this.state.form&&this.state.form.id))){
            this.setState({
                form: n.selectedResourse,
                isCreate:true
            })
         }
         else if(n.activeOperation==='addResource' && this.state.isCreate){
          this.setState({
            form:{
              itemType: this.props.itemType
            },
            isCreate:false
        })
         }
        }
    
    handleChange = (name, event) => {
      const { target } = event
      let value = target.value.toString()
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: value
              }
            },
            () => this.checkRequiredFields()
          )
      }
      checkRequiredFields () {
        let { name } = this.state.form
       
        if (name) {
          this.setState({
            isVisableUserBtn: true
          })
        } else {
          this.setState({
            isVisableUserBtn: false
          })
        }
      }
      formSubmit = () =>{
        this.props.changeResource(this.state.form)
        this.props.onCloseResource()
        this.setState({
          isCreate: true
        })
      }

      setProcessing = processing => {
        this.setState({processing})
      }

      checkHandleChange = (preState, item) => {
        this.setProcessing(true);
        if(preState){
          this.props.assignItem(this.state.form.id,item.id, () => {this.setProcessing(false)})
        } else{
          this.props.unassignItem(this.state.form.id,item.id, () => {this.setProcessing(false)})
        }
      }
      handleChangeRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage })
      }

      onChange = res => {
        this.props.fetchNestedItems(this.props.selectedResourse.id,1)
      }

    render() {
      let itemData = []
      this.props.itemPagination&&this.props.itemPagination.map((item, i) => {
        if(this.props.nestedResources.data.find(({id}) => id===item.id)){
          item={
            ...item,
            check: true
          }
        }else{
          item={
            ...item,
            check: false
          }
        }
        itemData.push(item)
          })

        let rowDefinition = [
          {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: this.props.translate('sharedName')
          },
          {
            id: 'uniqueId',
            numeric: false,
            disablePadding: false,
            label: this.props.translate('uniqueId')
          }
        ];
        
        if(this.props.itemType === 'Vehicle') {
          rowDefinition = [
            {
              id: 'label',
              numeric: false,
              disablePadding: false,
              label: this.props.translate('sharedName')
            },
            {
              id: 'vehicleLicensePlate',
              numeric: false,
              disablePadding: false,
              label: this.props.translate('vehicleLicensePlate')
            }
          ]
        }
        
        if(this.props.itemType === 'Geofence') {
          rowDefinition = [
            {
              id: 'name',
              numeric: false,
              disablePadding: false,
              label: this.props.translate('sharedName')
            },
            {
              id: 'description',
              numeric: false,
              disablePadding: false,
              label: this.props.translate('sharedDescription'),
            }
          ]
        }
       
        return (
            <div className="main-content-page">
              
                {['addResource', 'editResource'].includes(this.props.activeOperation) && (
                  <Grid
                    container
                    spacing={0}
                    className='driver-page-content'
                    style={{
                      background: this.props.themecolors.backgroundColor,
                      color: this.props.themecolors.textColor
                    }}
                  >
                    <AddRecourcesGroup
                      {...this.state}
                      title={this.props.title}
                      itemType={this.props.itemType}
                      handleChange={this.handleChange}
                      formSubmit={this.formSubmit}
                      onClose={this.props.onCloseResource}
                      />
                  </Grid>
                )}
                
                {this.props.linkResource && (
                <Dialog
                  disableFooter
                  title={this.props.selectedResourse.name}
                  onClose={this.props.onCloseResource}
                  maxWidth='sm'
                  // onFullScreen={this.onFullScreen}
                >
                  <Table
                    rows={itemData||[]}
                    title={this.state.processing || this.props.nestedResources.loading ? this.props.translate('sharedLoading') : this.props.itemType}
                    checkHandleChange={this.checkHandleChange}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    searchable
                    // insideDialog
                    // isEditable
                    selectAll={e =>
                    selectAll(
                      e,
                      'itemgroupid',
                      'itemid',
                      this.props.selectedResourse.id,
                      itemData,
                      itemData,
                      this.props.dispatch,
                      'abc',
                      this.props.translate,
                      this.onChange
                    )
                  }
                    disableHead
                    showCheckbox
                    rowsPerPage={this.state.rowsPerPage}
                    canRemove={checkPrivileges('itemgroupUnlinkItem')}
                    canAssign={checkPrivileges('itemgroupLinkItem')}
                    rowDefinition={rowDefinition}
                  />
                </Dialog>
                )}
            </div>
        );
    }
}

export default resourceModal;