import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler} from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import { FormHelperText, Grid } from '@material-ui/core'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import instance from '../../axios'
import { addTypes, getTypes } from '../../Actions/Services'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu'
import MenuItem from '../../Components/common/MenuItem'
import Table from '../../Components/common/tableWithBackEndPagination'

let allExpenseTypes = []
let subTypes = []
let allMakesData = []

const statusTypes = [
  {
    key: "packages",
    name: "packages",
  },
  {
    key: "services",
    name: "services",
  },
  {
    key: "items",
    name: "items",
  },
];
class Services extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // serviceForm: {generic:true},
      serviceForm: {
        // expensetypeId: this.props?.selectedServiceTypeId,
        attributes:{
          quantityPriceScale: [{quantity:"",price:""}],
        }
      },
      
      typeSelection: '',
      expenseId: '',
      mode: '',
      isVisableSubmitBtn: true,
      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      allMakes:[],
      selectedMake:'',
      deleteForm: {},
      servicesdata: [],
      expense: [],
      rowsPerPage: 5,
      allModels:[],
      allModelsData:[],
      serviceExpenseCollection:[{modelIds:'', makeId:'', selectedMakeTypes:[],edit:true}],
      intialFetch:true,
      checkEmpty:false,
      searchSubType:"",
      search:"",
      quantityError: "", // Initialize to empty string
      quantityHelperText:"",
      selectedExpenseType:""
    }
  }

  componentWillUnmount () {
    this.setState({
      serviceForm: {
        // expensetypeId: this.props?.selectedServiceTypeId,
        attributes:{
          quantityPriceScale: [{quantity:"",price:""}],
        }
      },
      mode: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      search:"",

      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
      // this.fetchVehicleMake()
      this.fetchMake()
    }
  }

   async fetchVehicleMake  (selectedMake) {
   return await instance({
      method: 'GET',
      url: `/api/models/get?page=1&limit=20&make=${selectedMake}&search= `,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        let defaultOptions = response&&response.data&&response.data.map(item => {
          return { ...item, label:item.modelName }
        })
        return defaultOptions
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
      })
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }
  
  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchServices(
          this.state.searchText,
          this.state.itemPagination.data
        )
      }
    )
  }

  fetchServices = (text, services) => {
    const u = services.filter(u =>(u.id + u.name).toLowerCase().includes(text.toLowerCase())
    )
    this.props.dispatch(getTypes(u))
  }

  fetchMake = () => {
    instance({
        method: 'GET',
        url:`/api/models/make`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          let defaultOptions = response&&response.categories&&response.categories.map(item => {
            return {  id:item, label:item }
          })
            this.setState({allMakes:defaultOptions},()=>{
              allMakesData=defaultOptions
            })
          })
        .catch(error => { errorHandler(error, this.props.dispatch) })
  }

  fetchData = (logInUser, page, perPage,pagination) => {
    let items = this.state.itemPagination.items
    instance({
      method: 'GET',
      url:
        this.props.ServerSetting && this.props.ServerSetting.contractNumber
          ? `/api/expensetypes/get?contractNumber=${ this.props.ServerSetting&&this.props.ServerSetting.contractNumber}`
          : `/api/expensetypes`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        page: page,
        limit: perPage,
      }
    })
      .then(response => {
        this.setState(
          {
            itemPagination: {
              ...response,
              items:items.concat(response.data)
            },
            currentPage: response.hasNext ? response.page + 1 : response.page,
            servicesdata:pagination?items.concat(response.data):[],
            checkEmpty:response.data && response.data.length ? false:true
          })
          if(this.state.intialFetch && (response.data&&response.data[0])) this.showSubTypes(response.data[0])
          if(response && response.data)  this.props.dispatch(getTypes(response.data))
        // this.setState({ servicesdata: response })
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
      })
  }

  openCreateTypeFrom = () => {
    this.setState({ mode: '', serviceForm: {}, typeSelection: '' }, () => {
      this.setState({
        mode: 'create',
        serviceForm: {
            contractNumber:this.props.ServerSetting&&this.props.ServerSetting.contractNumber
        },
        typeSelection: 'createType',
        checkEmpty:false
      })
    })
  }

  openCreateSubTypeFrom = () => {
    // console.log("TE=====", this.state.serviceForm);
    this.setState({  },()=>{
      this.setState({
        mode: 'create',
        serviceForm: {
          name:"",
          expenseType: this.state.serviceForm.name === "packages" || 
                       this.state.serviceForm.name === "services" || 
                       this.state.serviceForm.name === "items" ? 
                       this.state.serviceForm.name : "",
          attributes:{
            quantityPriceScale: [{quantity:"",price:""}],
          }
        },
        typeSelection: 'createSub'
      })
    })
  }

  openEditSubTypeFrom = data => {
    const item = data[0]
    const quantityPriceScaleArray = item?.attributes?.quantityPriceScale  ? Object.entries(item.attributes.quantityPriceScale).map(([quantity, price]) => ({
      quantity: parseFloat(quantity),
      price: parseFloat(price),
    })) : [{quantity:"",price:""}];
  
    const updatedItem = {
      ...item,
      attributes: {
        quantityPriceScale: quantityPriceScaleArray,
      },
    };
    this.setState({
      serviceForm: updatedItem,
     typeSelection: 'updateSub',
      mode: 'editSub',
    });
  }

  openEditTypeFrom = data => {
    this.setState({ mode: '', serviceForm: {}, typeSelection: '' }, () => {
      this.setState({
        mode: 'editSub',
        serviceForm: data,
        typeSelection: 'updateType'
      })
    })
  }

  onDeleteSub = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  removeSubType = () => {
    const id = this.state.deleteForm.id
    instance({
      url: `/api/expensetypes/subtype/${id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(commands => {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('subTypeIsDeleted'),
            autoDismiss: 10
          })
        )
        this.setState({
          deleteForm:{},
          onDeleteConfirmation:false
        },()=>{
          this.getExpense(this.state.expenseId)
        })
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
      })
  }

  showSubTypes = service => {
    // console.log("Ser=====", service);
    this.setState({ mode: '', serviceForm: {} }, () => {
      this.setState({
        mode: 'Show',
        serviceForm: {
          ...service,
          expenseType: service.name === "packages" || 
                       service.name === "services" || 
                       service.name === "items" ? 
                       service.name : "",
        },
        expenseId: service.id,
        selectedExpenseType:service,
        intialFetch:false,
        searchSubType: "",
        search: "",
      },()=>this.getExpense(service.id))
    } )
  }

  closeCreateFrom = () => {
    this.setState(
      {
        mode: '',
        // serviceForm: '',
        typeSelection: '',
        serviceForm: {generic:true},
        serviceExpenseCollection:[{modelIds:'', makeId:'', selectedMakeTypes:[],edit:true}]
      },
      () => this.checkRequiredFields()
    )
  }

  closeEditForm = () => {
    this.setState(
      {
        mode: '',
        serviceForm: '',
        typeSelection: '',
        checkEmpty:this.state.itemPagination.data.length ? false: true
      },
      () => this.checkRequiredFields()
    )
  }

  handleChange1 = (index, field, value) => {
    const { serviceForm } = this.state;
    const updatedForm = { ...serviceForm };
  
    // Update the quantity or price value with parseFloat
    if((field === "quantity" && value > 0) || (field === "price" && value >= 0)  ){

      updatedForm.attributes.quantityPriceScale[index][field] = parseFloat(value);
    }
    // Check for duplicate entries
    const quantities = updatedForm.attributes.quantityPriceScale.map(item => item.quantity);
    const hasDuplicates = (new Set(quantities)).size !== quantities.length;
  
    if (hasDuplicates) {
      this.setState({
        quantityError: "Duplicate entry",
        quantityHelperText: "Please enter unique quantities",
        
      } , ()=> this.checkRequiredFields());
      return;
    }
    else{
          this.setState({
            quantityError: "",
            quantityHelperText: "",
          } , ()=> this.checkRequiredFields());
        }
  
    this.setState({ serviceForm: updatedForm } , ()=> this.checkRequiredFields());
  };

  
  handleAddMore = () => {
    const { serviceForm } = this.state;
    const { quantityPriceScale } = serviceForm.attributes;
  
    const updatedQuantityPriceScale = [...quantityPriceScale, { quantity: "", price: "" }];
  
    const updatedForm = {
      ...serviceForm,
      attributes: {
        ...serviceForm.attributes,
        quantityPriceScale: updatedQuantityPriceScale,
      },
    };
  
    this.setState({ serviceForm: updatedForm });
  };
  

  handleDelete = index => {
    const { serviceForm } = this.state;
    const updatedForm = { ...serviceForm };
    if (updatedForm?.attributes?.quantityPriceScale?.length > 1) {
    updatedForm.attributes.quantityPriceScale.splice(index, 1);
    this.setState({ serviceForm: updatedForm });
  } else {
    // Display a message or perform any other action when attempting to delete the last row
    this.setState({
      deleteRow: true,
    });
  }
  };
  handleChange = (name, value) => {
    // console.log("name=====", name, value);
    if (name === 'vatPercent') {
      if (value < 0) {
        value = 0;
      }
      else if (value > 100) {
        value = 100;
      }
      
    }
    if(name === 'unitPrice' ||  name === 'purchasePrice'){
      if(value >= 0 ){
        this.setState(
          {
            serviceForm: {
              ...this.state.serviceForm,
              [name]: parseFloat(value)
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    else if (name === 'itemBrand'){
      this.setState(
        {
          serviceForm: {
            ...this.state.serviceForm,
            attributes:{
              ...this.state.serviceForm.attributes,
              [name]:value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
    else{
      this.setState(
        {
          serviceForm: {
            ...this.state.serviceForm,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  checkRequiredFields = () => {
    let { name, label, contractNumber ,subTypeNum, expenseType} = this.state.serviceForm
    let { typeSelection } = this.state
    if(typeSelection === "createType" || typeSelection === "updateType"){
      if(name && contractNumber ){
        this.setState({
          isVisableSubmitBtn: false
        })
      }
      else{
        this.setState({
          isVisableSubmitBtn: true
        })
      }
    }
    else{
      if (label && subTypeNum &&   expenseType) {
        this.setState({
          isVisableSubmitBtn: false
        })
      } else {
        this.setState({
          isVisableSubmitBtn: true
        })
      }
    }
  }
  
  searchSubTypeItem = (value, name) => {
    this.setState(
      { 
        currentPage:1,
        searchSubType:name === "itemSubType" ? value : '',
        search:name === "itemSubType" ? '' : value
      }
      );
  };

  handleChangeRowsPerPage1 = (rowsPerPage) => {
    this.setState({currentPage:1, pagesize: rowsPerPage }, () => {
      this.getExpense(this.state.selectedServiceTypeId);
    });
  };

  handleChangePage1 = (value) => {
    this.setState({ currentPage: value }, () => {
      this.getExpense(this.state.selectedServiceTypeId); 
    });
  };

  setPagination1 = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pagesize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pagesize = this.state.pagesize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
 
  getExpense = id => {
    if (id) {
      instance({
        method: 'GET',
        url: `/api/expensetypes/subtype/get?expenseTypeId=${id}&page=${this.state.currentPage}&limit=${this.state.pagesize}&subTypeNum=${this.state.searchSubType}&search=${this.state.search}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          this.setState({
            expense: res,
            selectedServiceTypeId:id,
            // serviceForm: {
            //   attributes:{
            //     quantityPriceScale: [{quantity:"",price:""}],
            //   }
            // },
          })
        })
        .catch(error => {
          errorHandler(error, this.props.dispatch)
        })
    }
  }

  checkData = ()=>{
    this.setState(
      {
        checkEmpty:this.state.itemPagination.data.length>1 ? false: true,
        mode:''
      }, ()=>{
        this.setState({
          mode: this.state.checkEmpty ? "": this.state.mode
        })
      })
  }
  
  submitForm1 = () => {
    let url =
      this.state.mode === "create"
        ? `/api/expensetypes/subtype`
        : `/api/expensetypes/subtype/${this.state.serviceForm.id}`;
  
    let updatedPayload;
    const quantityPriceScale = this.state.serviceForm?.attributes?.quantityPriceScale || {};
  
    if (Object.keys(quantityPriceScale).length === 0) {
      updatedPayload = {
        ...this.state.serviceForm,
        attributes: {
          quantityPriceScale: { 0: 0 },
        },
      };
    } else {
      updatedPayload = {
        ...this.state.serviceForm,
        attributes: {
          quantityPriceScale: quantityPriceScale.reduce((acc, item) => {
            acc[item.quantity] = item.price;
            return acc;
          }, {}),
        },
      };
    }
    // Convert empty strings to 0 and ensure values are numbers
    Object.keys(updatedPayload.attributes.quantityPriceScale).forEach((key) => {
      if (updatedPayload.attributes.quantityPriceScale[key] === '') {
        updatedPayload.attributes.quantityPriceScale[key] = 0;
      } else {
        updatedPayload.attributes.quantityPriceScale[key] = parseFloat(updatedPayload.attributes.quantityPriceScale[key]);
      }
    });
  
    // Convert the keys to numbers (if they are not already) and handle NaN keys
    updatedPayload.attributes.quantityPriceScale = Object.keys(updatedPayload.attributes.quantityPriceScale).reduce((acc, key) => {
      const numericKey = parseFloat(key);
      acc[Number.isNaN(numericKey) ? 0 : numericKey] = updatedPayload.attributes.quantityPriceScale[key];
      return acc;
    }, {});
  
    fetch(url, {
      method: this.state.mode === "create" ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedPayload),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            this.setState({
              serviceForm: {
                expensetypeId: this.props?.selectedServiceTypeId,
                attributes: {
                  quantityPriceScale: [{ quantity: "", price: "" }],
                }
              },
              createSubType: false
            }, () => {
              // this.props.selectService(this.props.selectedServiceTypeId);
            });
  
            if (res.status === "success") {
              this.props.dispatch(
                Notifications.success({
                  message:
                    this.state.mode === "create"
                      ? this.props.translate("subTypeCreated")
                      : this.props.translate("subTypeUpdated"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else {
              throw response;
            }
          });
  
          this.closeCreateFrom();
        }
      })
      .catch((error) => {
        console.log("error ==", error);
      });
  };
  
  submitForm = () => {
    let { mode, typeSelection, serviceForm, expenseId } = this.state
    let obj
    let params = ''
    let api
    if (mode === 'create') {
      if (typeSelection === 'createType') {
        obj = {
          ...serviceForm,
          attributes: {},
          parentId: 0
        }
        api = `/api/expensetypes/${params}`
      } else if (typeSelection === 'createSub') {

        if (Object.keys(this.state.serviceForm.attributes.quantityPriceScale).length === 0) {
          obj = {
            ...this.state.serviceForm,
                      parentId: 0,
              expensetypeId: expenseId,
            attributes: {
              // quantityPriceScale: { NaN: null },
              quantityPriceScale: { 0: 0 },
            },
          };
        }else{
          obj = {
            ...this.state.serviceForm,
                      parentId: 0,
              expensetypeId: expenseId,
            attributes: {
              quantityPriceScale: this.state.serviceForm?.attributes?.quantityPriceScale?.reduce((acc, item) => {
                acc[item.quantity] = item.price;
                return acc;
              }, {}),
            },
          };
        }
        // Convert empty strings to 0 and ensure values are numbers
          Object.keys(obj.attributes.quantityPriceScale).forEach((key) => {
            if (obj.attributes.quantityPriceScale[key] === '') {
              obj.attributes.quantityPriceScale[key] = 0;
            } else {
              obj.attributes.quantityPriceScale[key] = parseFloat(obj.attributes.quantityPriceScale[key]);
            }
          });
  
          // Convert the keys to numbers (if they are not already) and handle NaN keys
          obj.attributes.quantityPriceScale = Object.keys(obj.attributes.quantityPriceScale).reduce((acc, key) => {
            const numericKey = parseFloat(key);
            acc[Number.isNaN(numericKey) ? 0 : numericKey] = obj.attributes.quantityPriceScale[key];
            return acc;
          }, {});


          // if(!serviceForm.generic){
          //   let arr = []
          //   const newFile = (this.state.serviceExpenseCollection&&this.state.serviceExpenseCollection).map((item) => {
          //     if(item.modelIds){
          //          arr.push(item.modelIds.id)
          //         }
          //         })
          //         obj = {
          //           ...serviceForm,
          //           attributes:{
          //             modelIds:arr
          //           },
          //           parentId: 0,
          //           expensetypeId: expenseId,
          //         }
          // }

          // else{
          //   obj = {
          //     ...serviceForm,
          //     parentId: 0,
          //     expensetypeId: expenseId,
          //   }
          // }

        api = `/api/expensetypes/subtype/${params}`
      }
    }
    if (mode === 'editSub') {
      if (typeSelection === 'updateType') {
        obj = { ...serviceForm }
        params = serviceForm.id
        api = `/api/expensetypes/${params}`
      } else if (typeSelection === 'updateSub') {

        
        if (Object.keys(this.state.serviceForm.attributes.quantityPriceScale).length === 0) {
          obj = {
            ...this.state.serviceForm,
                      // parentId: 0,
              expensetypeId: expenseId,
            attributes: {
              // quantityPriceScale: { NaN: null },
              quantityPriceScale: { 0: 0 },
            },
          };
        }else{
          obj = {
            ...this.state.serviceForm,
                      // parentId: 0,
              expensetypeId: expenseId,
            attributes: {
              quantityPriceScale: this.state.serviceForm?.attributes?.quantityPriceScale?.reduce((acc, item) => {
                acc[item.quantity] = item.price;
                return acc;
              }, {}),
            },
          };
        }
        // Convert empty strings to 0 and ensure values are numbers
          Object.keys(obj.attributes.quantityPriceScale).forEach((key) => {
            if (obj.attributes.quantityPriceScale[key] === '') {
              obj.attributes.quantityPriceScale[key] = 0;
            } else {
              obj.attributes.quantityPriceScale[key] = parseFloat(obj.attributes.quantityPriceScale[key]);
            }
          });
  
          // Convert the keys to numbers (if they are not already) and handle NaN keys
          obj.attributes.quantityPriceScale = Object.keys(obj.attributes.quantityPriceScale).reduce((acc, key) => {
            const numericKey = parseFloat(key);
            acc[Number.isNaN(numericKey) ? 0 : numericKey] = obj.attributes.quantityPriceScale[key];
            return acc;
          }, {});

        // if(!serviceForm.generic){
        //   let arr = []
        //   const newFile = (this.state.serviceExpenseCollection&&this.state.serviceExpenseCollection).map((item) => {
        //     if(item.modelIds){
        //          arr.push(item.modelIds.id)
        //         }
        //         })
        //         obj = {
        //           ...serviceForm,
        //           attributes:{
        //             modelIds:arr
        //           },
        //           parentId: 0,
        //           expensetypeId: expenseId,
        //         }
        // }

        // else{
        //   obj = {
        //     ...serviceForm,
        //     parentId: 0,
        //     expensetypeId: expenseId,
        //   }
        // }
        // obj = { ...serviceForm }
        params = serviceForm.id
        api = `/api/expensetypes/subtype/${params}`
      }
    }
    if (obj) {
      instance({
        url: api,
        method: `${mode === 'editSub' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(res => {
          if (mode === 'editSub') {
            if (typeSelection === 'updateType') {
              this.props.dispatch(addTypes(res))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('savedTypeIsUpdated'),
                  autoDismiss: 10
                })
              )
              this.setState({
                serviceExpenseCollection:[{modelIds:'', makeId:'', selectedMakeTypes:[]}]
              })
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('savedSubTypeIsUpdated'),
                  autoDismiss: 10
                })
              )
              this.setState({
                serviceExpenseCollection:[{modelIds:'', makeId:'', selectedMakeTypes:[]}]
              })
            }
          } else if (mode === 'create') {
            if (typeSelection === 'createType') {
              this.props.dispatch(addTypes(res))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('typeIsCreated'),
                  autoDismiss: 10
                })
              )
            } else {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('SubTypeIsCreated'),
                  autoDismiss: 10
                })
              )
            }
          }
          this.closeCreateFrom()
        })
        .catch(error => {
          console.log('errror ===', error)
          errorHandler(error, this.props.dispatch)
        })
    }
  }

  onRemovedItem = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  fetchMoreItems = (a, b, c) => {
    let pagination
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      pagination=true
    )
  }

  handleChangeServices = async (name, value, index) =>{	
      let rows = [...this.state.serviceExpenseCollection]
      if(name === 'makeId'){
          const z = await this.fetchVehicleMake(value.label)
          rows[index].selectedMakeTypes=z
      }

      let keys = []
      rows[index][name] = value
      rows.map(i => {
        keys.push(i.x)
        return null
      })
      let sorted_arr = keys.slice().sort()
      let results = []
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
          results.push(sorted_arr[i])
        }
      }
      let a = []
      keys.map((item, index) => {
        results.map(w => {
          if (item === w) {
            a.push(index)
          }
          return null
        })
        return null
      })
      a.map(val => {
        rows[val] = { ...rows[val] }
        return null
      })
        this.setState({ serviceExpenseCollection:rows, error: a.length > 0 ? true : false })
      
  }

  addMore = () => {
    let rows = []
      rows = [...this.state.serviceExpenseCollection]

      if (rows.length) {
        let last = { ...rows[rows.length - 1] }
        rows.push({modelIds:'', makeId: '', selectedMakeTypes:[], edit:true})
      } else {
        let last = { ...rows[rows.length - 1] }
        rows.push(last)
      }
      this.setState({ serviceExpenseCollection:rows }, ()=>{this.checkRequiredFields()})
  }

  deleteRow = (row, index, e) => {
    let rows = [...this.state.serviceExpenseCollection]
    rows.splice(index, 1)
    this.setState({ serviceExpenseCollection : rows,
      isVisible: (rows[0].expenseSubTypeId&&rows[0].cost&&rows[0].quantity)?false:true }, () => {
      this.state.serviceExpenseCollection.map((row, index) => {
      this.handleChart2('modelIds', row['modelIds'], index)
      this.handleChart2('makeId', row['makeId'], index)
      this.handleChart2('selectedMakeTypes', row['selectedMakeTypes'], index)
        return null
      })
    })
  }

  handleChart2 = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection]
    let keys = []
    rows[index][name] = value
    rows.map(i => {
      keys.push(i.x)
      return null
    })
    let sorted_arr = keys.slice().sort()
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }
    let a = []
    keys.map((item, index) => {
      results.map(w => {
        if (item === w) {
          a.push(index)
        }
        return null
      })
      return null
    })
    rows.map(sal => {
      sal.e = false
      return null
    })
    a.map(val => {
      rows[val] = { ...rows[val], e: true }
      return null
    })
    this.setState({ serviceExpenseCollection:rows, error: a.length > 0 ? true : false })
  }

  async searchFromList (inputValue) {
    const result = allMakesData.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result
  }

  
  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {/* {this.props.translate('notFound')} */}
          -- {this.props.translate('end')} --
        </p>
      )
    const {data} = this.state.itemPagination
    if (checkPrivileges('service')) {
      const pp = {
        ...this.props,
        services: this.props.services
      }

      const myStyle={
        backgroundImage: "url(/assets/category/default/emptyfolder.svg)",
        height:'150px',
        width:'150px',
        marginLeft:"200px"
    }

      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            openCreateFrom={this.openCreateTypeFrom}
            editCommandForm={this.showSubTypes}
            searchItems={this.searchItems}
            onEditType={this.openEditTypeFrom}
            fetchMoreItems={this.fetchMoreItems}
            fetchData={this.fetchData}
            checkData={this.checkData}
            {...this.state}
          >
          {!this.state.checkEmpty ? 

            <div className='main-content-page'>
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeSubType}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.deleteForm?.name || this.state.deleteForm?.label ||''}
                />
              )}
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                {this.state.mode === 'Show' ? (
                  <>
                  <Table
                     rows={
                      (this.state.expense &&
                        this.state.expense.data) ||
                      []
                    }
                    pagination={this.setPagination1(
                      this.state.expense && this.state.expense
                    )}
                    onDelete={this.onDeleteSub}
                    onEdit={this.openEditSubTypeFrom}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage1}
                    handleChangePage={this.handleChangePage1}
                    SearchItem={this.searchSubTypeItem}
                    logInUser={this.props.logInUser}
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    // vehicles={this.props?.vehicles || []}
                    // viewInvoice
                    servicesLabel
                    searchableSubType
                    searchable
                    sellingPriceWithoutVAT={checkPrivileges("expensetypeUpdate")}
                    isEditable
                    hasAccessOfUpdate={checkPrivileges("expensetypeUpdate")}
                    hasAccessOfDelete={checkPrivileges("expensetypeDelete")}
                    // invoiceModule
                    ServerSetting={this.props.ServerSetting}
                    // invoiceRow
                    // downloadInvoicePdf={this.downloadInvoicePdf}
                    createButton={
                      <>
                      <div style={{ marginLeft: 10 }}>
                        <Button onClick={()=>this.getExpense(this.state.selectedServiceTypeId)}>
                          {this.props.translate('sharedSearch')}
                        </Button>
                      </div>
                      <div style={{ marginLeft: 10 }}>
                      <Button onClick={this.openCreateSubTypeFrom}>
                        {this.props.translate('sharedCreate')}
                      </Button>
                    </div>
                    </>
                    }
                    isDownload={
                        <ExportImportMenu
                            fileName = 'services/subtypes'
                            checkPrivilege = 'serviceCreate'
                            isServices
                            data = {this.state?.expense || []}
                            themecolors = {this.props.themecolors}
                            expensetypeId={this.state?.serviceForm?.id ||''}
                            fetchDataFunc = {this.fetchData}
                            expenseId={this.state.expenseId}
                            getExpense={this.getExpense}
                          />
                    }
                    // parent={2}
                    rowDefinition={checkPrivileges('invoicePurchasePriceView') ?[
                      {
                        id: 'label',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sharedName')
                      },
                      {
                        id: 'subTypeGroup',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('group')
                      },
                      {
                        id: 'subTypeNum',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('code')
                      },
                      {
                        id: 'attributes.itemBrand',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('Brand')
                      },
                      {
                        id: 'unitPrice',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sellingPriceWithoutVAT')
                      },
                      {
                        id: 'purchasePrice',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('purchasePrice')
                      },
                      
                    ]: [
                      {
                        id: 'label',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sharedName')
                      },
                      {
                        id: 'subTypeGroup',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('group')
                      },
                      {
                        id: 'subTypeNum',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('code')
                      },
                      {
                        id: 'attributes.itemBrand',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('Brand')
                      },
                      {
                        id: 'unitPrice',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sellingPriceWithoutVAT')
                      },
                      
                    ]}
                    canDelete={true}
                    canRemove={false}
                    canAssign={false}
                  />
                  </>
                ) : null}
                {this.state.mode === 'editSub' ||
                this.state.mode === 'create' ? (
                  <EditSubForm
                    selectedExpenseType={this.state.selectedExpenseType}
                    form={this.state.serviceForm}
                    handleChange={this.handleChange}
                    handleChange1={this.handleChange1}
                    handleDelete={this.handleDelete}
                    handleAddMore={this.handleAddMore}
                    translate={this.props.translate}
                    submitForm={this.submitForm}
                    submitForm1={this.submitForm1}
                    closeEditForm={this.closeEditForm}
                    mode={this.state?.mode}
                    isVisableSubmitBtn={this.state?.isVisableSubmitBtn}
                    typeSelection={this.state?.typeSelection}
                    field={
                      this.state.typeSelection === 'createSub' ||
                      this.state.typeSelection === 'updateSub'
                        ? 'label'
                        : 'name'
                    }
                    checkPrivileges={checkPrivileges}
                    logInUser={this.props.logInUser}
                    subTypes = {subTypes}
                    expenseType={allExpenseTypes}
                    serviceExpenseCollection = {this.state.serviceExpenseCollection}
                    handleChangeServices={this.handleChangeServices}
                    themecolors={this.props.themecolors}
                    expenseForm={[]}
                    addMore = {this.addMore}
                    allModels={this.state?.allModels || []}
                    deleteRow={this.deleteRow}
                    allMakes={this.state.allMakes || []}
                    searchFromList={this.searchFromList}
                  />
                ) : null}
                {this.state.mode === '' && (data&&data.length) ? (
                  <EmptyState
                    text={this.props.translate('noServicesSelected')}
                  />
                ) : null}
              </div>
            </div> :
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              > 
                <div style={myStyle}></div>
              </div>
          }
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const EditSubForm = ({
  form,
  handleChange,
  handleChange1,
  handleDelete,
  handleAddMore,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  typeSelection,
  field,
  checkPrivileges,
  quantityError,
  selectedExpenseType
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('services')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='name'
            required
            margin='dense'
            label={translate('sharedName')}
            variant='outlined'
            fullWidth
            value={form.name || form.label}
            onChange={e => handleChange(field, e.target.value)}
          />
        </Grid>
        {(typeSelection === 'createSub' || typeSelection === 'updateSub') && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='subTypeNum'
                margin='dense'
                label={translate('code')}
                variant='outlined'
                fullWidth
                required
                value={form.subTypeNum}
                onChange={e => handleChange('subTypeNum', e.target.value)}
              />
            </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                  id='status'
                  select
                  label={translate('expenseType')}
                  value={form?.expenseType  || ''}
                  onChange={e=>handleChange("expenseType", e.target.value)}
                  margin='dense'
                  fullWidth
                  required
                  disabled={selectedExpenseType.name === "packages" || 
                            selectedExpenseType.name === "services" || 
                            selectedExpenseType.name === "items"}
                >
                  {statusTypes.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {translate(option.name)}
                        </MenuItem>
                      ))}
                </TextField>
              </Grid> 
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='subTypeGroup'
                margin='dense'
                label={translate('group')}
                variant='outlined'
                fullWidth
                value={form.subTypeGroup}
                onChange={e => handleChange('subTypeGroup', e.target.value)}
              />
            </Grid>
      
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='itemBrand'
                margin='dense'
                label={translate('brand')}
                variant='outlined'
                fullWidth
                type='text'
                value={form?.attributes?.itemBrand || ''}
                onChange={e => handleChange('itemBrand', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='unitPrice'
                margin='dense'
                label={translate('sellingPriceWithoutVAT')}
                variant='outlined'
                fullWidth
                type='number'
                value={form.unitPrice}
                onChange={e => handleChange('unitPrice', e.target.value)}
              />
               <FormHelperText error={form?.unitPrice < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
            </Grid>
            
            
            {checkPrivileges('invoicePurchasePriceView') &&
              (<Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id='purchasePrice'
                    margin='dense'
                    label={translate('purchasePrice')}
                    variant='outlined'
                    fullWidth
                    type='number'
                    value={form.purchasePrice || ''}
                    onChange={e => handleChange('purchasePrice', e.target.value)}
                  />
                   <FormHelperText error={form?.purchasePrice < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
              </Grid>)}
          </>
        )}
        {(typeSelection === 'createType' || typeSelection === 'updateType') && (<>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='description'
              margin='dense'
              label={translate('sharedDescription')}
              variant='outlined'
              fullWidth
              value={form.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Grid>
          </>
        )}
      </Grid>

  {form?.attributes?.quantityPriceScale?.map((item, index) => (
          <Grid container spacing={1} 
          key={index}
          >
          <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
          id={`quantity-${index}`}
          required
          margin="dense"
          label={translate("quantity")}
          variant="outlined"
          fullWidth
          type="number"
          value={item.quantity || ''}
          onChange={(e) => handleChange1(index, 'quantity', e.target.value)}
        />
          <FormHelperText error={item.quantity <= 0 ? true : false}>
             {translate('minimum1Quantity')}
            </FormHelperText>
   </Grid>
   <Grid item xs={12} sm={6} md={4} lg={3}>
     <TextField
       margin="dense"
       label="Price"
       type="number"
       variant="outlined"
       fullWidth
       value={item.price || 0}
       onChange={(e) => handleChange1(index, 'price', e.target.value)}
     /> 
      <FormHelperText error={item.price < 0 ? true : false}>
                               {translate('valueMustbeGreaterthen0')}
                              </FormHelperText>
   </Grid>
   <Grid item xs={12} sm={6} md={4} lg={3}>
     <div style={{ display: 'flex', marginTop: 10 }}>
       <Button
         size="small"
         style={{ marginRight: 15 }}
         className="button-white"
         onClick={handleAddMore}
         disabled={quantityError ? "disabled" : ""}
       >
         Add More
       </Button>
       <Button size="small"
        onClick={() => handleDelete(index)}
        >
         Delete
       </Button>
     </div>
   </Grid>
 </Grid>
 ))}

      <div style={{ display: 'flex', marginTop: 10 }}>
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeEditForm}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('expensetypeCreate') && (
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === 'editSub' ? 'update' : 'sharedCreate')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  commands: state.commands,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  devices3: state.devices3,
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  services: state.services,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Services)))
