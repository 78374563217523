import React, { Component } from "react";
import styles from "./invoice-style.scss";
import { Grid } from "@material-ui/core";
import instance from "../../axios";
import moment from "moment";
import Loader from "../../Layout/Loader";

let selectedInvoice = false;
let serverTimeZoneName = "";
 
class MaintenanceInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      data1: {},
      name: "",
      loader: false,
    };
  }
  componentWillMount() {
    var baseUrl = window.location.origin;
    const queryString = window.location.search;

    var res = queryString.split("?&token=");
    let invoiceId = res[1].split("&leasing=");
    let sz = invoiceId[1].split("&timezone=");
    selectedInvoice = sz[0] === "true" ? "leaseinvoices" : "invoices";
    serverTimeZoneName = sz[1]

    // api/invoices/4121?contractNumber=PE150
    this.setState(
      {
        loader: true,
      },
      () => {
    instance({
      method: "GET",
      url: `/api/${selectedInvoice}/${invoiceId[0]}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => { 
        this.setState({ data1: response , loader: false,}, () => {
             
          if (selectedInvoice == "leaseinvoices" && this.state.data1?.invoiceType === 2) {
            let Taxable = 0;
            let VAT = 0;
            let Amount = 0;

            // saleItemPrice , 15, saleVATAmount , saleTotalAmount
            response.items &&
              response.items.map((item, index) => {
                Taxable = Taxable + item.saleItemPrice;
                VAT = VAT + item.saleVATAmount;
              });

            this.setState({
              
              leasingInvoiceSummary: {
                noOfInvoices:
                  response.items && response.items.length
                    ? response.items.length
                    : 0,
                TaxableAMT: response?.saleInvoicePrice || 0,
                VAT: response?.saleVATAmount || 0,
                totalAmount: response?.saleNetAmount || 0,
              },
            });
          }
        });
      })
      .catch((error) => {
        console.log("error ====", error);
      });
    })
  }

   replaceAmpersands(obj) {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].replace(/amp;/g, '');
        const tempKey = document.createElement('div');
        tempKey.innerHTML = obj[prop];
        obj[prop] = tempKey.textContent;
      } else if (typeof obj[prop] === 'object') {
        this.replaceAmpersands(obj[prop]);
      }
    }
  }

  render() {
    const { data1, leasingInvoiceSummary } = this.state;
    let data = data1
    this.replaceAmpersands(data);
    
    
    // const data = {
    //   ...data1,
    //   items: data1.items?.map((item) => {
    //     const updatedItem = {};
    //     for (const prop in item) {
    //       if (item.hasOwnProperty(prop)) {
    //         const propValue = item[prop];
    //         if (typeof propValue === 'string') {
    //           updatedItem[prop] = propValue.replace(/&amp;/g, '&');
    //         } else {
    //           updatedItem[prop] = propValue;
    //         }
    //       }
    //     }
    //     return updatedItem;
    //   }),
    // };
  


   
    if (data) {
      const Pack = data && data.packages;
      const Service = data && data.services;
      const Item = data && data.items;
      let packagesLength = 0;
      let servicesLength = 0;
      let ItemsLength = 0;
      Item &&
        Item.map((item) => {
          if (item.categoryName === "packages")
            packagesLength = packagesLength + 1;
          else if (item.categoryName === "services")
            servicesLength = servicesLength + 1;
          else if (item.categoryName === "items") 
          ItemsLength = ItemsLength + 1;
          else ItemsLength = ItemsLength + 1;
        });
      let indexLen = 1;
      let indexPackage = 1;
      let totalVATPrice = 0;
      let totalPackagePrice = 0;
      let totalPackageVAT = 0;

      let totalServicePrice = 0;
      let packageTotalPrice = 0;
      let packageVATAmount = 0;
      let packageNetPrice = 0;

      let serviceTotalPrice = 0;
      let serviceVATAmount = 0;
      let serviceNetPrice = 0;

      let itemsTotalPrice = 0;
      let itemsVATAmount = 0;
      let itemsNetPrice = 0;


      let itemsVATAmountSum = 0;

      const formattedCompanyName = data&&data.attributes&&data.attributes.c_accountName&&data.attributes.c_accountName.replace(/:amp;/g, '&');
      const modifiedData = JSON.parse(JSON.stringify(data).replaceAll('&amp;', '&'));
      
      const decodedHtml = data&&data.attributes&&data.attributes.c_accountName&&data.attributes.c_accountName
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");

      return (
        <Grid id="invoiceholder">
          {this.state.loader ? <Loader  component="filter"/> : 
          <Grid id="invoice" class="effect2">
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item md={5}>
                <img style={{ width: "200px" }} src="/logo.png" />
              </Grid>
              <Grid item md={7} style={{ paddingRight: 20 }}>
                <h5 class="head3">فاتورة ضريبة القيمة المضافة</h5>
                <h5 class="head3" style={{ marginLeft: 40 }}>
                  TAX INVOICE
                </h5>
              </Grid>
            </Grid>
            <Grid class="invoice-mid " style={{ border: "1px solid black" }}>
              <Grid container>
                <Grid item md={6} style={{ padding: "12px 0 0 12px" }}>
                  <p class="ptag">
                    <strong>National Transportation Solutions Company </strong>
                  </p>
                  <p class="ptag">P.O. BOX 1432, Al Mohammadiyyah</p>
                  <p class="ptag">Jeddah, 21431, Kingdom of Saudi Arabia</p>
                  <p class="ptag">VAT number of Supplier: 300000603210003</p>
                  <p class="ptag">Phone No: +966 92000 8206</p>
                  <p class="ptag">Email Address: info@ntsc.sa</p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                    <strong>شركة الوطنية لحلول النقل المحدودة </strong>
                  </p>
                  <p class="ptag">ص.ب.1432 المحمدية</p>
                  <p class="ptag">جدة ,21431 ,المملكة العربية السعودية</p>
                  <p class="ptag">الرقم الضريبي للمنشأة: 300000603210003 </p>
                  <p class="ptag"> +966 92000 8206 : رقم الهاتف</p>
                  <p class="ptag">info@ntsc.sa : البريد الإلكتروني</p>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  border: "1px solid black",
                  margin: "10px 0 0 0",
                  padding: 1,
                }}
              >
                <Grid item md={6} style={{ padding: 10 }}>
                {data.plateNumber?                      // this is for old vehicles
                  <strong class="titleHead">
                    CUSTOMER AND VEHICLE DETAILS
                  </strong> : 
                   <strong class="titleHead">
                   CUSTOMER   DETAILS
                 </strong>}
                </Grid>
                <Grid item md={6} style={{ padding: 6, textAlign: "end" }}>
                  <strong class="titleHead"> بيانات العميل والمركبة</strong>
                </Grid>
              </Grid>
               
              {selectedInvoice === "leaseinvoices" && data?.invoiceType === 2 ? (
                <>
                  <Grid class="row m-0 p-1">
                    <table style={{ fontSize: 20 }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td >Name/ Company Name :</td>
                              <td class="text-center" 
                              // dangerouslySetInnerHTML={{ __html: decodedHtml }}
                              >
                                {decodedHtml || []}
                                {/* {data?.attributes?.c_accountName || ""} */}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_nameArabic || ""}
                              </td>
                              <td class="text-right">: الاسم / اسم الشركة</td>
                            </tr>
                            <tr>
                              <td>Phone No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_contact || ""}
                              </td>
                              <td class="text-right">رقم الهاتف</td>
                            </tr>
                            <tr>
                              <td>Email Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_email || ""}
                              </td>
                              <td class="text-right"> البريد الإلكتروني </td>
                            </tr>
                            {/* <tr>
                              <td>Customer VAT Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_VATNumber || ""}
                              </td>
                              <td class="text-right">
                                : رقم ضريبة القيمة المضافة للعميل
                              </td>
                            </tr> */}
                            <tr>
                              <td>Customer Location :</td>
                              <td class="text-center">
                                {data?.attributes?.c_Location || ""}
                              </td>
                              <td class="text-right">: موقع العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Tax Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_taxNumber || ""}
                              </td>
                              <td class="text-right">: الرقم الضريبي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Building No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_buildingNo || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_buildingNoArabic}
                              </td>
                              <td class="text-right">: رقم بناء العميل </td>
                            </tr>
                          </table>
                        </td>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Customer City :</td>
                              <td class="text-center">
                                {data?.attributes?.c_city || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                  {data?.attributes?.c_cityArabic}
                              </td>
                              <td class="text-right">: المدينة</td>
                            </tr>
                            <tr>
                              <td>Customer Country :</td>
                              <td class="text-center">
                                {data?.attributes?.c_country || "Saudi Arabia"}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_countryArabic}
                              </td>
                              <td class="text-right">: الدولة </td>
                            </tr>
                            <tr>
                              <td>Customer Postal Code :</td>
                              <td class="text-center">
                                {data?.attributes?.c_postalCode || ""}
                              </td>
                              <td class="text-right">: الرمز البريدي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Additional No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_additionalNo || ""}
                              </td>
                              <td class="text-right">: رقم العميل الإضافي </td>
                            </tr>
                            {/* <tr>
                              <td> Other SellerId :</td>
                              <td class="text-center">
                                {data?.attributes?.c_otherSellerID || ""}
                              </td>
                              <td class="text-right">: معرف الآخر</td>
                            </tr> */}
                            <tr>
                              <td>Customer Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_address || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_addressArabic}
                              </td>
                              <td class="text-right">: عنوان العميل</td>
                            </tr>
                            <tr>
                              <td>Customer Street Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_streetName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_streetNameArabic}
                              </td>
                              <td class="text-right">: الشارع </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid class="row m-0 p-1">
                    <table style={{ fontSize: 20 }}>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Name/ Company Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_accountName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_nameArabic || ""}
                              </td>
                              <td class="text-right">: الاسم / اسم الشركة</td>
                            </tr>
                            <tr>
                              <td>Phone No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_contact || ""}
                              </td>
                              <td class="text-right">: رقم الهاتف</td>
                            </tr>
                            <tr>
                              <td>Email Address:</td>
                              <td class="text-center">
                                {data?.attributes?.c_email || ""}
                              </td>
                              <td class="text-right">: البريد الإلكتروني</td>
                            </tr>
                           { 
                           selectedInvoice !== "leaseinvoices" ? 
                           <>
                              <tr>
                              <td>Customer Additional No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_additionalNo || ""}
                              </td>
                              <td class="text-right">: رقم العميل الإضافي</td>
                            </tr>
                                  <tr>
                                  <td>Customer Tax Number :</td>
                                  <td class="text-center">
                                    {data?.attributes?.c_taxNumber || ""}
                                  </td>
                                  <td class="text-right">
                                    : الرقم الضريبي للعميل{" "}
                                  </td>
                                  </tr>
                                  <tr>
                              <td>Customer Building No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_buildingNo || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_buildingNoArabic}
                              </td>
                              <td class="text-right">: رقم بناء العميل </td>
                            </tr>

                                  </> : ""}



                            {/* <tr>
                              <td>Customer VAT Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_VATNumber || ""}
                              </td>
                              <td class="text-right">
                                : رقم ضريبة القيمة المضافة للعميل
                              </td>
                            </tr> */}
                            {/* <tr>
                              <td>Customer Location :</td>
                              <td class="text-center">
                                {data?.attributes?.c_Location || ""}
                              </td>
                              <td class="text-right">: موقع العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Tax Number :</td>
                              <td class="text-center">
                                {data?.attributes?.c_taxNumber || ""}
                              </td>
                              <td class="text-right">
                                : الرقم الضريبي للعميل{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Customer Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_address || ""}
                              </td>
                              <td class="text-right">: عنوان العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Building No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_buildingNo || ""}
                              </td>
                              <td class="text-right">: رقم بناء العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Street Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_streetName || ""}
                              </td>
                              <td class="text-right">: الشارع </td>
                            </tr> */}
                          </table>
                        </td>
                        {console.log("data====", data)}
                        <td style={{ width: "50%" }}>
                          <table>
                            <tr>
                              <td>Customer City :</td>
                              <td class="text-center">
                                {data?.attributes?.c_city || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                  {data?.attributes?.c_cityArabic}
                              </td>
                              <td class="text-right">: المدينة</td>
                            </tr>
                            <tr>
                              <td>Customer Country :</td>
                              <td class="text-center">
                                {data?.attributes?.c_country || "Saudi Arabia"}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_countryArabic}
                              </td>
                              <td class="text-right">: الدولة</td>
                            </tr>
                            <tr>
                              <td>Customer Postal Code :</td>
                              <td class="text-center">
                                {data?.attributes?.c_postalCode || ""}
                              </td>
                              <td class="text-right">: الرمز البريدي للعميل</td>
                            </tr>
                            <tr>
                              <td>Customer Location :</td>
                              <td class="text-center">
                                {data?.attributes?.c_Location || ""}
                              </td>
                              <td class="text-right">: موقع العميل </td>
                            </tr>
                            <tr>
                              <td>Customer Address :</td>
                              <td class="text-center">
                                {data?.attributes?.c_address || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_addressArabic}
                              </td>
                              <td class="text-right">: عنوان العميل </td>
                            </tr>
                          
                            <tr>
                              <td>Customer Street Name :</td>
                              <td class="text-center">
                                {data?.attributes?.c_streetName || ""}
                                 &nbsp;  &nbsp;  &nbsp; 
                                {data?.attributes?.c_streetNameArabic}
                              </td>
                              <td class="text-right">: الشارع </td>
                            </tr> 
                            {/* <tr>
                              <td>Customer Additional No :</td>
                              <td class="text-center">
                                {data?.attributes?.c_additionalNo || ""}
                              </td>
                              <td class="text-right">: رقم العميل الإضافي</td>
                            </tr> */}
                            {/* <tr>
                              <td> Other SellerId :</td>
                              <td class="text-center">
                                {data?.attributes?.c_otherSellerID || ""}
                              </td>
                              <td class="text-right">: معرف الآخر</td>
                            </tr> */}
                                {/* { data.plateNumber?
                                <>
                            <tr>
                              <td>Plate Number :</td>
                              <td class="text-center">
                                {data?.plateNumber || ""}
                              </td>
                              <td class="text-right">:رقم اللوحة </td>
                            </tr>
                            <tr>
                              <td>Model :</td>
                              <td class="text-center">
                                {data?.attributes?.model || ""}
                              </td>
                              <td class="text-right">: موديل</td>
                            </tr>
                            
                          <tr>
                              <td>VIN :</td>
                              <td class="text-center">
                                {data?.attributes?.vin || ""}
                              </td>
                              <td class="text-right">: رقم الشاسيه</td>
                            </tr>
                            <tr>
                              <td>Mileage Reading :</td>
                              <td class="text-center">
                                {data?.odometerReading || 0}
                              </td>
                              <td class="text-right">: قراءة العداد</td>
                            </tr> </>
                            :""} */}
                          </table>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              )}

              <Grid
                container
                style={{
                  border: "1px solid black",
                  margin: "10px 0 0 0",
                  padding: 1,
                }}
              >
                <Grid item md={2}>
                  <img
                    class="logo"
                    src={data?.qrCodeUrl || ""}
                    style={{ width: 130 }}
                  />
                </Grid>
                <Grid item md={10} style={{ padding: 1, marginTop: 15 }}>
                  <table>
                    <tr>
                      <td class="w-25">Invoice No:</td>
                      <td class="text-center w-25">
                        {data?.invoiceNumber || ""}
                      </td>
                      <td
                        class="text-right w-25"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : رقم الفاتورة
                      </td>
                    </tr>
                    {/* {selectedInvoice === "leaseinvoices" ? ( */}
                    <tr>
                      <td>Invoice Date:</td>
                      {selectedInvoice === "leaseinvoices"  ? (
                        <td class="text-center">
                          {/* {data?.invoiceDate || ""} */}

                          {data && data.invoiceDate
                            ? 
                            moment.utc(data.invoiceDate)
                            .tz(serverTimeZoneName)
                            .format("YYYY-MM-DD HH:mm:ss")
                            // moment(data.invoiceDate)
                            // // .tz(serverTimeZoneName)
                            // .format("DD-MM-YYYY HH:mm:ss")
                            : ""}
                        </td>
                      ) : (
                        <td class="text-center w-25">
                          {/* {data?.serviceDate || ""} */}
                          {/* {data && data.serviceDate
                            ? moment.utc(data.serviceDate)
                                .tz(serverTimeZoneName)
                                .format("DD-MM-YYYY HH:mm:ss")
                            : ""} */}

                        {data && data.serviceDate
                            ? 
                            moment.utc(data.serviceDate)
                            .tz(serverTimeZoneName)
                            .format("YYYY-MM-DD HH:mm:ss")
                            : ""}
                        </td>
                      )}
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : تاريخ الفاتورة
                      </td>
                    </tr>

                    <tr>
                      <td>Contract name:</td>
                      <td class="text-center">
                        {data?.attributes?.c_contractName || ""}
                      </td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        : اسم العقد/ معرف المرجع
                      </td>
                    </tr>
                    <tr>
                      <td>Contract No:</td>
                      <td class="text-center">{data?.contractNumber?.toUpperCase() || ""}</td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        رقم العقد
                      </td>
                    </tr>
                    {/* {selectedInvoice !== "leaseinvoices" ||   data?.invoiceType === 1 ? (
                      <>
                        <tr>
                          <td>Booking ID / Customer Ref #</td>
                          <td class="text-center">
                            {data?.serviceId  ? (
                              <>
                                {data?.serviceId || ""}/{" "}
                                {data?.serviceRefNumber}
                              </>
                            ) : (
                              <>{data?.serviceRefNumber || ""}</>
                            )}
                          </td>

                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            معرف الحجز / الرقم المرجعي العملاء
                          </td>
                        </tr>
                        <tr>
                          <td>Invoice Ref #: </td>
                          <td class="text-center">
                            {data?.invoiceRefNumber || ""}
                          </td>

                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            : مرجع الفاتورة
                          </td>
                        </tr>
                      </>
                    ) : null} */}
                    <tr>
                      <td>Credit Period:</td>
                      <td class="text-center">
                        {data?.attributes?.c_creditPeriod || 0}
                      </td>
                      <td
                        class="text-right"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        فترة الائتمان
                      </td>
                    </tr>
                     {selectedInvoice !== "leaseinvoices"  ||  data?.invoiceType === 1 ? (
                      data.plateNumber?
                      <>
                        
                        <tr>
                          <td>Reference No:</td>
                          <td class="text-center">{data?.invoiceRefNumber || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                             رقم المرجع
                          </td>
                        </tr>
                        <tr>
                          <td>Station Id:</td>
                          <td class="text-center">{data?.stationCode || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            معرف المحطة
                          </td>
                        </tr>
                        <tr>
                          <td>Station Name:</td>
                          <td class="text-center">{data?.stationName || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            اسم المحطة
                          </td>
                        </tr>
                        <tr>
                          <td>Station Address:</td>
                          <td class="text-center">{data?.stationCity || ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            عنوان المحطة
                          </td>
                        </tr>
                        {/* --------------------------------------------------------------------------- */}
                        </>
                :
                        <>
                       
                       <tr>
                          <td>Start Date :</td>
                          {/* <td class="text-center">{data?.attributes?.startDate ? this.formatDate(data.attributes.startDate) : ""}</td> */}
                          <td class="text-center">{moment(data?.attributes?.startDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            : تاريخ البدء 
                          </td>
                        </tr>
                        <tr>
                          <td>End Date:</td>
                          <td class="text-center">{data?.attributes?.endDate ? moment(data.attributes.endDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                          {/* <td class="text-center">{data?.attributes?.endDate || ""}</td> */}
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            : تاريخ الانتهاء    
                          </td>
                        </tr>
                        <tr>
                          <td>Invoice Due Date:</td>
                          <td class="text-center">{data?.attributes?.invoiceDueDate ? moment(data.attributes.invoiceDueDate, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                          {/* <td class="text-center">{data?.attributes?.invoiceDueDate|| ""}</td> */}
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                           : تاريخ استحقاق الفاتورة  
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery Note Count :</td>
                          <td class="text-center">{data?.stats ||  ""}</td>
                          <td
                            class="text-right"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                           : عدد مذكرة التسليم
                          </td>
                        </tr>
                      </>

                    ) : null}
                  </table>
                </Grid>
              </Grid>
              {selectedInvoice === "leaseinvoices"  &&  data?.invoiceType === 2 ? (
                <>
                  <Grid container style={{ marginBottom: 5 }}>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong> SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد الفواتير </strong>
                          <br />
                          <strong> Number of vehicles</strong>
                        </td>
                        <td class="tableData">
                          <strong> المبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>Taxable AMT </strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة </strong>
                          <br />
                          <strong>Tax Rate</strong>
                        </td>
                        <td class="tableData">
                          <strong>نسبة القيمه المضافه </strong>
                          <br />
                          <strong>VAT% </strong>
                        </td>
                        <td class="tableData">
                          <strong> (ريال سعودي) المبلغ </strong>
                          <br />
                          <strong>Amount (SAR)</strong>
                        </td>
                      </tr>

                      <tr>
                        <td class="tableData" style={{ padding: 2 }}>
                          {1}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.noOfInvoices
                            ? leasingInvoiceSummary.noOfInvoices.toFixed(2)
                            : 0}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.TaxableAMT
                            ? leasingInvoiceSummary.TaxableAMT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {15}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary && leasingInvoiceSummary.VAT
                            ? leasingInvoiceSummary.VAT.toFixed(2)
                            : ""}
                        </td>
                        <td class="tableData" style={{ padding: 2 }}>
                          {leasingInvoiceSummary &&
                          leasingInvoiceSummary.totalAmount
                            ? leasingInvoiceSummary.totalAmount.toFixed(2)
                            : ""}
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : null}
   
              {selectedInvoice === "leaseinvoices" &&    data?.invoiceType === 2 &&
              data.items &&
              data.items.length ? (
                <>
                  <Grid container>
                    <table>
                      <tr
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td class="tableData">
                          <strong>رقم التسلسلي </strong>
                          <br />
                          <strong>SL NO</strong>
                        </td>
                        <td class="tableData">
                          <strong> نوع المركبة/رقم</strong>
                          <br />
                          <strong>VEHICLE NUMBER</strong>
                        </td>
                        <td class="tableData">
                          <strong>الخدمة المقدمة </strong>
                          <br />
                          <strong>DESCRIPTION</strong>
                        </td>
                        <td class="tableData">
                          <strong>موديل </strong>
                          <br />
                          <strong>MODEL</strong>
                        </td>
                        <td class="tableData">
                          <strong>عدد</strong>
                          <br />
                          <strong># OF DAYS</strong>
                        </td>
                        <td class="tableData">
                          <strong>الإيجار الشهري </strong>
                          <br />
                          <strong>MONTHLY RENTAL</strong>
                        </td>
                        <td class="tableData">
                          <strong>لمبلغ الخاضع للضريبة </strong>
                          <br />
                          <strong>TAXABLE AMOUNT</strong>
                        </td>
                        <td class="tableData">
                          <strong>معدل الضريبة</strong>
                          <br />
                          <strong>TAX RATE</strong>
                        </td>
                        <td class="tableData">
                          <strong>ضريبه القيمه المضافه </strong>
                          <br />
                          <strong> VAT%</strong>
                        </td>
                        <td class="tableData">
                          <strong>مجموع </strong>
                          <br />
                          <strong> TOTAL </strong>
                        </td>
                      </tr>
                      {data.items &&
                        data.items.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {index + 1}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.plateNumber || ""}
                                </td>
                                <td class="tableData" style={{ width: 160 }}>
                                  {item?.itemDescription ||
                                    "Monthly fleet lease  الإيجار الشهري"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.model || ""}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.noOfDays || 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleItemPrice
                                    ? item.saleItemPrice.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleTotalAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item.vatPercentage ? item.vatPercentage + "%" : "0.0%"}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleVATAmount
                                    ? item.saleVATAmount.toFixed(2)
                                    : 0}
                                </td>
                                <td class="tableData" style={{ padding: 2 }}>
                                  {item?.saleTotalAmount
                                    ? item.saleNetAmount.toFixed(2)
                                    : 0}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </Grid>
                </>
              ) : null}
              {/* Package Details */}
              {/* {Pack&&Pack.map((p1,index)=>{
                                totalPack = totalPack + p1.packageTotalAmount
                                return(
                                <table>
                                    <tr style={{borderTop:"1px solid black", borderBottom:"1px solid black"}}>
                                        <td class="tableData"><strong>رقم التسلسلي SL NO</strong></td>
                                        <td class="tableData"><>
                                            <strong>تفاصيل قطع الغيار PARTICULARS OF Package</strong>
                                            <p style={{fontWeight:900,fontSize:12,textAlign:'center'}}>{p1?.packageName||''}</p>
                                        </></td>
                                        <td class="tableData"><strong>الكمية QTY</strong></td>
                                        <td class="tableData"><strong>المبلغ UNIT PRICE</strong></td>
                                        <td class="tableData"><strong>المبلغ الخاضع للضريبة TOTAL PRICE EXCL. VAT</strong></td>
                                        <td class="tableData"><strong>ضريبه القيمه المضافه VAT @ 15%</strong></td>
                                        <td class="tableData"><strong>تخفيض Discount</strong></td>
                                        <td class="tableData"><strong>ضريبه القيمه المضافه VAT%</strong></td>
                                        <td class="tableData"><strong>المبلغ AMOUNT</strong></td>
                                    </tr>
                                    
                                    {p1.Service && p1.Service.map((s1,index)=>{
                                        totalVatPack = totalVatPack + s1.ServiceVATAmount
                                        return(
                                            <>
                                        <tr>
                                            <td class="tableData">{indexPackage++}</td>  
                                            <td class="tableData">{s1?.ServiceName||''}</td>
                                            <td class="tableData">{s1?.qty||1}</td>
                                            <td class="tableData">{s1?.ServiceTotalAmount||0}</td>
                                            <td class="tableData">{s1?.ServiceTotalAmount||0}</td>
                                            <td class="tableData">{s1?.ServiceVATAmount||0}</td>
                                            <td class="tableData">{s1?.ServiceDiscount||0}</td>
                                            <td class="tableData">{"15%"}</td>
                                            <td class="tableData">{s1?.ServiceNetAmount||0}</td>
                                        </tr>
                                            {s1.item &&s1.item.map((it,ind)=>{
                                                totalVatPack = totalVatPack + it.ItemVATAmount
                                                return(
                                                    <tr>
                                                        <td class="tableData">{indexPackage++}</td>  
                                                        <td class="tableData">{it?.ItemName||''}</td>
                                                        <td class="tableData">{it?.ItemQty||1}</td>
                                                        <td class="tableData">{it?.ItemPrice||0}</td>
                                                        <td class="tableData">{it?.ItemTotalAmount||0}</td>
                                                        <td class="tableData">{it?.ItemVATAmount||0}</td>
                                                        <td class="tableData">{it?.ItemDiscount||0}</td>
                                                        <td class="tableData">{'15%'}</td>
                                                        <td class="tableData">{it?.ItemNetAmount||''}</td>
                                                    </tr>
                                                )
                                            })}
                                        
                                            </>)}
                                        )}
                                    <tr class="border-bottom">
                                        <td colspan="8" class="text-right"><strong>SUB TOTAL المجموع الفرعي</strong></td>
                                        <td class="tableData"><strong>{totalPack}</strong></td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td colspan="8" class="text-right"><strong>VAT @ 15%  ضريبه القيمه المضافه</strong></td>
                                        <td class="tableData"><strong>{totalVatPack}</strong></td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td colspan="8" class="text-right"><strong>PARTS NET AMOUNT  المبلغ الغيار لقطع الصافي</strong></td>
                                        <td class="tableData"><strong>{totalPack+totalVatPack}</strong></td>
                                    </tr>
                                </table>
                                )
                            })} */}

              {Item?.length > 0 ? (
                <>
                  {packagesLength ? (
                    <Grid container>
                      <table>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <td class="tableData">
                            <strong>رقم التسلسلي </strong>
                            <br />
                            <strong> SL NO</strong>
                          </td>
                          <td class="tableData">
                            <strong> تفاصيل الحزمة </strong>
                            <br />
                            <strong>PARTICULARS OF PACKAGES</strong>
                          </td>
                          <td class="tableData">
                            <strong>الكمية </strong>
                            <br />
                            <strong> QTY</strong>
                          </td>
                          <td class="tableData">
                            <strong>سعر الوحدة </strong>
                            <br />
                            <strong> UNIT PRICE</strong>
                          </td>
                          <td class="tableData">
                            <strong>المبلغ الخاضع للضريبة</strong>
                            <br />
                            <strong>TOTAL PRICE EXCL. VAT</strong>
                          </td>
                          <td class="tableData">
                            <strong> نسبة القيمه المضافه </strong>
                            <br />
                            <strong>VAT%</strong>
                          </td>
                          <td class="tableData">
                            <strong>قيمة الضريبة   </strong>
                            <br />
                            <strong>VAT AMOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong>تخفيض </strong>
                            <br />
                            <strong> DISCOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong> (ريال سعودي) المبلغ </strong>
                            <br />
                            <strong> AMOUNT (SAR)</strong>
                          </td>
                        </tr>
                        {Item &&
                          Item.map((item, index) => {
                            if (item.categoryName === "packages") {
                              {/* packageNetPrice = packageNetPrice + item.saleTotalAmount;
                               
                              packageVATAmount = (packageNetPrice * 0.15).toFixed(2)
                              packageTotalPrice = packageNetPrice + parseFloat(packageVATAmount) */}


                              packageNetPrice =  packageNetPrice + item.saleTotalAmount;
                              let pp =  parseFloat(packageNetPrice).toFixed(2);
                              let z = (pp * 0.15)
                              packageVATAmount = parseFloat(z).toFixed(2);
                              packageTotalPrice = packageNetPrice + parseFloat(packageVATAmount)

                            }

                            return (
                              <>
                                {item?.categoryName?.toLowerCase() ===
                                "packages" ? (
                                  <tr>
                                    <td class="tableData">{index + 1}</td>
                                    <td class="tableData">
                                      {item?.itemName || ""}
                                      {" - "}
                                      {item?.itemCode || ""}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemQty || 1}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleItemPrice.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleTotalAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">{item.vatPercentage ? item.vatPercentage + "%" : "0.0%"}</td>
                                    <td class="tableData">
                                      {item?.saleVATAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {/* {item?.itemDiscount.toFixed(2) || 0} */}
                                      0
                                    </td>
                                    <td class="tableData">
                                      {item?.saleNetAmount.toFixed(2) || 0}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>SUB TOTAL المجموع الفرعي</strong>
                          </td>
                          <td class="tableData">
                            <strong>{packageNetPrice.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>VAT AMOUNT قيمة الضريبة</strong>
                          </td>
                          <td class="tableData">
                            <strong>{packageVATAmount || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>NET AMOUNT المبلغ الصافي</strong>
                          </td>
                          <td class="tableData">
                            <strong>
                              {(packageTotalPrice).toFixed(2) || "0"}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </Grid>
                  ) : null}
                  {servicesLength ? (
                    <Grid container>
                      <table>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <td class="tableData">
                            <strong>رقم التسلسلي </strong>
                            <br />
                            <strong> SL NO</strong>
                          </td>

                          <td class="tableData">
                            <strong> تفاصيل الخدمات</strong>
                            <br />
                            <strong>PARTICULARS OF SERVICES </strong>
                          </td>
                          <td class="tableData">
                            <strong>الكمية </strong>
                            <br />
                            <strong> QTY</strong>
                          </td>
                          <td class="tableData">
                            <strong>سعر الوحدة </strong>
                            <br />
                            <strong> UNIT PRICE</strong>
                          </td>
                          <td class="tableData">
                            <strong>المبلغ الخاضع للضريبة</strong>
                            <br />
                            <strong> TOTAL PRICE EXCL. VAT</strong>
                          </td>
                          <td class="tableData">
                            <strong> نسبة القيمه المضافه </strong>
                            <br />
                            <strong>VAT%</strong>
                          </td>

                          <td class="tableData">
                            <strong>  قيمة الضريبة </strong>
                            <br />
                            <strong>VAT AMOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong>تخفيض </strong>
                            <br />
                            <strong> DISCOUNT</strong>
                          </td>
                          <td class="tableData">
                            <strong> (ريال سعودي) المبلغ </strong>
                            <br />
                            <strong> AMOUNT (SAR)</strong>
                          </td>
                        </tr>

                        {Item &&
                          Item.map((item, index) => {
                            if (item.categoryName === "services") {
                              {/* serviceNetPrice =  serviceNetPrice + item.saleTotalAmount;
                              serviceVATAmount = (serviceNetPrice * 0.15).toFixed(2)
                              serviceTotalPrice = serviceNetPrice + parseFloat(serviceVATAmount) */}

                              serviceNetPrice =  serviceNetPrice + item.saleTotalAmount;
                              let pp =  parseFloat(serviceNetPrice).toFixed(2);
                              let z = (pp * 0.15)
                              serviceVATAmount = parseFloat(z).toFixed(2);
                              serviceTotalPrice = serviceNetPrice + parseFloat(serviceVATAmount)

                             }
                            return (
                              <>
                                {item?.categoryName?.toLowerCase() ===
                                "services" ? (
                                  <tr>
                                    <td class="tableData">{index + 1}</td>
                                    <td class="tableData">
                                      {item?.itemName || ""}
                                      {" - "}
                                      {item?.itemCode || ""}
                                    </td>
                                    <td class="tableData">
                                      {item?.itemQty || 1}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleItemPrice.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {item?.saleTotalAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">{item.vatPercentage ? item.vatPercentage + "%" : "0.0%"}</td>
                                    <td class="tableData">
                                      {item?.saleVATAmount.toFixed(2) || 0}
                                    </td>
                                    <td class="tableData">
                                      {/* {item?.itemDiscount.toFixed(2) || 0} */} 0
                                    </td>
                                    <td class="tableData">
                                      {item?.saleNetAmount.toFixed(2) || 0}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}

                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>SUB TOTAL المجموع الفرعي</strong>
                          </td>
                          <td class="tableData">
                            <strong>{serviceNetPrice.toFixed(2) || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>VAT AMOUNT قيمة الضريبة  </strong>
                          </td>
                          <td class="tableData">
                            <strong>{serviceVATAmount  || 0}</strong>
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="8" class="text-right">
                            <strong>NET AMOUNT المبلغ الصافي</strong>
                          </td>
                          <td class="tableData">
                            <strong>
                            {/* {serviceTotalPrice || 0} */}
                              {(serviceTotalPrice).toFixed(2) || 0}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </Grid>
                  ) : null}

                  {selectedInvoice === "invoices" || data?.invoiceType === 1 ? (
                    <>
                      {/* {ItemsLength ? ( */}
                        <Grid container>
                          <table>
                            <tr
                              style={{
                                borderTop: "1px solid black",
                                borderBottom: "1px solid black",
                              }}
                            >
                              <td class="tableData">
                                <strong>رقم التسلسلي </strong>
                                <br />
                                <strong>SL NO</strong>
                              </td>
                              <td class="tableData">
                              {data.plateNumber ? 
                                <>  
                                  <strong>تفاصيل قطع الغيار</strong>
                                  <br />
                                  <strong>PARTICULARS OF ITEM</strong>
                                </>
                                :
                                <>
                                  <strong>الخدمة المقدمة </strong>
                                  <br />
                                  <strong>Service Name </strong>
                                  </>
                                  }
                              </td>
                              <td class="tableData">
                                <strong>رقم مذكرة التسليم </strong> <br />
                                <strong>Delivery Note Number</strong>
                              </td>
                              <td class="tableData">
                                <strong>الكمية </strong> <br />
                                <strong>QTY</strong>
                              </td>
                              <td class="tableData">
                                <strong>سعر الوحدة </strong> <br />
                                <strong>UNIT PRICE</strong>
                              </td>
                              <td class="tableData">
                                <strong>المبلغ الخاضع للضريبة</strong> <br />
                                <strong>TOTAL PRICE EXCL. VAT</strong>
                              </td>
                              <td class="tableData">
                                <strong>نسبة القيمه المضافه </strong> <br />
                                <strong>VAT%</strong>
                              </td>
                              <td class="tableData">
                                <strong>  قيمة الضريبة  </strong> <br />
                                <strong>VAT AMOUNT</strong>
                              </td>
                              <td class="tableData">
                                <strong>تخفيض </strong> <br />
                                <strong>DISCOUNT</strong>
                              </td>
                              <td class="tableData">
                                <strong> (ريال سعودي) المبلغ </strong>
                                <br />
                                <strong> AMOUNT (SAR)</strong>
                              </td>
                            </tr>
                            {Item &&
                              Item.map((item, index) => {
                                  if (item.categoryName === "items") {

                                  itemsTotalPrice =  itemsTotalPrice + item.saleTotalAmount;
                                    let pp =  parseFloat(itemsTotalPrice).toFixed(2);
                                    let z = (pp * 0.15)
                                    itemsVATAmount = data&&data.contractNumber === "pfmc1670" ? 0 : parseFloat(z).toFixed(2);
                                    itemsNetPrice = itemsTotalPrice + parseFloat(itemsVATAmount)
                                  }
                                 else if (item.categoryName !== "items" && item.categoryName !== "packages" && item.categoryName !== "services") {
                                    itemsTotalPrice =  itemsTotalPrice + item.saleTotalAmount; 
 
                                   let pp =  parseFloat(itemsTotalPrice).toFixed(2);
                                   let z = (pp * 0.15)
                                  //  
                                  itemsVATAmountSum = parseFloat(z).toFixed(2);
                                  // itemsVATAmountSum += parseFloat(item.saleVATAmount);
                                   itemsVATAmount = data&&data.contractNumber === "pfmc1670" ? 0 : parseFloat(itemsVATAmountSum).toFixed(2);
                                   itemsNetPrice= itemsTotalPrice + parseFloat(itemsVATAmount)
                                 }
                                 
                                return (
                                  <>
                                 
                                    {item?.categoryName.toLowerCase() !==
                                      "services" &&
                                    item?.categoryName.toLowerCase() !==
                                      "packages" ? (
                                      <tr>
                                        <td class="tableData">{index + 1}</td>
                                        <td class="tableData">
                                          {item?.itemName || ""}
                                          {" - "}
                                          {item?.itemCode || ""}
                                        </td>
                                        <td class="tableData">
                                          {item?.deliveryNoteNumber || " "}
                                        </td>
                                        <td class="tableData">
                                          {item?.itemQty || 1}
                                        </td>
                                       
                                        <td class="tableData">
                                          {item?.saleItemPrice.toFixed(2) || 0}
                                        </td>
                                        <td class="tableData">
                                          {item?.saleTotalAmount.toFixed(2) ||
                                            0}
                                        </td>
                                        <td class="tableData">{item.vatPercentage ? item.vatPercentage + "%" : "0.0%"}</td>
                                        <td class="tableData">
                                          {item?.saleVATAmount.toFixed(2) || 0}
                                        </td>
                                        <td class="tableData">
                                          {/* {item?.itemDiscount.toFixed(2) || 0} */}
                                          0
                                        </td>
                                        <td class="tableData">
                                          {item?.saleNetAmount.toFixed(2) || 0}
                                        </td>
                                      </tr>
                                     ) : (
                                      ""
                                    )} 
                                  </>
                                );
                              })}
                              
                            <tr class="border-bottom">
                              <td colspan="9" class="text-right">
                                <strong>SUB TOTAL المجموع الفرعي</strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>{itemsTotalPrice.toFixed(2) || 0}</strong>
                              </td>
                            </tr>
                            <tr class="border-bottom">
                              <td colspan="9" class="text-right">
                                <strong>VAT AMOUNT قيمة الضريبة</strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>
                                  {data&&data.contractNumber === "pfmc1670" ? "0" :(itemsVATAmount || 0)}
                                  {/* {itemsVATAmount || 0} */}
                                </strong>
                              </td>
                            </tr>
                            <tr class="border-bottom">
                              <td colspan="9" class="text-right">
                                <strong>NET AMOUNT المبلغ الصافي</strong>
                              </td>
                              <td class="tableData text-right">
                                <strong>
                                  {itemsNetPrice.toFixed(2) || 0}
                                </strong>
                              </td>
                            </tr>
                          </table>
                        </Grid>
                      {/* ) : null} */}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {selectedInvoice === "invoices"  ||  data?.invoiceType === 1 ? (
                <>
                  <Grid
                    container
                    style={{ padding: 1, marginTop: 3, marginBottom: 3 }}
                  >
                    <table>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>SUB TOTAL المجموع الفرعي </strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleTotalAmount
                              ? data.saleTotalAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>VAT AMOUNT  قيمة الضريبة</strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleVATAmount
                              ? data.saleVATAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="10" class="text-right">
                          <strong>TOTAL (SAR)المجموع الكلي</strong>
                        </td>
                        <td class="tableData text-right">
                          <strong>
                            {data && data.saleNetAmount
                              ? data.saleNetAmount.toFixed(2)
                              : "0"}
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : null}
              {/* 
              <Grid
                container
                style={{ padding: 6, marginTop: 3, marginBottom: 3 }}
              >
                <Grid item md={6}>
                  <p class="ptag">
                    <strong>Bank deposit and transfer details </strong>
                  </p>
                  <p class="ptag">
                    - {"Bank Name : "}
                    {"  "}
                    {data?.setting?.attributes?.bankNameEnglish || ""}
                  </p>
                  <p class="ptag">
                    - {"Bank Holder : "}
                    {"  "}
                    {data?.setting?.attributes?.bankHolderEnglish || ""}
                  </p>
                  <p class="ptag">
                    - {"Account Number : "}
                    {"  "}
                    {data?.setting?.attributes?.accountNumberEnglish || ""}
                  </p>
                  <p class="ptag">
                    - {"IBAN : "}
                    {"  "}
                    {data?.setting?.attributes?.ibanEnglish || ""}
                  </p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 2px 0 0" }}
                >
                  <p class="ptag">
                    <strong> معلومات الدفع</strong>
                  </p>
                  <p class="ptag">
                    {" اسم البنك"}
                    {" :  "}
                    {data?.setting?.attributes?.bankNameArabic || ""} -
                  </p>
                  <p class="ptag">
                    {" صاحب البنك"}
                    {" :  "}
                    {data?.setting?.attributes?.bankHolderArabic || ""} -
                  </p>
                  <p class="ptag">
                    {" رقم حساب"}
                    {" :  "}
                    {data?.setting?.attributes?.accountNumberArabic || ""} -
                  </p>
                  <p class="ptag">
                    {" "}
                    {data?.setting?.attributes?.ibanArabic || ""}{" "}
                    {" :  رقم -  IBAN"}-
                  </p>
                </Grid>
              </Grid> */}

              <Grid
                container
                style={{ padding: 6, marginTop: 10, marginBottom: 3 }}
              >
                {selectedInvoice === "leaseinvoices" &&  data?.invoicetype === 2 ? (
                  <>
                    <Grid item md={6}>
                      <p class="ptag">
                        <strong>Terms & Conditions</strong>
                      </p>
                      {data?.setting?.attributes?.termsEnglishLeasing ? (
                        <>
                          <p class="ptag">
                            {data?.setting?.attributes?.termsEnglishLeasing ||
                              ""}
                          </p>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: "right", padding: "12px 2px 0 0" }}
                    >
                      <p class="ptag">
                        <strong> الشروط والأحكام </strong>
                      </p>
                      {data?.setting?.attributes?.termsArabicLeasing ? (
                        <>
                          <p class="ptag">
                            {data?.setting?.attributes?.termsArabicLeasing ||
                              ""}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid item md={6}>
                      <p class="ptag">
                        <strong>Terms & Conditions</strong>
                      </p>
                      {data?.setting?.attributes?.termsEnglishMaintenance ? (
                        <>
                          <p class="ptag" style={{ whiteSpace: "pre-wrap" }}>
                            {data?.setting?.attributes
                              ?.termsEnglishMaintenance || ""}
                          </p>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={6}
                      style={{ textAlign: "right", padding: "12px 2px 0 0" }}
                    >
                      <p class="ptag">
                        <strong> الشروط والأحكام </strong>
                      </p>
                      {data?.setting?.attributes?.termsArabicMaintenance ? (
                        <>
                          <p
                            class="ptag"
                            style={{ whiteSpace: "pre-wrap", direction: "rtl" }}
                          >
                            {data?.setting?.attributes
                              ?.termsArabicMaintenance || ""}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                container
                style={{ padding: 1, marginTop: 3, marginBottom: 3 }}
              >
                <Grid item md={6}>
                  <p class="ptag">
                    This is a system generated email and needs no signatures.{" "}
                  </p>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ textAlign: "right", padding: "12px 12px 0 0" }}
                >
                  <p class="ptag">
                    فاتورة تم إنشاؤها بواسطة النظام ولا تتطلب توقيعا{" "}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <footer class="text-center">
              <Grid class="legalcopy clearfix">
                <p class="text-center">
                  شكرا لزيارتك <br /> THANK YOU FOR YOUR VISIT{" "}
                </p>
              </Grid>
            </footer>
          </Grid>
          }
        </Grid>
      );
    }
  }
}

export default MaintenanceInvoice;
