import React, { Component } from "react";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "../common/MenuItem";
import { errorHandler } from "../../Helpers";
import CustomDialog from "./Dialog";
import MaintenanceFilter from "./MaintenanceFilter";

export default class ExportExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMaintenanceModal: false,
      loader: false,
      countChecked: 0,
      anchorEl: null,
    };
  }
  manageExportMaintenance = () => {
    this.setState({
      openMaintenanceModal: true,
    });
  };
  onCloseDueMaintenanceModel = () => {
    this.setState({
      openMaintenanceModal: false,
      countChecked: 0,
      anchorEl: null,
    });
  };
  exportFile = (fileName, Dform) => {
    const {
      selectedAreaId,
      from,
      to,
      selectedMaintenanceId,
      selectedVehicleId,
      filterStatusType,
      createdBy,
      selectedAccount
    } = this.props;
    let api, Accept;
    let id = this.props.logInUser && this.props.logInUser.id;
    if( fileName === "vehicles" && (this.props.title === " FuelTypes" || this.props.title === " Categories" || this.props.title === " MakeModel")){
      api = `/api/${this.props.downloadType}/export`
    }
    else if( fileName === "vehicles" && this.props.reportType === "carbonfootprint"){
      api = `/api/vehicles/export/${this.props.downloadType}`
    }else if(this.props.fileName==="vehicles" && this.props.reportType === "entitlement"){
      api = `api/vehicles/${this.props.downloadType}/export`
    }
    else if( fileName === "vehicles" && this.props.reportType === "utilization"){
      api = `/api/vehicles/export/${this.props.downloadType}`
    }
    else if( fileName === "vehicles" && this.props.downloadType === "vehicles/status"){
      api = `/api/vehicles/status/export?sample=true`
    }
    // api/vehicles/status/export?sample=true
    else if (
      [
        "drivers",
        "devices",
        "binders",
        "users",
        "services",
        "invoices",
        "expensetypes",
        "deliverynotes"
      ].includes(fileName)
    ) {
      api = `/api/${fileName}/export?sample=${this.props.sample}`;
    } 
    // else if (fileName === "services") {
    //   let user = this.props.logInUser.customerType=== 1 &&  createdBy ? "me" : "all";
    //   if(selectedAccount){
    //     api = `/api/services/export?createdby=${user}&contractNumber=${selectedAccount}&sample=false&from=${from}&to=${to}${selectedVehicleId}${filterStatusType}`;
    //   }else{
    //     api = `/api/services/export?createdby=${user}&sample=false&from=${from}&to=${to}${selectedVehicleId}${filterStatusType}`;
    //   }
    //   } 
    else if (fileName === "vehicles") {
      // api = `/api/${fileName}/export?sample=${this.props.sample}&userId=${id}&all=true`;
      // api/vehicles/export?maintenanceDetails=true
      if(this.props.vehicleMaint){
        api =`/api/${fileName}/export?sample=${this.props.sample}&userId=${id}&maintenanceDetails=true&all=true`;
      }else{
        api = this.props.sample
        ? `/api/${fileName}/export?sample=${this.props.sample}`
        : `/api/${fileName}/export?sample=${this.props.sample}&userId=${id}&all=true`;
      }
  
    } else if (fileName === "services/subtypes") {
      api = this.props.sample
        ? `/api/expensetypes/subtypes/export?sample=${this.props.sample}`
        : `/api/expensetypes/subtypes/export?expensetypeId=${this.props.expensetypeId}&sample=${this.props.sample}`;
    } else if (fileName === "geofences") {
      api = `/api/geofences/export/circle?sample=${this.props.sample}`;
    } 
    else if (fileName === "downloadSamplev2") {
      api = `/api/expensetypes/export/v2`;
    }
     else if (fileName === "Dlogs") {
      api = `/api/positions/export?from=${Dform.from}&to=${Dform.to}&deviceId=${
        Dform.device
      }&detail=${Dform?.detail || false}`;
    }
    if (this.props.type === "PDF") {
      Accept = "application/pdf";
    } else {
      Accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // if (this.props.type === "PDF") {
        //   a.setAttribute("download", `${fileName}.pdf`);
        // } else {
        //   if(this.props.vehicleMaint){
        //   a.setAttribute("download", `Vehicl Maint Report.xlsx`);
        //   }else{
        //     a.setAttribute("download", `${fileName}.xlsx`);
        //   }
        // }
        // document.body.appendChild(a);
        // a.href = url;
        // a.click();
        let filename;
        if (this.props.type === "PDF") {
         filename = `${this.props.fileName}.pdf`;
        } else {
        if (this.props.vehicleMaint) {
        filename = `Vehicle Maint Report.xlsx`;
        } else {
         filename = `${this.props.fileName}-${this.props.title}.xlsx`;
        }
        }

        a.setAttribute("download", filename);
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })

      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  formSubmit = (data) => {
    const {
      selectedAreaId,
      from,
      to,
      selectedMaintenanceId,
      selectedvehicleId,
    } = data;
    // this.setState({ loader: true });
    let api,
      Accept,
      fileName = "services";
    if (this.props.type === "PDF") {
      Accept = "application/pdf";
    } else {
      Accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    api = `/api/services/export?sample=false${selectedMaintenanceId}&from=${from}&to=${to}${selectedvehicleId}${selectedAreaId}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (this.props.type === "PDF") {
          a.setAttribute("download", `${fileName}.pdf`);
        } else {
          a.setAttribute("download", `${fileName}.xlsx`);
        }
        document.body.appendChild(a);
        a.href = url;
        a.click();
        // this.setState({ loader: false });
      })

      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  render() {
    // console.log("this.props=====", this.props);
    return (
      <div>
        <Tooltip title={"Download" + this.props.title}>
          <MenuItem
            style={{ minWidth: "inherit" }}
            component="label"
            onClick={
              this.props.isMaintenance
                ? this.manageExportMaintenance
                : (e) => this.exportFile(this.props.fileName)
            }
          >
            {
              this.props.fileName === "vehicles" && this.props.vehicleMaint ? (
                this.props.translate("vehicleMaintenanceExcel")):
            
            this.props.fileName !== "geofences" ? (
              <> {this.props.translate("Download" + this.props.title)}</>
            ) : this.props.fileName === "services/subtypes" ? (
              "Download Type/SubType"
            ) : (
              this.props.translate('circleGeofences') + " " + this.props.translate(this.props.title) + " " + this.props.translate("Download")
            )}
          </MenuItem>
        </Tooltip>
        {this.state.openMaintenanceModal ? (
          <>
            <CustomDialog
              title="Download Services"
              // themecolors={themecolors}
              visable={true}
              onClose={this.onCloseDueMaintenanceModel}
              bodyPadding={10}
              isVisableBtn
              noFullScreen
              disableCloseBtn
            >
              {
                <>
                  <aside className="sidebar-bg-layer">
                    <MaintenanceFilter
                      {...this.props}
                      formSubmit={this.formSubmit}
                      gridSize={6}
                      loader={this.state.loader}
                    />
                  </aside>
                </>
              }
            </CustomDialog>
          </>
        ) : null}
      </div>
    );

    {
      /*  return (
      <>
       <Tooltip title='Download Excel'>
          <Button style={{ minWidth: 'inherit' }} onClick={this.export}>
            <GetAppIcon />
          </Button>
        </Tooltip>
        <ExcelExport
          data={this.props.data}
          fileName={this.props.fileName + '.xlsx'}
          ref={exporter => {
            this._exporter = exporter
          }}
        >

           {this.props.columns.map(col => {
            let w = 100
            if (col.key) {
              switch (col.key) {
                case 'id':
                case 'model':
                case 'deviceId':
                case 'vehicleType':
                  w = 60
                  break
                case 'vin':
                case 'make':
                  w = 120
                  break
                case 'chassisNumber':
                case 'garage':
                case 'label':
                  w = 250
                  break
                default:
                  w = null
                  break
              }
            }
            return (
              <ExcelExportColumn
                key={col.key}
                field={col.key}
                title={col.value}
                width={w}
              />
            )
          })} 
        </ExcelExport>
      </>
    )*/
    }
  }
}
