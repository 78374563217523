import React, { PureComponent } from 'react'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import CloseIcon from '@material-ui/icons/Close';
import './index.scss'
import { checkPrivileges, setAttributeFormat } from '../../../Helpers'
import { unsetDeviceId } from './../../../Actions/Devices'
import Style from 'style-it'
import moment from 'moment'
import Scrollbar from 'react-scrollbars-custom'
import ProtocolDialog from '../ProtocolDialog'
import PinIcon from './../../../assets/nav/pin.svg'
import ReactHtmlParser from 'react-html-parser'
import GroupIcon from '@material-ui/icons/GroupWorkOutlined';
import { ReactComponent as FileCopyIcon } from './../../../assets/monitoring/copy.svg'
import { ReactComponent as StatusIcon } from './../../../assets/monitoring/status.svg'
import { ReactComponent as BoxIcon } from './../../../assets/monitoring/3d-model.svg'
import { ReactComponent as PhoneIcon } from './../../../assets/monitoring/phone.svg'
import { ReactComponent as CurrentPositionIcon } from './../../../assets/monitoring/gun-pointer.svg'
import { ReactComponent as SatelliteIcon } from './../../../assets/monitoring/satellite.svg'
import { ReactComponent as UpIcon } from './../../../assets/monitoring/up.svg'
import { ReactComponent as DownIcon } from './../../../assets/monitoring/down.svg'
import { ReactComponent as OtherSensorIcon } from '../../../assets/sensors/sensor.svg'
import { ReactComponent as ElogicIcon } from '../../../assets/nav/algorithm.svg'
import { ReactComponent as AggregateIcon } from '../../../assets/sensors/chip.svg'
import { ReactComponent as MeasurementIcon } from '../../../assets/sensors/centimeter.svg'
import { ReactComponent as DiscreteIcon } from '../../../assets/sensors/discrete.svg'
import { ReactComponent as StaffIcon } from '../../../assets/nav/staff.svg'
import { ReactComponent as CallIcon } from '../../../assets/nav/phone-call.svg'
import { ReactComponent as TrailerIcon } from '../../../assets/nav/trailerr.svg'
import { ReactComponent as GeofenceIcon } from '../../../assets/nav/geofence.svg'

const mapStateToProps = (state, ownProps) => {
  let currentPosition = null
  let currentDevice = null
  let fixDriver = null
  if (state.deviceId) {
    currentDevice = state.devices3.find(d => d.id === state.deviceId)
    currentPosition = state.deviceRelatedData[state.deviceId]
    fixDriver = currentDevice.attributes.fixDriverId&&state.drivers.find(item => item.id === currentDevice.attributes.fixDriverId)
  }
  return {
    currentPosition: Object.assign({}, currentPosition),
    currentDevice: Object.assign({}, currentDevice),
    trailers: state.trailers,
    drivers: state.drivers,
    fixDriver,
    computedAttributes: state.computedAttributes,
    allComputedAttributes: state.allComputedAttributes,
    events: state.events,
    groups: state.groupNames,
    vehicles: state.vehicles,
    deviceId: state.deviceId,
    insideGeofence: state.insideGeofence,
    geofences: state.geoFence,
    logInUser: state.logInUsers
  }
}
class Row extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      device: {},
      details: {},
      collapseable: {
        basicDetails: true,
        Location: true,
        Sensors: true,
        trailer: true,
        bin: true,
        passenger: true,
        DRIVER: true,
        VEHICLES: true,
        EVENTS: true,
        ODOMETER: true,
        ENGINEHOURS: true,
        INPUT: true,
        OUTPUT: true,
        GSM: true,
      }
    }
    this.hideWidgets = this.hideWidgets.bind(this)
  }

  toggleCollapse = (key, value) => {
    const collapseable = Object.assign({}, this.state.collapseable);
    collapseable[key] = value;
    console.log(key, value, collapseable);
    this.setState({ collapseable });
  }

  hideWidgets () {
    if(this.props.dispatch) {
      this.props.dispatch(unsetDeviceId({ id: this.props.deviceId }))
      document.body.classList.remove('shown-widgets')
    }
  }
  render () {
    if (this.props.deviceId) {
      return (
        <Style>
          {`
				.type-toggle.active {
					color: ${this.props.themecolors.themeDarkColor}
				}
			`}
          <div className='feature-row'>
            {checkPrivileges('device') && [
              <div key={0} className="feature-row-head">
                <div style={{ display: 'flex' }}>
                  <img
                    style={{ width: 36, height: 36 }}
                    src={
                      '/assets/category/default/' +
                      (this.props.currentDevice.category || 'default') +
                      '.svg'
                    }
                    alt={this.props.currentDevice.category}
                  />
                  <span className='feature-row-head-name status'>
                    <span className={ 'status-light ' + this.props.currentPosition.statusText }></span> {this.props.currentPosition.statusText && this.props.translate('tracker.' + this.props.currentPosition.statusText)}
                  </span>
                  <CloseIcon
                    className={'feature-close-button'}
                    onClick={this.hideWidgets}
                  />
                </div>
                <span className='feature-row-head-name'>
                  {this.props.currentDevice.name}
                </span>
              </div>,
              <div key={1} className='feature-row-inner'>
                <Scrollbar disableTracksWidthCompensation>
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='basicDetails'
                    type='basicDetails'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    groups={this.props.groups}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                    logInUser={this.props.logInUser}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='Location'
                    type='Location'
                    insideGeofence={this.props.insideGeofence}
                    geofences={this.props.geofences}
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  {checkPrivileges('sensor') && 
                    <FeatureSection
                      toggleCollapse={this.toggleCollapse}
                      collapseable={this.state.collapseable}
                      trailers={this.props.trailers}
                      dispatch={this.props.dispatch}
                      key='Sensors'
                      type='Sensors'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      allComputedAttributes={this.props.allComputedAttributes}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                    />
                  }
                  {checkPrivileges('binder') && 
                    <FeatureSection
                      toggleCollapse={this.toggleCollapse}
                      collapseable={this.state.collapseable}
                      trailers={this.props.trailers}
                      dispatch={this.props.dispatch}
                      key='trailer'
                      type='Trailer'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      allComputedAttributes={this.props.allComputedAttributes}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                    />}
                  {checkPrivileges('binder') &&   
                    <FeatureSection
                      toggleCollapse={this.toggleCollapse}
                      collapseable={this.state.collapseable}
                      trailers={this.props.trailers}
                      dispatch={this.props.dispatch}
                      key='bin'
                      type='Bin'
                      device={this.props.currentDevice}
                      details={this.props.currentPosition}
                      drivers={this.props.drivers}
                      computedAttributes={this.props.computedAttributes}
                      allComputedAttributes={this.props.allComputedAttributes}
                      translate={this.props.translate}
                      copyToClipboard={this.props.copyToClipboard}
                    />
                    }
                    {checkPrivileges('binder') &&
                      <FeatureSection
                        toggleCollapse={this.toggleCollapse}
                        collapseable={this.state.collapseable}
                        trailers={this.props.trailers}
                        dispatch={this.props.dispatch}
                        key='passenger'
                        type='Passenger'
                        device={this.props.currentDevice}
                        details={this.props.currentPosition}
                        drivers={this.props.drivers}
                        computedAttributes={this.props.computedAttributes}
                        allComputedAttributes={this.props.allComputedAttributes}
                        translate={this.props.translate}
                        copyToClipboard={this.props.copyToClipboard}
                    />}
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                     dispatch={this.props.dispatch}
                     key='DRIVER'
                     type='DRIVER'
                     fixDriver={this.props.fixDriver}
                     device={this.props.currentDevice}
                     details={this.props.currentPosition}
                     translate={this.props.translate}
                   />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                     dispatch={this.props.dispatch}
                     key='VEHICLES'
                     type='VEHICLES'
                     device={this.props.currentDevice}
                     details={this.props.currentPosition}
                     vehicles={this.props.vehicles}
                     translate={this.props.translate}
                     copyToClipboard={this.props.copyToClipboard}
                   />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='EVENTS'
                    type='EVENTS'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    events={this.props.events}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='ODOMETER'
                    type='ODOMETER'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='ENGINEHOURS'
                    type='ENGINEHOURS'
                    details={this.props.currentPosition}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='INPUT'
                    type='INPUT'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='OUTPUT'
                    type='OUTPUT'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                  <FeatureSection
                    toggleCollapse={this.toggleCollapse}
                    collapseable={this.state.collapseable}
                    dispatch={this.props.dispatch}
                    key='GSM'
                    type='GSM'
                    device={this.props.currentDevice}
                    details={this.props.currentPosition}
                    translate={this.props.translate}
                    copyToClipboard={this.props.copyToClipboard}
                  />
                </Scrollbar>
              </div>
            ]}
          </div>
        </Style>
      )
    } else {
      return null
    }
  }
}

export class FeatureSection extends PureComponent {
  constructor (props) {
    super(props)
    this.getCardinal = this.getCardinal.bind(this)
    this.convertOdometer = this.convertOdometer.bind(this)
    this.checkLastResponseTime = this.checkLastResponseTime.bind(this)
    this.openProtocol = this.openProtocol.bind(this)
    this.closeProtocol = this.closeProtocol.bind(this)
    this.state = {
      displayOdo: 'km',
      km: 0,
      mi: 0,
      modal: false,
      input: '',
      protocol: '',
      inputName: ''
    }
  }

  toggleCollapse = () => {
    console.log(this.props.type, this.props.collapseable);
    this.props.toggleCollapse(this.props.type, !this.props.collapseable[this.props.type])
  }

  convertOdometer (unit) {
    if (unit !== this.state.displayOdo) {
      this.setState({ displayOdo: unit })
    }
  }

  componentWillReceiveProps (NextProps) {
    this.setState({
      km:
        (NextProps.details &&
        NextProps.details.attributes &&
        NextProps.details.attributes.totalDistance
          ? NextProps.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (NextProps.details &&
          NextProps.details.attributes &&
          NextProps.details.attributes.totalDistance &&
          (
            ((NextProps.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0
    })
  }

  componentWillMount () {
    this.setState({
      km:
        (this.props.details &&
        this.props.details.attributes &&
        this.props.details.attributes.totalDistance
          ? this.props.details.attributes.totalDistance / 1000
          : 0
        ).toFixed(2) || 0,
      mi:
        (this.props.details &&
          this.props.details.attributes &&
          this.props.details.attributes.totalDistance &&
          (
            ((this.props.details.attributes.totalDistance || 0) / 1000) *
            0.621371
          ).toFixed(2)) ||
        0
    })
  }

  //given "0-360" returns the nearest cardinal direction "N/NE/E/SE/S/SW/W/NW/N"
  getCardinal (angle) {
    //easy to customize by changing the number of directions you have
    let directions = 8

    let degree = 360 / directions
    angle = angle + degree / 2

    if (angle >= 0 * degree && angle < 1 * degree) return 'N'
    if (angle >= 1 * degree && angle < 2 * degree) return 'NE'
    if (angle >= 2 * degree && angle < 3 * degree) return 'E'
    if (angle >= 3 * degree && angle < 4 * degree) return 'SE'
    if (angle >= 4 * degree && angle < 5 * degree) return 'S'
    if (angle >= 5 * degree && angle < 6 * degree) return 'SW'
    if (angle >= 6 * degree && angle < 7 * degree) return 'W'
    if (angle >= 7 * degree && angle < 8 * degree) return 'NW'
    //Should never happen:
    return 'N'
  }

  checkLastResponseTime = minTime => {
    let posDate = this.props.device.lastUpdate
    if (minTime) {
      return moment(posDate).isBefore(moment().subtract(10, 'minutes'))
    } else if (this.props.details.serverTime) {
      posDate = this.props.details.serverTime
      return (
        moment(posDate).isBefore(moment().subtract(10, 'minutes')) &&
        !moment(this.props.device.lastUpdate).isBefore(
          moment().subtract(10, 'minutes')
        )
      )
    }
  }

  openProtocol (input, protocol, inputName) {
    this.setState({ modal: true, input, protocol, inputName })
  }
  closeProtocol () {
    this.setState({ modal: false, input: '', protocol: '', inputName: '' })
  }

  getStatusText = () => {
    let p = this.props.details
    const {stopTime, motion, parking, ignition} = p && p.attributes || {};    

    if (p && p.attributes) {
      if (!moment(p.serverTime).isBefore(moment().subtract(10, 'minutes'))) {
        // idling
        if (ignition && this.props.device && this.props.device.attributes && parseInt(stopTime) >
        (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)) {
          return this.props.translate('idling')
        } else if (
          (p.attributes.ignition && motion) ||
          (p.protocol === 'osmand' && motion)
        ) {
          // moving
          return this.props.translate('moving')
        } else if (p.attributes.ignition !== true && motion) {
          // towing
          return this.props.translate('towing')
        } else if (parking) {
          return this.props.translate('parking')
        } else if (this.props.device && this.props.device.attributes && parseInt(stopTime) <
        (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)) {
          return this.props.translate('stop')
        }
      }
    }
    return this.props.translate('unknown')
  }

  render () {
    const emptyLinks = null
    const position = this.props.details
    const { icons } = position;
    if (this.props.type === 'basicDetails') {
      let statusText = position.statusText
        return (
        <div className={'feature-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          <h3 key={0} className="feature-row-head" onClick={this.toggleCollapse}>
          <span>{this.props.translate('id')}: {this.props.device.uniqueId}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span>
          </h3>
          {this.props.collapseable[this.props.type] ?<div>
              <div className='feature-content-section'>
                {(this.props.logInUser&&this.props.logInUser.userType === -1) ?
                    this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.viewIMEI ?
                        (<div className='item-row'>
                        <span className='row-label'>{this.props.translate('id')}</span>
                        <span className='row-value'>{this.props.device.uniqueId}</span>
                        </div>) :null
                    : null}
                {this.props.device.phone ? (
                  <div className='item-row'>
                    <PhoneIcon />
                    <div>
                      <span className='row-label'>
                        {this.props.translate('sharedPhone')}
                      </span>
                      <span className='row-value'>{this.props.device.phone}</span>
                    </div>
                  </div>
                ) : null}
                
                  {(this.props.logInUser&&this.props.logInUser.userType === -1) ||
                    (checkPrivileges('deviceModel') &&
                    (this.props.logInUser&&this.props.logInUser.attributes&&this.props.logInUser.attributes.viewModel))
                    ?
                  (<div className='item-row'>
                      <BoxIcon />
                      <div>
                      <span className='row-label'>
                        {this.props.translate('deviceModel')}
                      </span>
                      <span className='row-value'>
                        {(this.props.device && this.props.device.model) || (position && position.protocol)}
                      </span>
                      </div>
                    </div>) : null}
                {this.props.device && this.props.device.lastUpdate ? (
                  <div className='item-row'>
                      <CurrentPositionIcon />
                    <span className='row-label'>
                       {this.props.translate('trackerUpdated')}
                    </span>
                    <span className='row-value'>
                      <TimeAgo title="" date={this.props.device.lastUpdate} minPeriod={60} />
                    </span>
                  </div>
                ) : (
                  <div className='item-row'>
                    <CurrentPositionIcon />
                    <span className='row-label'>
                      {this.props.translate('trackerUpdated')}
                    </span>
                    <span className='row-value'>
                      <TimeAgo title="" date={this.props.device.lastUpdate} minPeriod={60} />
                    </span>
                  </div>
                )}
                {position.serverTime ? (
                  <div className='item-row'>
                    <SatelliteIcon />
                    <span className='row-label'>
                      {this.props.translate('gpsUpdated')}
                    </span>
                    <span className='row-value'>
                      <TimeAgo title="" date={position.serverTime} minPeriod={60} />
                    </span>
                  </div>
                ) : null}
                <div className='item-row'>
                  <StatusIcon />
                  <span className='row-label'>
                    {this.props.translate('deviceStatus')}
                  </span>
                  <span className='row-value'>
                    {statusText && this.props.translate('tracker.' + statusText)}
                  </span>
                </div>
              </div>
              {this.props.groups && this.props.groups.length ? <div className='feature-section'>
                <h3 className="feature-row-head">
                  <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
                    <GroupIcon fill='currentColor' width={14} height={14} />
                  </span>
                  {this.props.translate('groups')}
                </h3>
                <div className="feature-content-section">
                  {this.props.groups.map((g,i) => {
                      return <div key={i} className="item-row"><span className="row-label">{g}</span></div> 
                  })}
                </div>

              </div> : null}
          </div> : null}
        </div>
      )
    }
    // short map / street view
    // sensors
    // -> deviceTemp
    // -> adb3 = fuel level
    // -> adb0 = ignition
    // -> hdop = GDP Accuracy
    else if (this.props.type === 'Location') {

      let parking = 0
      let stopTime = 0
      let idling = 0
      const geo = [];
      for(const k in this.props.insideGeofence) {
        if(this.props.insideGeofence[k].includes(parseInt(position.deviceId))) {
          geo.push(k)
        }
      }

      if (
        position.attributes &&
        this.props.device.attributes &&
        position.attributes.stopTime &&
        this.props.device.attributes.minimalParkingDuration
      ) {
        if (
          parseInt(position.attributes.stopTime) >
          (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000)
        ) {
          if(position.attributes.parking) {
            const ms = (position.attributes.stopTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            parking = ms > 0 ? ms : 0 
            stopTime = ms < 0 ?position.attributes.stopTime : 0
          }
          else {
            const ms = (position.attributes.idlTime - (parseInt(this.props.device.attributes.minimalParkingDuration) * 1000));
            idling = ms > 0 ? ms : 0
            stopTime = ms < 0 ? position.attributes.idlTime : 0
          }
        } else {
          stopTime = position.attributes.stopTime
        }
      }
      let latitude = position && position.latitude ? position.latitude : 0
      let longitude = position && position.longitude ? position.longitude : 0

      let hdop =
        position.attributes && position.attributes.hdop
          ? position.attributes.hdop
          : 0

      return (<>
        <div className={'feature-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          <h3 className="feature-row-head" onClick={this.toggleCollapse}>            
            <span>{this.props.translate('location')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span>
          </h3>
          {this.props.collapseable[this.props.type] ? <div>
          {position.exists ? <div className='feature-content-section'>
            <div className='item-row'>
              <span className='row-label'>
                {this.props.translate('movementStatus')}
              </span>
              <span className='row-value'>{this.props.translate((this.props.details&&this.props.details.attributes.devicePowerCut) ? "devicePowerCutOn" :
                  icons && icons.playicon && icons.playicon.label || 'unknown')}</span>
            </div>
            <div className='item-row'>
              <span className='row-label'>
                {this.props.translate('sensorsTranslation.speed')}
              </span>
              <span className='row-value'>
                {setAttributeFormat('speed', position.speed || 0)}
              </span>
            </div>

            <div className='item-row'>
              <span className='row-label'>
                {this.props.translate('altitude')}
              </span>
              <span className='row-value'>
                {setAttributeFormat('altitude', position.altitude)}
              </span>
            </div>
            <div className='item-row'>
              <span className='row-label'>
                {this.props.translate('direction')}
              </span>
              <span className='row-value'>
                <span style={{ display: 'inline-block' }}>
                  {setAttributeFormat('course', position.course || 0)}
                </span>
              </span>
            </div>
            {parking ? (
              <div className='item-row'>
                <span className='row-label'>
                  {this.props.translate('parking')}
                </span>
                <span className='row-value'>
                  {setAttributeFormat('hours', parking)}
                </span>
              </div>
            ) : null}
            {idling ? (
              <div className='item-row'>
                <span className='row-label'>
                  {this.props.translate('idling')}
                </span>
                <span className='row-value'>
                  {setAttributeFormat('hours', idling)}
                </span>
              </div>
            ) : null}
            {stopTime ? (
              <div className='item-row'>
                <span className='row-label'>
                  {this.props.translate('stopTime')}
                </span>
                <span className='row-value'>
                  {setAttributeFormat('hours', stopTime)}
                </span>
              </div>
            ) : null}
            {longitude !== 0 && latitude !== 0 && (
              <div className='item-row'>
                <span className='row-label'>
                  {this.props.translate('settingsCoordinates')}
                </span>
                <span className='row-value'>
                  {setAttributeFormat('latitude', latitude)},
                  {setAttributeFormat('longitude', longitude)}
                  {
                    <button
                      onClick={e => this.props.copyToClipboard(position)}
                      className='button-to-normal'
                    >
                      <FileCopyIcon
                        fill='currentColor'
                        width='14'
                        height='14'
                      />
                    </button>
                  }
                </span>
              </div>
            )}
            <div className='item-row'>
              <span className='row-label'>
                {this.props.translate('address')}
              </span>
              <span className='row-value'>
                {position.address
                  ? ReactHtmlParser(`${position.address}`)
                  : null}
              </span>
            </div>
            {hdop ? (
              <div className='item-row'>
                <span className='row-label'>
                  {this.props.translate('hdop')}
                </span>
                <span className='row-value'>
                  {setAttributeFormat('hdop', hdop)}
                </span>
              </div>
            ) : null}
          </div> : <div className='feature-content-section'>{this.props.translate('notAvailable')}</div>}
          {this.state.modal === true ? (
              <ProtocolDialog
                protocol={this.state.protocol}
                input={this.state.input}
                inputName={this.state.inputName}
                onClose={this.closeProtocol}
              />
            ) : null}
        {position.exists && this.props.geofences && this.props.geofences.length && this.props.geofences.filter(g => geo.includes(g.id.toString())).length ? <div className='feature-section'>
          <h3 className="feature-row-head">
            {this.props.translate('Geofences')}
          </h3>
          <div className="feature-content-section">
            {this.props.geofences.map((g,i) => {
                if(geo.includes(g.id.toString())) {
                  return <div key={i} className="item-row"><span className="row-label">{g.name}</span></div> 
                }
                else {
                  return null;
                }
            })}
          </div>
        </div> : null}
        </div> : null}
        </div>
        </>
      )
    } 
    else if (this.props.type === 'Sensors') {
      return (
        <div className={'feature-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sensors')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
                <div className='feature-content-section'>
                  {position.attributes &&
                  this.props.allComputedAttributes && 
                  this.props.allComputedAttributes.length ? 
                  this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}) => deviceIds.includes(this.props.device.id) && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId'?
                          <Wrapper
                            key={item.id}
                            {...item}
                            logInUserAttr={
                              this.props.logInUser
                                ? this.props.logInUser.attributes
                                : {}
                            }
                            category={item.category}
                            unit={item.attributes && item.attributes.unit}
                            customUnit={item.attributes && item.attributes.customUnit}
                            value={position.attributes[item.attribute]}
                            unknown={position.attributes.unknown}
                            drivers={this.props.drivers}
                            trailers={this.props.trailers}
                            translate={this.props.translate}
                            item2={item}
                          />
                        : null ): <>{this.props.translate('notAvailable')}</>}
                </div>
              
            {position.attributes &&
            this.props.allComputedAttributes && 
            this.props.allComputedAttributes.length ? 
            this.props.allComputedAttributes.length && this.props.allComputedAttributes.map( ({item, deviceIds}, index) => deviceIds.includes(this.props.device.id) && item.attribute === 'driverUniqueId' && item.attribute !== 'binUniqueId' && item.attribute !== 'trailerUniqueId' && item.attribute !== 'passengerUniqueId' ?
                    <React.Fragment key={index}>
                    <h3 className="feature-row-head">{this.props.translate('sharedDriver')}</h3>
                      <div className='feature-content-section'>
                        <Wrapper
                          driverDetails
                          key={item.id}
                          {...item}
                          logInUserAttr={
                            this.props.logInUser
                              ? this.props.logInUser.attributes
                              : {}
                          }
                          category={item.category}
                          unit={item.attributes && item.attributes.unit}
                          customUnit={item.attributes && item.attributes.customUnit}
                          value={position.attributes[item.attribute]}
                          unknown={position.attributes.unknown}
                          drivers={this.props.drivers}
                          trailers={this.props.trailers}
                          translate={this.props.translate}
                        />
                      </div>
                      </React.Fragment>
                  : null) : null}
            </div> : null}
        </div>
      )
    }
    else if (this.props.type === 'Trailer') {
      return (
        <>
        {position.attributes&&position.attributes.trailerUniqueId ? 
        <div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sharedTrailer')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
              <div className='feature-content-section'>
                <div>
                  {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                    position.attributes.trailerUniqueId === ev.uniqueId ? 
                    <>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('sharedName')}
                        </span>
                        <span className='row-value'>
                          {ev.name}
                        </span>
                    </div>
                    <div className='item-row'>
                        <span className='row-label'>
                        {this.props.translate('trailerUniqueId')}
                        </span>
                        <span className='row-value'>
                          {ev.uniqueId}
                        </span>
                    </div>
                    </>
                    : null
                  )) : <>{this.props.translate('notAvailable')}</>}
                </div>
              </div>
          </div> : null}
        </div>
        : null}
        </>
        )
      }

    else if (this.props.type === 'Bin') {
      if(checkPrivileges('binder')) {
      return (
        <>
        {position.attributes&&position.attributes.binUniqueId ? 
        <div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sharedBin')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
              <div className='feature-content-section'>
                <div>
                  {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                    position.attributes.binUniqueId === ev.uniqueId ? 
                    <>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('sharedName')}
                        </span>
                        <span className='row-value'>
                          {ev.name}
                        </span>
                    </div>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('binUniqueId')}
                        </span>
                        <span className='row-value'>
                          {ev.uniqueId}
                        </span>
                    </div></>
                    : null
                  )) : null}
                </div>
              </div>
          </div> : null}
        </div>
        : null}
        </>
        )}
        else{
          return null
        }
      }

    else if (this.props.type === 'Passenger') {
      if(checkPrivileges('binder')) {
      return (
        <>
        {position.attributes&&position.attributes.passengerUniqueId ? 
        <div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sharedPassenger')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
              <div className='feature-content-section'>
                <div>
                  {this.props.trailers && this.props.trailers.length ? this.props.trailers.map(ev => (
                    position.attributes.passengerUniqueId === ev.uniqueId ? 
                    <>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('sharedName')}
                        </span>
                        <span className='row-value'>
                          {ev.name}
                        </span>
                    </div>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('passengerUniqueId')}
                        </span>
                        <span className='row-value'>
                          {ev.uniqueId}
                        </span>
                    </div></>
                    : null
                  )) : <>{this.props.translate('notAvailable')}</>}
                </div>
              </div>
          </div> : null}
        </div>
        : null}
        </>
        )
        }
        else{
          return null
        }
      }

     else if (this.props.type === 'DRIVER') {
      return this.props.fixDriver ?(
          <div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sharedDriver')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
              <div className='feature-content-section'>
                <div>
                    <div className='item-row'>
                        <span className='row-label'>
                          {this.props.translate('sharedName')}
                        </span>
                        <span className='row-value'>
                          {this.props.fixDriver.name}
                        </span>
                    </div>
                    <div className='item-row'>
                        <span className='row-label'>
                        {this.props.translate('driverUniqueIdsssss')}
                        </span>
                        <span className='row-value'>
                          {this.props.fixDriver.uniqueId}
                        </span>
                    </div>
                    {this.props.fixDriver.attributes && this.props.fixDriver.attributes.phone ?
                    <div className='item-row'>
                        <span className='row-label'>
                        {this.props.translate('sharedPhone')}
                        </span>
                        <span className='row-value'>
                          {this.props.fixDriver.attributes.phone}
                        </span>
                    </div> : null}
                </div>
              </div>
          </div> : null} 

              
        </div>
      ) : null
      }
      else if (this.props.type === 'EVENTS') {
      return (
        <div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('event')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
          {this.props.collapseable[this.props.type] ? <div>
          <div className='feature-content-section'>
            <div>
              {this.props.events && this.props.events.length ? this.props.events.map(ev => (
                <p key={ev.id + '' + ev.type}>
                  {ev.name} <TimeAgo title="" date={ev.serverTime} />
                </p>
              )) : <>{this.props.translate('notAvailable')}</>}
            </div>
          </div>
          </div> : null}
        </div>
      )
    }
     else if (this.props.type === 'VEHICLES') {
      return (<div className={'feature-section event-section meter-section collapseable ' + (this.props.collapseable[this.props.type] ? 'open' : '')}>
      {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
      <h3 className="feature-row-head" onClick={this.toggleCollapse}><span>{this.props.translate('sharedVehicle')}</span> <span className="collapse-icon">{this.props.collapseable[this.props.type] ? <UpIcon /> : <DownIcon />}</span></h3>
      
        {this.props.collapseable[this.props.type] ? <div>
            <div className='feature-content-section'>
              {this.props.vehicles && this.props.vehicles.length ? this.props.vehicles.map(ev => (
                this.props.device.id === ev.deviceId ? 
                <>
                
                        <div className='item-row'>
                            <span className='row-label'>
                              {this.props.translate('sharedName')}
                            </span>
                            <span className='row-value'>
                              {ev.label}
                            </span>
                        </div>
                        <div className='item-row'>
                            <span className='row-label'>
                            {this.props.translate('vehicleLicensePlate')}
                            </span>
                            <span className='row-value'>
                              {ev.vehicleLicensePlate}
                            </span>
                        </div>
                        
                </>
                : null
              )) 
              : null
              }
          </div>
        </div> : null}
        </div>
         
        )
      }
      else if (this.props.type === 'ODOMETER') {
      return (
        <div className='feature-section meter-section'>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix'>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('odometer')}
              </li>
              <li className='odo-meter-action pull-right'>
                <a
                  className={
                    'type-toggle ' +
                    (this.state.displayOdo === 'km' ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={e => this.convertOdometer('km')}
                >
                  KM
                </a>
                |
                <a
                  className={
                    'type-toggle ' +
                    (this.state.displayOdo === 'mi' ? 'active' : '')
                  }
                  href={emptyLinks}
                  onClick={e => this.convertOdometer('mi')}
                >
                  Mile
                </a>
              </li>
              <li className='odo-meter pull-right'>
                <DrawHTML word={this.state[this.state.displayOdo]} />
              </li>
            </ul>
            {/* <TimeAgo title="" date={position.serverTime} minPeriod={60} /> */}
          </div>
        </div>
      )
      } else if (this.props.type === 'ENGINEHOURS') {
        return (
        <div className='feature-section meter-section'>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix'>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('reportEngineHours')}
              </li>
              <li className='odo-meter pull-right'>
              {this.props.details &&
                this.props.details.attributes &&
                this.props.details.attributes.hours ? (this.props.details.attributes.hours / (1000 * 3600)).toFixed(2)+" hrs" : null}
              </li>
            </ul>
            {/* <TimeAgo title="" date={position.serverTime} minPeriod={60} /> */}
          </div>
        </div>
      )
    } else if (
      this.props.type === 'INPUT' &&
      this.props.device.model !== 'FX Tracker'
    ) {
      return (
        <div className='feature-section meter-section'>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div className='feature-content-section input-meter-section'>
            <ul className='list-unstyled clearfix'>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('input')}
              </li>
              {/* <li className='odo-meter-action pull-right'>
                <Icon>signal_cellular_alt</Icon>
              </li> */}
              <li className='odo-meter pull-right'>
                <DrawInput attributes={position.attributes} />
              </li>
            </ul>
            {/* <TimeAgo title="" date={position.serverTime} minPeriod={60} /> */}
          </div>
        </div>
      )
    } else if (
      this.props.type === 'OUTPUT' &&
      this.props.device.model !== 'FX Tracker'
    ) {
      return (
        <div className='feature-section meter-section'>
          {/*<button className="feature-section-close"><MaterialIcon icon="close" size="15" /></button>*/}
          <div className='feature-content-section input-meter-section'>
            <ul className='list-unstyled clearfix'>
              <li className='odo-meter-header pull-left'>
                {this.props.translate('output')}
              </li>

              {/* <li className='odo-meter-action pull-right'>
                <Icon>signal_cellular_alt</Icon>
              </li> */}

              <li className='odo-meter pull-right'>
                <DrawOutputHTML
                  word={
                    position.attributes && position.attributes.output
                      ? position.attributes.output
                      : 0
                  }
                />
              </li>
            </ul>
            <TimeAgo title="" date={position.serverTime} minPeriod={60} />
          </div>
        </div>
      )
    } else if (
      this.props.type === 'INPUT' &&
      this.props.device.model === 'FX Tracker' &&
      position &&
      position.attributes &&
      position.attributes.networkType
    ) {
      return (
        <div className='feature-section meter-section'>
          <div className='feature-content-section odo-meter-section'>
            <ul className='list-unstyled clearfix'>
              <li
                className='odo-meter-header pull-left'
                style={{ marginTop: 4 }}
              >
                {'GSM'}
              </li>
              <li className='odo-meter-action pull-right'>
                {position.attributes&&position.attributes.cellularType
                  ? position.attributes.cellularType
                  : signalStrength(position.attributes)}
              </li>
              <li className='odo-meter pull-right' style={{ marginTop: 6 }}>
                <span style={{ color: '#242121' }}>
                  {this.props.translate(position.attributes.networkType)}
                </span>
                |
              </li>
            </ul>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export class DrawHTML extends PureComponent {
  render () {
    const s = '' + this.props.word
    const cls = this.props.className || 'letter'
    const p = s.split(' ')
    if (p.length) {
      const before = p[0].split('')

      let w = [
        before.map((c, i) => {
          return (
            <span key={i} className={cls + ' ' + cls + '-' + i}>
              {c}
            </span>
          )
        })
      ]

      if (p[1]) {
        const after = p[1].split('')
        w.push(
          after.map((c, i) => {
            return (
              <span
                key={i}
                className={cls + ' ' + cls + '-alt ' + cls + '-' + i}
              >
                {c}
              </span>
            )
          })
        )
      }

      return w
    }
  }
}

export class DrawOutputHTML extends PureComponent {
  render () {
    const s = this.props.attributes || {}
    const cls = this.props.className || 'device-input'
    //const p = s.split(".");
    let a = {}
    a.value0 = s.out0
    a.value1 = s.out1
    a.value2 = s.out2
    a.value3 = s.out3
    a.value4 = s.out4
    a.value5 = s.out5

    const html = []

    for (let i = 0; i <= 3; i++) {
      html.push(
        <span
          key={i}
          className={
            (a['value' + i] ? cls + '-alt ' : '') + cls + ' ' + cls + '-' + i
          }
        >
          {i + 1}
        </span>
      )
    }

    return html
  }
}

const Wrapper = ({
  unknown,
  attribute,
  value,
  unit,
  customUnit,
  description,
  logInUserAttr,
  category,
  drivers,
  trailers,
  translate,
  driverDetails,
  item2
}) => {
  let u = ''
  let trailer = null
  // if(attribute === 'trailerUniqueId' && trailers && trailers.length && parseInt(value)) {
  //     let trailerName = "";
  //     trailers.map(d => {
  //       if (
  //         value && 
  //         d.uniqueId === value
  //       ) {
  //         trailerName = d.name
  //       }
  //       return null
  //     })

  //     trailer = (<div className='item-row'>
  //       <TrailerIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('trailersName')}</span>
  //       <span className='row-value'>
  //           {trailerName || 'unknown'}
  //       </span>
  //     </div>);
  // }
  let driver = null
  if(attribute === 'driverUniqueId' && drivers && drivers.length && (value) && driverDetails) {    //parseInt(value) false if uniqueId have character values
    let driverName = "";
    let driverPhone = "";
    drivers.map(d => {
      if (
        value && 
        d.uniqueId === value
      ) {
        driverName = d.name
        driverPhone = d.attributes.phone
      }
      return null
    })

    driver = (<><div className='item-row'>
      <StaffIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverName')}</span>
      <span className='row-value'>
          {driverName || 'unknown'}
      </span>
    </div>
    <div className='item-row'>
      <CallIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}} className='row-label'>{translate && translate('driverPhone')}</span>
      <span className='row-value'>
          {driverPhone || ''}
      </span>
    </div></>);
  }
  if (attribute === 'mileage' || attribute === 'distance') {
    u = logInUserAttr.distanceUnit || customUnit || unit
      // u = item2.attributes.unit || customUnit || unit
  } else if (attribute === 'fuel') {
    u = logInUserAttr.volumeUnit || customUnit || unit
  } else if (attribute === 'speed') {
    u = logInUserAttr.speedUnit || customUnit || unit
  } else {
    u = customUnit || unit
  }
  /* if (unknown && unknown.length && unknown.indexOf(attribute) !== -1) {
    return (
      <div className='item-row'>
        <span className='row-label'>{description}</span>
        <span className='row-value'>Unknown</span>
      </div>
    )
  } else */
   if (value !== undefined || attribute === 'ignition' ) {
        return (
          <>
          <div className='item-row'>
            {category === 0 ? <ElogicIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 1 ? <DiscreteIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 2 ? <MeasurementIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 3 ? <OtherSensorIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : <AggregateIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} />}
            <span className='row-label' style={{marginLeft: 5}}>{description}</span>
            <span className='row-value'>
              {setAttributeFormat(attribute, value, u, null, category)}
            </span>
          </div>
          {driver}
          </>
        )
      }  
   else return null
}

export class DrawInput extends PureComponent {
  render () {
    const s = this.props.attributes || {}
    const cls = this.props.className || 'device-input'
    //const p = s.split(".");
    let a = {}
    a.adc0 = s.adc0 || s.in0
    a.adc1 = s.adc1 || s.in1
    a.adc2 = s.adc2 || s.in2
    a.adc3 = s.adc3 || s.in3
    a.adc4 = s.adc4 || s.in4
    a.adc5 = s.adc5 || s.in5

    const html = []

    for (let i = 0; i <= 5; i++) {
      html.push(
        <span
          key={i}
          className={
            (a['adc' + i] ? cls + '-alt ' : '') + cls + ' ' + cls + '-' + i
          }
        >
          {i + 1}
        </span>
      )
    }

    return html
  }
}

export const FeatureRow = connect(mapStateToProps)(Row)

const signalStrength = att => {
  let ssd = 0
  if (att.signalStrength) {
    ssd = Math.round(att.signalStrength / 20)
  }
  let drawValue = []
  for (let i = 1; i < 6; i++) {
    if (i <= ssd) {
      drawValue.push(
        <li class={'sg-' + i}>
          <div id={'sg-' + i} style={{ background: 'white' }}></div>
        </li>
      )
    } else {
      drawValue.push(
        <li class={'sg-' + i}>
          <div id={'sg-' + i} style={{ background: '#04526666' }}></div>
        </li>
      )
    }
  }
  return (
    <ul id='signal-strength' style={{ padding: 0 }}>
      {drawValue}
    </ul>
  )
}
