import React, {  PureComponent } from "react";
import Menu from "./../Menu/CommonMenu";
import MenuItem from "./../common/MenuItem";
import { withLocalize } from "react-localize-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import "./PositionMenu.scss";
import { Tooltip } from "@material-ui/core";
import Style from "style-it";
import { applyDevicesFilter } from "../../Actions/Devices";
import {
  fetchGeofences,
  fetchDrivers,
  fetchDevices,
  fetchUsers,
} from "./../../Actions/Devices/index";
import { fetchTrailers } from "./../../Actions/Trailer/index";
// import MoreVertIcon from '@material-ui/icons/KeyboardArrowDown';
import Notifications from "react-notification-system-redux";
import Button from "../common/Button";
import Radio from "../common/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExportExcel from "../common/ExportExcel";
import { checkPrivileges, errorHandler } from "../../Helpers";
import CustomDialog from "../common/Dialog";
import instance from "../../axios";
import { unitsList } from "../Devices/unitsPortList";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import SingleSelect from "../common/SingleSelect";
class ExportImportMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      process: false,
      enableImportPopup: false,
      enableInvoiceImport: false,
      enableImportServicesPopup: false,
      uploadedFile: "",
      insertionMode: "1",
      selectedFile: "",
      uploadStatus: false,
      serviceStatus: false,
      serviceData: [],
      isSubmit: true,
      selectedUser: "",
      defaultOption: [],
      vehicleStatusUpload:false,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  onCloseModal = () => {
    this.setState({
      enableImportPopup: false,
      enableInvoiceImport: false,
      enableImportServicesPopup: false,
      selectedFile: "",
      isSubmit: true,
      selectedUser: "",
      vehicleStatusUpload:false
    });
  };
  onCloseServiceModel = () => {
    this.setState({
      enableImportPopup: false,
      enableInvoiceImport: false,
      enableImportServicesPopup: false,
      serviceStatus: false,
      selectedFile: "",
      isSubmit: true,
      vehicleStatusUpload:false

    });
  };
  onOpenModal = (name, e) => {
    console.log("name======", name);
    if (this.props.fileName === "invoices") {
      this.setState({
        enableInvoiceImport: true,
      });
    } else {
      this.setState({
        enableImportPopup:
          this.props.fileName === "services/subtypes" ? false : true,
        enableImportServicesPopup:
          this.props.fileName === "services/subtypes" ? true : false,
        uploadStatus: name === "uploadStatus" ? true : false,
        vehicleStatusUpload: name === "vehicleStatus" ? true : false

      });
    }
  };
  uploadFile = (e) => {
    this.setState({
      uploadedFile: e.target.files && e.target.files[0],
      selectedFile:
        e.target.files && e.target.files[0] && e.target.files[0].name,
      isSubmit: false,
    });
  };
  selectInsertionMode = (e) => {
    this.setState({
      insertionMode: e.target.value,
    });
  };

  formSubmit = async (e, fileName) => {
    let api,
      message = this.props.translate("geofenceCreated");
    const file = await this.convertBase64(e);
    const base64 = file.split("base64,");

    let allData = {
      name: "anyName",
      contentType:
        "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      oldUrl: "",
      insertionMode: this.state.insertionMode,
      data: base64[1],
    };
    let vehicleData = {
      name: "anyName",
      contentType:
        "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      oldUrl: "",
      insertionMode: this.state.insertionMode,
      data: base64[1],
      parentIdForData: this.state.selectedUser?.id || 0,
    };
    if (
      [
        // "vehicles",
        "drivers",
        "devices",
        "binders",
        "users",
        "services",
        "invoices",
        "deliverynotes",
      ].includes(fileName)
    ) {
      api = `/api/${fileName}/import`;
    } else if (fileName === "vehicles") {
      api = this.state.vehicleStatusUpload ? `/api/${fileName}/importstatus` : `/api/${fileName}/import`;
    }else if (fileName === "category") {
      api = `/api/expensetypes/import/${this.props.ServerSetting.contractNumber}`;
    } else if (fileName === "expensetypes" && this.props.downloadSamplev2) {
      api = `/api/expensetypes/import/v2`;
    }else if (fileName === "expensetypes") {
      api = `/api/expensetypes/import/${this.props.ServerSetting.contractNumber}`;
    } else if (fileName === "services/subtypes") {
      api = `api/expensetypes/subtypes/import?expensetypeId=${this.props.expensetypeId}`;
    } else if (fileName === "uploadStatus") {
      api = `api/services/import/status`;
    } else if (fileName === "geofences") {
      api = `/api/geofences/import/circle`;
    }
    
    this.setState({ isSubmit: false },()=>{;
    
    if (e) {
      instance({
        method: "POST",
        url: api,
        data: fileName === "vehicles" ? vehicleData : allData,
      })
        .then((response) => {
          // if(response && response.data && response.data.status === 'success'){
          if (fileName === "vehicles") {
            let data = response;
            let flag = this.state.vehicleStatusUpload ? "vehicleStatus" : this.state.selectedUser
            console.log("Res=====", response);
            if(data) this.props.openImportTable(data, flag);
            // this.props.dispatch(
            //   Notifications.success({
            //     message: this.props.translate("Vehicle Upload Successfull"),
            //     autoDismiss: 5,
            //     location: this.props.location,
            //   })
            // )
            // fetchVehicles(this.props.dispatch, this.props.logInUser);
            // this.props.fetchMoreItems(true);
            // message =
            //   "Total " +
            //   response.total +
            //   "   " +
            //   "Added " +
            //   response.added +
            //   "  " +
            //   "Updated " +
            //   response.updated +
            //   "  " +
            //   " Skipped" +
            //   "   " +
            //   response.skipped;
          } else if (fileName === "drivers") {
            fetchDrivers(this.props.dispatch, this.props.logInUser);
          } else if (fileName === "devices") {
            this.props.dispatch(fetchDevices(this.props.logInUser, true));
          } else if (fileName === "geofences") {
            fetchGeofences(this.props.dispatch, this.props.logInUser);
          } else if (fileName === "binders") {
            fetchTrailers(this.props.dispatch, this.props.logInUser);
          } else if (fileName === "users") {
            fetchUsers(this.props.dispatch, this.props.logInUser);
          } else if (fileName === "deliverynotes"){
            let data =
            response && response

            if (data.errorMsg) this.props.openImportTable(data.errorMsg);
            const valuesArray = Object.values(data.errorMsg);
            const valueToPrint = valuesArray[0];
            if(valueToPrint){
              message = valueToPrint
            }else{
              message = this.props.translate("DN-UploadedSuccessfully")
            }
            // message= valuesArray[0];
            // if( data.added <=0) {  
            // this.props.dispatch(
            //   Notifications.error({
            //     message: this.props.translate(message),
            //     autoDismiss: 5,
            //     location: this.props.location,
            //   })
            // ); }
            // else{
            // this.props.dispatch(
            //   Notifications.success({
            //     message: this.props.translate(message),
            //     autoDismiss: 5,
            //     location: this.props.location,
            //   })
            // )
            // }
          }
          else if (fileName === "invoices") {
            this.props.fetchData(this.props.logInUser, 1, 20);
            let data = response;
            if (data.errorMsg) this.props.openImportTable(data.errorMsg);
            message =
              "Total " + data.total + "   " + " Skipped" + "   " + data.skipped;
          } else if (fileName !== "invoices") {
            const b = [];
            const c = response;
            Object.keys(c).map((key) => {
              if (c[key] && key !== "total" && c.total !== c.skiped)
                b.push({ type: this.props.translate(key), value: c[key] });
            });
            b.map((data, i) => {
              if (b.length > 1) {
                if (b.length - 2 === i) {
                  message +=
                    data.value +
                    " " +
                    data.type +
                    " " +
                    this.props.translate("AND").toLowerCase() +
                    " ";
                } else if (b.length - 1 !== i) {
                  message += data.value + " " + data.type + ", ";
                } else {
                  message += data.value + " " + data.type;
                }
              } else {
                message += data.value + " " + data.type;
              }
            });
            if (fileName === "uploadStatus") {
              this.setState({
                serviceStatus: true,
                serviceData: response,
                uploadStatus: false,
                
              });
              message += this.props.translate("successfullyUploaZHelded");
            }
            if (fileName === "services") {
              message = `${response.length} services imported`;
              this.onCloseModal();
              this.props.importFunc(response);
            } else if (fileName === "category") {
              message = this.props.translate("contractImportedSuccessfully");
              this.props.fetchDataFunc(this.props.logInUser, 1, 50);
            } else if (b.length === 0) {
              message = this.props.translate("allItemsOmitted");
            } else if (fileName === "services/subtypes") {
              message = this.props.translate("subType") + this.props.translate("successfullyImported")
              this.props.getExpense(this.props.expenseId);
            } else {
              message += this.props.translate(" successfully");
            }
          }
          if (fileName !== "services" && fileName !== "vehicles") {
            this.props.dispatch(
              Notifications.success({
                message: message,
                autoDismiss: 10,
              })
            );
          }
          this.setState({
          isSubmit: true 
        });

          this.onCloseModal();
          //     }else{
          //     this.props.dispatch(
          //         Notifications.error({
          //           message: this.props.translate('invalidFileFormat'),
          //           autoDismiss: 10
          //         })
          //       )
          // }
        })
        .catch((error) => {
          console.log("error ====", error);
          if (
            error &&
            error.message &&
            error?.message?.includes(
              "Duplicate entry Invoice Number already in use"
            )
          ) {
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(
                  "Duplicate entry Invoice Number already in use"
                ),
                autoDismiss: 10,
              })
            );
          } else {
            errorHandler(error, this.props.dispatch);
          }

          this.onCloseModal();
        });
    }
  })
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  componentWillMount() {}

  showSelectedUnits(data) {
    const list = [...this.props.filterList];
    let newList = [];
    if (list.includes(data)) {
      list.map((l) => (l != data ? newList.push(l) : null));
    } else {
      newList = [...list].concat(data);
    }
    this.props.dispatch(applyDevicesFilter(newList));
  }

  componentWillReceiveProps(n) {
    if (this.props.users.length && this.state.defaultOption.length < 1) {
      let result = this.props.users.map((item) => ({
        label: item.name || "",
        name: item.name || "",
        value: item.name,
        key: item.id,
        id: item.id,
      }));
      this.setState({ defaultOption: result });
    }
  }

  wrapperXML = (name) => {
    let data = [];
    name.map((e, i) => {
      data.push(i + 1 + " " + (e + "\n"));
    });

    return data;
  };

  clickDownload = (fileName) => {
    instance(`/api/devices/categories`)
      .then((response) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href =
          "data: application/xml;charset=utf-8," +
          encodeURIComponent(this.wrapperXML(response.categories));
        a.href = a.href.replaceAll("%2C", "");
        a.download = fileName + ".txt";
        a.click();
        a.remove();
        return null;
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  DownloadDeviceModal = (fileName) => {
    let res = unitsList && unitsList.map((a) => a.device);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href =
      "data: application/xml;charset=utf-8," +
      encodeURIComponent(this.wrapperXML(res));
    a.href = a.href.replaceAll("%2C", "");
    a.download = fileName + ".txt";
    a.click();
    a.remove();
    return null;
  };

  handleChangeUser = (name, event) => {
    this.setState({
      selectedUser: event,
    });
  };

  searchList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let json = await response.json();
    let result =
      (await json.data) &&
      json.data.data &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };

  render() {
    let { value, defaultOption } = this.state;
    const { classes, group } = this.props;
    const defaultOptions = [];
    unitsList.map((item, i) => {
      if (i <= 9) {
        defaultOptions.push({
          id: item.id,
          name: item.name,
          key: item.id,
          label: item.name,
          value: item.name,
        });
      }
      return null;
    });
    const { anchorEl } = this.state;
    const empty = null;
    const { data, fileName, checkPrivilege } = this.props;
    return (
      <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            {this.props.isMaintenance || this.props.isInvoices ? (
              <Button style={{ marginLeft: 10, marginLeft: 6 }}>
                {this.props.translate("select")}
                <KeyboardArrowDown
                  style={{
                    width: 21,
                    display: "block",
                    height: 24,
                    marginLeft: 10,
                    marginRight: -5,
                  }}
                />
              </Button>
            ) : this.props.isServices ? (
              <Button style={{ marginLeft: 10 }}>
                {this.props.translate("select")}
                <KeyboardArrowDown
                  style={{
                    width: 21,
                    display: "block",
                    height: 24,
                    marginLeft: 10,
                    marginRight: -5,
                  }}
                />
              </Button>
            ) : (
              <MoreVertIcon
                style={{
                  borderRadius: this.props.radius || 4,
                  width: 24,
                  display: "block",
                  height: 26,
                }}
              />
            )}
          </a>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
            {(fileName !== "users" &&
              fileName !== "category"
              && fileName !== "invoices"
              ) 
              || fileName === "deliverynotes" ||
              
            fileName === "services" ||
            fileName === "services/subtypes" ||
            fileName === "vehicles" ||
            fileName === "devices" ||
            fileName === "expensetypes" ||
            fileName === "drivers" ||
            fileName === "binders" ||
            fileName === "geofences" ? (
              <ExportExcel
                type="Sample"
                fileName={fileName}
                data={data ? data : []}
                title="Sample"
                sample={true}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                logInUser={this.props.logInUser}
              />
            ) : null}

                {fileName === "expensetypes"? (
                  <ExportExcel
                    type="Sample"
                    fileName={"downloadSamplev2"}
                    data={data ? data : []}
                    title={this.props.translate("downloadSamplev2")}
                    sample={true}
                    dispatch={this.props.dispatch}
                    translate={this.props.translate}
                    logInUser={this.props.logInUser}
                  />
                ) : null}
                
           {fileName === "services" ||
            fileName === "invoices" ||
            fileName === "users" ||
            fileName === "vehicles" ||
            fileName === "devices" ||
            fileName === "drivers" ||
            // fileName === "deliverynotes" ||
            fileName === "binders" ||
            fileName === "geofences" ? (
              <ExportExcel
                // {...this.props}
                selectedAreaId={this.props.selectedAreaId}
                filterStatusType={this.props.filterStatusType}
                selectedVehicleId={this.props.selectedVehicleId}
                from={this.props?.from}
                to={this.props?.to}
                createdBy={this.props?.createdBy}
                selectedAccount={this.props.selectedAccount}
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title="Excel"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
              />
            ) : null}

           {/* {
            (fileName !== "users" &&
            fileName !== "category"
            && fileName !== "invoices" &&  
            fileName !== "deliverynotes" &&
            fileName !== "services" &&
            fileName !== "services/subtypes" && 
            fileName !== "devices" &&
            fileName !== "expensetypes" &&
            fileName !== "drivers" &&
            fileName !== "binders" &&
            fileName !== "geofences"
            ) 
            &&
             fileName === "vehicles" 
              ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" FuelTypes"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/fueltypes"}
              />
            ) : null} */}

           {/* {
            (fileName !== "users" &&
            fileName !== "category"
            && fileName !== "invoices" &&  
            fileName !== "deliverynotes" &&
            fileName !== "services" &&
            fileName !== "services/subtypes" && 
            fileName !== "devices" &&
            fileName !== "expensetypes" &&
            fileName !== "drivers" &&
            fileName !== "binders" &&
            fileName !== "geofences"
            ) 
            &&
             fileName === "vehicles" 
              ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Categories"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/categories"}
              />
            ) : null} */}
           {
            (fileName !== "users" &&
            fileName !== "category"
            && fileName !== "invoices" &&  
            fileName !== "deliverynotes" &&
            fileName !== "services" &&
            fileName !== "services/subtypes" && 
            fileName !== "devices" &&
            fileName !== "expensetypes" &&
            fileName !== "drivers" &&
            fileName !== "binders" &&
            fileName !== "geofences"
            ) 
            &&
             fileName === "vehicles" 
              ? (
                <>
                 <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" FuelTypes"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/fueltypes"}
              />
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Status"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/status"}
              />
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" MakeModel"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/makemodel"}
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Categories"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/categories"}
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleEntitleMent")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="entitlement"
                downloadType={"entitlement"}
                expensetypeId={this.props.expensetypeId}
                services
              />
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Carbon Footprint"
                // sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                // logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="carbonfootprint"
                downloadType={"carbonfootprint"}
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Vehicle Utilization"
                // sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                // logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="utilization"
                downloadType={"utilization"}
              />
              </>
            ) : null}
          
            {data && data.length && fileName === "services/subtypes" ? (
              <ExportExcel
                expensetypeId={this.props.expensetypeId}
                type="Excel"
                fileName={fileName}
                data={data}
                title="Excel"
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
              />
            ) : null}
            {fileName !== "geofences" &&
            fileName !== "services" &&
            data &&
            data.length &&
            fileName !== "services/subtypes" ? (
              <ExportExcel
                type="PDF"
                fileName={fileName}
                data={data}
                title="PDF"
                sample={false}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                logInUser={this.props.logInUser}
              />
            ) : null}
            {fileName !== "users" && checkPrivileges(checkPrivilege || "") ? (
              <Tooltip title={this.props.translate('uploadExcel')}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.onOpenModal}
                >
                  {this.props.translate("uploadExcel")}
                </MenuItem>
              </Tooltip>
            ) : null}

          {fileName === "vehicles" && checkPrivileges(checkPrivilege || "") ? (
              <Tooltip title={this.props.translate('uploadExcelStatus')}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={(e)=>this.onOpenModal("vehicleStatus", e)}
                >
                  {this.props.translate("uploadExcelStatus")}
                </MenuItem>
              </Tooltip>
            ) : null}
            {/* {fileName === "vehicles" 
              ? (
                <>   <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleEntitleMent")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="entitlement"
                downloadType={"entitlement"}
                expensetypeId={this.props.expensetypeId}
                services
              />
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Carbon Footprint"
                // sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                // logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="carbonfootprint"
                downloadType={"carbonfootprint"}
              />
              </>
            ) : null} */}
            {/* {fileName === "vehicles" 
              ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title=" Vehicle Utilization"
                // sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                // logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="utilization"
                downloadType={"utilization"}
              />
            ) : null} */}
            
            {(fileName === "deliverynotes"  &&  checkPrivileges('deliverynoteCreate')) || fileName === "expensetypes"   ? (
              <Tooltip title={fileName === "expensetypes" ? this.props.translate('uploadExcelv2') :  this.props.translate('uploadExcel')}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.onOpenModal}
                >
                  {fileName === "expensetypes" ? this.props.translate('uploadExcelv2') : this.props.translate('uploadExcel')}
                </MenuItem>
              </Tooltip>
            ) : null}

            {fileName === "devices" ? (
              <MenuItem
                style={{ minWidth: "inherit" }}
                component="label"
                onClick={() => this.clickDownload("Categories")}
              >
                {this.props.translate("sampleCategory")}
              </MenuItem>
            ) : null}
            {fileName === "devices" ? (
              <MenuItem
                style={{ minWidth: "inherit" }}
                component="label"
                onClick={() => this.DownloadDeviceModal("DeviceModal")}
              >
                {" "}
                {this.props.translate("sampleDevicesModel")}{" "}
              </MenuItem>
            ) : null}
          </Menu>
          {this.state.enableImportServicesPopup && (
            <CustomDialog
              title="Import Excel"
              themecolors={this.props.themecolors}
              visable={true}
              onClose={this.onCloseModal}
              bodyPadding={10}
              headerActions={
                <Button
                  onClick={() =>
                    this.formSubmit(
                      this.state.uploadedFile,
                      this.state.uploadStatus
                        ? "uploadStatus"
                        : this.props.fileName
                    )
                  }
                  disabled={this.state.isSubmit}
                >
                  {this.props.translate("Submit")}
                </Button>
              }
              isVisableBtn
              noFullScreen
            >
              <label
                style={{
                  padding: 10,
                  border: `1px dashed`,
                  borderRadius: 6,
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ marginRight: "auto" }}>
                  {fileName !== "geofences"
                    ? this.state.selectedFile ||
                      this.props.translate("uploadExcel")
                    : this.state.selectedFile || "Circle Geofences Upload"}
                  <input
                    type="file"
                    onChange={(e) => this.uploadFile(e)}
                    hidden
                  />
                </div>
                <Button component="span">browse file</Button>
              </label>
            </CustomDialog>
          )}

          {(this.state.enableImportPopup || this.state.enableInvoiceImport) && (
            <CustomDialog
              title="Import Excel"
              themecolors={this.props.themecolors}
              visable={true}
              onClose={this.onCloseModal}
              bodyPadding={10}
              dialogHeight={fileName === "vehicles" ? 300 : 50}
              headerActions={
                <Button
                  onClick={() =>
                    this.formSubmit(
                      this.state.uploadedFile,
                      this.state.uploadStatus
                        ? "uploadStatus"
                        : this.props.fileName
                    )
                  }
                  disabled={this.state.isSubmit}
                >
                  {this.props.translate("Submit")}
                </Button>
              }
              isVisableBtn
              noFullScreen
            >
              <label
                style={{
                  padding: 10,
                  border: `1px dashed`,
                  borderRadius: 6,
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ marginRight: "auto" }}>
                  {fileName !== "geofences"
                    ? this.state.selectedFile ||
                      this.props.translate("uploadExcel")
                    : this.state.selectedFile || "Circle Geofences Upload"}
                  <input
                    type="file"
                    onChange={(e) => this.uploadFile(e)}
                    hidden
                  />
                </div>
                <Button component="span">browse file</Button>
              </label>
              {!this.state.enableInvoiceImport && this.state.vehicleStatusUpload === false && (
                <RadioGroup
                  aria-label="selectedDate"
                  name="selectedDate"
                  value={this.state.insertionMode}
                  onChange={(e) => this.selectInsertionMode(e)}
                  row
                >
                  <FormControlLabel
                    classes={{
                      label: "form-contol-label",
                    }}
                    value={"1"}
                    control={<Radio size="small" />}
                    label={this.props.translate("addNewData")}
                    labelPlacement="end"
                  />
                  {fileName !== "geofences" && fileName !== "services"  && fileName !== "vehicles" && (
                    <FormControlLabel
                      classes={{
                        label: "form-contol-label",
                      }}
                      value={"2"}
                      control={<Radio size="small" />}
                      label={this.props.translate("addNewAndUpdate")}
                      labelPlacement="end"
                    />
                  )}
                </RadioGroup>
              )}
              {this.props.fileName === "vehicles" && this.state.vehicleStatusUpload === false ? (
                <>
                  <SingleSelect
                    array={[]}
                    async
                    selectName="users"
                    defaultOptions={defaultOption}
                    loadOptions={this.searchList}
                    label={this.props.translate("selectChildUser")}
                    value={
                      this.state.selectedUser && this.state.selectedUser.id
                        ? {
                            key: this.state.selectedUser.id,
                            id: this.state.selectedUser.id,
                            label: this.state.selectedUser.name,
                          }
                        : ""
                    }
                    handleChange={this.handleChangeUser}
                    canAssign={true}
                  />
                </>
              ) : null}
            </CustomDialog>
          )}
          {this.state.serviceStatus && (
            <CustomDialog
              title="Services"
              themecolors={this.props.themecolors}
              visable={true}
              onClose={this.onCloseServiceModel}
              bodyPadding={10}
              isVisableBtn
              noFullScreen
            >
              <div>
                <table style={{ width: 500 }}>
                  <thead>
                    <th>Vehicle Plate </th>
                    <th> Message </th>
                  </thead>
                  <tbody>
                    {Object.entries(this.state.serviceData).map((key, val) => {
                      return (
                        <tr>
                          <td>{key[0]}</td>
                          <td>{key[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CustomDialog>
          )}
        </div>
      </Style>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  logInUser: state.logInUsers,
  filterList:
    state.filterList && state.filterList.filters
      ? state.filterList.filters
      : [],
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users,
});

export default connect(mapStateToProps)(withLocalize(ExportImportMenu));
