import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import TextField from './../../../Components/common/SearchField'
import { GroupModal } from './../../../Components/Groups/groupModal'
import { DevicesShortList, ListRow } from './../../../Components/Devices/shortList'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import Style from 'style-it'
import Loader from './../../Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices } from '../../../Actions/Devices'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import RecourcesList from './RecourcesList'
import { useSelector } from 'react-redux'
import instance from '../../../axios'
import { Icon } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SortAscIcon from '@material-ui/icons/Sort'
import ItemGroupMenu from '../../../Components/Maps/ItemGroupMenu'
import { Translate } from 'react-localize-redux'


function importAll (r) {
  return r.keys().map(r)
}
const images = importAll(
  require.context('./../../../images', false, /\.(png|jpe?g|svg)$/)
)

export default class UnitsSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      switchView: 'units',
      isPopupVisable: false,
      is_gm_Visable: false,
      isDeviceLimit: false,
      countCall: true
    }
    this.switchView = this.switchView.bind(this)
  }

  switchView (type, e) {
    this.setState({
      switchView: type,
      isChecked: true,
      sort: 'ASC',
      search: ''
    })
  }
  checkedDeviceLimit = () => {
    if (
      this.props.logInUser.deviceLimit &&
      (this.state.countCall || this.props.checkeDeviceLimit)
    ) {
      if (this.props.logInUser.deviceLimit !== -1) {
        this.setState(
          {
            countCall: false
          },
          () => {
            this.props.checkedDeviceLimit()
            // fetch('/api/devices/count')
            instance({
              url: `/api/devices/count`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(count => {
                  if (count === this.props.logInUser.deviceLimit) {
                    this.setState({
                      isDeviceLimit: true
                    })
                  } else {
                    this.setState({
                      isDeviceLimit: false
                    })
                  }
              //   })
              // }
              // else{
              //   throw response
              // }
            }).catch(error => {
              // errorHandler(error, this.props.dispatch)
            })
          }
        )
      }
    }
  }
  render () {
    this.checkedDeviceLimit()
    const endMessage =
      this.props.devices && this.props.devices.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
        <div>
          {this.state.is_gm_Visable && (
            <GroupModal onCloseModal={this.onCloseModal} />
          )}
          <div className='section-head section-head-filter' style={{ paddingTop: 0 }}>
            <ul className='filter-row'>
              <li className="flex sidebar-icon-gap">
                <TextField
                  label={this.props.translate('searchUnits')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.props.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
            </ul>
            <ul className="filter-row">
              <li>{this.props.resourceList ? <Translate id='groups' /> : <Translate id='trackers' />}</li>
              <li style={{ paddingTop: 4, paddingBottom: 4, marginRight: 'auto' }}>
                <ItemGroupMenu
                  individualTitle={<Translate id='trackers' />}
                  groupTitle={<Translate id='groups' />}
                  showResources={this.props.showResources}
                  themecolors = {this.props.themecolors}
                />
              </li>
              {this.props.resourceList ?
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>{/*, paddingRight: 0 }}> */}
                {checkPrivileges('itemgroupCreate') && (
                  <Button
                    size='medium'
                    style={{ marginRight: 5 }}
                    disabled={this.state.isDeviceLimit}
                    fullWidth
                    onClick={() => {
                      this.props.addResource()
                    }}
                  >
                    <AddIcon />
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}
              </li>:
              <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
              {checkPrivileges('deviceCreate') && (
                <Button
                  size='medium'
                  style={{ marginRight: 5 }}
                  disabled={this.state.isDeviceLimit}
                  fullWidth
                  onClick={() => {
                    this.props.addDevice()
                  }}
                >
                  <AddIcon />
                  {this.props.translate('sharedCreate')}
                </Button>
              )}
              </li>}
              <li style={{ padding: 0 }}>
                <ExportImportMenu
                  fileName = 'devices'
                  checkPrivilege = 'deviceCreate'
                  data = {this.props.devices.data}
                  themecolors = {this.props.themecolors}
                />
              </li>
            </ul>
          </div>
          {this.props.resourceList ? 
          <RecourcesList 
          ItemChild={ListRow}
          ItemChildProps={this.props}
          fromArray={this.props.devices && this.props.devices.data || []}
          {...this.props} /> :
          <div className='sidebar-devices-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div
                      {...restProps}
                      ref={elementRef}
                      id='scrollableDivDevices'
                    />
                  )
                }
              }}
            >
              {this.props.devices.isFetching ? (
                <Loader defaultStyle={true} />
              ) : this.props.devices.total ? (
                <InfiniteScroll
                  dataLength={this.props.devices.data.length}
                  next={() => this.props.dispatch(fetchMoreDevices())}
                  hasMore={this.props.devices.hasNext}
                  loader={<Loader defaultStyle={true} />}
                  scrollableTarget='scrollableDivDevices'
                  endMessage={endMessage}
                >
                  <DevicesShortList
                    {...this.state}
                    removeDevice={this.props.removeDevice}
                    resetFrom={this.props.resetFrom}
                    devicesIcons={images}
                    devices={this.props.devices.data}
                    match={this.props.match}
                    isChecked={this.props.isChecked}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                  />
                </InfiniteScroll>
              ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}>
                    {this.props.translate('notFound')}
                  </p>}
            </Scrollbar>
          </div>}
        </div>
      </Style>
    )
  }
}
