import React, { Component } from 'react'
import './../../../node_modules/leaflet-draw/dist/leaflet.draw.css'
import { connect } from 'react-redux'
import {
  setBounds,
  resetBounds,
  setBoundApply,
  setTrackId,
  updateDeviceVisible
} from './../../Actions/Devices'
import { addPOI, updatePOI } from './../../Actions/POI'
import {
  Map,
  TileLayer,
  FeatureGroup,
  Circle,
  Popup,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  ZoomControl
} from 'react-leaflet'
import CustomMarker from './CustomMarker'
import CustomMarker2 from './CustomMarker2'
import 'leaflet-plugins/layer/tile/Yandex'
import { EditControl } from 'react-leaflet-draw'
import L from 'leaflet'
import LCG from 'leaflet-control-geocoder'
import isEqual from 'react-fast-compare'
import { MapFilters, MapFiltersVerticle } from './MapFilters'
import 'react-contextmenu'
import MarkerClusterGroup from './MarkerClusterGroup'
import ReactHtmlParser from 'react-html-parser'
import './DrawMap.scss'
import { MapTooltip } from './MapTooltip'
import Notifications from 'react-notification-system-redux'
import CloseIcon from '@material-ui/icons/Close';
import Button from '../common/Button'
import moment from 'moment'
import SearchGeo from './SearchGeo'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import { toggleTrafficLayer } from '../../Actions/Maps'
const polyDefault = [[0,0], [0,0]];

let initState = {
  lat: 0,
  lng: 0,
  zoom: 3,
  minZoom: 3,
  positions: [],
  bounds: null,
  apply: false,
  referencePoints: [],
  poi: [],
  numMapClicks: 0,
  update: true,
  updatePOI: true,
  EditControlObject: {},
  addGeoFenceObject: null,
  addGeoFenceObjectProps: null,
  EditControlObjectProps: {},
  updatedGeo: {},
  geo: {},
  pointer: null,
  newVectorId: 0,

  whichControls: '',
  poiForm: {},

  EditMode: false,
  DrawControl: {},
  MeasureControl: {},
  MeasureEditMode: false,
  newVector: {},

  Drawing: '',
  Measuring: '',

  fitBounds: {},
  referenceMarker: {},
  contextMarker: {},
  polylineMarkers: [],

  animCount: 0,
  bounding: false,

  assignMarkers: false
}

class DrawMap extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ...initState,
      getGeofanceItem: this.getGeofanceItem.bind(this),
      cancelMapChanges: this.cancelMapChanges.bind(this),
      savePOI: this.savePOI.bind(this),
      updateVisible: this.updateVisible,
      updatePOIVisible: this.updatePOIVisible.bind(this)
    }

    this.myRef = this.myRef.bind(this)
    this.setBoundOptions = this.setBoundOptions.bind(this)
    this.mapEvent = this.mapEvent.bind(this)
    this.createReferencePoint = this.createReferencePoint.bind(this)
    this.createPOI = this.createPOI.bind(this)
    this.deleteRef = this.deleteRef.bind(this)
    this.addPOIOnMap = this.addPOIOnMap.bind(this)
    this.handleMoveEnd = this.handleMoveEnd.bind(this)

    this.addMeasureShape = this.addMeasureShape.bind(this)

    this.setEditControlProps = this.setEditControlProps.bind(this)
    this.setEditControlValue = this.setEditControlValue.bind(this)
    this.reactECref = {}
    this.markers = {}
    this.clusterMarkerList = {}
    this.clusterMarkers = null
  }

  getGeofanceItem (item) {
    this.setState({
      geo: { ...item }
    })
  }

  setEditControlProps (obj) {
    this.setState({
      newVectorId: obj.id || 0,
      EditControlObjectProps: obj
    })
  }

  setEditControlValue (obj) {
    this.setState({
      EditControlObject: obj
    })
  }

  addPOIOnMap () {
    const center = this.map && this.map.getCenter()
    this.setState({
      poiForm: {
        name: "POI's Name",
        description: "POI's Description",
        visible: true,
        area: 'CIRCLE (' + center.lat + ' ' + center.lng + ', 50)',
        attributes: {
          radius: 50,
          latlng: center
        }
      }
    })
  }

  myRef (el) {
    if (el) {
      this.map = el.leafletElement
      this.map && this.props.setMapRef(this.map)
      if (this.map &&  L.Control.geocoder) {
        L.Control.geocoder({
        position: 'topright',
        placeholder: 'Search location...',
        defaultMarkGeocode: false,
        expand: 'click',
        geocoder: L.Control.Geocoder?.nominatim({
            htmlTemplate: r => {
              return `<span class="leaflet-control-geocoder-address-context">${r.display_name}</span><br>`
            }
          })
        })
        .on('finishgeocode', e => {
          let results = e.results.map(res => ({ ...res, html: res.name }))
          e.target._alts.childNodes.forEach(el => (el.innerHTML = 'hello'))
        })
        .on('markgeocode', e => {
          this.map && this.map.fitBounds([[e.geocode.center.lat, e.geocode.center.lng]])
          this.setState({
            pointer: (
              <Marker
                position={[e.geocode.center.lat, e.geocode.center.lng]}
                icon={L.icon({
                  iconUrl: '/assets/images/location-pin.svg',
                  iconSize: [79, 64],
                  iconAnchor: [20, 64]
                })}
              />
            )
          })

          if (this.props.getPostion) {
            this.map && this.props.getPostion(e.geocode.center, this.map.getZoom())
          }
        })
        .addTo(this.map)
      }
    }
  }

  saveMapChanges (obj) {
    this.setState({ geo: obj })
    if (this.state.EditControlObject[obj.id]._toolbars.edit._actionButtons[0]) {
      this.state.EditControlObject[
        obj.id
      ]._toolbars.edit._actionButtons[0].button.click()
    }
  }

  bindMap (el) {
    this.map = el.leafletElement
  }

  setBoundOptions () {
    return {}
  }

  checkZoom = () => {
    this.setState({ bounds: null, bounding: false })
    
    // this.props.dispatch(resetBounds())
    // this.props.dispatch(setTrackId(0))
    /* if(this.map && (this.map.getZoom() < 12) || this.map.getZoom() > 18 ) {
    } */

  }

  mapContextMenu = (e) => {
    this.setState({ contextMarker: { position: e&&e.latlng, address: '' } })
    this.getContextMenuInfo(e);
  }

  getContextMenuInfo = (e, column, ref) => {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${e&&e.latlng&&e.latlng.lat}&lon=${e&&e.latlng&&e.latlng.lng}&zoom=18&addressdetails=1`
    )
    // axios({
    //   url: `https://nominatim.openstreetmap.org/reverse`,
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   params:{
    //     format: 'json',
    //     lat: e&&e.latlng.lat,
    //     lon: e&&e.latlng.lng,
    //     zoom: 18,
    //     addressdetails: 1
    //   }
    // })
    .then(response => {
      if (response.ok) {
        response.json()
        .then(address => {
          this.setState({
            contextMarker: {
              ...this.state.contextMarker&&this.state.contextMarker,
              address: address&&address.display_name,
              obj: address&&address.display_name
            }
          })
        })
      } else{
        throw response
      }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  createReferencePoint (location) {
    location.id = 'ref_' + new Date().getTime().toString()

    var referencePoints = this.state.referencePoints.concat(location)

    this.setState({
      ...this.state,
      referencePoints: referencePoints,
      contextMarker: {}
    })

    this.map && this.map.closePopup()
  }

  createPOI (obj) {
    //var poi = this.state.poi.concat(obj);
    this.setState({
      contextMarker: {},
      poiForm: {
        name: 'POI',
        description: obj.address,
        visible: true,
        area: 'CIRCLE (' + obj.position.lat + ' ' + obj.position.lng + ', 50)',
        attributes: {
          radius: 50,
          latlng: obj.position
        }
      }
    })

    this.map && this.map.closePopup()
  }

  mapEvent (e) {
    if (this.props.markerEnabled) {
      this.setState({ referenceMarker: { position: e&&e.latlng, address: '' } })

      this.getInfoReference(e)
    }
  }

  getInfoReference = e => {
    if (this.map && LCG && LCG.L && LCG.L.Control && LCG.L.Control.fGeocoder) {
      const geocoder = LCG.L.Control.Geocoder?.nominatim()

      geocoder.reverse(e&&e.latlng, this.map.options.crs.scale(18), results => {
        var r = results[0]

        this.setState({
          referenceMarker: { ...this.state.referenceMarker, address: r.name }
        })
      })
    }
  }

  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//

  onFeatureGroupAdd = (e, b) => {
    this.setState({
      fitBounds: {
        ...this.state.fitBounds,
        [b]: e.target.getBounds()
      }
    })
  }
  updateVisible = () => {
    //this.props.dispatch(setBoundApply({apply: false}));

    setTimeout(() => {
      let bounds = []
      this.props.geoFence.map(g => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id])
        }
        return null
      })

      if (bounds) {
        this.setState({ bounds }, () => {
          this.props.dispatch(setBounds(bounds))
        })
      }
    }, 50)
  }

  updatePOIVisible () {
    //this.props.dispatch(setBoundApply({apply: false}));

    setTimeout(() => {
      let bounds = []
      this.props.POI.map(g => {
        if (g.visible === true) {
          bounds.push(g.attributes&&g.attributes.latlng)
        }
        return null
      })

      if (bounds) {
        this.setState({ bounds }, () => {
          this.props.dispatch(setBounds(bounds))
        })
      }
    }, 50)
  }

  cancelMapChanges () {
    this.setState({ poiForm: {} })
  }

  onChangePOI = (key, value) => {
    this.setState({ poiForm: { ...this.state.poiForm, [key]: value } })
  }

  enableEditMap = poiForm => {
    this.setState({ poiForm })
  }

  savePOI () {
    let obj = this.state.poiForm
    let latlng = {}
    if (obj.id) {
      latlng =obj.attributes&&obj.attributes.latlng
    } else {
      latlng = this.state.poiForm.attributes&&this.state.poiForm.attributes.latlng
    }
    const newPOIObj = {
      ...obj,
      name: ReactHtmlParser(obj.name)[0] || ' ',
      description: ReactHtmlParser(obj.description)[0] || ' ',
      attributes: { ...obj.attributes, latlng: latlng },
      area:
        'CIRCLE (' +
        latlng.lat +
        ' ' +
        latlng.lng +
        ', ' +
        obj.attributes.radius +
        ')'
    }

    delete newPOIObj.visible

    if (obj.id) {
      // update
      // fetch(`api/pois/${obj.id}`, {
      //   method: 'PUT',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ ...newPOIObj })
      // })
      instance({
        url: `api/pois/${obj.id}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...newPOIObj
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(poiData => {
            this.props.dispatch(updatePOI({ ...poiData, visible: false }))
            this.props.dispatch(updatePOI({ ...poiData, visible: true }))
            this.setState({ poiForm: {} })
        //   })
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    } else {
      // fetch(`api/pois`, {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ ...newPOIObj, id: 0 })
      // })
      instance({
        url: `api/pois`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...newPOIObj,
          id: 0
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(poiData => {
            this.props.dispatch(addPOI([{ ...poiData, visible: true }]))
            this.setState({
              poiForm: {}
            })
        //   })
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  // measure code

  _onMeasureControlReady = reactECref => {
    if (reactECref) {
      reactECref.leafletElement._container.classList.add('custom-edit')
    }
  }

  addMeasureShape (type) {
    this.setState({ whichControls: 'measure', Measuring: type }, () => {
      this.cancelMeasuring(type)
    })
  }

  _onMeasureDrawStart = e => {
    if (this.state && this.state.whichControls === 'measure') {
      this.setState({
        Measuring: e.layerType
      })
    }
  }

  _onMeasureDrawCreated = e => {
    if (this.state && this.state.whichControls === 'measure') {
      let latlng = []
      let dist = 0
      if (e.layers.getLayers().length) {
        e.layers.getLayers().map(layer => {
          latlng.push(layer._latlng)
          return null
        })

        for (let i = 0; i < latlng.length; i++) {
          if (latlng[i + 1]) {
            dist += latlng[i].distanceTo(latlng[i + 1])
          }
        }

        let polylineMarkers = this.state.polylineMarkers

        let currentLatlng = latlng[latlng.length - 1]

        polylineMarkers.push(
          <Marker
            ref={this.openPopup}
            key={new Date().getTime()}
            position={currentLatlng}
            iconAnchor={[21, 41]}
          >
            <Tooltip direction={'top'} permanent>
              {dist ? (dist / 1000).toFixed(2) : '0'} km
            </Tooltip>
          </Marker>
        )

        this.setState({ polylineMarkers: [...polylineMarkers] })
      }
    }
  }

  _onMeasureDrawStop = e => {
    if (this.state && this.state.whichControls === 'measure') {
      let currentShap = {}
      Object.keys(e.target._targets).map(id => {
        if (parseInt(id) !== e.target._containerId) {
          currentShap = e.target._targets[id]
        }
        return null
      })

      if (Object.keys(currentShap).length && currentShap.options.newShap) {
        if (this.state.Measuring === 'polygon') {
          if (currentShap._latlngs) {
            let area = L.GeometryUtil.geodesicArea(currentShap._latlngs[0]) || 0
            let areaFormated = area ? area.toFixed(2) + ' m<sup>2</sup>' : 0
            currentShap
              .bindPopup(
                '<div className="position-box"><strong>Area</strong><div className="position-box-body">Total area: ' +
                  areaFormated +
                  '</div></div>'
              )
              .openPopup()
          }
        } else if (this.state.Measuring === 'polyline') {
          let polylineMarkers = this.state.polylineMarkers
          this.setState({ polylineMarkers: [...polylineMarkers] })
        }
        this.setState({ MeasureEditMode: false })
      } else {
        this.setState({ MeasureEditMode: false }, () => {
          this.cancelMeasuring()
        })
      }
    }
  }

  cancelMeasuring = type => {
    if (
      this.state &&
      this.state.whichControls === 'measure' &&
      this.state.MeasureEditMode === false
    ) {
      this.setState({ polylineMarkers: [] }, () => {
        if (
          this.state.Measuring === 'polygon' ||
          this.state.Measuring === 'polyline'
        ) {
          //this.state.MeasureControl.leafletElement._toolbars.draw._actionButtons[2].button.click()
          const el = document.getElementsByClassName('custom-edit')
          if (el && el.length) {
            const container = el[0]
            const polyline = container?.childNodes[0]?.childNodes[0]?.childNodes[0]
            const polygon = container?.childNodes[0]?.childNodes[0]?.childNodes[1]
            const deleteOptions =
              container?.childNodes[1]?.childNodes[0]?.childNodes[1]

            if (
              deleteOptions &&
              deleteOptions.classList &&
              !deleteOptions.classList.contains('leaflet-disabled')
            ) {
              deleteOptions.click()
              setTimeout(() => {
                if (
                  container?.childNodes[1]?.childNodes[1] &&
                  container?.childNodes[1]?.childNodes[1]?.childNodes[2]
                ) {
                  const clearAll =
                    container?.childNodes[1]?.childNodes[1]?.childNodes[2]
                      .childNodes[0]
                  clearAll.click()
                  if (type && type === 'polyline' && polyline) polyline.click()
                  if (type && type === 'polygon' && polygon) polygon.click()
                }
              }, 50)
            } else {
              if (type && type === 'polyline' && polyline) polyline.click()
              if (type && type === 'polygon' && polygon) polygon.click()
            }
          }
          /* if (
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[0].childNodes[1]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[1].childNodes[0].childNodes[1].click()
          } if (
            this.state.MeasureControl.leafletElement._container.childNodes[0]
              .childNodes[1].childNodes[2]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[0].childNodes[1].childNodes[2].childNodes[0].click()
          }
          if (
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[1].childNodes[2] &&
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[1].childNodes[2].childNodes[0]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[1].childNodes[1].childNodes[2].childNodes[0].click()
          } */
        }
        this.setState({ Measuring: '' })
      })
    }
  }

  /// measure code end

  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//

  componentWillReceiveProps (NextProps) {
    if(NextProps.trackId && this.props.trackId !== NextProps.trackId) {
      // this.setState({animCount: 0})
      this.setState({bounding: true});
      this.props.dispatch(
        updateDeviceVisible({ checked: true, id: NextProps.trackId })
      )
      
    } 
    if (
      NextProps.deviceRelatedData &&
      Object.keys(NextProps.deviceRelatedData).length
    ) {
      NextProps.devices.map((deviceData, index) => {
        const position = NextProps.deviceRelatedData[deviceData.id]
        const driver =
          NextProps.drivers &&
          NextProps.drivers.find(
            dr =>
              position &&
              position.exists &&
              position.driverId &&
              [position.driverId].includes(dr.id)
          )
          const trailer =
          NextProps.trailers &&
          NextProps.trailers.find(
            t =>
              position &&
              position.exists &&
              position.attributes.trailerUniqueId &&
              [position.attributes.trailerUniqueId].includes(t.uniqueId)
            )
        if (
          position &&
          position.exists &&
          deviceData &&
          deviceData.visible === true
        ) {

          if(this.props.logInUser.attributes &&
          this.props.logInUser.attributes.clusters) {
            this.clusterMarkerList[deviceData.id] = (
            <CustomMarker2
              key={deviceData.id}
              position={{ lat: position.latitude, lng: position.longitude }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={
                deviceData.id === this.props.trackId && this.state.animCount === 1 ? position.animationTime : 0.5
              }
              tracking={this.props.trackId}
              icon={L.divIcon({
                iconUrl:
                  '/assets/category/default/' +
                  (deviceData.category || 'default') +
                  'top.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img
                  style="transform: rotate(${position.course}deg)"
                    src=
                      '/assets/category/default/${deviceData.category ||
                        'default'}top.svg'
                    
                    alt=''
                  />`
              })}
              iconSize={[50, 50]}
            >
              <Tooltip direction={'top'}>
                <MapTooltip
                  key={deviceData.id}
                  trailer={trailer}
                  themecolors={this.props.themecolors}
                  position={position}
                  driver={driver}
                  device={deviceData}
                  logInUser={this.props.logInUser}
                  translate={this.props.translate}
                />
              </Tooltip>
            </CustomMarker2>
            )
          } else {
            
            this.markers[deviceData.id] = (
              <CustomMarker
                key={deviceData.id}
                position={{ lat: position.latitude, lng: position.longitude, updated: moment(position.serverTime) }}
                rotationAngle={0}
                rotationOrigin='center'
                color={deviceData.attributes.color}
                polyHead={this.polyHead}
                tracking={this.props.trackId === deviceData.id}
                bounding={this.state.bounding}
                category={deviceData.category || 'default'}
                course={position.course}
              >
                <Tooltip direction={'top'}>
                  <MapTooltip
                    key={deviceData.id}
                    trailer={trailer}
                    themecolors={this.props.themecolors}
                    position={position}
                    driver={driver}
                    device={deviceData}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                  />
                </Tooltip>
              </CustomMarker>
            )
          }
        }
        else {
          delete this.markers[deviceData.id]
          delete this.clusterMarkerList[deviceData.id]
        }
      })
    } else {
      this.markers = this.clusterMarkerList = {}
    }

    if (!isEqual(NextProps, this.props)) {
      if (NextProps.trackId) {
        let pos = NextProps.deviceRelatedData[NextProps.trackId]

        if (pos && pos.exists && pos.latitude && pos.longitude) {
          //this.map && this.map.setMaxZoom(16);//.fitBounds([[pos.latitude, pos.longitude]])
            /* this.map && this.map.setView({lat: pos.latitude, lng: pos.longitude}, 16, {
              animate: true,
              duration: 1
            }) */
            /* setTimeout(() => {
              this.map && this.map.setMaxZoom(NextProps.mapLayer.maxZoom)
            }, 100) */
            this.props.dispatch(setBoundApply({ apply: true }))
        }
      }
      if (
        NextProps.trackId !== 0 &&
        (this.props.trackId === 0 ||
          (this.props.trackId !== 0 &&
            this.props.trackId !== NextProps.trackId))
      ) {
        let pos = NextProps.deviceRelatedData[NextProps.trackId]
        if (pos && pos.exists && pos.latitude && pos.longitude) {
          // this.map && this.map.setMaxZoom(16).fitBounds([[pos.latitude, pos.longitude]])
          this.setState({bounding: true});
          this.map && this.map.setView({lat: pos.latitude, lng: pos.longitude}, 16, {
            animate: true,
            duration: 1
          })
          setTimeout(() => {
            this.map && this.map.setMaxZoom(NextProps.mapLayer.maxZoom)
          }, 100)
        }

        this.props.dispatch(setBoundApply({ apply: true }))

        // this.setState({ animCount: 0 })
      } /* else {
        this.setState({ animCount: 1 })
      } */

      if (NextProps.markerEnabled === false) {
        this.setState({ referencePoints: [], referenceMarker: {} })
      }

      if (!NextProps.bounded) {
        if (
          NextProps.logInUser &&
          NextProps.logInUser.zoom &&
          NextProps.logInUser.zoom !== this.state.zoom &&
          NextProps.logInUser.latitude !== this.state.lat &&
          NextProps.logInUser.longitude !== this.state.lng
        ) {
          this.setState({
            zoom: NextProps.logInUser.zoom,
            lat: NextProps.logInUser.latitude,
            lng: NextProps.logInUser.longitude
          })
        } else if (
          NextProps.ServerSetting &&
          NextProps.ServerSetting.zoom &&
          NextProps.ServerSetting.zoom !== this.state.zoom &&
          NextProps.ServerSetting.latitude !== this.state.lat &&
          NextProps.ServerSetting.longitude !== this.state.lng
        ) {
          this.setState({
            zoom: NextProps.ServerSetting.zoom,
            lat: NextProps.ServerSetting.latitude,
            lng: NextProps.ServerSetting.longitude
          })
        } else {
          if (
            NextProps.deviceRelatedData &&
            Object.values(NextProps.deviceRelatedData).length
          ) {
            const arr = []
            Object.values(NextProps.deviceRelatedData).map(pos => {
              if (pos.exists && pos.latitude && pos.longitude)
                arr.push([pos.latitude, pos.longitude])
            })
            if (arr.length) {
              NextProps.dispatch(setBounds([arr]))
              NextProps.dispatch(setBoundApply({ apply: true }))
            }
          }
        }
      }
    }
  }

  componentDidMount () {
    if (!this.props.bounded) {
      if (
        this.props.deviceRelatedData &&
        Object.keys(this.props.deviceRelatedData).length
      ) {
        const arr = []
        Object.values(this.props.deviceRelatedData).map(pos => {
          if (pos.exists && pos.latitude && pos.longitude)
            arr.push([pos.latitude, pos.longitude])
        })
        if (arr.length) {
          this.props.dispatch(setBounds([arr]))
          this.props.dispatch(setBoundApply({ apply: true }))
        }
      }
    }
  }

  componentWillMount() {
    if (!this.props.bounded) {
      if (
        this.props.logInUser &&
        this.props.logInUser.zoom &&
        this.props.logInUser.zoom !== this.state.zoom &&
        this.props.logInUser.latitude !== this.state.lat &&
        this.props.logInUser.longitude !== this.state.lng
      ) {
        this.setState({
          zoom: this.props.logInUser.zoom,
          lat: this.props.logInUser.latitude,
          lng: this.props.logInUser.longitude
        })
      } else if (
        this.props.ServerSetting &&
        this.props.ServerSetting.zoom &&
        this.props.ServerSetting.zoom !== this.state.zoom &&
        this.props.ServerSetting.latitude !== this.state.lat &&
        this.props.ServerSetting.longitude !== this.state.lng
      ) {
        this.setState({
          zoom: this.props.ServerSetting.zoom,
          lat: this.props.ServerSetting.latitude,
          lng: this.props.ServerSetting.longitude
        })
      } else {
        if (
          this.props.deviceRelatedData &&
          Object.values(this.props.deviceRelatedData).length
        ) {
          const arr = []
          Object.values(this.props.deviceRelatedData).map(pos => {
            if (pos.exists && pos.latitude && pos.longitude)
              arr.push([pos.latitude, pos.longitude])
          })
          if (arr.length) {
            this.props.dispatch(setBounds([arr]))
            this.props.dispatch(setBoundApply({ apply: true }))
          }
        }
      }
    }
  }

  componentWillUnmount () {
    this.props.dispatch(setBoundApply({ apply: false }))
    this.referencePoints = this.poi = this.clusterMarkers = null;
    this.markers = {};
    this.clusterMarkerList = {};
    this.setState({ bounds: null })
    this.props.dispatch(resetBounds())
    this.map && this.map.eachLayer(layer => {
      this.map && this.map.removeLayer(layer)
    })
    this.state = this.map = null
  }

  deleteRef (d) {
    this.state.referencePoints.map((obj, index) => {
      if (obj.id === d.id) {
        this.state.referencePoints.splice(index, 1)
      }
      return ''
    })
    this.setState({ ...this.state, referenceMarker: {} })
    this.map && this.map.closePopup()
  }

  getMapAttr = obj => {
    return obj
  }

  openPopup = marker => {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()
      })
    } else if (marker && marker.latlng) {
      marker.target.openPopup()
    }
  }

  handleMoveEnd (e) {
    this.setState({
      poiForm: {
        ...this.state.poiForm,
        attributes: {
          ...this.state.poiForm.attributes,
          latlng: e.target._latlng
        }
      }
    })
  }

  updateLayer = () => {
    let zoom = this.map && this.map.getZoom()
    let center = this.map && this.map.getCenter()
    setTimeout(() => {
      this.map && this.map.setView(center, zoom)
    }, 100)
    if(this.props.mapTraffic){
      this.props.dispatch(toggleTrafficLayer())
    }
  }

  copyToClipboard2 = obj => {
    const el = document.createElement('textarea')
    el.value = `${obj.lat.toFixed(8)},${obj.lng.toFixed(8)}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }
markerClose = () =>{
  this.setState({ contextMarker: {} })
}

  render () {
    if (this.props.mapLayer) {
      const settings = {
        rectangle: false,
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        polygon: false
      }

      const position = [this.state.lat || 0, this.state.lng || 0]
      const drawOptions = {
        shapeOptions: {
          color: this.props.themecolors.themeDarkColor,
          opacity: 1,
          newShap: true
        }
      }
      const drawSettings = {
        ...settings,
        circle: drawOptions,
        polygon: drawOptions,
        polyline: drawOptions
      }

      const referenceMarkers = this.state.referenceMarker.position ? (
        <Marker
          ref={this.openPopup}
          onMove={this.openPopup}
          key={122212}
          position={this.state.referenceMarker.position}
          className='ref-marker'
          icon={L.icon({
            iconUrl: '/assets/category/default/defaulttop.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 50],
            popupAnchor: [0, -50]
          })}
        >
          <Popup>
            <div className='position-box'>
              <strong>Reference Point</strong>
              <div className='position-box-body'>
                Coordinates: {this.state.referenceMarker.position.lat},
                {this.state.referenceMarker.position.lng}
              </div>
            </div>
          </Popup>
        </Marker>
      ) : null

      const contextMarker = this.state.contextMarker.position ? (
        <Marker
          ref={this.openPopup}
          onMove={this.openPopup}
          key={1222}
          position={this.state.contextMarker.position}
          className='ref-marker'
          iconSize={[25, 41]}
          icon={L.icon({
            iconUrl: '/assets/images/location-pin.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 50],
            popupAnchor: [0, -50]
          })}
        >
          <Popup>
          <div className='position-box'>
              <strong>Reference Point

              <CloseIcon
                size="small"
                className={'feature-close-button'}
                onClick={this.markerClose}
                style={{top: 0, right: 5}}
              />
              </strong>
              <div className='position-box-body'>
              
              <b>Address:</b>
              {this.state.contextMarker.address ? (
                this.state.contextMarker.address
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginTop: -5,
                    marginBottom: -5,
                    color: this.props.themecolors.textColor
                  }}
                >
                  Loading...
                </span>
              )}<br /> 
              <b>Coordinates:</b> {this.state.contextMarker.position.lat.toFixed(8)},
                {this.state.contextMarker.position.lng.toFixed(8)} <br /> <br />
              <Button
                onClick={e =>
                  this.copyToClipboard2(this.state.contextMarker.position)
                }
              >
                Copy Coordinates
              </Button>
              </div>
            </div>
          </Popup>
        </Marker>
      ) : null

      this.referencePoints = this.state.referencePoints.map(
        (position, index) => {
          return (
            <Marker
              key={position.id}
              position={{ lat: position.lat, lng: position.lng }}
              icon={L.icon({
                iconUrl: '/assets/category/default/defaulttop.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 50],
                popupAnchor: [0, -50]
              })}
            >
              <Popup>
                <div className='position-box'>
                  <strong>Reference Point</strong>
                  <div className='position-box-body'>
                    Coordinates: {position.lat}, {position.lng} <br />
                    <button onClick={e => this.deleteRef(position)}>
                      Delete
                    </button>
                  </div>
                </div>
              </Popup>
            </Marker>
          )
        }
      )

      this.poi = this.props.POI.map((obj, index) => {
        if (obj.visible) {
          let n = obj
          if (this.state.poiForm['id'] === obj.id) {
            n = this.state.poiForm
          }
          return (
            <Marker
              key={'poi-' + index}
              position={n.attributes&&n.attributes.latlng}
              icon={L.icon({
                iconUrl: '/assets/images/location-pin.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 50],
                popupAnchor: [0, -50],
                tooltipAnchor: [0, -50]
              })}
              draggable={this.state.poiForm['id'] === obj.id}
              onMoveend={e => this.handleMoveEnd(e, n)}
            >
              <Tooltip permanent direction='top'>
                <div className='position-box'>
                  <strong>POI</strong>
                  <div className='position-box-body'>
                    Name: {ReactHtmlParser(n.name)} <br />
                    Description: {ReactHtmlParser(n.description)} <br />
                    Coordinates:
                    {parseFloat(n.attributes&&n.attributes.latlng&&n.attributes.latlng.lat).toFixed(8) +
                      ', ' +
                      parseFloat(n.attributes&&n.attributes.latlng&&n.attributes.latlng.lng).toFixed(8)}
                  </div>
                </div>
              </Tooltip>
            </Marker>
          )
        } else {
          return null
        }
      })

      const geofences = this.props.geoFence.map(obj => {
        if (obj.attributes.type === 'circle' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Circle
                id={obj.id}
                radius={obj.attributes.radius}
                center={obj.attributes&&obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Circle>
            </FeatureGroup>
          )
        } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Polygon
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes&&obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Polygon>
            </FeatureGroup>
          )
        } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              key={obj.id}
            >
              <Polyline
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes&&obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Polyline>
            </FeatureGroup>
          )
        }

        return ''
      })

      let drawPOI = null
      if (this.state.poiForm.name && !this.state.poiForm['id']) {
        drawPOI = (
          <Marker
            key={'poi-newpoi'}
            position={this.state.poiForm.attributes&&this.state.poiForm.attributes.latlng}
            icon={L.icon({
              iconUrl: '/assets/category/timemachine/default.svg',
              iconSize: [50, 50],
              iconAnchor: [25, 50],
              popupAnchor: [0, -50],
              tooltipAnchor: [0, -50]
            })}
            draggable={true}
            onMoveend={this.handleMoveEnd}
          >
            <Tooltip direction='top' permanent>
              <div className='position-box'>
                <strong>POI</strong>
                <div className='position-box-body'>
                  Name: {ReactHtmlParser(this.state.poiForm.name)} <br />
                  Description: {ReactHtmlParser(this.state.poiForm.description)}
                  <br />
                  Coordinates:
                  {this.state.poiForm.attributes&&this.state.poiForm.attributes.latlng.lat.toFixed(8)},
                  {this.state.poiForm.attributes&&this.state.poiForm.attributes.latlng.lng.toFixed(8)}
                </div>
              </div>
            </Tooltip>
          </Marker>
        )
      }

      const poly = this.props.tailEnabled && this.props.trackId && this.props.tailSegments && this.props.tailSegments.length ? this.props.tailSegments.map((p, i) => i > 1 ? <Polyline weight={4} color={p.from.color} positions={[[p.from.lat, p.from.lng], [p.to.lat, p.to.lng]]} smoothFactor="1" opacity={1 - i * (1 / this.props.tailSegments.length)} /> : null) : null;
      const polyPoint = this.props.tailEnabled && this.props.trackId  ? <Polyline weight={4} ref={this.polyRef} positions={polyDefault} /> : null;
      const body = [
        <ZoomControl key={1} position={'topright'} />,
        <div key={3}>
          {this.props.logInUser.attributes &&
          this.props.logInUser.attributes.clusters ? (
            <MarkerClusterGroup key={1}>{Object.values(this.clusterMarkerList)}</MarkerClusterGroup>
          ) : (
            Object.values(this.markers)
          )}

          {this.referencePoints}
          {this.state.pointer}
          {this.poi}
          {drawPOI}

          {geofences}
          {this.state.polylineMarkers}
          {referenceMarkers}
          {contextMarker}

          <FeatureGroup key={2}>
            <EditControl
              ref={this._onMeasureControlReady}
              className='control'
              draw={drawSettings}
              position='bottomleft'
              onDrawStop={this._onMeasureDrawStop}
              onDrawStart={this._onMeasureDrawStart}
              onDrawVertex={this._onMeasureDrawCreated}
            />
          </FeatureGroup>
        </div>
      ]

      let crs = {}
      if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
        crs = { crs: L.CRS.EPSG3395 }
      }
      const thisMap = [
        <Map
          key={1}
          ref={this.myRef}
          onZoomAnim={this.checkZoom}
          boundsOptions={this.setBoundOptions}
          bounds={this.props.bounds.length ? this.props.bounds : null}
          style={{ height: this.props.height, width: this.props.width }}
          center={position}
          zoom={this.state.zoom}
          zoomControl={false}
          onClick={this.mapEvent}
          minZoom={this.state.minZoom}
          maxZoom={this.props.mapLayer.maxZoom}
          maxNativeZoom={this.props.mapLayer.maxZoom}
          onContextMenu={this.mapContextMenu}
          {...crs}
          >
          <TileLayer {...this.props.mapLayer} url={this.props.mapTraffic ? this.props.mapLayer.trafficUrl : this.props.mapLayer.url} maxNativeZoom={this.props.mapLayer.maxZoom} maxZoom={this.props.mapLayer.maxZoom} minZoom={this.state.minZoom} />
          {body}
        </Map>,
        <div className="search-field">
          <SearchGeo
            deviceRelatedData={this.props.deviceRelatedData}
            updateVisible={this.updateVisible}
          />
        </div>
      ]
      return (
        <div style={{ height: '100%' }}>
          {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
          {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) ?
          thisMap : null}
          {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
          {[
            'googleTerrain',
            'googleHybrid',
            'googleSatellite',
            'googleRoad',
            'gccStreet'
          ].includes(this.props.mapLayer.id) ? thisMap : null}
          {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
          
          <div className='map-filters-wrapper'>
            <MapFiltersVerticle
              logInUser={this.props.logInUser}
              themecolors={this.props.themecolors}
              mapLayer={this.props.mapLayer}
              geoFence={this.props.geoFence}
              POI={this.props.POI}
              trackId={this.props.trackId}
              deviceId={this.props.deviceId}
              devices={this.props.devices}
              allNotifications={this.props.allNotifications}
              filterList={this.props.filterList}
              updateLayer={this.updateLayer}
              translate={this.props.translate}
              addPOIOnMap={this.addPOIOnMap}
              onChangePOI={this.onChangePOI}
              enableEditMap={this.enableEditMap}
              {...this.props}
              {...this.state}
              mapRef={this.map}
              setEditControlProps={this.setEditControlProps}
              setEditControlValue={this.setEditControlValue}
            />
          </div>
          <div style={{ display: 'none' }}>
            <button
              style={{ position: 'absolute', left: '-10000px' }}
              onClick={e => this.addMeasureShape('polygon')}
              id='addMeasurePolygon'
            ></button>
            <button
              style={{ position: 'absolute', left: '-10000px' }}
              onClick={e => this.addMeasureShape('polyline')}
              id='addMeasurePolyline'
            ></button>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  bounds: state.bounds,
  deviceRelatedData: state.deviceRelatedData,
  bounded: state.bounded,
  geoFence: state.geoFence,
  mapTraffic: state.mapTraffic,
  tailSegments: state.tailSegments,
  POI: state.POI
})

export default connect(mapStateToProps)(DrawMap)
