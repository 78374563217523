import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../../../Components/common/Button'
import Table from '../../../Components/common/TableMultiCheckbox'
import { checkPrivileges } from '../../../Helpers'
export default class Notification extends Component {
  render () {
    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item sm={this.props.visableCreateNotification ? 8 : 12} xs={12}>
          <Table
            insideDialog
            createButton={
              checkPrivileges('notificationCreate') && (
                <div style={{ marginLeft: 10 }}>
                  <Button
                    size='small'
                    onClick={() => this.props.onCreateNewNotification()}
                  >
                    {this.props.translate('sharedCreate')}
                  </Button>
                </div>
              )
            }
            title={this.props.translate('sharedNotifications')}
            key='Notifi'
            disableHead
            showCheckbox
            checkHandleChange={this.props.notification_Selection}
            rows={this.props.notificationData}
            rowsPerPage={this.props.rowsPerPage}
            handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            isEditable
            canUpdate={checkPrivileges('notificationUpdate')}
            canDelete={checkPrivileges('notificationDelete')}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            themecolors={this.props.themecolors}
            selectAll={this.props.selectAll}
            searchable
            canRemove={this.props.canAssign}
            canAssign={this.props.canRemove}
            className='custom-table2'
            rowDefinition={[
              {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('sharedName')
              },
              {
                id: 'type',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('notificationType'),
                translate: true,
                prefix: ''
              },
              {
                id: 'notificators1',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('notificationNotificatorsMail')
              },
              {
                id: 'notificators2',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('notificationNotificatorsWeb')
              },
              {
                id: 'notificators3',
                numeric: false,
                disablePadding: false,
                label: this.props.translate('notificationNotificatorsFirebase')
              }
            ]}
          />
        </Grid>
      </Grid>
    )
  }
}
