import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler} from '../../Helpers'
import Layout from '../../Layout'
import TextField from '../../Components/common/TextField'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import { Collapse,Grid } from '@material-ui/core'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import instance from '../../axios'
import CommonTableSection from '../Users/Components/commonTableSection'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleSelect from '../../Components/common/SingleSelect'
import MenuItem from '../../Components/common/MenuItem'

let allMakesData = []

class Make extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modelForm: {},
      mode: '',
      isVisableSubmitBtn: true,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      allMakes:[],
      searchModel:"",
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      deleteForm: {},
      modelData: [],
      rowsPerPage: 5,
      intialFetch:true
    }
  }

  componentWillUnmount () {
    this.setState({
      modelForm: {},
      mode: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      allMakes:[],
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      intialFetch:true
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          this.fetchMake()
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }
  
  searchItems = text => {
    this.setState({searchModel: text},()=>{this.fetchData(this.props?.logInUser?.id,1,50)})
  }

  fetchMake = () => {
    instance({
        method: 'GET',
        url:`/api/models/make`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        let defaultOptions = response&&response.categories&&response.categories.map(item => {
          return {  id:item, label:item, value:item }
        })
          this.setState({allMakes:defaultOptions},()=>{
            allMakesData=defaultOptions
          })
        })
        .catch(error => { errorHandler(error, this.props.dispatch) })
  }

  fetchData = (logInUser, page, perPage,pagination) => {
    let items = this.state.itemPagination.items
    instance({
      method: 'GET',
      url:`/api/models/get`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        page: page,
        limit: perPage,
        search:this.state.searchModel
      }
    })
      .then(response => {
        this.setState(
          {
            itemPagination: {
              ...response,
              items:items.concat(response.data)
            },
            currentPage: response.hasNext ? response.page + 1 : response.page,
            modelData:pagination?items.concat(response.data):(response.data)
          })
          if(this.state.intialFetch && (response.data&&response.data[0])) this.openEditModel(response.data[0])
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
      })
  }

  closeCreateFrom = () => {
    this.setState(
      {
        mode: '',
        modelForm: '',
      },
      () => this.checkRequiredFields()
    )
  }

  closeEditForm = () => {
    this.setState(
      {
        mode: '',
        modelForm: '',
      },
      () => this.checkRequiredFields()
    )
  }

  handleChange = (name, value) => {
      this.setState(
        {
          modelForm: {
            ...this.state.modelForm,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
  }
  checkRequiredFields = () => {
    let { makeName,modelName } = this.state.modelForm
      if(makeName,modelName){
        this.setState({
          isVisableSubmitBtn: false
        })
      }
      else{
        this.setState({
          isVisableSubmitBtn: true
        })
      }
    }

  submitForm = () => {
    let { mode, modelForm } = this.state
    let obj
    let params = ''
    let api
    if (mode === 'createModel') {
        obj = {
          makeName:modelForm.makeName.id,
          modelName:modelForm.modelName,
          parentId: 0
        }
        api = `/api/models`
    }
    if (mode === 'editModel') {
        obj = { 
          ...modelForm,
          makeName:modelForm?.makeName?.label || ''
         }
        api = `/api/models/${modelForm.id}`
      }
    if (obj) {
      instance({
        url: api,
        method: `${mode === 'editModel' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(res => {
          if (mode === 'editModel') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('modelUpdated'),
                  autoDismiss: 10
                })
              )
              this.fetchData(true,1,50)
          } else if (mode === 'createModel') {
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('makeCreated'),
                    autoDismiss: 10
                  })
                )
                this.fetchData(true,1,50)
          }
          this.closeCreateFrom()
        })
        .catch(error => {
          console.log('errror ===', error)
          errorHandler(error, this.props.dispatch)
        })
    }
  }

  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage })
  }

  fetchMoreItems = (a, b, c) => {
    let pagination
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      pagination=true
    )
  }

  openCreateFrom = ()=>{
    this.setState({mode:'createModel',modelForm:{}})
  }

  openEditModel = (item)=>{
    const findMakeName = allMakesData.find(itm=> itm.id === item.makeName)
    this.setState({mode:'editModel', modelForm:{...item,makeName:findMakeName}, intialFetch:false})
  }

  async searchFromList (inputValue) {
    const result = allMakesData.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result
  }

  
  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {/* {this.props.translate('notFound')} */}
          -- {this.props.translate('end')} --
        </p>
      )

    if (checkPrivileges('service')) {
      const pp = {
        ...this.props,
        make: this.props.services
      }
      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            editModelForm={this.openEditModel}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            openCreateFrom={this.openCreateFrom}
            fetchData={this.fetchData}
            {...this.state}
          >
            <div className='main-content-page'>
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.deleteForm?.name || this.state.deleteForm?.label ||''}
                />
              )}
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  paddingTop: 10
                }}
              >
                {/* {this.state.mode === 'Show' ? (
                  <CommonTableSection
                    themecolors={this.props.themecolors}
                    componentData={this.state?.expense || []}
                    showCheckbox={false}
                    showGroupAccess={false}
                    label={this.props.translate('services')}
                    canUpdate={true}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPage={this.state?.rowsPerPage}
                    translate={this.props.translate}
                    createButton={
                      <div style={{ marginLeft: 10 }}>
                        <Button onClick={this.openCreateSubTypeFrom}>
                          {this.props.translate('Create')}
                        </Button>
                      </div>
                    }
                    isDownload={
                        <ExportImportMenu
                            fileName = 'services/subtypes'
                            checkPrivilege = 'serviceCreate'
                            isMake
                            data = {this.state?.expense || []}
                            themecolors = {this.props.themecolors}
                            expensetypeId={this.state?.modelForm?.id ||''}
                          />
                    }
                    parent={2}
                    rowDefinition={[
                      {
                        id: 'label',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sharedName')
                      }
                    ]}
                    canDelete={true}
                    canRemove={false}
                    canAssign={false}
                  />
                ) : null} */}
                {this.state.mode ==='createModel' || this.state.mode === 'editModel' ? (
                  <EditSubForm
                    form={this.state.modelForm}
                    handleChange={this.handleChange}
                    translate={this.props.translate}
                    submitForm={this.submitForm}
                    closeEditForm={this.closeEditForm}
                    mode={this.state?.mode}
                    isVisableSubmitBtn={this.state?.isVisableSubmitBtn}
                    checkPrivileges={checkPrivileges}
                    logInUser={this.props.logInUser}
                    themecolors={this.props.themecolors}
                    allMakes={this.state?.allMakes || []}
                    searchFromList={this.searchFromList}
                  />
                ) : null}
                {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noModelSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const EditSubForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  field,
  checkPrivileges,
  allMakes,
  searchFromList
}) => {
  let category=100
  return (
    <div>
      <h4 className='page-title'>{translate('Model')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SingleSelect
              array={[]}
              async
              isClearable
              id='makeName'
              selectName='makeName'
              label={translate('Make')}
              defaultOptions={allMakes}
              loadOptions={searchFromList}
              value={form.makeName ?
                    {
                      id: form.makeName.id,
                      value: form.makeName.label,
                      label: form.makeName.label.toUpperCase()
                    }: ''
              }
              handleChange={handleChange}
              canRemove={true}
              canAssign={true}
            />
            {/* <TextField
                id='makeName'
                required
                margin='dense'
                label={translate('makeName')}
                variant='outlined'
                fullWidth
                value={form?.makeName || ''}
                onChange={e => handleChange('makeName', e.target.value)}
                select
            >
              {allMakes &&allMakes.map(option => (
                  <MenuItem key={option} value={option}>
                    {translate(option)}
                  </MenuItem>
                ))}
            </TextField> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='modelName'
            required
            margin='dense'
            label={translate('model')}
            variant='outlined'
            fullWidth
            value={form?.modelName || ''}
            onChange={e => handleChange('modelName', e.target.value)}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeEditForm}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {(
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === 'editModel' ? 'update' : 'sharedCreate')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Make)))
