import React, { Component } from 'react'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Grid from '@material-ui/core/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import moment from 'moment'
import Collapse from '@material-ui/core/Collapse'
import { CompactPicker } from 'react-color'
import Style from 'style-it'
import { checkPrivileges, errorHandler, getTimeFormat, setAttributeFormat } from '../../../Helpers'
import 'moment-timezone'
import Scrollbar from 'react-scrollbars-custom'
import PublishIcon from '@material-ui/icons/Publish';
import { CheckIcon } from '../../../Components/common/CheckIcon'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Icon, Tooltip } from '@material-ui/core'
import { ReactComponent as LoaderIcon } from './../../../assets/tracks-loader.svg'
import instance from '../../../axios';
class DrawTrips extends Component {
  constructor (props) {
    super(props)
    this.state = { devices: props.trips || {}, assigned: false }
    this.toggleAllGroup = this.toggleAllGroup.bind(this)
  }

  componentWillReceiveProps (n) {
    this.setState({ devices: n.trips })
  }

  toggleAllGroup (groupId, check) {
    this.props.toggleAllGroup(groupId, check)
  }

  render () {
    if (this.state.emptyScreen) {
      return <div>No Record</div>
    } else {
      return (
        <Devices
          groups={this.state.devices}
          toggleAllGroup={this.toggleAllGroup}
          {...this.props}
        />
      )
    }
  }
}

export default class Tracks extends Component {
  constructor (props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.state = {
      kmlFile: {}
    }
  }
  createReport = type => {
    //this.props.collapseSidebar();
    this.props.createReport(type)
  }
  goBack () {
    this.props.openForm()
  }

  uploadTrigger = () => {
    const el = document.getElementById('uploadTrigger')
    el.click()
  }

  kmlFileUpload = event => {
    if(event && event.target && event.target.files && event.target.files.length) {
      const kmlFile = this.props.kmlFileUpload(event)
      if (kmlFile) {
        this.setState({ kmlFile })
      }
    }
  }

  clearSelection = () => {
    this.setState({ kmlFile: {} }, () => {
      this.props.clearSelection()
    })
  }

  render () {
    let viewScreen
    const empty = null
    if (this.props.view === 'reportsData') {
      viewScreen = (
        <div>
          <div
            style={{
              display: 'flex',
              margin: '10px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h2 className='alt-title sidebar-icon-gap' style={{ margin: 0 }}>{this.props.translate('Tracks')}</h2>
            <Button
              onClick={e => this.goBack('displayForm')}
            >
              {this.props.translate('sharedBack')} <KeyboardArrowLeftIcon />
            </Button>
          </div>
          {this.props.mainView === 'tracks' && (
            <ul className='list-view with-padding-right'>
              {this.props &&
              this.props.reportsData &&
              this.props.reportsData.length ? (
                <DrawTrips
                  logInUser={this.props.logInUser}
                  changeCheckbox={this.props.changeCheckbox}
                  routesLoading={this.props.routesLoading}
                  updateColor={this.props.updateColor}
                  downloadAllTracks={this.props.downloadAllTracks}
                  pageLoading={this.props.pageLoading}
                  trips={this.props.trips}
                  drawRoute={this.props.drawRoute}
                  toggleAllGroup={this.props.toggleAllGroup}
                  toggleDaywiseGroup={this.props.toggleDaywiseGroup}
                  cancelRoute={this.props.cancelRoute}
                  collapseSidebar={this.props.collapseSidebar}
                  translate={this.props.translate}
                  themecolors={this.props.themecolors}
                />
              ) : (
                <div style={{ padding: 50, textAlign: 'center', fontSize: 20 }}>
                  {this.props.translate('noRecordFound')}
                </div>
              )}
            </ul>
          )}
        </div>
      )
    } else if (this.props.view === 'displayForm') {
      viewScreen = (
        <form style={{ padding: "14px 10px 20px" }}>
          <h2 className='alt-title sidebar-icon-gap'>
            {this.props.translate('trackForm')}{' '}
            {!this.state.kmlFile.name && (
              <label style={{ float: 'right', marginTop: -5 }}>
                <Tooltip title={this.props.translate('uploadkml')}>
                  <Button onClick={this.uploadTrigger}>
                    <PublishIcon style={{ fontSize: 18 }}/>
                  </Button>
                </Tooltip>
                <input
                  id='uploadTrigger'
                  style={{ display: 'none' }}
                  type='file'
                  onChange={this.kmlFileUpload}
                />
              </label>
            )}
          </h2>
          {!this.state.kmlFile.name ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DateRangePicker
                    fill
                    selectedDate={this.props.selectedDate}
                    label={
                      this.props.translate('reportFrom') +
                      ' ' +
                      this.props.translate('reportTo')
                    }
                    onEvent={this.props.showDates}
                  />
                </Grid>
              </Grid>
              <DeviceSelector
                value={this.props.deviceId}
                onChange={this.props.selectedDevices}
                rows={10}
              />
              <Button
                disabled={!this.props.validatedForm}
                style={{ marginBottom: 10, marginTop: 15 }}
                variant='contained'
                onClick={this.createReport}
              >
                {this.props.translate('submit')}
              </Button>
            </>
          ) : (
            <div>
              <div>File Name: {this.state.kmlFile.name}</div>
              <p style={{ marginTop: 0 }}>Size: {this.state.kmlFile.size}</p>
              <Button onClick={this.clearSelection}>Clear</Button>
            </div>
          )}
        </form>
      )
    }

    if (viewScreen) {
      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <aside className='fms-sidebar sidebar-bg-layer'>
            {checkPrivileges('track') && (
              <Scrollbar disableTracksWidthCompensation={true}>
                {viewScreen}
              </Scrollbar>
            )}
          </aside>
        </Style>
      )
    } else {
      return this.props.trackLoading ? (
        <div className='sidebar-bg-layer'>
          <Loader />
        </div>
      ) : null
    }
  }
}

class Devices extends Component {
  constructor (props) {
    super(props)

    this.state = {
      driversProcess: false,
      groupsProcess: false,
      calendarsProcess: false
    }

    this.devices = []
  }

  render () {
    return <DrawGroups {...this.props} />
  }
}

export class DrawGroups extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange (id, event) {
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }

  render () {
    return (
      <div className='group-view list-row-condest tracks-view'>
        <ul className='list-view-group'>
          {Object.keys(this.props.groups).map((key, index) => {
            return (
              <DrawGroupRow
                key={index}
                groupId={key}
                group={this.props.groups[key]}
                {...this.props}
              />
            )
          })}
        </ul>
        {/*<ul className="list-view">
            <ListRow motion="play" signals="online" unit="car" status="online" />
            <ListRow motion="play" signals="online" unit="car" status="online" />
            <ListRow motion="pause" signals="online" unit="car" status="offline" />
            <ListRow motion="pause" signals="online" unit="car" status="offline" />
          </ul>*/}
      </div>
    )
  }
}

export class DrawGroupRow extends Component {
  constructor (props) {
    super(props)
    this.groupCollapse = this.groupCollapse.bind(this)
    this.toggleGroupDevices = this.toggleGroupDevices.bind(this)
    this.state = {
      isChecked: false,
      open: true
    }
  }

  groupCollapse (event) {
    event.preventDefault()
    let open = this.state.open
    this.setState({ open: !open })
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }

  componentWillReceiveProps (NextProps) {
    let devices = Object.assign({ ...NextProps.group })

    if (Object.keys(devices['data']).length) {
      this.setState({ isChecked: true })

      Object.keys(devices['data']).map(key => {
        devices['data'][key].map(row => {
          if (row.checked !== true) {
            this.setState({ isChecked: false })
          }
          return null
        })
        return null
      })
    }
  }

  toggleGroupDevices (event) {
    //this.props.dispatch(toggleGroupDevices({checked: event.target.checked, id: this.props.group.id}));
    this.props.toggleAllGroup(this.props.groupId, event.target.checked)
    this.setState({
      isChecked: event.target.checked
    })
  }

  /*shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }*/

  render () {
    const emptyLinks = null

    let mileage = 0
    if (
      this.props.group &&
      this.props.group.data &&
      Object.keys(this.props.group.data) &&
      Object.keys(this.props.group.data).length
    ) {
      Object.keys(this.props.group.data).map(date => {
        if (this.props.group.data[date]) {
          this.props.group.data[date].map(row => {
            if (row.checked) {
              mileage += row.distance
            }
            return null
          })
        }
        return null
      })
    }

    return (
      <li key={this.props.group.id}>
        <div className='clearfix group-list-row list-row sticky'>
          <label className='checkbox'>
            <input
              type='checkbox'
              checked={this.state.isChecked}
              onChange={this.toggleGroupDevices.bind(this)}
            />
            <CheckIcon className='theme-input-checkbox' />
            <span className='unit-name alt-title' style={{ marginLeft: 10, marginBottom: 0, fontSize: 18 }}>
              {this.props.group.detail.deviceName}
            </span>
          </label>
          <div className='pull-right'>
            <strong className='action-item'>
              <small>
                {mileage ? (mileage / 1000).toFixed(2) + ' km' : null}
              </small>
            </strong>
            <span className='action-item action-additional-menu'>
              <a
                href={emptyLinks}
                onClick={e => this.props.downloadAllTracks(e)}
                title='Download all tracks'
              >
                <DownloadIcon />
              </a>
            </span>
            <span className='action-item '>
              <a
                href={emptyLinks}
                onClick={e => this.groupCollapse(e)}
                title='Additional Menu'
              >
                <KeyboardArrowDownIcon style={{fontSize:24}}/> 
              </a>
            </span>
            {/*<span className="action-item action-clear-list">
                  <a href={emptyLinks} title="Clear List"><MaterialIcon size="18" icon='close'/></a>
                </span>
                <span className="action-item" href={emptyLinks} title=""><MaterialIcon size="22" icon='add_box'/></span>*/}
            {/*<span className="action-item action-ellipsis">
                  <a href={emptyLinks} title="Monitoring Panel Customizer"><MaterialIcon size="18" icon='more_vert'/></a>
                </span>*/}
          </div>
        </div>
        <Collapse in={this.state.open} timeout='auto' unmountOnExit>
          <ListRows {...this.props} isChecked={this.state.isChecked} />
        </Collapse>
      </li>
    )
  }
}

export class ListRows extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      selectedDate: ''
    }
    this.groupCollapse = this.groupCollapse.bind(this)
  }
  componentWillMount () {
    Object.keys(this.props.group.data).map(key =>
      this.setState({
         [key]: true,
         date:{
          ...this.state.date,
          [key]: false
         },
         selectedDate: key
         })
    )
  }
  componentWillReceiveProps (NextProps) {
    let devices = Object.assign({ ...NextProps.group })
        if (Object.keys(devices['data']).length) {
          this.setState({ 
                date:{
                ...this.state.date,
                [this.state.selectedDate] : true
              } },
            ()=>{
               devices['data'][this.state.selectedDate].map(row => {
                if (row.checked !== true) {
                  this.setState({ 
                    date:{
                    ...this.state.date,
                    [this.state.selectedDate] : false
                   }})
                }
                return null
              })
            })
        }
    }
  groupCollapse (id) {
    let open = this.state[id] || false
    this.setState({ [id]: !open })
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }
  toggleDaywiseGroup = (groupId, event, key) => {
    let check = event.target.checked
    if(check){
      this.setState({
      selectedDate: key,
      date:{
        ...this.state.date,
        [key]: true
       },
    },()=>{
      this.props.toggleDaywiseGroup(groupId,check, key)
    })
    }else{
      this.setState({
        selectedDate: key,
        date:{
          ...this.state.date,
          [key]: false
         },
      },()=>{
        this.props.toggleDaywiseGroup(groupId,check, key)
      })
    }
    
  }
  render () {

    return (
      <ul className='tracks-list-view'>
        {Object.keys(this.props.group.data).map((key, index) => {
          let duration = 0
          let distance = 0
          this.props.group.data[key].map(row => {
            distance += row.distance
            duration += row.duration
            return null
          })

          return (
            <React.Fragment key={key}>
              <li
                className='trip-date-row'
                style={{ cursor: 'pointer' }}
                onClick={() => this.groupCollapse(key)}
              >
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={this.props.isChecked || this.state.date[key]}
                  onChange={(e) => this.toggleDaywiseGroup(this.props.groupId,e,key)}
                />
                <CheckIcon className='theme-input-checkbox' />
              </label>
                <em>{key}</em>
                <span className='pull-right'>
                  {this.props.translate('reportTrips')}
                </span>
                <span>|</span>
                <strong>{this.props.group.data[key].length}</strong>
                <KeyboardArrowDownIcon style={{fontSize:24, verticalAlign: 'middle'}}/>
              </li>

              <Collapse in={this.state[key]} timeout='auto' unmountOnExit>
                <li>
                  <table className='list-mini-table'>
                    <thead>
                      <tr>
                        <th>{this.props.translate('distance')}</th>
                        <th>{this.props.translate('maintenanceStart')}<span style={{margin: '0 4px'}}>-</span>{this.props.translate('end')}</th>
                        <th>{this.props.translate('reportDuration')}</th>
                      </tr>
                    </thead>
                    <tbody>
                    <ListRow1
                      groupDate={key}
                      rows={this.props.group.data[key]}
                      {...this.props}
                    />
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>{setAttributeFormat('distance', distance)}</th>
                        <th>--:--</th>
                        <th>{moment
                        .duration(duration, 'milliseconds')
                        .format('H[h]m[m]')}</th>
                      </tr>
                    </tfoot>
                  </table>
                </li>
              </Collapse>
            </React.Fragment>
          )
        })}
      </ul>
    )
  }
}

export class ListRow1 extends Component {
  /*  constructor (props) {
    super(props);
  }*/
  render () {
    return this.props.rows.map((position, index) => (
      <ListRow key={position.startPositionId} data={position} {...this.props} />
    ))
  }
}

export class ListRow extends Component {
  constructor (props) {
    super(props)
    this.state = {
      trackId: '',
      deviceId: '',
      anchorEl: null,
      selectedDevice: '',
      editOption: false,
      removeOption: false,
      d_attriubtes: '',
      play: 'offline',
      colorPicker: false,
      emptyScreen: false,
      playicon: { label: 'Pause', value: 'pause' }
    }

    this.handleChange = this.handleChange.bind(this)
    this.viewOnMap = this.viewOnMap.bind(this)
    this.hideColorPicker = this.hideColorPicker.bind(this)
    this.onClose = this.onClose.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.onCloseConfirm = this.onCloseConfirm.bind(this)
    this.removeDevice = this.removeDevice.bind(this)
    this.editDevice = this.editDevice.bind(this)
    this.updateColor = this.updateColor.bind(this)

    if (props.data) {
      props.pageLoading(false)
    }
  }

  editDevice (selectedDevice, event) {
    this.setState({
      editOption: true,
      removeOption: false,
      selectedDevice: selectedDevice
    })
  }

  onCloseConfirm () {
    this.setState({ removeOption: false, editOption: false })
  }

  /*removeDevice(selectedDevice, event) {

    this.setState({ removeOption: true,
      editOption: false,
      selectedDevice: selectedDevice
    });     
  }*/

  removeDevice (selectedDevice) {
    if (window.confirm('Are you Sure to Delete')) {
      // fetch(`/api/devices/${selectedDevice.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/devices/${selectedDevice.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // if (response.status === 204) {
            //this.props.dispatch(removeDevice(selectedDevice.id));
            alert('Unit is Deleted')
          // } else {
          //   throw response
          // }
        }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  handleChange (event) {
    const { target } = event

    //this.props.dispatch(updateDeviceVisible({checked: target.checked, id: this.props.data.id}));

    if (target.checked === true) {
      this.viewOnMap()
    } else {
      this.props.changeCheckbox(
        this.props.groupDate,
        this.props.groupId,
        this.props.data.startPositionId,
        false
      )
      this.props.cancelRoute(this.props.data)
      //this.props.collapseSidebar();
    }
  }
  viewOnMap () {
    this.props.changeCheckbox(
      this.props.groupDate,
      this.props.groupId,
      this.props.data.startPositionId,
      true
    )
    this.props.drawRoute(this.props.data, true, 'single')

    this.props.collapseSidebar()
  }

  openMenu (event) {
    this.setState({ colorPicker: true })
  }
  hideColorPicker (event) {
    this.setState({ colorPicker: false })
  }

  componentWillReceiveProps (NextProps) {
    if (NextProps.data) {
      NextProps.pageLoading(false)
    }
  }
  /*componentWillReceiveProps(NextProps) {

    var checked = true;

      NextProps.devices.map((item, index) => {
          if(item.visible === false) {          
            checked = false;
          }
          return '';
      });

      this.props.isChecked(checked);

      if(NextProps.trackId === this.props.data.id) {
        this.setState({trackId: 'online'});
      }
      else {
        this.setState({trackId: ''});       
      }

      if(NextProps.deviceId === this.props.data.id) {
        this.setState({deviceId: 'online'});
      }
      else {
        this.setState({deviceId: ''});        
      }


      if(NextProps.trackId) {
        if(NextProps.positions.length) {
        NextProps.positions.map((pos) => {
          if(pos.deviceId === NextProps.trackId) {
            if(!isEqual(NextProps.bounds, [[pos.latitude, pos.longitude]])) {
              //this.props.dispatch(setBounds([[pos.latitude, pos.longitude]]));
            }
          }
          return '';
        })        
      }       
      }


      this.makeIcons(NextProps);


    }*/

  onClose () {
    this.setState({ anchorEl: null })
  }

  /*    shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }*/

  checkLastResponseTime = date => {
    return moment(date).isBefore(moment().subtract(1, 'day'))
  }

  updateColor (e) {
    this.props.updateColor(
      this.props.groupDate,
      this.props.groupId,
      this.props.data.startPositionId,
      e.hex
    )
  }

  render () {
    const emptyLinks = null

    let distanceUnit =
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.distanceUnit
        ? this.props.logInUser.attributes.distanceUnit
        : ''

    let tz = 'Asia/Dubai'

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      tz = this.props.logInUser.attributes.timezone
    }

    let timeFormat = getTimeFormat()
    return (
      <tr className={(this.props.data.checked ? 'active ' : '') + (this.checkLastResponseTime(this.props.data.lastUpdate) && 'disabled-row')} style={{ position: 'relative' }}>
        <td>
          <label className='checkbox' style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 6}}>
            <input
              type='checkbox'
              onChange={this.handleChange}
              checked={this.props.data.checked}
            />
            <CheckIcon className='theme-input-checkbox' />
          </label>
          <span style={{ lineHeight: '28px', verticalAlign: 'middle', display: 'inline-block'}} onClick={this.viewOnMap}>
            {setAttributeFormat(
              'distance',
              this.props.data.distance,
              distanceUnit
            )}
            <small>
              <em>{}</em>
            </small>
          </span>
        </td>
        <td onClick={this.viewOnMap}>
          {moment(this.props.data.startTime).tz(tz).format(timeFormat)}<span style={{margin: '0 5px'}}>-</span> 
          {moment(this.props.data.endTime).tz(tz).format(timeFormat)}
        </td>
        <td>
          {moment
            .duration(this.props.data.duration, 'milliseconds')
            .format('H[h]m[m]')}
          <span className='has-menu' style={{ float: 'right', marginTop: 8 }}>
            <a
              href={emptyLinks}
              onClick={this.openMenu}
              title='Monitoring Panel Customizer'
            >
            <div className="color-box" style={{ background: this.props.data.color }}/>
            </a>
          </span>
          {this.props.routesLoading &&
          this.props.routesLoading[this.props.data.startPositionId] ? (
            <span
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                borderRadius: 6,
                background: this.props.themecolors.themeLightColor + '80',
                color: this.props.themecolors.themeInverse,
                textAlign: 'center'
              }}
            >
              <LoaderIcon style={{ width: 30, height: 30 }} />
            </span>
          ) : null}
          {this.state.colorPicker ? (
          <div
            style={{ position: 'absolute', bottom: '100%', right: 0 }}
            onMouseLeave={this.hideColorPicker}
          >
            {
              <CompactPicker
                color={this.props.data.color}
                onChangeComplete={this.updateColor}
              />
            }
          </div>
        ) : null}
        </td>
      </tr>
    )
  }
}
