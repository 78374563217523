import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { withLocalize } from 'react-localize-redux'
import Moment from 'moment'
import MenuItem from "@material-ui/core/MenuItem";
import Notifications from 'react-notification-system-redux'
import TextField from '../../Components/common/TextField'
import Loader from '../../Layout/Loader'
import Table from '../../Components/common/tableWithBackEndPagination'
import Style from 'style-it'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import Layout from '../../Layout';
import { actionTypes,itemTypes,AccountsItem,AttributesItem,CalenderItem,
        CommandItem,CouponItem,DashboardItem,DevicesItem,DriverItem,
        EcoDrivingItem,EventsItem,GeoFenceItem,ResourcesItem,ItemGroups,
        JobsItem,MaintenanceItem,NotificationItem,
        OrdersItem,PasswordsItem,POIItem,PositionItem,RolesItem,
        ReportTemplatesItem,SensorsItem,ServicesItem,SessionsItem,
        TrailersItem,UsersItem,VehiclesItem,LinkUnlinkItems,GarageItem,
        AreaItem,ExpenseTypeItem,ExpenseSubTypeItem
    } from './ActionLogsTypes'


class Logs extends Component {
    constructor () {
      super()
      this.state = {
          tableData: [],
          laoder:true,
          pageSize:20,
          page:1,
          itemSearch: 'all',
          subType:'',
          typeSelection:'',
          loader:true
      }
    }
   
    componentWillMount () {
      this.getActionLogs()
    }
  
    getActionLogs =()=> {
      let {
          page,
          pageSize,
          itemSearch,
          typeSelection,
          subType
        } = this.state
      const { form } = this.state 
      let url = `/api/actionlogs?page=${page}&limit=${pageSize}`
    
      if(this.state.itemSearch){
        // url = `/api/actionlogs?page=${page}&limit=${pageSize}&type=${itemSearch}${typeSelection}`
        url = `/api/actionlogs?page=${page}&limit=${pageSize}${typeSelection}`
      }
      if(subType){
        url = `/api/actionlogs?page=${page}&limit=${pageSize}&type=${subType}`
      }
      instance({
          method: 'GET',
          url:url,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
        })
        .then(response => {
          //   response.json().then(res => {
                if (response.data) {
                  let udata = response.data
                  let logs = udata.map(u => {
                    if (u.actionTime) {
                      return {
                        ...u,
                        actionTime: this.props.logInUser.twelveHourFormat
                          ? Moment(u.actionTime).format('YYYY-MM-DD hh:mm A')
                          : Moment(u.actionTime).format('YYYY-MM-DD HH:mm'),
                           action: actionTypes.find(action => action.key === u.action),
                           actionDetails: `${u.itemLabel} ${u.itemType}`
                      }
                    } else {
                      return { ...u }
                    }
                  })
                  this.setState({
                      tableData:logs,
                      pagData: response,
                      loader:false
                  })
                }
          //   })
          })
          .catch(error => {
            // errorHandler(error,this.props.dispatch)
          })
    }
  
    handleChangeRowsPerPage = value => {
      this.setState( {pageSize: value}, () => this.getActionLogs()
      )
    }
  
    handleChangePage = value => {
      this.setState( {page: value},() => this.getActionLogs())
    }
  
    setPagination = user => {
      if (this.state.tableData.length) {
        let nUser = { ...user }
        let lastPage = nUser.total / this.state.pageSize
        let IsFloate = this.checkFloteNumber(lastPage)
        delete nUser.data
        nUser.pageSize = this.state.pageSize
        nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
        return nUser
      }
      return {}
    }
  
    checkFloteNumber (n) {
      return Number(n) === n && n % 1 !== 0
    }
  
    handleChangeFilter = (event) => {    
        if(event){
          this.setState({subType:'',pageSize:20,page:1})
        }  
        let typeSelection = ''
        if(event.target.value ===1){
          AccountsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        // else if(event.target.value ===11){
        //   ArchiveItem.map(i => {
        //     typeSelection += '&type=' + i.key
        //   })
        // }
        else if(event.target.value ===21){
          AttributesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===31){
          CalenderItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===41){
          CommandItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===51){
          CouponItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===61){
          DashboardItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===71){
          DevicesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===91){
          DriverItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===101){
          EcoDrivingItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===111){
          EventsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===121){
          GeoFenceItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===131){
          ResourcesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===141){
          ItemGroups.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===151){
          JobsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===161){
          MaintenanceItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===171){
          NotificationItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===181){
          OrdersItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===191){
          PasswordsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===201){
          POIItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===211){
          PositionItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        // else if(event.target.value ===216){
        //   ReportsItem.map(i => {
        //     typeSelection += '&type=' + i.key
        //   })
        // }
        else if(event.target.value ===231){
          RolesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===241){
          ReportTemplatesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===251){
          SensorsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===261){
          ServicesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===271){
          SessionsItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===281){
          TrailersItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===291){
          UsersItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===311){
          VehiclesItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===321){
          LinkUnlinkItems.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===501){
          GarageItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===526){
          ExpenseTypeItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===541){
          ExpenseSubTypeItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else if(event.target.value ===551){
          AreaItem.map(i => {
            typeSelection += '&type=' + i.key
          })
        }
        else{
          typeSelection =''
        }
        this.setState({itemSearch:event.target.value, loader:true, typeSelection:typeSelection},()=>this.getActionLogs())
    }
    handleChangeSubType = (event) => {
        this.setState({subType: event.target.value, loader:true,typeSelection:'',pageSize:20,page:1},()=>this.getActionLogs())
    }
  
    render() {
        const { tableData } = this.state
        return (
            <Layout
                 classFromChildren='has-padding'
                 noSidebar
                {...this.props}
            >   
            <div
                style={{
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
                borderRadius: 6,
                paddingTop: 50,
                paddingLeft: 0,
                paddingRight: 0
                }}
                >
                   <Fragment>
            <Style>{`
            .breadcrumb-row {
                > .MuiGrid-item {
                  height: 50px;
                  align-items: center;
                  display: flex;
                  > div {
                    width: 100%;
                  }
                }
              }
            `}</Style>
            <Grid container spacing={1} className='breadcrumb-row'>
               <Grid item xs={12} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                   <h3 style={{margin:'0'}}>{this.props.translate('actionLogs')}</h3>
                </Grid>  
                <Grid item container spacing={1} xs={12} sm={10}  style={{display:'flex',justifyContent:'flex-end'}}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id='name'
                            select
                            name="name"
                            value={this.state.itemSearch}
                            onChange={e=>this.handleChangeFilter(e)}
                            margin='dense'
                            fullWidth
                            >  
                            {itemTypes.map(option => (
                                <MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    {this.state.itemSearch && this.state.itemSearch!=='all' && (
                        <Grid item xs={12} sm={2}>
                        <TextField
                            id='name'
                            select
                            name="name"
                            value={this.state?.subType || ''}
                            onChange={e=>this.handleChangeSubType(e)}
                            margin='dense'
                            fullWidth
                            > 
                            {this.state.itemSearch == 1 ?
                                AccountsItem.map(option => (<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) : 
                                this.state.itemSearch == 21 ?
                                AttributesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 31 ?
                                CalenderItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 41 ?
                                CommandItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 51 ?
                                CouponItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 61 ?
                                DashboardItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 71 ?
                                DevicesItem.map(option => (<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 91 ?
                                DriverItem.map(option => (<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 101 ?
                                EcoDrivingItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 111 ?
                                EventsItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 121 ?
                                GeoFenceItem.map(option => (<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 131 ?
                                ResourcesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 141 ?
                                ItemGroups.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 151 ?
                                JobsItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 161 ?
                                MaintenanceItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 171 ?
                                NotificationItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 181 ?
                                OrdersItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 191 ?
                                PasswordsItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 201 ?
                                POIItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 211 ?
                                PositionItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 231 ?
                                RolesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 241 ?
                                ReportTemplatesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 251 ?
                                SensorsItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 261 ?
                                ServicesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 271 ?
                                SessionsItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 281 ?
                                TrailersItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 291 ?
                                UsersItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 311 ?
                                VehiclesItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 321 ?
                                LinkUnlinkItems.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 501 ?
                                GarageItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 526 ?
                                ExpenseTypeItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 541 ?
                                ExpenseSubTypeItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                this.state.itemSearch == 551 ?
                                AreaItem.map(option =>(<MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem> )) :
                                null
                            }
                            {/* {itemTypes.map(option => (
                                <MenuItem value={option.key}> {this.props.translate(option.name)}</MenuItem>
                            ))} */}
                        </TextField> 
                        </Grid>
                        )}
                </Grid>

        </Grid>
       
        {tableData && !this.state.loader ?  (
             <Table
                rows={tableData || []}
                pagination={this.setPagination(this.state.pagData)}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}                
                rowsPerPage={20}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.ServerSetting}
                themecolors={this.props.themecolors}
                isRowIcons={false}
                isCursoPointer
                setSerialNo
                // actionlogsLabel
                translate={this.props.translate}
                rowDefinition={[
                    {
                    id: 'actionDetails',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('actionDetails')
                    },
                    {
                    id: 'actionTime',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('actionTime')
                    },
                    // {
                    // id: 'host',
                    // numeric: false,
                    // disablePadding: false,
                    // label: this.props.translate('host')
                    // },
                    // {
                    // id: 'ipAddress',
                    // numeric: false,
                    // disablePadding: false,
                    // label: this.props.translate('ipAddress')
                    // },
                ]}
         />
        ): 
        <Loader /> 
    }
        </Fragment>
    
                </div>
              </Layout>
        )}
}

const mapStateToProps = state => ({
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    themecolors: state.themeColors
  })
  
export default connect(mapStateToProps)(withLocalize(Logs))
  