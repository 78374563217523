import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler, postionAttributes } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import MenuItem from '../../Components/common/MenuItem'
import Button from '../../Components/common/Button'
import Checkbox from '../../Components/common/CheckboxPermissions'
import Notifications from 'react-notification-system-redux'
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import { Container } from './Example'
import SearchItems from '../../Components/Devices/searchItems'
import {
  addComputedAttribute,
  getComputedAttributes
} from '../../Actions/ComputedAttributes'
import Autocomplete from '../../Components/common/Autocomplete'
import ReactHtmlParser from 'react-html-parser'
import Tooltip from '../../Components/common/Tooltip'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import instance from '../../axios'
import HtmlParser from 'react-html-parser'
import { center } from '@turf/turf'

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: '',
  attributes:{copyFromLast:false}
}

class Parameters extends Component {
  constructor (props) {
    super(props)
    this.state = {
      parameterForm: { ...ComputedAttributesDataFormat },
      mode: '',
      isVisible: true,

      model: false,
      assignMode: '',
      currentMaintenance: '',
      entityId: 0,
      repeatCall: false,
      expressionMode: 'dnd',

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      //selctedDeviceAttributes: [],
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    }
  }

  componentWillUnmount () {
    this.setState({
      parameterForm: { ...ComputedAttributesDataFormat },
      mode: '',

      model: false,
      assignMode: '',
      currentMaintenance: '',
      entityId: 0,

      result: '',

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
        }
      )
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    )
  }

  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props.logInUser, 1, this.state.pagesize, true)
      }
    )
  }

  fetchData = (logInUser, page, perPage, reset) => {
    let searchText = this.state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = this.state.itemPagination.items
    if (reset) {
      items = []
    }

    // fetch(`/api/attributes/computed/get?userId=${logInUser.id}&category=elogic&all=true&page=${page}&limit=${perPage}${searchText}`)
    instance({
      url: `/api/attributes/computed/get`,
      method: `GET`,
      params: {
        userId: logInUser.id,
        category: 'elogic',
        all: true,
        page: page,
        limit: perPage,
        search: this.state.searchText
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then(res => {
        this.setState(
          {
            itemPagination: {
              ...res,
              items: items.concat(res.data)
            },
            currentPage: res.hasNext ? res.page + 1 : res.page
          },
          () => {
            this.props.dispatch(getComputedAttributes(items.concat(res.data)))
          }
        )
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch(error => {
        // errorHandler(error,this.props.dispatch)
      })
  }

  assignModal = (assignMode, currentMaintenance) => {
    this.setState({
      assignMode,
      currentMaintenance,
      selectEntity: '',
      model: true
    })
  }

  selectEntity = e => {
    this.setState({
      selectEntity: e
    })
  }

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === 'unit') {
      let obj = {
        deviceId: this.state.selectEntity.id,
        attributeId: this.state.currentMaintenance.id
      }
      this.assignParameters(obj)
    } else if (this.state.assignMode && this.state.assignMode === 'user') {
      let obj = {
        userId: this.state.selectEntity.id,
        attributeId: this.state.currentMaintenance.id
      }
      this.assignParameters(obj)
    } else if (this.state.assignMode && this.state.assignMode === 'group') {
      let obj = {
        groupId: this.state.selectEntity.id,
        attributeId: this.state.currentMaintenance.id
      }
      this.assignParameters(obj)
    }
  }

  assignParameters = obj => {
    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: `POST`,
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.ok) {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('attributeAssignedSuccessfully'),
            autoDismiss: 10
          })
        )
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
        const error1 = {
          ...error,
          message: HtmlParser(error?.message)[0]
        }
        errorHandler(error1, this.props.dispatch)
        /* e.text().then(err => {
          if (err && err.includes('Duplicate entry')) {
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate('alreadyAssigned'),
                autoDismiss: 10
              })
            )
          } else {
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate('somthingWentWrongMessage'),
                autoDismiss: 10
              })
            )
          }
        }) */
      })
  }

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: '',
      commandTitle: '',
      currentMaintenance: ''
    })
  }

  openCreateFrom = () => {
    this.setState(
      { mode: '', parameterForm: { ...ComputedAttributesDataFormat } },
      () => {
        this.setState({
          mode: 'create',
          selectEntity: '',
          result: '',
          parameterForm: {
            ...ComputedAttributesDataFormat
          }
        })
      }
    )
  }

  editParameterForm = parameterForm => {
    this.setState({ mode: '', parameterForm: {} }, () => {
      let obj = JSON.parse(JSON.stringify(parameterForm))

      if (obj.type === 'totalDistance') {
        obj.start = parseFloat(obj.start / 1000)
        obj.period = parseFloat(obj.period / 1000)
      } else if (obj.type === 'hours') {
        obj.start = parseFloat(obj.start / (1000 * 3600))
      } else if (obj.type === 'date') {
        obj.start = 1
      }

      delete obj.progress
      this.setState({
        mode: 'update',
        selectEntity: '',
        result: '',
        parameterForm: {
          ...obj
        }
      })
    })
  }
  closeCreateFrom = () => {
    this.setState({
      mode: '',
      parameterForm: ''
      //currentMaintenance: '',
      //result: ''
    })
  }

  handleChange = (name, value) => {
    let v = value
    if(name ==='copyFromLast'){
      this.setState(
        {
          parameterForm: {
            ...this.state.parameterForm,
            attributes:{
              [name]: v
            }  
          }
        },
        () => this.checkRequiredFields()
      )
    }else{
    this.setState(
      {
        parameterForm: {
          ...this.state.parameterForm,
          [name]: v
        }
      },
      () => this.checkRequiredFields()
    )
    }
  }
  checkRequiredFields = () => {
    let { description, expression, type, attribute } = this.state.parameterForm
    if (description && type && expression && attribute) {
      this.setState({
        isVisible: false
      })
    } else {
      this.setState({
        isVisible: true
      })
    }
  }
  loadAttributes = value => {
    this.setState(
      {
        repeatCall: true,
        selectEntity: value,
        selctedDeviceAttributes: [],
        result: ''
      },
      () => {
        if (
          this.props.deviceRelatedData &&
          this.props.deviceRelatedData[value.id] &&
          this.props.deviceRelatedData[value.id].exists
        ) {
          this.setState({
            selctedDeviceAttributes: Object.keys(
              this.props.deviceRelatedData[value.id].attributes
            ).filter(e => !['unknown'].includes(e))
          })
        }
      }
    )
    // this.props.deviceRelatedData[value.id]
  }

  playResult = items => {
    let expression = ''

    if (items.length && (items.length % 2 === 1 || items.length > 2)) {
      items.map(item => {
        expression += ' ' + item.name
        return null
      })
      this.setState(
        {
          parameterForm: {
            ...this.state.parameterForm,
            expression
          },
          currentMaintenance: {
            ...this.state.parameterForm,
            expression
          }
        },
        () => {
          this.runTest()
          this.checkRequiredFields()
        }
      )
    } else {
      this.setState(
        {
          result: '',
          parameterForm: {
            ...this.state.parameterForm,
            expression
          },
          currentMaintenance: {
            ...this.state.parameterForm,
            expression
          }
        },
        () => {
          this.checkRequiredFields()
        }
      )
    }
  }

  /* handleChangeAttributes = (name, value) => {
    if (value) {
      this.setState({
        parameterForm: {
          ...this.state.parameterForm,
          attributes: {
            ...this.state.parameterForm.attributes,
            [name]: value
          }
        }
      })
    } else {
      if (this.state.parameterForm && this.state.parameterForm.attributes) {
        let attributes = { ...this.state.parameterForm.attributes }
        delete attributes[name]
        this.setState({
          parameterForm: {
            ...this.state.parameterForm,
            attributes: {
              ...attributes
            }
          }
        })
      }
    }
  } */

  submitForm = () => {
    let obj = JSON.parse(JSON.stringify(this.state.parameterForm))

    if (obj.type === 'totalDistance') {
      obj.start = parseFloat(obj.start * 1000)
      obj.period = parseFloat(obj.period * 1000)
    } else if (obj.type === 'hours') {
      obj.start = parseFloat(obj.start * (1000 * 3600))
    } else if (obj.type === 'date') {
      obj.start = 1
    }

    delete obj.check

    let params = ''
    if (this.state.mode === 'update') {
      params = obj.id
    }
    this.setState({
      isVisible: true
    })
    if (obj) {
      // fetch(`/api/attributes/computed/${params}`, {
      //   method: `${this.state.mode === 'update' ? 'PUT' : 'POST'}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${this.state.mode === 'update' ? 'PUT' : 'POST'}`,
        data: {
          ...obj
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then(attribute => {
          this.props.dispatch(addComputedAttribute(attribute))
          if (this.state.selectEntity && this.state.selectEntity.id) {
            const obj = {
              deviceId: this.state.selectEntity.id,
              attributeId: attribute.id
            }
            this.assignParameters(obj)
          }
          if (this.state.mode === 'update') {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('computedAttributeIsUpdated'),
                autoDismiss: 10
              })
            )
          } else {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('computedAttributeIsCreated'),
                autoDismiss: 10
              })
            )
          }
          this.closeCreateFrom()
          //   })
          // }
          // else{
          //   throw response
          // }
        })
        .catch(error => {
          errorHandler(error, this.props.dispatch)
        })
    }
  }

  /* runTest = () => {
    let obj = {
      deviceId: this.state.selectEntity.id,
      attributeId: this.state.currentMaintenance.id
    }
  } */
  runTest = () => {
    let body = { ...this.state.currentMaintenance }

    delete body.check

    let id = (this.state.selectEntity && this.state.selectEntity.id) || 0

    if (id && this.state.repeatCall) {
      this.setState({ result: '...', repeatCall: false })
      // fetch('/api/attributes/computed/test?deviceId=' + id, {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...body
      //   })
      // })
      instance({
        url: `/api/attributes/computed/test`,
        method: `POST`,
        params: {
          deviceId: id
        },
        data: { ...body }
      })
        // .then(response => {
        //   if (response.ok) {
        //     if (response.status === 200) {
        //       response.json()
        .then(result => {
          // if (result.status === 'success') {
          this.setState({ result: 'Result: ' + result })
          // } else {
          //   this.setState({ result: 'Result: ' + result.message })
          // }
        })
        //     }
        //   } else {
        //     throw response
        //   }
        // })
        .catch(error => {
          const error1 = {
            ...error,
            message: HtmlParser(error?.message)[0]
          }
          errorHandler(error1, this.props.dispatch)
          let message = error1.message.split(':')[1]
          this.setState({ result: 'Result: ' + message.replace(')', '') })
        })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       this.setState({ result: 'Result: ' + err })
      //     })
      //   }
      // })
    }
  }

  changeBuilder = (event, expressionMode) => {
    console.log(expressionMode)
    this.setState({ expressionMode })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )

    if (checkPrivileges('attribute')) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            closeCreateFrom={this.closeCreateFrom}
            editParameterForm={this.editParameterForm}
            fetchMoreItems={this.fetchMoreItems}
            searchItems={this.searchItems}
            {...this.state}
          >
            <div className='main-content-page'>
              <div
                style={{
                  paddingTop: 10
                }}
              >
                {this.state.mode === 'create' ||
                this.state.mode === 'update' ? (
                  <CreateParameterForm
                    assignModal={this.assignModal}
                    submitForm={this.submitForm}
                    mode={this.state.mode}
                    closeCreateFrom={this.closeCreateFrom}
                    // handleChangeAttributes={this.handleChangeAttributes}
                    form={this.state.parameterForm}
                    handleChange={this.handleChange}
                    loadAttributes={this.loadAttributes}
                    selectEntity={this.state.selectEntity}
                    selctedDeviceAttributes={this.state.selctedDeviceAttributes}
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    playResult={this.playResult}
                    result={this.state.result}
                    changeBuilder={this.changeBuilder}
                    expressionMode={this.state.expressionMode}
                    isVisible={this.state.isVisible}
                  />
                ) : null}
                {this.state.model ? (
                  <Dialog
                    overflow={'visible'}
                    isVisableBtn={true}
                    headerActions={
                      <Button
                        onClick={this.submitAssignModel}
                        variant='outlined'
                        size='small'
                      >
                        {this.props.translate('assignElogic')}
                      </Button>
                    }
                    leftActions={
                      this.state.assignMode === 'unit' ? (
                        <Button
                          onClick={this.runTest}
                          variant='outlined'
                          size='small'
                        >
                          {this.props.translate('runTest')}
                        </Button>
                      ) : null
                    }
                    open={this.state.model}
                    onClose={this.closeAssignModal}
                    title={this.props.translate('assignElogic')}
                  >
                    <div style={{ padding: 16 }}>
                      <TextField
                        id='name'
                        margin='dense'
                        label={this.props.translate('sharedDescription')}
                        variant='outlined'
                        fullWidth
                        readOnly
                        value={
                          this.state.currentMaintenance &&
                          this.state.currentMaintenance.description
                        }
                      />

                      {this.state.assignMode === 'unit' ? (
                        <SearchItems
                          api='devices'
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate('selectedTrackers')}
                        />
                      ) : null}

                      {this.state.assignMode === 'user' ? (
                        <SearchItems
                          api='users'
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate('searchUsers')}
                        />
                      ) : null}

                      {this.state.assignMode === 'group' ? (
                        <SearchItems
                          api='groups'
                          fill
                          isMulti={false}
                          onChange={this.selectEntity}
                          value={this.state.selectEntity}
                          placeholder={this.props.translate('searchGroup')}
                        />
                      ) : null}
                      {this.state.result && (
                        <div
                          style={{
                            color: this.props.themecolors['error'],
                            fontWeight: 700,
                            fontStyle: 'italic'
                          }}
                        >
                          {ReactHtmlParser(this.state.result)}
                        </div>
                      )}
                    </div>
                  </Dialog>
                ) : null}
                {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noParameterSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const CreateParameterForm = ({
  form,
  handleChange,
  translate,
  selectEntity,
  // handleChangeAttributes,
  selctedDeviceAttributes,
  loadAttributes,
  submitForm,
  closeCreateFrom,
  mode,
  assignModal,
  themecolors,
  result,
  playResult,
  changeBuilder,
  expressionMode,
  isVisible
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('sharedComputedAttributes')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='description'
            margin='dense'
            label={translate('sharedDescription')}
            variant='outlined'
            fullWidth
            value={form.description}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            suggestions={postionAttributes}
            fieldName='attribute'
            translate={translate}
            label={translate('sharedAttribute')}
            value={form.attribute}
            handleChange={handleChange}
            canRemove={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
            canAssign={
              checkPrivileges('attributeCreate') ||
              checkPrivileges('attributeUpdate')
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='type'
            select
            label={translate('sharedType')}
            value={form.type}
            onChange={e => handleChange('type', e.target.value)}
            margin='dense'
            fullWidth
          >
            {att_valueType.map(option => (
              <MenuItem key={option.Type} value={option.Type}>
                {translate(option.Type)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} style={{display:'flex',alignItems:'center'}}>
            <Checkbox
            canAssign
            canRemove
            translate={translate}
            checked={form.attributes.copyFromLast}
            onChange={e =>
              handleChange('copyFromLast', e.target.checked)
            }
            label={translate('copyFromLast')}
          />
        </Grid>
      </Grid>
      <h4 className='page-title' style={{ marginTop: 20 }}>{translate('sharedExpression')}</h4>
      <AppBar
        position='static'
        color='inherit'
        style={{ background: 'none', boxShadow: 'none' }}
      >
        <Tabs
          value={expressionMode || 'dnd'}
          onChange={changeBuilder}
          aria-label='simple tabs example'
          indicatorColor='primary'
          textColor='primary'
          scrollButtons='auto'
          variant='scrollable'
          classes={{
            root: 'custom-tabs-root',
            flexContainer: 'custom-tabs',
            scrollable: 'custom-tabs-scrollable',
            indicator: 'custom-indicator',
            scrollButtons: 'scrollable-buttons'
          }}
        >
          <Tab
            classes={{
              root: 'custom-tab-button custom-tab-button-1',
              selected: 'custom-tab-button-selected'
            }}
            value='dnd'
            label={translate('dragDropBuilder')}
          />
          <Tab
            classes={{
              root: 'custom-tab-button custom-tab-button-1',
              selected: 'custom-tab-button-selected'
            }}
            value='custom'
            label={translate('directExpression')}
          />
        </Tabs>
      </AppBar>
      <div className='dnd-ebuilder-wrapper'>
        {expressionMode === 'dnd' ? (
          <DndProvider backend={HTML5Backend}>
            <Container
              playResult={playResult}
              result={result}
              form={form}
              themecolors={themecolors}
              selctedDeviceAttributes={selctedDeviceAttributes}
              deviceSelector={
                <div style={{ top: -4, position: 'relative' }}>
                  <SearchItems
                    api='devices'
                    fill
                    isMulti={false}
                    onChange={loadAttributes}
                    value={selectEntity}
                    placeholder={translate('selectedTrackers')}
                  />
                </div>
              }
            />
          </DndProvider>
        ) : null}
        {expressionMode === 'custom' ? (
          <Tooltip
            title={translate('eLogicParameterHint')}
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <TextField
              id='expression'
              label={translate('sharedExpressionLabel')}
              value={form.expression}
              onChange={e => handleChange('expression', e.target.value)}
              variant='outlined'
              margin='dense'
              multiline
              rows={3}
              rowsMax={5}
              fullWidth
            />
          </Tooltip>
        ) : null}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        {mode !== 'create' ? (
          <>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('unit', form)}
            >
              {translate('assignUnit')}{' '}
            </Button>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('user', form)}
            >
              {translate('assignUser')}{' '}
            </Button>
            {/* <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('group', form)}
            >
              {translate('assignGroup')}{' '}
            </Button> */}
          </>
        ) : null}

        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('attributeCreate') && (
          <Button size='small' onClick={submitForm} disabled={isVisible}>
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  computedAttributes: state.computedAttributes,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Parameters)))

const att_valueType = [
  {
    Type: 'string'
  },
  { Type: 'number' },
  { Type: 'boolean' }
]
