import React, { PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./index.scss";
import { Icon, Tooltip } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { checkPrivileges, errorHandler } from "../../Helpers";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Style from "style-it";
import MenuBar from "./navbar";
import moment from "moment";

import { ReactComponent as MonitorIcon } from "./../../assets/nav/monitor.svg";
import { ReactComponent as DashboardIcon } from "./../../assets/nav/dashboard.svg";
import { ReactComponent as EmployeeIcon } from "./../../assets/nav/employees-one.svg";
import { ReactComponent as BinderIcon } from "./../../assets/nav/binders.svg";
import { ReactComponent as TrashIcon } from "./../../assets/nav/trash.svg";
import { ReactComponent as GeofenceIcon } from "./../../assets/nav/geofence.svg";
import { ReactComponent as MessageIcon } from "./../../assets/nav/message.svg";
import { ReactComponent as MailIcon } from "./../../assets/nav/mail.svg";
import { ReactComponent as TracksIcon } from "./../../assets/nav/tracks.svg";
import { ReactComponent as UnitsIcon } from "./../../assets/nav/units.svg";
import { ReactComponent as VehicleIcon } from "./../../assets/nav/vehicle.svg";
import { ReactComponent as StaffIcon } from "./../../assets/nav/staff.svg";
import { ReactComponent as TimeMachineIcon } from "./../../assets/nav/time-machine.svg";
import { ReactComponent as ReportIcon } from "./../../assets/nav/reports.svg";
import { ReactComponent as ReportHistoryIcon } from "./../../assets/nav/report-history.svg";
import { ReactComponent as CalendarIcon } from "./../../assets/nav/calendar.svg";
import { ReactComponent as NotificationIcon } from "./../../assets/nav/notifications.svg";
import { ReactComponent as UsersIcon } from "./../../assets/nav/user-two.svg";
import { ReactComponent as UserIcon } from "./../../assets/nav/user.svg";
import { ReactComponent as CommandIcon } from "./../../assets/nav/command.svg";
import { ReactComponent as MaintenanceIcon } from "./../../assets/nav/spanner.svg";
import { ReactComponent as InvoiceIcon } from "./../../assets/nav/invoice.svg";
import { ReactComponent as ParameterIcon } from "./../../assets/nav/algorithm.svg";
import { ReactComponent as actionLog } from "./../../assets/nav/actionLog.svg";
import { ReactComponent as Garage } from "./../../assets/nav/garage-icon.svg";
import { ReactComponent as Area } from "./../../assets/nav/area-icon.svg";
import { ReactComponent as Costing } from "./../../assets/nav/costing.svg";
import { ReactComponent as Services } from "./../../assets/nav/car-expence.svg";
import { ReactComponent as ModelIcon } from "./../../assets/nav/car-modal.svg";
import { ReactComponent as ResourceIcon } from "./../../assets/nav/resources.svg";
import { ReactComponent as DeliveryIcon } from "./../../assets/nav/delivery-note.svg";
import { ReactComponent as MissedOpportunity } from "./../../assets/nav/mised-opportunity-01.svg";

import instance from "../../axios";
const emptyLink = "#";
const mapStateToProps = (state) => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  dashboards:
    state.dashboards?.data?.map((item) => ({
      ...item,
      icon: DashboardIcon,
    })) || [],
});

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentLang: "en",
    };
  }
  componentWillReceiveProps(n) {
    if (
      n.activeLanguage &&
      n.activeLanguage.code &&
      n.activeLanguage.code !== this.props.activeLanguage.code
    ) {
      this.setState({ currentLang: "" }, () => {
        this.setState({ currentLang: n.activeLanguage.code });
      });
    }
  }

  render() {
    return this.props.logInUser &&
      this.props.logInUser.id &&
      localStorage.getItem("frontPrivileges") &&
      this.state.currentLang ? (
      <HeaderInner {...this.props} />
    ) : null;
  }
}
class HeaderInner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapseMenu: true,
      privilagesProcess: false,
      navigation: {
        // maintenance_dashboard: {
        //   checked: checkPrivileges("maintenanceDashboard"),
        //   // checked: true,
        //   href: "/maintenance_dashboard",
        //   state: "maintenance_dashboard",
        //   icon: DashboardIcon,
        //   title: this.props.translate("sharedMaintenance"),
        // },
        dashboard: {
          checked: this.props.logInUser.customerType !== 1,
          href: "/dashboard",
          state: "dashboard",
          icon: DashboardIcon,
          title: this.props.translate("dashboard"),
        },
        invoice: {
          checked: checkPrivileges("invoice"),
          href: "/invoice",
          state: "invoice",
          icon: InvoiceIcon,
          title: this.props.translate("sharedInvoice"),
        },
        deliveryNote: {
          checked: checkPrivileges("deliverynote") && (this.props?.ServerSetting?.contractType === 1),
          href: "/deliveryNote",
          state: "deliveryNote",
          icon: DeliveryIcon,
          title: this.props.translate("deliveryNote"),
        },
        vehicles: {
          checked: checkPrivileges("vehicle"),
          href: "/vehicles",
          state: "vehicles",
          icon: VehicleIcon,
          title: this.props.translate("Fleet"),
        },

        monitoring: {
          checked: checkPrivileges("device"),
          href: "/monitoring",
          state: "monitoring",
          icon: MonitorIcon,
          title: this.props.translate("Monitoring"),
        },

        
        maintenance: {
          checked: checkPrivileges("maintenance"),
          href: "/maintenance",
          state: "maintenance",
          icon: MaintenanceIcon,
          title: this.props.translate("sharedMaintenance"),
        },
        missedOpportunities : {
          checked: checkPrivileges("aiPms"),
          href: "/missed-opportunities",
          icon: MissedOpportunity,
          title: this.props.translate("missedOpportunities"),
        },
        geofences: {
          checked: checkPrivileges("geofence"),
          href: "/geofences",
          state: "geofences",
          icon: GeofenceIcon,
          title: this.props.translate("Geofences"),
        },
        timemachine: {
          checked: checkPrivileges("timeLapse"),
          href: "/timemachine",
          state: "timemachine",
          icon: TimeMachineIcon,
          title: this.props.translate("timeMachine"),
        },
        reports: {
          checked: checkPrivileges("report"),
          href: "/reports",
          state: "reports",
          icon: ReportIcon,
          title: this.props.translate("Reports"),
        },
        events: {
          checked: checkPrivileges("notification"),
          href: "/events",
          state: "events",
          icon: NotificationIcon, // EventsIcon,
          title: this.props.translate("reportEvents"),
        },
        garage: {
          checked: checkPrivileges("garage"),
          href: "/garage",
          state: "garage",
          icon: Garage,
          title: this.props.translate("workShops"),
        },
        users: {
          checked: checkPrivileges("user"),
          href: "/users",
          state: "users",
          icon: UsersIcon,
          title: this.props.translate("Users"),
        },
        drivers: {
          checked: checkPrivileges("driver"),
          href: "/drivers",
          state: "drivers",
          icon: EmployeeIcon,
          title: this.props.translate("Staff"),
        },
        services: {
          checked: checkPrivileges("expensetypeTab"),
          href: "/services",
          state: "services",
          icon: Services,
          title: this.props.translate("contract"),
        },
        model: {
          checked: checkPrivileges("model"),
          href: "/model",
          state: "model",
          icon: ModelIcon,
          title: this.props.translate("models"),
        },
        
        area: {
          checked: checkPrivileges("area"),
          href: "/area",
          state: "area",
          icon: Area,
          title: this.props.translate("areas"),
        },
        costing: {
          checked: checkPrivileges("maintenanceCosting"),
          href: "/costing",
          state: "costing",
          icon: Costing,
          title: this.props.translate("costing"),
        },
        
        units: {
          checked: checkPrivileges("device"),
          href: "/units",
          state: "units",
          icon: UnitsIcon,
          title: this.props.translate("trackers"),
        },
        // resources: {
        //   checked: checkPrivileges("group"),
        //   href: "/resources",
        //   state: "resources",
        //   icon: ResourceIcon,
        //   title: this.props.translate("resources"),
        // },        
        trailer: {
          checked: checkPrivileges("binder"),
          href: "/binders",
          state: "binders",
          icon: BinderIcon,
          title: this.props.translate("binder"),
        },
        staff: {
          checked: false,
          href: "/staff",
          state: "staff",
          icon: StaffIcon,
          title: this.props.translate("staffs"),
        },
        
        calendars: {
          checked: false, //checkPrivileges('calendar'),
          href: "/calendars",
          state: "calendars",
          icon: CalendarIcon,
          title: this.props.translate("sharedCalendar"),
        },
        
        notifications: {
          checked: false, //checkPrivileges('device'),
          href: "/notifications",
          state: "notifications",
          icon: NotificationIcon,
          title: this.props.translate("Notifications"),
        },
        
        // reportsHistory: {
        //   checked: checkPrivileges('report'),
        //   href: '/reportsHistory',
        //   icon: ReportHistoryIcon,
        //   title: this.props.translate('reportHistory')
        // },
        messages: {
          checked: checkPrivileges("position"),
          href: "/Dlogs",
          state: "Dlogs",
          icon: MessageIcon,
          title: this.props.translate("Dlogs"),
        },
        tracks: {
          checked: checkPrivileges("track"),
          href: "/tracks",
          state: "tracks",
          icon: TracksIcon,
          title: this.props.translate("Tracks"),
        },
        
        jobs: {
          checked: checkPrivileges("job"),
          href: "/schedules",
          state: "schedules",
          icon: MailIcon,
          title: this.props.translate("sharedSchedule"),
        },
        task: {
          checked: false,
          href: "/task",
          state: "task",
          icon: DashboardIcon,
          title: "Task",
        },
        commands: {
          checked: checkPrivileges("command"),
          href: "/commands",
          state: "commands",
          icon: CommandIcon,
          title: this.props.translate("sharedSavedCommand"),
        },
        parameters: {
          checked: checkPrivileges("attribute"),
          href: "/parameters",
          state: "parameters",
          icon: ParameterIcon,
          title: this.props.translate("sharedComputedAttributes"),
        },
        trash: {
          checked: true,
          href: "/trash",
          state: "trash",
          icon: TrashIcon,
          title: this.props.translate("trash"),
        },
        actionlogs: {
          checked: true,
          href: "/actionlogs",
          state: "actionlogs",
          icon: actionLog,
          title: this.props.translate("actionLogs"),
        },
      },
      rightNav: {},
    };
    this.collapseMenu = this.collapseMenu.bind(this);
  }

  componentWillReceiveProps(n) {
    this.setState({
      navigation: {
        ...this.state.navigation,
        events: { ...this.state.navigation.events, count: n.notificationCount },
      },
    });
  }

  componentWillMount() {
    this.setState({
      privilagesProcess: false,
    });
  }
  handleUserSetting = () => {
    this.props.history.push("/accountManagements");
  };
  handleUserChangedPassword = () => {
    let changedPass = 4;
    this.props.history.push("/accountManagements", [changedPass]);
  };
  collapseMenu() {
    document.body.classList.toggle("header-open");
  }

  VisibleLeftMenuItem(items) {
    return (
      <MenuBar {...this.props} items={items} collapseMenu={this.collapseMenu} />
    );
  }

  AllMenuItem(items) {
    return Object.keys(items).map((userData, i) => {
      return (
        <li key={i}>
          <label href={items[userData].href} title={items[userData].title}>
            <input
              type="checkbox"
              name={userData}
              onChange={this.showHideMenuItems(userData)}
              checked
            />
            {items[userData].title}
          </label>
        </li>
      );
    });
  }

  refreshPriviliges = () => {
    this.setState(
      {
        privilagesProcess: true,
      },
      () => {
        // fetch(`/api/privileges?roleId=${this.props.logInUser.roleId}`, {
        //   method: 'Get'
        // })
        instance({
          url: `/api/privileges`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            roleId: this.props.logInUser.roleId,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((privileges) => {
            let privilegeKeys = [];
            privileges.map((item) => {
              privilegeKeys.push(item.key);
              return null;
            });
            localStorage.setItem(
              "frontPrivileges",
              JSON.stringify({ ...privilegeKeys })
            );
            //   })
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
    );
  };
  goToLogoutpage = () => {
    localStorage.removeItem("backtoAdmin");
    this.props.history.push("/logout");
  };
  switchUserAccount = () => {
    this.props.history.push(
      "/logout",
      this.props.ServerSetting.attributes.adminUrl
    );
  };

  collapseSidebar = () => {
    this.props.collapseSidebar();
  };

  render() {
    if (this.props.ServerSetting) {
      const { attributes } = this.props.ServerSetting;
      return (
        <Style>
          {`
          .fms-header {
            background-color:  ${this.props.themecolors.themeDarkColor};
            color:  ${this.props.themecolors.themeInverse};
            border-bottom: 1px solid ${this.props.themecolors.menuActiveBackground};
            padding: 5px;
            padding-right: 0;
          }
				.nav .active {
					color:  ${this.props.themecolors.themeDarkColor};
        }
        .nav .user-avatar {
          background-color:  ${this.props.themecolors.themeDarkColor};
        }
				.sub-menu label:hover, .sub-menu .active a, .sub-menu a:hover {
					color:  ${this.props.themecolors.themeDarkColor}
        }
        .navbar-menu .navbar-menu-item:hover,
        .navbar-menu .navbar-menu-item.active {
          color:  ${this.props.themecolors.menuActiveColor}; 
          background-color:  ${this.props.themecolors.menuActiveBackground}; 
        }
        .navbar-menu-item .counter {
          background: ${this.props.themecolors.themeLightInverse};
          color: ${this.props.themecolors.themeLightColor}
        }

			`}
          <div>
            <header className="fms-header">
              <nav className="nav-container">
                <div className="logo-section">
                  <MenuIcon style={{ display: "none" }}>menu</MenuIcon>
                  <button
                    style={{
                      color: this.props.themecolors.themeInverse,
                      background: this.props.themecolors.themeDarkColor
                    }}
                    className='collapse-header'
                    onClick={this.collapseMenu}
                  >
                    <MenuIcon style={{width: 26, height: 26}}>menu</MenuIcon>
                  </button>
                  <NavLink to="/">
                    <img
                      className="logo"
                      src={attributes.logo ? attributes.logo : ""}
                      alt=""
                    />
                  </NavLink>
                </div>
                <div className="left-nav">
                  <ul className="nav">
                    {this.VisibleLeftMenuItem(this.state.navigation)}
                  </ul>
                </div>
                <div className="right-nav">
                  <div>
                    <ul className="nav">
                      {localStorage.getItem("backtoAdmin") ? (
                        <li>
                          <Tooltip
                            title={
                              this.props.translate(
                                "youAreTemporarilyLoggedInAsAnotherUser"
                              ) +
                              " " +
                              this.props.translate("switch") +
                              " " +
                              this.props.translate("backToYourAccount")
                            }
                            classes={{
                              popper: "menu-popper",
                              tooltip: "menu-popper-tooltip",
                            }}
                          >
                            <a
                              href={emptyLink}
                              onClick={this.switchUserAccount}
                              title=""
                            >
                              <Icon>logout</Icon>
                            </a>
                          </Tooltip>
                        </li>
                      ) : null}
                      {/* <li>
                          <Tooltip
                              title={ this.props.notificationCount +" "+this.props.translate('Notifications') }
                              classes={{
                                popper: 'menu-popper',
                                tooltip: 'menu-popper-tooltip'
                              }}
                            >
                            
                            <NavLink
                              to='/events'
                              style={{position: 'relative'}}
                            >
                              {this.props.notificationCount !== undefined ? <span className="counter"></span> : null}
                              <NotificationIcon fill="currentColor" width={18} height={18} />
                            </NavLink>
                            </Tooltip>
                        </li> */}
                      <li className="has-menu">
                        <a href={emptyLink} title={this.props.logInUser.name}>
                          <UserIcon />
                          <span className="user-menu-name">
                            {this.props.translate("greetings")} {this.props.logInUser.name}
                          </span>
                        </a>
                        <ul className="sub-menu right-menu">
                          <li>
                            <a
                              href={emptyLink}
                              onClick={this.handleUserSetting}
                            >
                              {this.props.translate("settingsUserSetting")}
                            </a>
                          </li>

                          {attributes.privacyPolicyLink && (
                            <li>
                              <a href={attributes.privacyPolicyLink}>
                                {this.props.translate(
                                  "settingsPrivacyPolicyLink"
                                )}
                              </a>
                            </li>
                          )}
                          {attributes.termsLink && (
                            <li>
                              <a href={attributes.termsLink}>
                                {this.props.translate("settingsTermsLink")}
                              </a>
                            </li>
                          )}
                          <li className="divider"></li>
                          <li>
                            <a href={emptyLink} onClick={this.goToLogoutpage}>
                              {this.props.translate("loginLogout")}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </header>
          </div>
        </Style>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(withLocalize(withRouter(Header)));
