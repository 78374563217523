import React from 'react'
import { withLocalize } from "react-localize-redux";
import { withSnackbar } from "notistack";
import { connect } from 'react-redux'
import { ReactComponent as MessageIcon } from "../../assets/messageicon.svg";
import SupportModal from './SupportModal'
import Notifications from "react-notification-system-redux";
import { errorHandler } from "../../Helpers";
import instance from "../../axios";

const supportDataFormat = (data, user) => ({
  name: user && user.name ? user.name : "",
  email: user && user.email ? user.email : "",
  subject: data?.subject,
  contact: user && user.phone ? user.phone : "",
  country: user && user.country ? user.country : "",
  message: data?.message
});
const formDefault = {
  subject: "",
  message: ""
};
class Support extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        subject: this.props?.openModal
          ? "Let's Go Green with NTSC Expert Help"
          : formDefault.subject,
        message: ""
      },
      openSupportModal: false,
      disabledUserBtn: true,
      supportStatus: "",
    }
    this.handleChange = this.handleChange.bind(this);
    this.onCloseSubModal = this.onCloseSubModal.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  onOpenModal = () => {
    this.setState({
      openSupportModal: true,
    });
  };
  onCloseSubModal = () => {
    this.setState({
      openSupportModal: false,
      form: {},
      disabledUserBtn: true,
    });
    if (this.props.handleCloseMessage) {
      this.props.handleCloseMessage();
    }
  }
  handleChange(name, event) {
    const { target } = event;
    let value = target.value.toString();
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        this.checkRequiredFields()
      );
  }
  checkRequiredFields() {
    let { subject, message} = this.state.form;
      if (subject && message) {
        this.setState({
          disabledUserBtn: false,
        });
      } else {
        this.setState({
          disabledUserBtn: true,
        });
      }
  }
  csm_submit = () => {
    let { form } = this.state;
    this.setState({
      supportStatus: (
        <span className="loading-status-text">
          <LoadingIndicator /> Sending
        </span>
      ),
    },()=>this.onCloseSubModal());
    const obj = supportDataFormat(form,this.props?.logInUser);
    this.setState({
      disabledUserBtn: true,
    });
    instance({
      url: `api/feedbacks`,
      method: `POST`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        if(response) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("emailSentSuccessfully"),
              autoDismiss: 10,
            })
          );
          this.setState({ openSupportModal: false, supportStatus: "" });
        }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        this.setState({ supportStatus: "" });
      });
  };
  
  render () {
    return (
      <>
        <MessageIcon 
          style={{ 
            position: 'fixed', bottom: '65px', right: '20px', zIndex: 9990, cursor: 'pointer', width: '45px' }}
            onClick={this.onOpenModal}
        >
        </MessageIcon>
        {this.props.openModal === true || this.state.openSupportModal ? (
          <>
            <SupportModal
              {...this.state}
              {...this.props}
              dispatch={this.props.dispatch}
              onCloseSubModal={this.onCloseSubModal}
              formSubmit={this.csm_submit}
              form={this.state.form}
              handleChange={this.handleChange}
              supportStatus={this.state.supportStatus}
            />
          </>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
});

export default connect(mapStateToProps)(withSnackbar(withLocalize(Support)));

const LoadingIndicator = () => {
  return (
    <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: "0ms" }} />
      <span className="loading-dot" style={{ animationDelay: "160ms" }} />
      <span className="loading-dot" style={{ animationDelay: "320ms" }} />
    </span>
  );
};